import {
  RoomCreateInput,
  RoomResponsiblePersonsConnectFieldInput,
  RoomUpdateInput,
  useCreateRoomsMutation,
  useDeleteRoomsMutation,
  useRoomsListQuery,
  useUpdateRoomsMutation,
} from '../../types/planung-graphql-client-defs';
import { useMemorizedCacheTag } from '../../hooks/useMemorizedCacheTag';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { useCallback, useMemo, useReducer } from 'react';
import { loadingReducer, loadingReducerInitialState } from '../../reducer/loadingReducer';
import { useTranslation } from 'react-i18next';

export type RoomsFormInitialValuesType = {
  uuid?: string;
  name: string;
  position?: string | null;
  roomNumber?: string | null;
  description?: string | null;
  canBeLeased: boolean;
  capacity?: number | null;
  chairs?: number | null;
  tables?: number | null;
  size?: number | null;
  otherInventory?: string | null;
  classroom: boolean;
  barrierFree: boolean;
  canBeDarkened: boolean;
  hasProjector: boolean;
  hasScreen: boolean;
  hasMediaCabinet: boolean;
  hasPiano: boolean;
  building?: { uuid: string; name: string } | null;
  responsiblePersons: { uuid: string; displayNameShort: string }[];
};

export const booleanKeys: string[] = [
  'barrierFree',
  'hasProjector',
  'canBeLeased',
  'hasPiano',
  'hasScreen',
  'hasMediaCabinet',
  'classroom',
  'canBeDarkened',
];

type CheckboxTypes = (typeof booleanKeys)[number];

export const useRooms = () => {
  const context = useMemorizedCacheTag('ROOMS');
  const { pimAuthClaims } = useAuthClaims();
  const { t } = useTranslation();

  const [loadingState, dispatch] = useReducer(loadingReducer, loadingReducerInitialState);

  const [, create] = useCreateRoomsMutation();
  const [, update] = useUpdateRoomsMutation();
  const [, remove] = useDeleteRoomsMutation();

  const [{ data }] = useRoomsListQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
    },
    context,
  });

  const getFormData = useCallback(
    (roomUuid?: string | null): RoomsFormInitialValuesType => {
      const currentRoom = data?.rooms.find((r) => r.uuid === roomUuid);

      return {
        roomNumber: currentRoom?.roomNumber,
        description: currentRoom?.description,
        capacity: currentRoom?.capacity,
        chairs: currentRoom?.chairs,
        tables: currentRoom?.tables,
        building: currentRoom?.building,
        classroom: currentRoom?.classroom ?? true,
        canBeDarkened: currentRoom?.canBeDarkened ?? false,
        hasMediaCabinet: currentRoom?.hasMediaCabinet ?? false,
        hasPiano: currentRoom?.hasPiano ?? false,
        hasProjector: currentRoom?.hasProjector ?? false,
        hasScreen: currentRoom?.hasScreen ?? false,
        barrierFree: currentRoom?.barrierFree ?? false,
        name: currentRoom?.name ?? '',
        canBeLeased: currentRoom?.canBeLeased ?? false,
        position: currentRoom?.position,
        otherInventory: currentRoom?.otherInventory,
        size: currentRoom?.size,
        responsiblePersons:
          currentRoom?.responsiblePersonsConnection.edges.map((value) => ({
            uuid: value.node.uuid,
            displayNameShort: value.node.displayNameShort ?? '',
          })) ?? [],
      };
    },
    [data],
  );

  const roomData = useMemo(() => {
    // to remove null values; to make the filters work
    const rooms = data?.rooms.map((room) => ({ ...room, ...getFormData(room.uuid) }));
    if (data && rooms) data.rooms = rooms;
    return data;
  }, [data, getFormData]);

  const createRoom = async (values: RoomsFormInitialValuesType) => {
    const persons: RoomResponsiblePersonsConnectFieldInput[] =
      values.responsiblePersons &&
      values.responsiblePersons
        .filter((p) => p !== undefined)
        .map((p, i) => {
          return { edge: { order: i }, where: { node: { uuid: p.uuid } } };
        });

    const createInput: RoomCreateInput = {
      position: values.position,
      roomNumber: values.roomNumber,
      description: values.description,
      canBeLeased: values.canBeLeased,
      capacity: values.capacity,
      chairs: values.chairs,
      tables: values.tables,
      size: values.size,
      otherInventory: values.otherInventory,
      classroom: values.classroom,
      barrierFree: values.barrierFree,
      canBeDarkened: values.canBeDarkened,
      hasProjector: values.hasProjector,
      hasScreen: values.hasScreen,
      hasMediaCabinet: values.hasMediaCabinet,
      hasPiano: values.hasPiano,
      name: values.name,
      responsiblePersons: { connect: persons },
      organization: { connect: { where: { node: { uuid: pimAuthClaims.getOrganizationUuid() } } } },
    };
    if (values.building) {
      createInput.building = { connect: { where: { node: { uuid: values.building?.uuid } } } };
    }
    return await create(
      {
        input: createInput,
      },
      context,
    );
  };

  const updateRoom = async (values: RoomsFormInitialValuesType, roomUuid: string) => {
    dispatch({ type: 'SET_LOADING', uuids: [roomUuid] });
    const persons: RoomResponsiblePersonsConnectFieldInput[] =
      values.responsiblePersons &&
      values.responsiblePersons
        .filter((p) => p !== undefined)
        .map((p, i) => {
          return { edge: { order: i }, where: { node: { uuid: p.uuid } } };
        });

    const updateInput: RoomUpdateInput = {
      name: values.name,
      position: values.position,
      roomNumber: values.roomNumber,
      description: values.description,
      canBeLeased: values.canBeLeased,
      capacity: values.capacity,
      chairs: values.chairs,
      tables: values.tables,
      size: values.size,
      otherInventory: values.otherInventory,
      classroom: values.classroom,
      barrierFree: values.barrierFree,
      canBeDarkened: values.canBeDarkened,
      hasProjector: values.hasProjector,
      hasScreen: values.hasScreen,
      hasMediaCabinet: values.hasMediaCabinet,
      hasPiano: values.hasPiano,
      responsiblePersons: [{ disconnect: [{}], connect: persons }],
    };

    if (values.building) {
      updateInput.building = { disconnect: {}, connect: { where: { node: { uuid: values.building?.uuid } } } };
    }

    const res = await update(
      {
        where: { uuid: roomUuid },
        update: updateInput,
      },
      context,
    );
    dispatch({ type: 'REMOVE_LOADING', uuids: [roomUuid] });
    return res;
  };

  const duplicateRooms = async (uuids: string[]) => {
    dispatch({ type: 'SET_LOADING', uuids: uuids });
    const roomData = data?.rooms
      .filter((r) => uuids.includes(r.uuid))
      .map((r) => {
        return getFormData(r.uuid);
      });
    const createResponses = roomData?.map((data) => createRoom(data)) ?? [];
    dispatch({ type: 'REMOVE_LOADING', uuids: uuids });
    return Promise.all(createResponses);
  };

  const deleteRooms = async (uuids: string[]) => {
    dispatch({ type: 'SET_LOADING', uuids: uuids });
    const res = await remove({ uuids: uuids }, context);
    dispatch({ type: 'REMOVE_LOADING', uuids: uuids });
    return res;
  };

  const updateCheckboxes = async ({ type, uuids, value }: { type: CheckboxTypes; uuids: string[]; value: boolean }) => {
    dispatch({ type: 'SET_LOADING', uuids: uuids });
    const res = await update({ where: { uuid_IN: uuids }, update: { [type]: value } }, context);
    dispatch({ type: 'REMOVE_LOADING', uuids: uuids });
    return res;
  };

  const getBooleanLabelKey = (type: CheckboxTypes) => {
    const data: Record<CheckboxTypes, string> = {
      classroom: t('rooms.isClassroom'),
      canBeLeased: t('rooms.canBeLeased'),
      barrierFree: t('rooms.isBarrierFree'),
      canBeDarkened: t('rooms.canBeDarkened'),
      hasProjector: t('rooms.hasProjector'),
      hasScreen: t('rooms.hasScreen'),
      hasMediaCabinet: t('rooms.hasMediaCabinet'),
      hasPiano: t('rooms.hasPiano'),
    };

    return data[type];
  };

  const assignToBuilding = async (uuids: string[], buildingUuid: string) => {
    dispatch({ type: 'SET_LOADING', uuids: uuids });

    const res = await update(
      {
        where: { uuid_IN: uuids },
        update: { building: { disconnect: {}, connect: { where: { node: { uuid: buildingUuid } } } } },
      },
      context,
    );
    dispatch({ type: 'REMOVE_LOADING', uuids: uuids });
    return res;
  };

  const detachFromBuilding = async (uuids: string[]) => {
    dispatch({ type: 'SET_LOADING', uuids: uuids });

    const res = await update(
      {
        where: { uuid_IN: uuids },
        update: { building: { disconnect: {} } },
      },
      context,
    );
    dispatch({ type: 'REMOVE_LOADING', uuids: uuids });
    return res;
  };

  return {
    createRoom,
    updateRoom,
    deleteRooms,
    getFormData,
    roomData,
    updateCheckboxes,
    loadingState,
    getBooleanLabelKey,
    duplicateRooms,
    assignToBuilding,
    detachFromBuilding,
  };
};
