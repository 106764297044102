import { useTranslation } from 'react-i18next';
import { Page } from '../../../components/Page/Page';
import { IconMenu } from '../../../components/IconMenu/IconMenu';
import { IconMenuItem } from '../../../components/IconMenuItem/IconMenuItem';
import { useNavigate } from 'react-router-dom';
import { usePermissionChecker } from '../../../hooks/usePermissionChecker';

export const SubstitutionsSetupPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const permissions = usePermissionChecker();

  return (
    <Page title={`${t(`substitutions.title`, { count: 2 })} ${t('substitutions.setup').toLowerCase()}`}>
      <IconMenu headline={t('common.whatToSetup')}>
        <IconMenuItem
          label={t('schoolEvents.title', { count: 2 })}
          icon={undefined}
          bubbleType={1}
          onClick={() => navigate(t('routes.schoolEvents.slug'))}
          disabled={!permissions?.isOmniAdmin()}
        />
        <IconMenuItem
          label={t('classes.attendanceClass', { count: 2 })}
          icon={undefined}
          bubbleType={1}
          onClick={() => navigate(t('routes.attendanceClass.slug'))}
          disabled={!permissions?.isOmniAdmin()}
        />
      </IconMenu>
    </Page>
  );
};
