import { Card, LazyLoader } from '@bp/ui-components';
import { Suspense, useMemo, useState } from 'react';
import { useMemorizedCacheTag } from '../../hooks/useMemorizedCacheTag';
import styles from './Holidays.module.scss';
import { HolidaysEntriesList } from './HolidaysEntriesList';
import { SchoolYearType } from '../SchoolYear/graphql/types';
import { HolidaysList } from './HolidaysList';
import { useUserConfigContext } from '../../hooks/useUserConfigContext';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { observer } from 'mobx-react-lite';
import { useSchoolYearsQuery } from '../../types/planung-graphql-client-defs';
import { RowSelectionState } from '@tanstack/react-table';

export const Holidays = observer(() => {
  const { pimAuthClaims } = useAuthClaims();
  const context = useMemorizedCacheTag('SCHOOL_YEAR');
  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;

  const [{ data }] = useSchoolYearsQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
    },
    context: context,
  });

  const memoizedData = useMemo((): SchoolYearType[] => {
    return data && data ? data.schoolYears : [];
  }, [data]);

  const [yearUuid, setYearUuid] = useState<string>(
    memoizedData.find((year) => year.uuid === currentSchoolYear?.uuid)?.uuid ?? '',
  );

  const [selectedYear, setSelectedYear] = useState<RowSelectionState>({ 0: true });

  const onSelect = (uuid: string) => {
    setYearUuid(uuid);
    const index = memoizedData.findIndex((year) => year.uuid === uuid);
    setSelectedYear(index >= 0 ? { [index]: true } : { 0: true });
  };

  return (
    <div className={'tks__grid'}>
      <div className={'tks__row'}>
        <div className={`tks__col ${memoizedData.length === 0 ? 'col-xs-12' : 'col-xs-6'}`}>
          <HolidaysList
            data={memoizedData}
            currentSchoolYear={memoizedData.find((year) => year.uuid === yearUuid) ?? null}
            onSelect={onSelect}
            selected={selectedYear}
          />
        </div>
        {memoizedData.length !== 0 && (
          <div className={'tks__col col-md-6'}>
            <Card className={styles['holidays']} contentPadding='none' style={{ paddingTop: 'var(--spacing-5)' }}>
              <Suspense fallback={<LazyLoader embedded />}>
                <HolidaysEntriesList selectedYearUuid={yearUuid} />
              </Suspense>
            </Card>
          </div>
        )}
      </div>
    </div>
  );
});
