import { Header, ScenarioIllustration } from '@bp/ui-components';
import { Page } from '../../../components/Page/Page';
import { useTranslation } from 'react-i18next';
import { IconMenuItem } from '../../../components/IconMenuItem/IconMenuItem';
import { IconMenu } from '../../../components/IconMenu/IconMenu';
import { useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { use_TimetableBlocksQuery, use_TimetablesQuery } from '../../../types/planung-graphql-client-defs';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { observer } from 'mobx-react-lite';

export const EvaluatePage = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const schoolYear = useUserConfigContext().selectedSchoolYear;

  const [{ data }] = use_TimetableBlocksQuery({
    variables: {
      where: {
        timetables_SOME: { versions_SINGLE: { active: true } },
      },
    },
  });

  const [{ data: timetablesData }] = use_TimetablesQuery({
    variables: {
      where: {
        versions_SOME: {
          active: true,
        },
      },
    },
  });

  const memoizedDeputate = useMemo(() => {
    return data?.timetableBlocks
      .filter((b) => b.schoolYear.uuid === schoolYear?.uuid)
      .map((block) => {
        const timetableNames = timetablesData?.timetables.filter((t) =>
          block.timetables.map((t) => t.uuid).includes(t.uuid),
        );
        return (
          <IconMenuItem
            key={block.uuid}
            label={timetableNames?.map((t) => t.name).join(', ') ?? ''}
            icon={<ScenarioIllustration />}
            bubbleType={18}
            onClick={() => navigate(t('routes.deputate.evaluate.current.slug') + '/' + block.uuid)}
          />
        );
      });
  }, [data?.timetableBlocks, navigate, timetablesData?.timetables, schoolYear]);

  return (
    <Page title={`${t('deputate.title', { count: 2 })} ${t('deputate.evaluate.title').toLowerCase()}`}>
      <IconMenu hasMarginBottom>
        <IconMenuItem
          label={t('deputate.planTitle')}
          icon={<ScenarioIllustration />}
          bubbleType={1}
          onClick={() => navigate(t('routes.deputate.evaluate.planned.slug'))}
        />
      </IconMenu>
      <Header headline={t('deputate.currentTitle')} subHeadline={t('deputate.currentInfo')} className='mb-5' />
      <IconMenu>{memoizedDeputate}</IconMenu>
    </Page>
  );
});
