import { useTranslation } from 'react-i18next';
import { Tab } from '@bp/ui-components';
import { SettingsSubjectsTable } from '../../../components/Subjects/Tables/SettingsSubjectsTable';
import { Page } from '../../../components/Page/Page';
import { SubjectContainerTable } from '../../../components/SubjectContainer/Tables/SubjectContainerTable';
import { SubjectGroupTable } from '../../../components/SubjectGroup/Tables/SubjectGroupTable';

export const SetupSubjectsAndSubjectContainer = () => {
  const { t } = useTranslation();

  return (
    <Page title={null}>
      <Tab
        variant={'large'}
        tabs={[
          {
            title: t('subject.title', { count: 2 }),
            value: 'subjects',
            content: <SettingsSubjectsTable key={'subjects'} />,
          },
          {
            title: t('subjectContainer.title', { count: 2 }),
            value: 'subjectsContainer',
            content: <SubjectContainerTable key={'subjectsContainer'} />,
          },
          {
            title: t('subjectGroup.title', { count: 2 }),
            value: 'subjectGroup',
            content: <SubjectGroupTable key={'subjectGroup'} />,
          },
        ]}
      />
    </Page>
  );
};
