import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonGroup,
  DeleteIcon,
  DropdownMenu,
  DropdownMenuItem,
  EditIcon,
  Modal,
  Row,
  Table,
  TableColumns,
  Tooltip,
  useDefaultSelecting,
} from '@bp/ui-components';
import { useClassesListQuery, useUpdateClassesColorsMutation } from '../../../types/planung-graphql-client-defs';
import { ClassesForm } from '../Forms/ClassesForm';
import { useHiddenColumns } from '../../../hooks/useHiddenColumns';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { observer } from 'mobx-react-lite';
import { useColumnsSort } from '../../../hooks/useColumnsSort';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { useConfirm } from '../../../hooks/useConfirm';
import { useIsUsedInLesson } from '../../../hooks/useIsUsedInLesson';
import { partition } from '../../../utils/arrayFunc';
import { UsedInLessonsTooltipContent } from '../../TooltipContents/LessonUsedInTooltipContent/UsedInLessonsTooltipContent';
import { hexToColorOption } from '../../../utils/colorUtils';
import { ClassesTableType } from '../graphql/types';
import colors from '../../../assets/colors/Colors.json';
import { showSuccessDeleteToast, showUserErrorToast } from '../../../utils/toast';
import { useRooms } from '../../Rooms/useRooms';
import { useDeleteClasses } from '../../../hooks/useDeleteClasses';

export const TimetableClassesTable: FC = observer(() => {
  const { pimAuthClaims } = useAuthClaims();

  const { confirm, ConfirmationDialog } = useConfirm();
  const check = useIsUsedInLesson();

  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [classUuid, setClassUuid] = useState<null | string>(null);
  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;
  const { columnVisibility, saveColumnVisibility } = useHiddenColumns('classes-list', {
    tutor3: false,
    'timetableConfig.printOrder': false,
    'timetableConfig.printPageNr': false,
    color: false,
    roomName: false,
  });
  const { sorting, saveSorting } = useColumnsSort('classes-list');
  const { rowSelection, onRowSelectionChange } = useDefaultSelecting();

  const context = useMemorizedCacheTag('CLASSES');
  const [{ data, fetching, error }] = useClassesListQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
      schoolYearUuid: currentSchoolYear?.uuid,
    },
    context,
  });
  const colorsInUse = data?.classes?.map((sc) => sc.timetableConfig ?? null) ?? [];
  const { deleteClass } = useDeleteClasses();
  const [, updateColor] = useUpdateClassesColorsMutation();

  function createColumns(): TableColumns<ClassesTableType>[] {
    return [
      {
        header: t('common.name'),
        accessorKey: 'name',
        id: 'name',
        size: 250,
        canExpand: true,
      },
      {
        header: t('classes.shortName'),
        accessorKey: 'shortName',
        id: 'shortName',
        size: 75,
      },
      {
        header: t('classes.level.short'),
        meta: {
          filterName: t('classes.level.full'),
          tooltip: t('classes.level.full'),
        },
        accessorKey: 'grade',
        id: 'grade',
        size: 75,
        cell: ({ row }) => {
          return (row.original.grade || row.original.grade === 0) && row.original.grade >= 0 ? row.original.grade : '-';
        },
        accessorFn: ({ grade }) => {
          return (grade || grade === 0) && grade >= 0 ? grade.toString() : '-';
        },
      },
      {
        header: t('gradeGroups.title', { count: 1 }),
        meta: {
          filterName: t('gradeGroups.title', { count: 1 }),
          tooltip: t('gradeGroups.title', { count: 1 }),
        },
        cell: ({ row }) => {
          return row.original.gradeGroup?.shortName ?? '-';
        },
        accessorFn: ({ gradeGroup }) => {
          return gradeGroup?.shortName ?? '-';
        },
        id: 'gradeGroup',
        size: 75,
      },
      {
        header: `${t('classes.tutor.short')} 1`,
        meta: {
          filterName: `${t('classes.tutor.full')} 1`,
          tooltip: `${t('classes.tutor.full')} 1`,
        },
        enableGlobalFilter: false,
        enableColumnFilter: false,
        size: 200,
        id: 'tutor1',
        accessorFn: (originalRow) => {
          if (
            originalRow.tutorsConnection &&
            originalRow.tutorsConnection.edges &&
            originalRow.tutorsConnection.edges[0]
          ) {
            return originalRow.tutorsConnection.edges[0].node.displayNameShort;
          }
        },
        cell: ({ row }) => {
          const value = row.original.tutorsConnection.edges[0]?.node;
          return <>{value && value.displayNameShort}</>;
        },
      },
      {
        header: `${t('classes.tutor.short')} 2`,
        meta: {
          filterName: `${t('classes.tutor.full')} 2`,
          tooltip: `${t('classes.tutor.full')} 2`,
        },
        enableGlobalFilter: false,
        enableColumnFilter: false,
        size: 200,
        id: 'tutor2',
        accessorFn: (originalRow) => {
          if (
            originalRow.tutorsConnection &&
            originalRow.tutorsConnection.edges &&
            originalRow.tutorsConnection.edges[1]
          ) {
            return originalRow.tutorsConnection.edges[1].node.displayNameShort;
          }
        },
        cell: ({ row }) => {
          const value = row.original.tutorsConnection.edges[1]?.node;
          return <>{value && value.displayNameShort}</>;
        },
      },
      {
        header: `${t('classes.tutor.short')} 3`,
        meta: {
          filterName: `${t('classes.tutor.full')} 3`,
          tooltip: `${t('classes.tutor.full')} 3`,
        },
        enableGlobalFilter: false,
        enableColumnFilter: false,
        size: 200,
        id: 'tutor3',
        accessorFn: (originalRow) => {
          if (
            originalRow.tutorsConnection &&
            originalRow.tutorsConnection.edges &&
            originalRow.tutorsConnection.edges[2]
          ) {
            return originalRow.tutorsConnection.edges[2].node.displayNameShort;
          }
        },
        cell: ({ row }) => {
          const value = row.original.tutorsConnection.edges[2]?.node;
          return <>{value && value.displayNameShort}</>;
        },
      },
      {
        header: t('lesson.table.rooms'),
        id: 'roomName',
        accessorKey: 'roomName',
        size: 300,
      },
      {
        header: t('classes.printOrder.short'),
        meta: {
          filterName: t('classes.printOrder.full'),
          tooltip: t('classes.printOrder.full'),
        },
        accessorKey: 'timetableConfig.printOrder',
        id: 'timetableConfig.printOrder',
        size: 40,
      },
      {
        header: t('classes.printPageNr.short'),
        meta: {
          filterName: t('classes.printPageNr.full'),
          tooltip: t('classes.printPageNr.full'),
        },
        accessorKey: 'timetableConfig.printPageNr',
        id: 'timetableConfig.printPageNr',
        size: 40,
      },
      {
        header: t('common.color'),
        accessorKey: 'color',
        id: 'color',
        type: 'color',
      },
    ];
  }

  const tableColumns = useMemo(createColumns, [t]);

  const { roomData } = useRooms();
  const memoizedData = useMemo((): ClassesTableType[] => {
    return data && data
      ? data.classes.map((c) => {
          const roomName = roomData?.rooms.find((room) => room.uuid === c.defaultRoom?.uuid)?.name ?? '';
          const { html, label } = hexToColorOption(c.timetableConfig?.color ?? '');
          return {
            ...c,
            roomName,
            color: {
              color: html,
              colorLabel: label,
            },
          };
        })
      : [];
  }, [data, roomData]);

  const onModalClose = () => {
    setClassUuid(null);
    setIsModalOpen(false);
  };

  const handleEdit = (row: Row<ClassesTableType>) => {
    setClassUuid(row.original.uuid ?? null);
    setIsModalOpen(true);
  };

  async function handleDelete(rows: Row<ClassesTableType>[]) {
    const [used, notUsed] = partition(rows, (row) => check(row.original.uuid, 'class').isUsed);
    const uuids = notUsed.map((r) => r.original.uuid);
    await confirm({
      message: (
        <div>
          <div>{t('classes.deleteConfirm', { count: uuids.length })}</div>
          <ul>
            {notUsed.map((s) => {
              return <li key={s.original.uuid}>{s.original.name}</li>;
            })}
          </ul>
          {used && (
            <>
              <div>{t('classes.canNotDelete', { count: used.length })}</div>
              <ul>
                {used.map((s) => {
                  return <li key={s.original.uuid}>{s.original.name}</li>;
                })}
              </ul>
            </>
          )}
        </div>
      ),
      onConfirm: async () => {
        if (uuids.length) {
          const response = await deleteClass(uuids[0]);
          if (response.error) {
            showUserErrorToast({ error: response.error });
          } else {
            showSuccessDeleteToast(notUsed.map((r) => r.original.name));
          }
        }
      },
    });
  }

  async function addColors(uuids: string[]) {
    uuids.forEach((uuid) => {
      const color = colors[Math.floor(Math.random() * colors.length)];
      updateColor({ color: color.html, uuid: uuid }, context);
    });
  }

  const createBulkEditItems = useCallback(
    (rows: Row<ClassesTableType>[]): DropdownMenuItem[] => {
      return [
        {
          label: t('common.add-random-color'),
          onClick: async () => {
            await addColors(rows.map(({ original }) => original.uuid));
          },
        },
        {
          label: t('common.delete'),
          type: 'default',
          color: 'error',
          onClick: async () => {
            await handleDelete(rows);
          },
        },
      ];
    },
    [handleDelete],
  );

  return (
    <>
      {!error && !fetching && (
        <Table<ClassesTableType>
          showBorderRadius
          showShadow
          canScroll
          minHeight={600}
          showVisibility
          showSelect
          rowSelection={rowSelection}
          onRowSelectionChange={onRowSelectionChange}
          isOnWhite={false}
          sorting={sorting}
          onSortingChange={saveSorting}
          columnVisibility={columnVisibility}
          onColumnVisibilityChange={saveColumnVisibility}
          columns={tableColumns}
          data={memoizedData}
          printerSettings={{
            headline: pimAuthClaims.getProfile()?.organization.name,
            subline: `${t('classes.title', { count: 2 })} - ${t('common.schoolYear')} ${currentSchoolYear?.shortName}`,
            filename: `${t('classes.title', { count: 2 })}_${currentSchoolYear?.shortName}`,
          }}
          showActionBar
          actionBarSettings={{
            showExpertFilter: true,
            showAddButton: currentSchoolYear?.name !== '',
            showPrintButton: true,
            showBulkEdit: true,
          }}
          bulkEditDropdownContent={(rows) => {
            return <DropdownMenu data={createBulkEditItems(rows)} />;
          }}
          onAddClick={() => {
            setClassUuid(null);
            setIsModalOpen(true);
          }}
          lastColWidth='80px'
          lastCol={(row) => {
            const used = check(row.original.uuid, 'class');
            const deleteButton = (
              <Button
                disabled={used.isUsed}
                hierarchy='secondary'
                type='button'
                icon={<DeleteIcon className='small' />}
                onClick={() => handleDelete([row])}
              />
            );
            return (
              <ButtonGroup>
                <Button
                  hierarchy='secondary'
                  type='button'
                  onClick={() => handleEdit(row)}
                  icon={<EditIcon className='small' />}
                />
                {used.isUsed ? (
                  <Tooltip
                    content={
                      <UsedInLessonsTooltipContent lessons={used.lessons} usedInText={t('classes.usedInLesson')} />
                    }
                  >
                    {deleteButton}
                  </Tooltip>
                ) : (
                  deleteButton
                )}
              </ButtonGroup>
            );
          }}
        />
      )}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={onModalClose}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        title={classUuid ? (t('classes.editClass') as string) : (t('classes.createClass') as string)}
      >
        <ClassesForm
          classUuid={classUuid}
          htmlColorsInUse={colorsInUse}
          closeForm={() => {
            setIsModalOpen(false);
            setClassUuid(null);
          }}
        />
      </Modal>
      <ConfirmationDialog />
    </>
  );
});
