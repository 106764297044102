import { useMemo, useState } from 'react';
import {
  Button,
  ButtonGroup,
  DotsHorizontalIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  EditIcon,
  Modal,
  Row,
  Table,
  TableColumns,
  useDefaultSelecting,
} from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { useHiddenColumns } from '../../../hooks/useHiddenColumns';
import { RoomsForm } from '../Forms/RoomsForm';
import { observer } from 'mobx-react-lite';
import { useColumnsSort } from '../../../hooks/useColumnsSort';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { useConfirm } from '../../../hooks/useConfirm';
import { useIsUsedInLesson } from '../../../hooks/useIsUsedInLesson';
import { partition } from '../../../utils/arrayFunc';
import { showSuccessDeleteToast, showUserErrorToast } from '../../../utils/toast';
import { booleanKeys, useRooms } from '../useRooms';
import { useCreateSelectOptions } from '../../../hooks/useCreateSelectOptions';

export type RoomsTableType = {
  uuid: string;
  roomNumber: string;
  name: string;
  building: string;
  position: string;
  description: string;
  responsiblePerson1: string;
  responsiblePerson2: string;
  responsiblePerson3: string;
  capacity: number | null;
  chairs: number | null;
  tables: number | null;
  classroom: boolean;
  hasPiano: boolean;
  barrierFree: boolean;
  hasProjector: boolean;
  canBeDarkened: boolean;
  hasScreen: boolean;
  otherInventory: string;
};

export const RoomsTable = observer(() => {
  const { pimAuthClaims } = useAuthClaims();
  const { t } = useTranslation();

  const { rowSelection, onRowSelectionChange } = useDefaultSelecting();

  const { columnVisibility, saveColumnVisibility } = useHiddenColumns('rooms-list', {
    position: false,
    description: false,
    responsiblePerson2: false,
    responsiblePerson3: false,
    chairs: false,
    isBarrierFree: false,
    hasPiano: false,
    hasProjector: false,
    canBeDarkened: false,
    hasScreen: false,
    otherInventory: false,
  });

  const { sorting, saveSorting } = useColumnsSort('rooms-list');

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [roomUuid, setRoomUuid] = useState<string | null>(null);
  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;

  const { confirm, ConfirmationDialog } = useConfirm();
  const check = useIsUsedInLesson();

  const {
    roomData,
    deleteRooms,
    updateCheckboxes,
    getBooleanLabelKey,
    duplicateRooms,
    assignToBuilding,
    detachFromBuilding,
    loadingState,
  } = useRooms();

  const buildings = useCreateSelectOptions(roomData?.buildings, 'uuid', 'name');

  function createColumns(): TableColumns<RoomsTableType>[] {
    return [
      {
        header: t('rooms.tooltip.roomNumber'),
        meta: {
          filterName: t('rooms.roomNumber'),
          tooltip: t('rooms.roomNumber'),
        },
        accessorKey: 'roomNumber',
        id: 'roomNumber',
        size: 60,
      },
      {
        header: t('common.name'),
        accessorKey: 'name',
        id: 'name',
        size: 150,
      },
      {
        header: t('rooms.building'),
        accessorKey: 'building',
        id: 'building',
        size: 150,
      },
      {
        header: t('rooms.position'),
        accessorKey: 'position',
        id: 'position',
        size: 80,
      },
      {
        header: t('common.description'),
        accessorKey: 'description',
        id: 'description',
      },
      {
        header: t('rooms.responsiblePersons') + ' 1',
        id: 'responsiblePerson1',
        accessorKey: 'responsiblePerson1',
      },
      {
        header: t('rooms.responsiblePersons') + ' 2',
        id: 'responsiblePerson2',
        accessorKey: 'responsiblePerson2',
      },
      {
        header: t('rooms.responsiblePersons') + ' 3',
        accessorKey: 'responsiblePerson3',
        id: 'responsiblePerson3',
      },
      {
        header: t('rooms.tooltip.capacity'),
        meta: {
          filterName: t('rooms.capacity'),
          tooltip: t('rooms.capacity'),
        },
        accessorKey: 'capacity',
        type: 'number',
        id: 'capacity',
        size: 40,
      },
      {
        header: t('rooms.chairs'),
        accessorKey: 'chairs',
        type: 'number',
        id: 'chairs',
        size: 40,
      },
      {
        header: t('rooms.tooltip.isClassroom'),
        meta: {
          filterName: t('rooms.isClassroom'),
          tooltip: t('rooms.isClassroom'),
        },
        accessorKey: 'classroom',
        id: 'classroom',
        type: 'boolean',
        size: 40,
      },
      {
        header: t('rooms.hasPiano'),
        accessorKey: 'hasPiano',
        id: 'hasPiano',
        type: 'boolean',
        size: 40,
      },
      {
        header: t('rooms.tooltip.isBarrierFree'),
        meta: {
          filterName: t('rooms.isBarrierFree'),
          tooltip: t('rooms.isBarrierFree'),
        },
        accessorKey: 'barrierFree',
        id: 'barrierFree',
        type: 'boolean',
        size: 40,
      },
      {
        header: t('rooms.hasProjector'),
        accessorKey: 'hasProjector',
        id: 'hasProjector',
        type: 'boolean',
        size: 40,
      },
      {
        header: t('rooms.tooltip.canBeDarkened'),
        meta: {
          filterName: t('rooms.canBeDarkened'),
          tooltip: t('rooms.canBeDarkened'),
        },
        accessorKey: 'canBeDarkened',
        id: 'canBeDarkened',
        type: 'boolean',
        size: 40,
      },
      {
        header: t('rooms.tooltip.hasScreen'),
        meta: {
          filterName: t('rooms.hasScreen'),
          tooltip: t('rooms.hasScreen'),
        },
        accessorKey: 'hasScreen',
        id: 'hasScreen',
        type: 'boolean',
        size: 40,
      },
      {
        header: t('rooms.otherInventory') as string,
        accessorKey: 'otherInventory',
        id: 'otherInventory',
      },
    ];
  }

  const tableColumns = useMemo(createColumns, [t]);

  const memoizedData = useMemo((): RoomsTableType[] => {
    return roomData
      ? roomData?.rooms.map((r) => {
          const sortedPersons = r.responsiblePersonsConnection.edges.sort(
            (a, b) => a.properties.order - b.properties.order,
          );
          const responsiblePerson1 = sortedPersons[0]?.node.displayNameShort ?? '';
          const responsiblePerson2 = sortedPersons[1]?.node.displayNameShort ?? '';
          const responsiblePerson3 = sortedPersons[2]?.node.displayNameShort ?? '';
          return {
            uuid: r.uuid,
            roomNumber: r.roomNumber ?? '',
            name: r.name,
            building: r.building?.name ?? '',
            position: r.position ?? '',
            description: r.description ?? '',
            otherInventory: r.otherInventory ?? '',
            responsiblePerson1: responsiblePerson1,
            responsiblePerson2: responsiblePerson2,
            responsiblePerson3: responsiblePerson3,
            capacity: r.capacity ?? null,
            chairs: r.chairs ?? null,
            tables: r.tables ?? null,
            barrierFree: r.barrierFree ?? false,
            canBeDarkened: r.canBeDarkened ?? false,
            classroom: r.classroom ?? false,
            hasPiano: r.hasPiano ?? false,
            hasProjector: r.hasProjector ?? false,
            hasScreen: r.hasScreen ?? false,
          };
        })
      : [];
  }, [roomData]);

  const onModalClose = () => {
    setRoomUuid(null);
    setIsModalOpen(false);
  };

  const handleEdit = (roomsRow: Row<RoomsTableType>) => {
    setRoomUuid(roomsRow.original.uuid);
    setIsModalOpen(true);
  };

  const handleDelete = async (rows: Row<RoomsTableType>[]) => {
    const [used, notUsed] = partition(rows, (row) => check(row.original.uuid, 'room').isUsed);
    const uuids = notUsed.map((r) => r.original.uuid);
    await confirm({
      message: (
        <div>
          <div>{t('rooms.deleteConfirm', { count: uuids.length })}</div>
          <ul>
            {notUsed.map((s) => {
              return <li key={s.original.uuid}>{s.original.name}</li>;
            })}
          </ul>
          {used && (
            <>
              <div>{t('rooms.canNotDelete', { count: used.length })}</div>
              <ul>
                {used.map((s) => {
                  return <li key={s.original.uuid}>{s.original.name}</li>;
                })}
              </ul>
            </>
          )}
        </div>
      ),
      onConfirm: async () => {
        if (uuids) {
          const response = await deleteRooms([uuids[0]]);
          if (response.error) {
            showUserErrorToast({ error: response.error });
          } else {
            showSuccessDeleteToast(used.map((r) => r.original.name));
          }
        }
      },
    });
  };

  const bulkEdit = (selectedFlatRows: Row<RoomsTableType>[]) => {
    return (
      <DropdownMenu
        data={[
          ...booleanKeys.map((type): DropdownMenuItem => {
            return {
              label: getBooleanLabelKey(type),
              type: 'default',
              subContent: [
                {
                  label: t('common.activateAll'),
                  onClick: async () => {
                    await updateCheckboxes({
                      type,
                      uuids: selectedFlatRows.map(({ original }) => original.uuid),
                      value: true,
                    });
                  },
                },
                {
                  label: t('common.deactivateAll'),
                  onClick: async () => {
                    await updateCheckboxes({
                      type,
                      uuids: selectedFlatRows.map(({ original }) => original.uuid),
                      value: false,
                    });
                  },
                },
              ],
            };
          }),
          { type: 'ruler' },
          {
            label: t('room.actions.assignToBuilding'),
            subContent: buildings
              ? buildings.map((building): DropdownMenuItem => {
                  return {
                    type: 'default',
                    label: building.label ?? '',
                    onClick: async () => {
                      await assignToBuilding(
                        selectedFlatRows.map((s) => s.original.uuid),
                        building.value as string,
                      );
                    },
                  };
                })
              : [],
          },
          {
            label: t('room.actions.detachFromBuilding'),
            onClick: async () => {
              await detachFromBuilding(selectedFlatRows.map((s) => s.original.uuid));
            },
          },
          {
            label: t('common.duplicate'),
            type: 'default',
            onClick: async () => {
              const uuids = selectedFlatRows.map(({ original }) => original.uuid);
              await duplicateRooms(uuids);
            },
          },
          { type: 'ruler' },
          {
            label: t('common.delete'),
            type: 'default',
            color: 'error',
            onClick: async () => {
              await handleDelete(selectedFlatRows);
            },
          },
        ]}
      ></DropdownMenu>
    );
  };

  return (
    <>
      <Table<RoomsTableType>
        showBorderRadius
        showShadow
        showSort
        canScroll
        minHeight={600}
        breakpoint={null}
        showVisibility
        sorting={sorting}
        showSelect={true}
        onSortingChange={saveSorting}
        rowSelection={rowSelection}
        onRowSelectionChange={onRowSelectionChange}
        columnVisibility={columnVisibility}
        onColumnVisibilityChange={saveColumnVisibility}
        columns={tableColumns}
        data={memoizedData}
        bulkEditDropdownContent={bulkEdit}
        printerSettings={{
          headline: pimAuthClaims.getProfile()?.organization.name,
          subline: `${t('rooms.title', { count: 2 })} - ${t('common.schoolYear')} ${currentSchoolYear?.shortName}`,
          filename: `${t('rooms.title', { count: 2 })}_${currentSchoolYear?.shortName}`,
        }}
        showActionBar
        actionBarSettings={{
          showExpertFilter: true,
          showPrintButton: true,
          showBulkEdit: true,
          showAddButton: true,
        }}
        isOnWhite={false}
        onAddClick={() => {
          setIsModalOpen(true);
        }}
        lastColWidth='80px'
        lastCol={(roomsRow) => {
          const used = check(roomsRow.original.uuid, 'room').isUsed;
          return (
            <ButtonGroup>
              <Button
                hierarchy='secondary'
                type='button'
                onClick={() => handleEdit(roomsRow)}
                icon={<EditIcon className='small' />}
              />
              <Dropdown
                disabled={loadingState.some(({ loading, uuid }) => uuid === roomsRow.original.uuid && loading)}
                trigger={
                  <Button
                    isLoading={loadingState.some(({ loading, uuid }) => uuid === roomsRow.original.uuid && loading)}
                    hierarchy='secondary'
                    icon={<DotsHorizontalIcon className='small' />}
                  />
                }
              >
                <DropdownMenu
                  data={[
                    { type: 'ruler' },
                    {
                      label: t('room.actions.assignToBuilding'),
                      subContent: buildings
                        ? buildings.map((building): DropdownMenuItem => {
                            return {
                              type: 'default',
                              label: building.label ?? '',
                              onClick: async () => {
                                await assignToBuilding([roomsRow.original.uuid], building.value as string);
                              },
                            };
                          })
                        : [],
                    },
                    {
                      label: t('room.actions.detachFromBuilding'),
                      onClick: () => {
                        detachFromBuilding([roomsRow.original.uuid]);
                      },
                    },
                    {
                      label: t('common.duplicate'),
                      type: 'default',
                      onClick: async () => {
                        await duplicateRooms([roomsRow.original.uuid]);
                      },
                    },
                    { type: 'ruler' },
                    {
                      disabled: used,
                      label: t('common.delete'),
                      type: 'default',
                      color: 'error',
                      onClick: async () => {
                        await handleDelete([roomsRow]);
                      },
                    },
                  ]}
                />
              </Dropdown>
            </ButtonGroup>
          );
        }}
      />
      <Modal
        isOpen={isModalOpen}
        onRequestClose={onModalClose}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        title={roomUuid ? t('rooms.edit') : t('rooms.add')}
      >
        <RoomsForm closeForm={onModalClose} roomUuid={roomUuid}></RoomsForm>
      </Modal>
      <ConfirmationDialog />
    </>
  );
});
