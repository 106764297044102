import { useTeachingBlockVersionsQuery } from '../../../types/planung-graphql-client-defs';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { useColumnsSort } from '../../../hooks/useColumnsSort';
import {
  Button,
  ButtonGroup,
  DotsHorizontalIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  EditIcon,
  Row,
  Table,
  TableColumns,
  useDefaultSelecting,
} from '@bp/ui-components';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TeachingBlockVersionTableType } from '../graphql/types';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { observer } from 'mobx-react-lite';
import { hexToColorOption } from '../../../utils/colorUtils';
import { isFirstBeforeSecond } from '../../../utils/dateCalculations';

type TeachingBlockVersionDependenciesTableProps = {
  teachingBlockVersionUuid: string;
};

export const TeachingBlockVersionDependenciesTable = observer(
  ({ teachingBlockVersionUuid }: TeachingBlockVersionDependenciesTableProps) => {
    const { pimAuthClaims } = useAuthClaims();
    const { t } = useTranslation();
    const schoolYear = useUserConfigContext().selectedSchoolYear;

    const { sorting, saveSorting } = useColumnsSort('teachingBlockVersionDependenciesTable');
    const { rowSelection, onRowSelectionChange } = useDefaultSelecting();

    const versionsContext = useMemorizedCacheTag('TEACHINGBLOCK_VERSIONS');

    const [{ data: versionResult }] = useTeachingBlockVersionsQuery({
      variables: {
        uuid: teachingBlockVersionUuid ?? '',
      },
      context: versionsContext,
    });

    const classes: TeachingBlockVersionTableType[] = useMemo(() => {
      return (
        versionResult?.teachingBlockVersions[0]?.classesConnection.edges.map((edge) => {
          const c = edge.node;
          const { html, label } = hexToColorOption(c.timetableConfig?.color ?? '');
          return {
            uuid: c.uuid,
            name: c.name,
            count: 0,
            shortName: c.shortName,
            color: {
              color: html,
              colorLabel: label,
            },
            exclusions: versionResult?.teachingBlockVersions[0].availabilityExclusions
              .filter((exclusion) => {
                return exclusion.holder.__typename === 'Class' && exclusion.holder.uuid === c.uuid;
              })
              .sort((a, b) => (isFirstBeforeSecond(a.from, b.from) ? 0 : 1)),
          };
        }) ?? []
      );
    }, [versionResult]);

    function createColumns(): TableColumns<TeachingBlockVersionTableType>[] {
      return [
        {
          header: t('common.priority.short'),
          meta: {
            tooltip: t('common.priority.full'),
          },
          accessorKey: 'priority',
          id: 'priority',
          size: 30,
        },
        {
          header: t('subject.title', { count: 2 }),
          accessorKey: 'subjects',
          id: 'subjects',
          size: 130,
        },
        {
          header: t('classes.title', { count: 2 }),
          accessorKey: 'classes',
          id: 'classes',
          size: 130,
        },
        {
          header: t('common.type'),
          accessorKey: 'type',
          id: 'type',
          size: 130,
        },
        {
          header: t('common.comment'),
          accessorKey: 'comment',
          id: 'comment',
          size: 130,
        },
        {
          header: t('common.active.short'),
          meta: {
            tooltip: t('common.active.full'),
          },
          accessorKey: 'isActive',
          id: 'isActive',
          type: 'active',
        },
      ];
    }

    const columns = useMemo(createColumns, [t]);

    const createActionItems = useCallback(
      (row: Row<TeachingBlockVersionTableType>): DropdownMenuItem[] => {
        return [
          {
            label: t('teachingBlockVersion.useAsTemplate'),
          },
          {
            label: t('common.activate'),
          },
          {
            label: t('common.delete'),
            type: 'default',
            color: 'error',
          },
        ];
      },
      [t, versionResult],
    );

    function handleEdit() {
      console.log('edit clicked');
    }

    function handleAdd() {
      console.log('add clicked');
    }

    return (
      <Table<TeachingBlockVersionTableType>
        showBorderRadius
        showShadow
        canScroll
        rowSelection={rowSelection}
        onRowSelectionChange={onRowSelectionChange}
        data={classes}
        columns={columns}
        sorting={sorting}
        onSortingChange={saveSorting}
        onAddClick={handleAdd}
        printerSettings={{
          headline: pimAuthClaims.getProfile()?.organization.name,
          subline: `${t('dependencies.title', { count: 2 })} - ${t('common.schoolYear')} ${schoolYear?.shortName}`,
          filename: `${t('dependencies.title', { count: 2 })}_${schoolYear?.shortName}`,
        }}
        lastCol={(row) => {
          return (
            <ButtonGroup>
              <Button hierarchy='tertiary' icon={<EditIcon className='small' />} onClick={handleEdit} />
              <Dropdown
                noPadding
                trigger={<Button hierarchy='secondary' icon={<DotsHorizontalIcon className='small' />} />}
              >
                <DropdownMenu data={createActionItems(row)} />
              </Dropdown>
            </ButtonGroup>
          );
        }}
      />
    );
  },
);
