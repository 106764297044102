import { Checkbox, Grid, GridColumn, GridRow, Select, SelectOptionType } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import styles from './TimetablePrintConfigurationBaseData.module.scss';
import { TimetablePrintColorOrigins, TimetablePrintColorPositions } from '@bp/planung-graphql-types';
import { TimetablePrintBaseData, TimetablePrintConfigurationOptions } from '../TimetablePrintConfiguration';

type TimetablePrintConfigurationBaseDataProps = {
  onOriginChange: (origin: TimetablePrintColorOrigins) => void;
  onPositionChange: (position: TimetablePrintColorPositions) => void;
  onConfigChange: (config: TimetablePrintConfigurationOptions, value: boolean) => void;
  data: TimetablePrintBaseData;
};

export const TimetablePrintConfigurationBaseData = ({
  onOriginChange,
  onPositionChange,
  onConfigChange,
  data,
}: TimetablePrintConfigurationBaseDataProps) => {
  const { t } = useTranslation();

  const originOptions: SelectOptionType[] = [
    {
      label: t('common.none'),
      value: TimetablePrintColorOrigins.None,
    },
    {
      label: t('rooms.title', { count: 1 }),
      value: TimetablePrintColorOrigins.Room,
    },
    {
      label: t('subject.title', { count: 1 }),
      value: TimetablePrintColorOrigins.Subject,
    },
    {
      label: t('persons.title', { count: 1 }),
      value: TimetablePrintColorOrigins.Teacher,
    },
    {
      label: t('classes.title', { count: 1 }),
      value: TimetablePrintColorOrigins.Class,
    },
  ];

  const positionOptions: SelectOptionType[] = [
    {
      label: t('timetable.configuration.options.full'),
      value: TimetablePrintColorPositions.Full,
    },
    {
      label: t('timetable.configuration.options.top'),
      value: TimetablePrintColorPositions.Top,
    },
    {
      label: t('timetable.configuration.options.right'),
      value: TimetablePrintColorPositions.Right,
    },
    {
      label: t('timetable.configuration.options.bottom'),
      value: TimetablePrintColorPositions.Bottom,
    },
    {
      label: t('timetable.configuration.options.left'),
      value: TimetablePrintColorPositions.Left,
    },
    {
      label: t('common.none'),
      value: TimetablePrintColorPositions.None,
    },
  ];

  return (
    <Grid className={styles['timetable-print-configuration-base-data']} useFormGap>
      <GridRow spacingBottom='none' spacingTop='none'>
        <GridColumn width={6}>
          <Select
            options={originOptions}
            defaultValue={originOptions.find((o) => o.value === data.colorOrigin)}
            onChange={(option) => onOriginChange((option as SelectOptionType).value as TimetablePrintColorOrigins)}
            name={'color-origin'}
            label={t('timetable.configuration.colorOrigin')}
          />
        </GridColumn>
        <GridColumn width={6}>
          <Select
            disabled={data.colorOrigin === TimetablePrintColorOrigins.None}
            options={positionOptions}
            defaultValue={positionOptions.find((o) => o.value === data.colorPosition)}
            onChange={(option) => onPositionChange((option as SelectOptionType).value as TimetablePrintColorPositions)}
            name={'color-position'}
            label={t('timetable.configuration.colorPosition')}
          />
        </GridColumn>
      </GridRow>
      <GridRow spacingTop='none' spacingBottom='none'>
        <GridColumn width={3}>
          <Checkbox
            name={'print-classes'}
            checked={data.printClasses}
            onChange={(event) => onConfigChange(TimetablePrintConfigurationOptions.printClasses, event.target.checked)}
            label={t('timetable.configuration.printClasses')}
          />
          <Checkbox
            name={'scale-text'}
            checked={data.scaleText}
            onChange={(event) => onConfigChange(TimetablePrintConfigurationOptions.scaleText, event.target.checked)}
            label={t('timetable.configuration.scaleText')}
          />
          <Checkbox
            name={'exact-minute'}
            checked={data.exactMinute}
            onChange={(event) => onConfigChange(TimetablePrintConfigurationOptions.exactMinute, event.target.checked)}
            label={t('timetable.configuration.exactMinute')}
          />
        </GridColumn>
        <GridColumn width={3}>
          <Checkbox
            name={'print-subtitle'}
            checked={data.printSubtitle}
            onChange={(event) => onConfigChange(TimetablePrintConfigurationOptions.printSubtitle, event.target.checked)}
            label={t('timetable.configuration.printSubtitle')}
          />
          <Checkbox
            name={'print-logo'}
            disabled={true}
            checked={data.printLogo}
            onChange={(event) => onConfigChange(TimetablePrintConfigurationOptions.printLogo, event.target.checked)}
            label={t('timetable.configuration.printLogo')}
          />
        </GridColumn>
      </GridRow>
    </Grid>
  );
};
