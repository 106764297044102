import { Outlet } from 'react-router-dom';
import { SubstitutionsTopBar } from '../../../components/SubstitutionsTopBar/SubstitutionsTopBar';
import { Page } from '../../../components/Page/Page';

type PlanSubstitutionsProps = {
  baseSlug: string;
};

export const SubstitutionsPlanSubpage = ({ baseSlug }: PlanSubstitutionsProps) => {
  return (
    <Page title={null}>
      <SubstitutionsTopBar baseSlug={baseSlug} />
      <Outlet />
    </Page>
  );
};
