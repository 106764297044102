import { Button, DotsHorizontalIcon, Dropdown, DropdownMenu, Table, TableColumns } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';

export type SchoolEventTableType = {
  uuid: string;
  name: string;
  classes: string;
  groups: string;
  subject: string;
  teachers: string;
  start: string;
  end: string;
};

type SchoolEventTableProps = {
  onAdd: () => void;
  onEdit: (uuid: string) => void;
  onDuplicate: (uuid: string) => void;
  onDelete: (uuid: string) => void;
  data: SchoolEventTableType[];
};

export const SchoolEventTable = ({ onAdd, onEdit, onDuplicate, onDelete, data }: SchoolEventTableProps) => {
  const { t } = useTranslation();

  const columns: TableColumns<SchoolEventTableType>[] = [
    {
      header: t('common.name'),
      accessorKey: 'name',
      id: 'name',
    },
    {
      header: t('classes.title', { count: 2 }),
      accessorKey: 'classes',
      id: 'classes',
    },
    {
      header: t('groups.title', { count: 2 }),
      accessorKey: 'groups',
      id: 'groups',
    },
    {
      header: t('subject.title', { count: 1 }),
      accessorKey: 'subject',
      id: 'subject',
    },
    {
      header: t('persons.title', { count: 2 }),
      accessorKey: 'teachers',
      id: 'teachers',
    },
    {
      header: t('common.from'),
      accessorKey: 'start',
      id: 'start',
    },
    {
      header: t('common.until'),
      accessorKey: 'end',
      id: 'end',
    },
  ];

  return (
    <Table<SchoolEventTableType>
      isOnWhite={false}
      showBorderRadius
      showShadow
      showActionBar
      actionBarSettings={{ showExpertFilter: true, showAddButton: true, addButtonText: t('common.add') }}
      onAddClick={() => onAdd()}
      emptyStateSettings={{ title: t('schoolEvents.noEvents') }}
      columns={columns}
      data={data}
      lastColWidth='80px'
      lastCol={(row) => {
        return (
          <Dropdown trigger={<Button icon={<DotsHorizontalIcon />} hierarchy='tertiary' />}>
            <DropdownMenu
              data={[
                {
                  label: t('common.edit'),
                  onClick: () => onEdit(row.original.uuid),
                },
                {
                  label: t('common.duplicate'),
                  onClick: () => onDuplicate(row.original.uuid),
                  disabled: true,
                },
                {
                  type: 'ruler',
                },
                {
                  label: t('common.delete'),
                  onClick: () => onDelete(row.original.uuid),
                  color: 'error',
                },
              ]}
            />
          </Dropdown>
        );
      }}
    />
  );
};
