import { GroupedGroupOption, GroupPicker, Select, SelectOptionType } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';

export const LessonClassesFormItem = ({
  index,
  disabled,
  classUuid,
  className,
  groups,
  onClassChange,
  onGroupsChange,
  classOptions,
  groupOptions,
}: {
  index: number;
  disabled: boolean;
  onClassChange: (classUuid: string) => void;
  onGroupsChange: (groups: string[]) => void;
  groups: string[];
  classUuid: string;
  className: string;
  classOptions: SelectOptionType[];
  groupOptions: GroupedGroupOption[];
}) => {
  const { t } = useTranslation();

  const currentGroupOptions = groupOptions.find((option) => option.options.some((o) => groups.includes(o.value)));

  return (
    <div className={'form-row'}>
      <Select
        readonly={disabled}
        isSearchable
        className={'half'}
        onChange={async (option) => {
          const opt = option as SingleValue<SelectOptionType>;
          onClassChange(opt?.value as string);
        }}
        options={classOptions}
        label={t('classes.title', { count: 2 })}
        tooltipText={t('classes.tooltip')}
        name={`lessonClasses.${index}.class`}
        value={classOptions.find((option) => option.value === classUuid)}
      />
      <GroupPicker
        readonly={disabled}
        className={'half'}
        name={`lessonClasses.${index}.groups`}
        label={t('common.group_other')}
        disabled={disabled}
        options={groupOptions}
        onChange={async (multiValue) => {
          onGroupsChange(multiValue.map((option) => option.value));
        }}
        value={currentGroupOptions?.options.filter((option) => groups.includes(option.value)) ?? []}
        dropdownHeader={t('classGroups.divisionsListTitle', { class: className })}
        dropdownFooter={<span>{t('classGroups.manage')}</span>}
      />
    </div>
  );
};
