import { useTranslation } from 'react-i18next';
import { Checkbox, InfoIcon, Input, Select, SelectOptionType, Tooltip } from '@bp/ui-components';
import { SingleValue } from 'react-select';
import { useFormikContext } from 'formik';
import { LessonFormTeacherType, LessonFormType } from '../../types';
import { observer } from 'mobx-react-lite';

export const LessonTeacherFormItem = observer(({
  index,
  qualifiedTeachers,
  otherTeachers,
  teacher,
  disabled,
}: {
  index: number;
  qualifiedTeachers: {
    uuid: string;
    listName: string;
  }[];
  otherTeachers: {
    uuid: string;
    listName: string;
  }[];
  teacher: LessonFormTeacherType;
  disabled: boolean;
}) => {
  const { t } = useTranslation();
  const formik = useFormikContext<LessonFormType>();

  return (
    <>
      <div className='tks__row'>
        <div className='tks__col col-xs-11 no-gap'>
          <div className='form-row'>
            <Select
              isSearchable
              readonly={disabled}
              className='half'
              name={`teachers.${index}.person`}
              label={t('persons.title', { count: 1 })}
              tooltipText={t('persons.tooltip.teachers')}
              hasGroups={true}
              options={[
                ...qualifiedTeachers.map((person) => ({
                  value: person.uuid,
                  label: person.listName ?? '',
                  group: t('qualifications.withQualification'),
                })),
                ...otherTeachers.map((person) => ({
                  value: person.uuid,
                  label: person.listName ?? '',
                  group: t('qualifications.withoutQualification'),
                })),
              ]}
              defaultValue={{
                value: teacher.person?.uuid,
                label: teacher.person?.displayNameShort ?? '',
              }}
              onChange={(option) => {
                const opt = option as SingleValue<SelectOptionType>;
                formik.setFieldTouched(`teachers.${index}.person`, true);
                formik.setFieldValue(`teachers.${index}.person`, {
                  uuid: opt?.value,
                  displayNameShort: opt?.label,
                });
              }}
            />
            <Input
              className={'half'}
              label={t('lesson.person.teachingLoadHours')}
              tooltipText={t('lesson.tooltip.teachingLoadHours')}
              name={`teachers.${index}.teachingLoadHours`}
              onChange={() => {}}
              value={teacher.teachingLoadHoursDisplay ?? 0}
              readonly
              suffix={
                <Tooltip content={'Deputat'}>
                  <InfoIcon style={{ width: '24px', height: '24px' }} />
                </Tooltip>
              }
            />
          </div>
          <div className={'form-row'}>
            <Input
              readonly={disabled}
              className={'full'}
              name={`teachers.${index}.description`}
              onChange={formik.handleChange}
              value={teacher.description ?? ''}
              label={t('lesson.person.description')}
              tooltipText={t('lesson.tooltip.description')}
            />
          </div>
        </div>
        <div className='tks__col col-xs-2 no-gap mt-6'>
          <Checkbox
            disabled={disabled}
            name={`teachers.${index}.writesCertificate`}
            checked={teacher.writesCertificate ?? false}
            onChange={(event) => {
              formik.setFieldValue(`teachers.${index}.writesCertificate`, event.target.checked, true);
              formik.setFieldTouched(`teachers.${index}.writesCertificate`, true);
            }}
            label={t('lesson.person.writesCertificate')}
          />
          <Checkbox
            name={`teachers.${index}.teachingLoad`}
            checked={teacher.teachingLoad ?? false}
            onChange={(event) => {
              formik.setFieldValue(`teachers.${index}.teachingLoad`, event.target.checked, true);
              formik.setFieldTouched(`teachers.${index}.teachingLoad`, true);
            }}
            label={t('lesson.person.teachingLoad')}
          />
          <Checkbox
            disabled={disabled}
            name={`teachers.${index}.present`}
            checked={teacher.present ?? false}
            onChange={(event) => {
              formik.setFieldValue(`teachers.${index}.present`, event.target.checked, true);
              formik.setFieldTouched(`teachers.${index}.present`, true);
            }}
            label={t(`lesson.person.present`)}
          />
        </div>
      </div>
    </>
  );
});
