import {
  _RoomsQuery,
  SortDirection,
  use_ClassesQuery,
  use_DivisionsQuery,
  use_GradeGroupsQuery,
  use_GroupsQuery,
  use_LessonClassesQuery,
  use_PeopleQuery,
  use_RoomsQuery,
  use_SubjectContainersQuery,
  use_SubjectsQuery,
} from '../types/planung-graphql-client-defs';
import { useMemorizedCacheTag } from './useMemorizedCacheTag';
import { useUserConfigContext } from './useUserConfigContext';

export const useLoadBasicData = ({ pause }: { pause: boolean }) => {
  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;

  const classContext = useMemorizedCacheTag('CLASSES_FIXED');
  const teacherContext = useMemorizedCacheTag('PERSONS');
  const roomContext = useMemorizedCacheTag('ROOMS');
  const subjectContext = useMemorizedCacheTag('SUBJECTS');
  const subjectContainerContext = useMemorizedCacheTag('SUBJECT_CONTAINER');
  const gradeGroupContext = useMemorizedCacheTag('GRADE_GROUPS');
  const lessonClassContext = useMemorizedCacheTag('LESSON_CLASS');
  const groupContext = useMemorizedCacheTag('GROUPS');
  const divisionContext = useMemorizedCacheTag('DIVISIONS');

  const [{ data: teacherData }, refetchTeacherData] = use_PeopleQuery({
    variables: {
      options: {
        sort: [{ active: SortDirection.Desc }, { lastName: SortDirection.Asc }, { firstName: SortDirection.Asc }],
      },
    },
    context: teacherContext,
    pause,
  });

  const [{ data: _roomsData }] = use_RoomsQuery({
    variables: {
      options: {
        sort: [
          {
            name: SortDirection.Asc,
          },
          {
            classroom: SortDirection.Asc,
          },
        ],
      },
    },
    context: roomContext,
    pause,
  });

  const sortedRooms = _roomsData?.rooms.sort((a, b) => {
    const numA = parseInt(a.name.match(/^\d+/)?.[0] || 'Infinity', 10);
    const numB = parseInt(b.name.match(/^\d+/)?.[0] || 'Infinity', 10);
    return numA - numB || a.name.localeCompare(b.name);
  });

  const roomsData: _RoomsQuery = {
    __typename: 'Query',
    rooms: sortedRooms ?? [],
  };

  const [{ data: subjectData }] = use_SubjectsQuery({
    variables: {
      options: {
        sort: [
          {
            active: SortDirection.Desc,
          },
          {
            name: SortDirection.Asc,
          },
        ],
      },
    },
    context: subjectContext,
    pause,
  });

  const [{ data: subjectContainerData }] = use_SubjectContainersQuery({
    pause,
    context: subjectContainerContext,
  });

  const [{ data: gradeGroupData }] = use_GradeGroupsQuery({
    pause,
    context: gradeGroupContext,
  });

  const [{ data: lessonClassesData }] = use_LessonClassesQuery({
    variables: {
      where: {
        class: {
          schoolYear: {
            uuid: currentSchoolYear?.uuid,
          },
        },
      },
    },
    pause,
    context: lessonClassContext,
  });

  const [{ data: classesData }] = use_ClassesQuery({
    variables: {
      where: {
        schoolYear: {
          uuid: currentSchoolYear?.uuid,
        },
      },
      options: {
        sort: [
          {
            sortName: SortDirection.Asc,
          },
        ],
      },
    },
    pause,
    context: classContext,
  });

  const [{ data: groupsData }] = use_GroupsQuery({
    variables: {
      where: {
        divisionAggregate: {
          count_GT: 0,
        },
        division: {
          class: {
            schoolYear: {
              uuid: currentSchoolYear?.uuid,
            },
          },
        },
      },
      options: {
        sort: [
          {
            name: SortDirection.Asc,
          },
        ],
      },
    },
    context: groupContext,
    pause,
  });

  const [{ data: divisionsData }] = use_DivisionsQuery({
    pause,
    context: divisionContext,
  });

  return {
    subjectData,
    subjectContainerData,
    roomsData,
    gradeGroupData,
    teacherData,
    lessonClassesData,
    classesData,
    groupsData,
    divisionsData,
    refetchTeacherData,
  };
};
