import { Button, Card, DuplicateIcon, ImportIcon, LazyLoader, Skeleton, Tab, Tooltip } from '@bp/ui-components';
import { Suspense, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../TimetableVersionData.module.scss';
import { TimetableVersionRoomsTableTable } from '../../../../../../components/TimetableVersion/TimetableVersionDataTables/TimetableVersionRoomsTable';
import { useNavigate, useParams } from 'react-router-dom';
import { TimetableVersionTableType } from '../../../../../../components/TimetableVersion/graphql/types';
import { TimetableVersionRoomsMatrix } from '../../../../../../components/TimetableVersion/TimetableVersionDataAvailabilityMatrix/TimetableVersionRoomsMatrix';
import { useMemorizedCacheTag } from '../../../../../../hooks/useMemorizedCacheTag';
import {
  TimetableRelationshipProperties,
  use_UpdateTimetableVersionsMutation,
} from '../../../../../../types/planung-graphql-client-defs';
import { availabilityStore } from '../../TimetableVersion';
import { createLessonFilterUrl } from '../../../../../../utils/create-lesson-filter-url';
import { showSuccessSaveToast, showUserErrorToast } from '../../../../../../utils/toast';
import { useTimetableStore } from '../../../../../../components/TimetableGrid/TimetableProvider';
import { useLoadBasicData } from '../../../../../../hooks/useLoadBasicData';
import { initialMatrix } from '../../../../../../components/TimetableVersion/utils/TimetableVersionTableUtils';
import { observer } from 'mobx-react-lite';

export const PlanTimetableRooms = observer(() => {
  const { t } = useTranslation();
  const { versionUuid } = useParams();
  const navigate = useNavigate();

  const store = useTimetableStore();
  const timeAvailabilityStore = store.getTimeAvailabilityStore();

  const [tab, setTab] = useState<'availability' | 'condition'>('availability');
  const [selected, setSelected] = useState<TimetableVersionTableType | null>(null);

  const [loading, setLoading] = useState(false);

  const versionsContext = useMemorizedCacheTag('TIMETABLE_VERSIONS');
  const [, updateVersion] = use_UpdateTimetableVersionsMutation();

  const versionRoomUuids: string[] = Array.from(store.rooms.keys());

  const { roomsData } = useLoadBasicData({ pause: !versionUuid });

  const roomsTableData: TimetableVersionTableType[] = useMemo(() => {
    return (
      roomsData?.rooms.map((r) => {
        return {
          uuid: r.uuid,
          name: r.name,
          count: 0,
          shortName: r.roomNumber ?? '',
          color: {},
        };
      }) ?? []
    );
  }, [roomsData]);

  async function handleChange(items: TimetableRelationshipProperties) {
    if (versionUuid && selected) {
      store.removeConflicts();

      availabilityStore?.setAvailability('room', selected.uuid, items, versionUuid);
      timeAvailabilityStore?.setResourceAvailability(
        [
          {
            uuid: selected.uuid,
            days: {
              mon: items.mon,
              tue: items.tue,
              wed: items.wed,
              thu: items.thu,
              fri: items.fri,
              sat: items.sat,
              sun: items.sun,
            },
          },
        ],
        'room',
      );
      store.setConflicts();
    }
  }

  async function handleImport() {
    setLoading(true);
    if (versionUuid) {
      const result = await updateVersion(
        {
          where: {
            uuid: versionUuid,
          },
          update: {
            rooms: [
              { connect: [{ where: { node: { uuid_IN: delta?.map((r) => r.uuid) } }, edge: { ...initialMatrix } }] },
            ],
          },
        },
        versionsContext,
      );
      if (!result || result.error) {
        showUserErrorToast({ text: t('common.errorToastText'), error: result.error });
      } else {
        showSuccessSaveToast();
      }
    }
    store.addRooms(delta ?? []);
    setLoading(false);
  }

  const delta = roomsData?.rooms.filter((c) => !versionRoomUuids.includes(c.uuid));

  const onRemove = async (uuids: string[]) => {
    if (store.readonly) return;
    setSelected(roomsTableData[0]);
    await updateVersion(
      {
        where: {
          uuid: versionUuid,
        },
        update: {
          rooms: [{ disconnect: [{ where: { node: { uuid_IN: uuids } } }] }],
        },
      },
      versionsContext,
    );
    store.removeRooms(uuids);
  };

  return (
    <div>
      <div className={'tks__grid'}>
        <div className='tks__row row end-xs'>
          <Button
            hierarchy='tertiary'
            icon={<ImportIcon />}
            className='mb-4'
            style={{ marginLeft: 'auto' }}
            onClick={handleImport}
            disabled={!delta?.length || loading || store.readonly}
          >
            {t('timetableVersion.import', { import: t('rooms.title', { count: 2 }) })}
          </Button>
        </div>
        <div className={'tks__row'}>
          <div className={`${roomsTableData.length === 0 ? 'col-xs-12' : 'col-xs-6'} ${styles['left']}`}>
            <TimetableVersionRoomsTableTable
              onRowSelection={(selected) => {
                setSelected(selected);
              }}
              onShowLessonClick={(uuid) => {
                if (versionUuid) {
                  navigate(
                    createLessonFilterUrl({
                      context: 'stundenplan',
                      filterValue: uuid,
                      versionUuid: versionUuid,
                      column: 'rooms',
                    }),
                  );
                }
              }}
              versionUuid={versionUuid ?? ''}
              versionRoomUuids={versionRoomUuids}
              onRemove={onRemove}
            />
          </div>
          {roomsTableData.length !== 0 && (
            <div className={'tks__col col-xs-6'}>
              <Suspense fallback={<LazyLoader />}>
                <Card className={styles['version-data']} contentPadding='none' fitContent>
                  <small>{t('timetableVersion.specificInfo', { for: selected?.name })}</small>
                  <div className={styles['mini-tab']}>
                    <Tab
                      className={styles.tabs}
                      onValueChange={(content) => setTab(content as 'availability' | 'condition')}
                      tabs={[
                        {
                          title: t('availability.title', { count: 2 }),
                          value: 'availability',
                          content: (
                            <TimetableVersionRoomsMatrix
                              timeGridEntries={store.timeGridEntries ?? []}
                              versionUuid={versionUuid}
                              selected={selected}
                              onChange={handleChange}
                            />
                          ),
                        },
                        {
                          title: t('conditions.title', { count: 2 }),
                          value: 'condition',
                          content: (
                            <div className='px-6 py-4'>
                              <Skeleton />
                            </div>
                          ),
                        },
                      ]}
                    />
                    <div className={styles['additional-actions']}>
                      {tab === 'availability' && (
                        <Tooltip content={t('availability.duplicate')}>
                          <Button hierarchy='ghost' icon={<DuplicateIcon />} disabled />
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </Card>
              </Suspense>
            </div>
          )}
        </div>
      </div>
    </div>
  );
});
