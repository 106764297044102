import { IconMenu } from '../../../../../components/IconMenu/IconMenu';
import { IconMenuItem } from '../../../../../components/IconMenuItem/IconMenuItem';
import { Suspense } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ClassesIllustration,
  DependenciesIllustration,
  LazyLoader,
  LessonIllustration,
  RoomIllustration,
  SubjectIllustration,
  TeacherIllustration,
} from '@bp/ui-components';
import { Page } from '../../../../../components/Page/Page';

export const TeachingBlockVersionData = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Page title={null}>
      <IconMenu className='mb-6'>
        <IconMenuItem
          label={t('classes.title', { count: 2 })}
          icon={<ClassesIllustration />}
          bubbleType={3}
          onClick={() => navigate(t('routes.versionsFilter.class.slug'))}
          active={location.pathname.includes(t('routes.versionsFilter.class.slug'))}
        />
        <IconMenuItem
          label={t('subject.title', { count: 2 })}
          icon={<SubjectIllustration />}
          bubbleType={1}
          onClick={() => navigate(t('routes.versionsFilter.subject.slug'))}
          active={location.pathname.includes(t('routes.versionsFilter.subject.slug'))}
        />
        <IconMenuItem
          label={t('persons.title', { count: 2 })}
          icon={<TeacherIllustration />}
          bubbleType={2}
          onClick={() => navigate(t('routes.versionsFilter.person.slug'))}
          active={location.pathname.includes(t('routes.versionsFilter.person.slug'))}
        />
        <IconMenuItem
          label={t('rooms.title', { count: 2 })}
          icon={<RoomIllustration />}
          bubbleType={4}
          onClick={() => navigate(t('routes.versionsFilter.room.slug'))}
          active={location.pathname.includes(t('routes.versionsFilter.room.slug'))}
        />
        <IconMenuItem
          label={t('lesson.title', { count: 2 })}
          icon={<LessonIllustration />}
          bubbleType={18}
          onClick={() => navigate(t('routes.versionsFilter.lesson.slug'))}
          active={location.pathname.includes(t('routes.versionsFilter.lesson.slug'))}
        />
        <IconMenuItem
          label={t('dependencies.title', { count: 2 })}
          icon={<DependenciesIllustration />}
          bubbleType={11}
          onClick={() => navigate(t('routes.versionsFilter.dependency.slug'))}
          active={location.pathname.includes(t('routes.versionsFilter.dependency.slug'))}
        />
      </IconMenu>
      <Suspense fallback={<LazyLoader transparent size='xxl' forceHeight='50vh' />}>
        <Outlet />
      </Suspense>
    </Page>
  );
};
