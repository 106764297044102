import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { OrganizationsTable } from '../../../components/Organizations/OrganizationsTable';
import { Page } from '../../../components/Page/Page';

export const OrganizationsSubpage: FC = () => {
  const { t } = useTranslation();

  return (
    <Page title={t('organizations.title', { count: 2 })}>
      <OrganizationsTable />
    </Page>
  );
};
