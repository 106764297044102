import styles from './DeputatePlanPage.module.scss';
import {
  InfoIcon,
  LessonIllustration,
  ManualDeputateIllustration,
  Tooltip,
  TransferQuotaTableIllustration,
} from '@bp/ui-components';
import { IconMenu } from '../../../components/IconMenu/IconMenu';
import { IconMenuItem } from '../../../components/IconMenuItem/IconMenuItem';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { Page } from '../../../components/Page/Page';
import { observer } from 'mobx-react-lite';

export const DeputatePlanPage = observer(() => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userConfigContext = useUserConfigContext();

  const schoolYear = userConfigContext.selectedSchoolYear;

  return (
    <Page
      title={`${t('deputate.title', { count: 2 })} ${t('deputate.plan').toLowerCase()}`}
      className={styles['deputate-plan-page']}
      fullHeight
    >
      <div className={styles.hint}>
        {t('schoolYear.selectedSchoolyear', { year: schoolYear?.name })}
        <Tooltip triggerClass={styles.year} content={t('deputate.tooltipContent')}>
          <InfoIcon className='small' />
        </Tooltip>
      </div>
      <IconMenu className={'mb-10'}>
        <IconMenuItem
          label={t('lesson.title', { count: 2 })}
          icon={<LessonIllustration />}
          bubbleType={18}
          onClick={() => navigate(t('routes.lessons.slug'))}
        />
        <IconMenuItem
          label={t('deputate.manual')}
          icon={<ManualDeputateIllustration />}
          bubbleType={15}
          onClick={() => navigate(t('routes.deputate.additional.slug'))}
        />
        <IconMenuItem
          label={t('curriculum.copyCurriculum')}
          icon={<TransferQuotaTableIllustration />}
          bubbleType={13}
          onClick={() => navigate(t('routes.classes.slug'))}
        />
      </IconMenu>
    </Page>
  );
});
