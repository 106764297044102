import { useTranslation } from 'react-i18next';
import { Page } from '../../../components/Page/Page';
import { Skeleton } from '@bp/ui-components';

export const SubstitutionsEvaluatePage = () => {
  const { t } = useTranslation();

  return (
    <Page title={`${t(`substitutions.title`, { count: 2 })} ${t('substitutions.evaluate').toLowerCase()}`}>
      <Skeleton />
    </Page>
  );
};
