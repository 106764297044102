import { memo, ReactNode } from 'react';
import styles from './IconMenu.module.scss';
import classNames from 'classnames';

type IconMenuProps = {
  fullWidth?: boolean;
  headline?: string;
  hasMarginBottom?: boolean;
  hasMarginTop?: boolean;
  children?: ReactNode | undefined;
  className?: string;
  noWrap?: boolean;
};

const IconMenuComponent = ({
  fullWidth = false,
  headline,
  hasMarginTop,
  hasMarginBottom,
  children,
  className,
  noWrap,
}: IconMenuProps) => {
  const classes = classNames(
    styles['icon-menu'],
    {
      [styles['full-width']]: fullWidth,
      [styles['margin-top']]: hasMarginTop,
      [styles['margin-bottom']]: hasMarginBottom,
    },
    className,
  );

  const childrenClasses = classNames(styles.children, {
    [styles['no-wrap']]: noWrap,
  });

  return (
    <div className={classes}>
      {headline && <div className={styles.headline}>{headline}</div>}
      <div className={childrenClasses}>{children}</div>
    </div>
  );
};

IconMenuComponent.displayName = 'IconMenu';

export const IconMenu = memo(IconMenuComponent);
