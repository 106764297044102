import { VisibilityState } from '@tanstack/table-core';
import { Updater } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useUserConfigContext } from './useUserConfigContext';

export function useHiddenColumns(tableName: string, initial?: VisibilityState) {
  const userConfig = useUserConfigContext();
  const tableConfigs = userConfig.tableConfigs;

  const hiddenColumns = tableConfigs
    ?.find((tc) => tc.table === tableName)
    ?.hiddenColumns.reduce((acc: { [key: string]: boolean }, currentValue) => {
      acc[currentValue] = false;
      return acc;
    }, {});
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(hiddenColumns ?? initial ?? {});

  const saveColumnVisibility = (updaterOrValue: Updater<VisibilityState>) => {
    if (updaterOrValue) {
      setColumnVisibility(updaterOrValue as VisibilityState);
    }
  };

  useEffect(() => {
    const hiddenColumns = Object.entries(columnVisibility as VisibilityState)
      .filter((a) => !a[1]) // filter only false
      .map((b) => b[0]); // we need only the string
    const a = async () => {
      await userConfig.saveHiddenColumns(tableName, hiddenColumns);
    };
    a();
  }, [columnVisibility, tableName, userConfig]);

  return { columnVisibility, saveColumnVisibility };
}
