import { useTranslation } from 'react-i18next';
import { SubstitutionSubjectsTable } from '../../../../components/SubstitutionSubjects/tables/SubstitutionSubjectsTable';
import {
  ActionBar,
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  Card,
  Grid,
  GridColumn,
  GridRow,
  TimetableView,
  UploadIcon,
} from '@bp/ui-components';
import styles from './PlanSubstitutions.module.scss';
import dayjs from 'dayjs';
import { useState } from 'react';

export const PlanSubstitutions = () => {
  const { t } = useTranslation();

  const [selectedClass] = useState<string | null>(null);

  return (
    <div className={styles['plan-stubstitutions']}>
      <ActionBar
        onGlobalFilterChange={() => console.log('TODO: implement')}
        extendedActionsRight={
          <>
            <Button hierarchy='tertiary' onClick={() => console.log('TODO: implement')}>
              {t('common.showAll')}
            </Button>
            <Button hierarchy='tertiary' icon={<UploadIcon />} onClick={() => console.log('TODO: implement')}>
              {t('substitutions.publish')}
            </Button>
          </>
        }
        showPrintButton
        onPrintClick={() => console.log('TODO: implement')}
      />
      <Grid useFormGap>
        <GridRow>
          <GridColumn width={9}>
            <SubstitutionSubjectsTable />
          </GridColumn>
          <GridColumn width={3}>
            <Card>
              <div className={styles.actions}>
                <Button
                  hierarchy='tertiary'
                  icon={<ArrowLeftIcon />}
                  disabled={!selectedClass}
                  onClick={() => console.log('TODO: implement')}
                />
                <div className={styles['selected-class']}>{selectedClass ?? t('substitutions.noClassSelected')}</div>
                <Button
                  hierarchy='tertiary'
                  icon={<ArrowRightIcon />}
                  disabled={!selectedClass}
                  onClick={() => console.log('TODO: implement')}
                />
              </div>
              <TimetableView
                showTimegrid
                start={dayjs().set('hour', 6).toDate()}
                end={dayjs().set('hour', 18).toDate()}
                entries={[]}
              />
            </Card>
          </GridColumn>
        </GridRow>
      </Grid>
    </div>
  );
};
