import { RouteObject } from 'react-router-dom';
import { SubstitutionsPlanPage } from './SubstitutionsPlanPage';
import { t } from 'i18next';
import { SubstitutionsPlanSubpage } from './SubstitutionsPlanSubpage';
import { PlanTeacherAbsences } from './PlanTeacherAbsences/PlanTeacherAbsences';
import { PlanSubstitutions } from './PlanSubstitutions/PlanSubstitutions';
import { PlanAnnouncements } from './PlanAnnouncements/PlanAnnouncements';

export const substitutionPlanRoutes = (baseSlug: string): RouteObject[] => {
  return [
    {
      index: true,
      element: <SubstitutionsPlanPage />,
    },
    {
      path: t('routes.absences.slug'),
      element: <SubstitutionsPlanSubpage baseSlug={baseSlug} />,
      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.absences.slug')}`,
          title: t('routes.absences.title'),
        }),
      },
      children: [
        {
          element: <PlanTeacherAbsences />,
          index: true,
        },
      ],
    },
    {
      path: t('routes.substitutions.slug'),
      element: <SubstitutionsPlanSubpage baseSlug={baseSlug} />,
      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.substitutions.slug')}`,
          title: t('routes.substitutions.title'),
        }),
      },
      children: [
        {
          element: <PlanSubstitutions />,
          index: true,
        },
      ],
    },
    {
      path: t('routes.announcements.slug'),
      element: <SubstitutionsPlanSubpage baseSlug={baseSlug} />,
      handle: {
        crumb: () => ({
          slug: `${baseSlug}/${t('routes.announcements.slug')}`,
          title: t('routes.announcements.title'),
        }),
      },
      children: [
        {
          element: <PlanAnnouncements />,
          index: true,
        },
      ],
    },
  ];
};
