import { useMemo } from 'react';

export const cacheTags = {
  PERSONS: 'Person',
  CLASSES: 'planung',
  DIVISIONS: 'Division',
  CLASS_DIVISIONS: 'planung',
  SUBJECTS: 'Subject',
  SUBJECT_CONTAINER: 'SubjectContainer',
  SCHOOL_YEAR: 'planung',
  ROOMS: 'Room',
  LESSONS: 'Lesson',
  LESSONS_WITHOUT_CARDS: 'LESSONS_WITHOUT_CARDS',
  TIME_GRID: 'planung',
  BUILDINGS: 'planung',
  TIMETABLE: 'Timetable',
  TIMETABLE_BLOCK: 'TimetableBlock',
  DEPENDENCIES: 'Dependency',
  TEACHINGLOADFACTOR: 'TeachingLoadFactor',
  VERSIONS: 'VERSIONS',
  VERSIONS_BOARD: 'VERSIONS_BOARD',
  TIMETABLE_VERSIONS: 'TimetableVersion',
  CLASSES_MATRIX: 'classes_matrix',
  ROOMS_MATRIX: 'rooms_matrix',
  SUBJECT_MATRIX: 'subject_matrix',
  TEACHER_MATRIX: 'teacher_matrix',
  CLASSES_CONSTRAINTS: 'CLASSES_CONSTRAINTS',
  GRADE_GROUPS: 'GradeGroup',
  SUBJECT_GROUPS: 'subject_group',
  CLASS_GRID_CARDS: 'ClassGridCard',
  ORGANIZATION: 'organization',
  APPLICATION_GRANT: 'application_grant',
  PIM_PROFILE: 'pim_profile',
  CURRICULA: 'CURRICULA',
  CURRICULUM_CLASSES: 'CURRICULUM_CLASSES',
  CURRICULUM_VERSIONS: 'CURRICULUM_VERSIONS',
  LABELS: 'LABELS',
  ADDITIONAL_TEACHINGLOAD: 'additionalTeachingLoads',
  DEPUTATE: 'DEPUTATE',
  TEACHINGBLOCK: 'TeachingBlock',
  TEACHINGBLOCK_VERSIONS: 'TeachingBlockVersion',
  TEACHINGBLOCK_LESSONS: 'TEACHINGBLOCK_LESSONS',
  SUBJECT_GRID_CARDS: 'SUBJECT_GRID_CARDS',
  TEACHING_BLOCK_CLASS_GRID_CARD: 'TeachingBlockClassGridCard',
  TEACHING_BLOCK_CARDS: 'TeachingBlockCard',
  TIMETABLE_CARD: 'TimetableCard',
  LESSON_UNITS: 'LessonUnits',
  TEACHING_BLOCK_AVAILABILITY_EXCLUSION: 'TeachingBlockAvailabilityExclusion',
  TIMETABLE_PRINT_CONFIG: 'TimetablePrintConfig',
  LESSON_CLASS: 'LessonClass',
  EVENT: 'Event',
  CLASSES_FIXED: ' Class',
  GROUPS: 'Group',
};

function useMemorizedCacheTag(tag: keyof typeof cacheTags, suffix?: string | null) {
  return useMemo(() => {
    return getCacheTag(tag, suffix);
  }, [tag, suffix]);
}

function useMemorizedCacheTags(tags: Array<keyof typeof cacheTags>) {
  return useMemo(() => {
    return getCacheTags(tags);
  }, [tags]);
}

function getCacheTag(tag: keyof typeof cacheTags, suffix?: string | null) {
  return {
    additionalTypenames: [cacheTags[tag] + (suffix ? `-${suffix}` : '')],
  };
}

function getCacheTags(tags: Array<keyof typeof cacheTags>) {
  return {
    additionalTypenames: tags.map((tag) => cacheTags[tag]),
  };
}

export { useMemorizedCacheTag, getCacheTag, useMemorizedCacheTags, getCacheTags };
