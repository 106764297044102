import React from 'react';
import { SubjectsType } from '../graphql/types';
import { FormikHelpers } from 'formik/dist/types';
import { Form, Formik } from 'formik';
import { schema } from './validation/schema';
import {
  Checkbox,
  ColorPicker,
  Grid,
  GridColumn,
  GridRow,
  Input,
  ModalBottomButtons,
  Select,
  SelectOptionType,
} from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { MultiValue, SingleValue } from 'react-select';
import { colorPickerOptions, UsedColors } from '../../../utils/colorPickerOptions';
import { useSubject } from '../graphql/hooks/useSubject';
import { showSuccessSaveToast, showUserErrorToast } from '../../../utils/toast';

type SubjectFormProps = {
  subjectUuid: string | null;
  closeForm: () => void;
  htmlColorsInUse: Array<UsedColors | null>;
};

export type SubjectFormType = {
  name: string;
  subjectHourEnabled: boolean;
  epochEnabled: boolean;
  active: boolean;
  other: boolean;
  shortName: string;
  defaultRooms?: string[];
  subjectGroup: string;
  color: string;
  colorLabel: string;
};

export const SubjectForm = ({ subjectUuid, closeForm, htmlColorsInUse }: SubjectFormProps) => {
  const { t } = useTranslation();

  const { createSubject, updateSubject, loadingState, data, subjectGroupsData, roomsData } = useSubject();

  const currentSubject = data?.subjects.find((subject: SubjectsType) => subject.uuid === subjectUuid);

  const initialValues: SubjectFormType = {
    name: currentSubject?.name ?? '',
    active: currentSubject?.active ?? true,
    shortName: currentSubject?.shortName ?? '',
    subjectGroup: currentSubject?.subjectGroup?.uuid ?? '',
    epochEnabled: currentSubject?.epochEnabled ?? false,
    subjectHourEnabled: currentSubject?.subjectHourEnabled ?? false,
    other: currentSubject?.other ?? false,
    color: currentSubject?.timetableConfig?.color ?? '',
    colorLabel: currentSubject?.timetableConfig?.colorLabel ?? '',
    defaultRooms: currentSubject?.defaultRoomsConnection?.edges.map(({ node }) => node.uuid) ?? [],
  };

  const subjectGroupOptions: SelectOptionType[] = subjectGroupsData
    ? subjectGroupsData.subjectGroups.map((subject) => {
        return { value: subject.uuid, label: subject.name };
      })
    : [];

  const roomsOptions: SelectOptionType[] = roomsData
    ? roomsData.rooms.map((room) => {
        return { value: room.uuid, label: room.name };
      })
    : [];

  const colorPickerData = colorPickerOptions({
    htmlColorsInUse: htmlColorsInUse,
    currentColor: initialValues.color ?? '',
    currentColorUsedBy: initialValues.name,
    forTypename: 'Subject',
  });

  const handleSubmit = async (values: SubjectFormType, formikHelpers: FormikHelpers<SubjectFormType>) => {
    let result;
    if (currentSubject?.uuid) {
      result = await updateSubject(currentSubject.uuid, values);
    } else {
      result = await createSubject(values);
    }
    if (!result || result.error) {
      showUserErrorToast({ error: result.error });
    } else {
      showSuccessSaveToast();
    }
    formikHelpers.resetForm();
    closeForm();
  };

  return (
    <Formik<SubjectFormType>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({
        values,
        handleChange,
        handleBlur,
        resetForm,
        dirty,
        isValidating,
        isSubmitting,
        setFieldTouched,
        setFieldValue,
        errors,
        touched,
      }) => (
        <Form>
          <Grid useFormGap>
            <GridRow spacingBottom={'none'}>
              <GridColumn width={10}>
                <GridRow spacingBottom={'none'}>
                  <GridColumn width={6}>
                    <Input
                      label={t('subject.name')}
                      name={'name'}
                      onChange={handleChange}
                      value={values.name}
                      error={touched.name ? errors.name : undefined}
                    ></Input>
                  </GridColumn>
                  <GridColumn width={6}>
                    <Input
                      label={t('common.shortName')}
                      name={'shortName'}
                      onChange={handleChange}
                      value={values.shortName}
                      error={touched.shortName ? errors.shortName : undefined}
                    />
                  </GridColumn>
                </GridRow>
                <GridRow spacingBottom={'none'}>
                  <GridColumn width={6}>
                    <Select
                      name={'subjectGroup'}
                      onChange={async (options) => {
                        const opt = options as SingleValue<SelectOptionType>;
                        await setFieldTouched('subjectGroup', true);
                        await setFieldValue('subjectGroup', opt?.value ?? '');
                      }}
                      options={subjectGroupOptions}
                      value={subjectGroupOptions.find((option) => option.value === values.subjectGroup)}
                      isSearchable
                      isClearable
                      label={t('subjectGroup.title', { count: 1 })}
                    />
                  </GridColumn>
                  <GridColumn width={6}>
                    <ColorPicker
                      name='color'
                      label={t('common.color')}
                      options={colorPickerData.groupedColorOptions}
                      onChange={async (option) => {
                        await setFieldValue('color', option?.html ?? '');
                        await setFieldValue('colorLabel', option?.label ?? '');
                        await setFieldTouched('color', true, false);
                      }}
                      onBlur={handleBlur}
                      defaultValue={colorPickerData.currentColor}
                    />
                  </GridColumn>
                </GridRow>
                <GridRow>
                  <GridColumn width={12}>
                    <Select
                      label={t('subject.defaultRooms')}
                      isSearchable={true}
                      options={roomsOptions}
                      isMulti={true}
                      value={roomsOptions.filter((option) => values.defaultRooms?.includes(option.value as string))}
                      onChange={async (options) => {
                        const opt = options as MultiValue<SelectOptionType>;
                        await setFieldTouched('defaultRooms', true);
                        await setFieldValue('defaultRooms', opt?.map((option) => option.value) ?? []);
                      }}
                      name={'rooms'}
                    ></Select>
                  </GridColumn>
                </GridRow>
              </GridColumn>
              <GridColumn width={2}>
                <GridRow direction={'column'}>
                  <div className={'mt-3 ml-3'}>
                    <Checkbox
                      name={'subjectHourEnabled'}
                      label={t('subject.subjectHour')}
                      checked={values.subjectHourEnabled ?? false}
                      onChange={async (event) => {
                        await setFieldValue('subjectHourEnabled', event.target.checked);
                        await setFieldTouched('subjectHourEnabled');
                      }}
                    />
                    <Checkbox
                      name={'epochEnabled'}
                      label={t('subject.epoch')!}
                      checked={values.epochEnabled ?? false}
                      onChange={async (event) => {
                        await setFieldValue('epochEnabled', event.target.checked);
                        await setFieldTouched('epochEnabled');
                      }}
                    />
                    <Checkbox
                      name={'other'}
                      label={t('common.other')}
                      checked={values.other ?? false}
                      onChange={async (event) => {
                        await setFieldValue('other', event.target.checked);
                        await setFieldTouched('other');
                      }}
                    />
                    <Checkbox
                      name={'active'}
                      label={t('common.active.full') as string}
                      checked={values.active ?? false}
                      onChange={async (event) => {
                        await setFieldValue('active', event.target.checked);
                        await setFieldTouched('active');
                      }}
                    />
                  </div>
                </GridRow>
              </GridColumn>
            </GridRow>
          </Grid>
          <ModalBottomButtons
            closeButton={{
              text: t('common.cancelChanges'),
              callback: () => {
                resetForm();
                closeForm();
              },
            }}
            submitButton={{ disabled: isSubmitting || !dirty || isValidating }}
            isLoading={loadingState.some((loading) => loading.loading)}
            errors={errors}
          />
        </Form>
      )}
    </Formik>
  );
};
