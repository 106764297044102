import { DatePicker, Grid, GridColumn, GridRow, Select, SelectOptionType } from '@bp/ui-components';
import { ModalBottomButtons } from '../../ModalBottomButtons/ModalBottomButtons';
import { Formik } from 'formik';
import { Form } from 'react-router-dom';
import styles from './TimetableBlockForm.module.scss';
import { useTranslation } from 'react-i18next';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { useTimetableQuery } from '../../../types/planung-graphql-client-defs';
import { TimetableBlockType } from '../graphql';
import { MultiValue } from 'react-select';
import { showSuccessCreateToast, showSuccessUpdateToast, showUserErrorToast } from '../../../utils/toast';
import { timetableBlockSchema } from './validation/schema';
import { ProcessedTimetableBlockType, useTimetableBlock } from '../useTimetableBlock';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { useCallback, useMemo, useState } from 'react';
import dayjs from 'dayjs';

type TimetableBlockFormProps = {
  block: ProcessedTimetableBlockType;
  canEditStart: boolean;
  canEditEnd: boolean;
  canEditTimetables: boolean;
  onClose: () => void;
};

export const TimetableBlockForm = ({
  block,
  canEditStart,
  canEditEnd,
  canEditTimetables,
  onClose,
}: TimetableBlockFormProps) => {
  const { t } = useTranslation();
  const schoolYear = useUserConfigContext().selectedSchoolYear;

  const {
    createTimetableBlock,
    updateTimetableBlock,
    getFormData,
    getEarliestEnd,
    getLatestStart,
    findOverlappingTimetables,
  } = useTimetableBlock();

  const context = useMemorizedCacheTag('TIMETABLE');

  const [{ data: timetableData }] = useTimetableQuery({
    variables: {
      where: {
        schoolYear: {
          uuid: schoolYear?.uuid,
        },
      },
    },
    context,
  });

  const timetables = useMemo(() => {
    return timetableData?.timetables ?? [];
  }, [timetableData?.timetables]);

  const initialTimetableOptions = useMemo(() => {
    return timetables.map((tt) => ({
      label: tt.name,
      value: tt.uuid,
    }));
  }, [timetables]);

  const [timetableOptions, setTimetableOptions] = useState(initialTimetableOptions);

  const values: TimetableBlockType = getFormData(block.uuid);

  async function handleSubmit(values: TimetableBlockType) {
    if (block.empty) {
      const result = await createTimetableBlock(values);
      if (result.error) {
        showUserErrorToast({ error: result.error });
      } else {
        showSuccessCreateToast();
      }
    } else {
      const result = await updateTimetableBlock(values, block.uuid);
      if (result.error) {
        showUserErrorToast({ error: result.error });
      } else {
        showSuccessUpdateToast();
      }
    }
    onClose();
  }

  const filterWithOverlappingClasses = useCallback(
    (values: TimetableBlockType) => {
      const selectedClasses: { [timetableUuid: string]: string[] }[] = values.timetables.flatMap((tt) =>
        tt.versions.map((tv) => ({ [tt.uuid]: tv.classes.map((cl) => cl.uuid) })),
      );

      const otherTimetables = timetables.filter(
        (tt) => !values.timetables.map((timetable) => timetable.uuid).includes(tt.uuid),
      );

      const otherClasses: { [timetableUuid: string]: string[] }[] = otherTimetables.flatMap((tt) =>
        tt.versions.map((tv) => ({ [tt.uuid]: tv.classes.map((cl) => cl.uuid) })),
      );

      const overlaps = findOverlappingTimetables(selectedClasses, otherClasses);

      // remove timetables with overlapping classes from select
      setTimetableOptions(initialTimetableOptions.filter((p) => !overlaps.includes(p.value)));
    },
    [findOverlappingTimetables, initialTimetableOptions, timetables],
  );

  const notBefore = getLatestStart(values.uuid);
  const notAfter = getEarliestEnd(values.uuid);

  return (
    <div className={styles['timetable-block-form']}>
      <Formik initialValues={values} onSubmit={handleSubmit} validationSchema={timetableBlockSchema}>
        {({
          values,
          setFieldValue,
          setFieldTouched,
          setFieldError,
          resetForm,
          isSubmitting,
          isValidating,
          dirty,
          errors,
          touched,
        }) => {
          return (
            <Form>
              <Grid useFormGap>
                <GridRow spacingBottom='s'>
                  <GridColumn width={6}>
                    <DatePicker
                      minDate={dayjs(notBefore).add(1, 'day').toDate()}
                      maxDate={dayjs(notAfter).subtract(1, 'day').toDate()}
                      label={t('common.start')}
                      value={values.start}
                      onChange={async (date) => {
                        await setFieldTouched('start');
                        await setFieldValue('start', date, true);
                      }}
                      name='start'
                      disabled={!values.timetables || values.timetables.length === 0 || !canEditStart}
                      error={errors.start as string}
                    />
                  </GridColumn>
                  <GridColumn width={6}>
                    <DatePicker
                      label={t('common.end')}
                      value={values.end}
                      minDate={dayjs(notBefore).add(1, 'day').toDate()}
                      maxDate={dayjs(notAfter).subtract(1, 'day').toDate()}
                      onChange={async (date) => {
                        await setFieldTouched('end');
                        await setFieldValue('end', date, true);
                      }}
                      name='end'
                      disabled={!values.timetables || values.timetables.length === 0 || !canEditEnd}
                      error={errors.end as string}
                    />
                  </GridColumn>
                </GridRow>
                <GridRow spacingTop='s' spacingBottom='s'>
                  <Select
                    name='timetables'
                    isSearchable
                    isClearable
                    disabled={!canEditTimetables}
                    isMulti
                    menuPosition={'fixed'}
                    label={t('timetable.active')}
                    defaultValue={values.timetables.map((timetable) => {
                      return { value: timetable.uuid, label: timetable.name };
                    })}
                    options={timetableOptions}
                    onChange={async (option) => {
                      const options = option as MultiValue<SelectOptionType>;
                      const timetable = timetables.find((timetable) =>
                        options.find((option) => timetable.uuid === option.value),
                      );
                      if (!option || !options || options.length === 0 || !timetable) {
                        setTimetableOptions(initialTimetableOptions);
                        resetForm();
                        setFieldValue('timetables', []);
                      } else {
                        if (!touched.start) {
                          await setFieldValue('start', values.start);
                          await setFieldTouched('start');
                        }
                        if (!touched.end) {
                          await setFieldValue('end', values.end);
                          await setFieldTouched('end');
                        }
                        await setFieldValue(
                          'timetables',
                          options.map((option) => timetables.find((timetable) => timetable.uuid === option.value)),
                        );

                        filterWithOverlappingClasses(values);
                      }
                    }}
                    error={errors.timetables as string}
                  />
                </GridRow>
              </Grid>

              <ModalBottomButtons
                closeButton={{
                  callback: () => {
                    resetForm();
                    onClose();
                  },
                  text: t('common.cancelChanges'),
                }}
                submitButton={{
                  disabled: isSubmitting || !dirty || isValidating,
                  callback: () => handleSubmit(values),
                }}
                isLoading={isSubmitting}
                errors={errors}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
