import {
  Button,
  Card,
  ControlIcon,
  DeleteIcon,
  EditIcon,
  HandIcon,
  LockIcon,
  PinIcon,
  RoomIcon,
  Tooltip,
  UnlockIcon,
  UnpinIcon,
} from '@bp/ui-components';
import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './CardInfo.module.scss';
import { observer } from 'mobx-react-lite';
import { PinboardCard } from '../../PinboardCard/PinboardCard';
import { CardType } from '../../TimetableVersion/graphql/types';
import classNames from 'classnames';
import { useTimetableStore } from '../TimetableProvider';
import { CardInfoConflict } from './CardInfoConflict';

export const CardInfo: FC = observer(() => {
  const { t } = useTranslation();

  const store = useTimetableStore();

  const gridType = store.context;
  const selectedRows = store.selectedRows;
  const selectedCard = store.pinnedCard || store.hoveredCard ? store.getCardToShowOnInfo() : null;

  function onPick(e: React.MouseEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    if (selectedCard) {
      store.pickCard({
        cardUuid: selectedCard.uuid,
        cardRows: selectedCard.badgeCardRows,
        duration: selectedCard.duration ?? 1,
        counter: 1,
        fromStack: false,
        label: selectedCard.subject?.shortName ?? '',
        labelColor: selectedCard.badgeCardTextColor,
        position: { x: e.clientX, y: e.clientY },
      });
    }
  }

  function onPin() {
    if (store.pinnedCard === selectedCard?.uuid) {
      store.pinnedCard = null;
      store.setPinnedCard(null);
    } else {
      store.setPinnedCard(selectedCard?.uuid ?? null);
    }
  }

  function onLock() {
    if (selectedCard) {
      if (store.lockedCards.has(selectedCard.uuid)) {
        store.unlockCard(selectedCard.uuid);
      } else {
        store.lockCard(selectedCard.uuid);
      }
    }
  }

  const onCardClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>, card: CardType) => {
      event.stopPropagation();
      event.preventDefault();
      if (!card.locked) {
        store.pickCard({
          cardUuid: card.uuid,
          cardRows: card.badgeCardRows,
          duration: card.duration ?? 1,
          counter: 1,
          fromStack: false,
          label: card.subject?.shortName ?? '',
          labelColor: card.badgeCardTextColor,
          position: { x: event.clientX, y: event.clientY },
        });
      }
    },
    [store],
  );

  const cardTitles = selectedCard
    ? (selectedCard?.subject?.name ?? '-')
    : selectedRows.length > 0
      ? gridType === 'subjects'
        ? selectedRows.map((value) => value.label).join(', ')
        : '-'
      : '-';

  const cardClasses = selectedCard
    ? (selectedCard?.classes.map((c) => c.shortName).join(', ') ?? '-')
    : selectedRows.length > 0
      ? gridType === 'classes'
        ? selectedRows.map((value) => value.label).join(', ')
        : '-'
      : '-';

  const cardTeachers = selectedCard
    ? (selectedCard?.teachers.map((t) => t.displayNameShort).join(', ') ?? '-')
    : selectedRows.length > 0
      ? gridType === 'teachers'
        ? selectedRows.map((value) => value.label).join(', ')
        : '-'
      : '-';

  const rooms = selectedCard ? store.cardRooms.get(selectedCard.uuid) : null;
  const roomsArray = rooms ? Array.from(rooms) : [];
  const cardRooms = selectedCard
    ? roomsArray.map((r) => r[1].label).join(', ')
    : selectedRows.length > 0
      ? gridType === 'rooms'
        ? selectedRows.map((value) => value.label).join(', ')
        : '-'
      : '-';

  const cardGroups =
    selectedCard?.groups
      .map((g) => {
        return g.shortName;
      })
      .filter((s) => !!s)
      .join(' / ') ?? '-';

  return (
    <Card
      wrapperClass={classNames(styles['card-info'], {
        [styles['control-mode']]: store.controlMode.isActive,
      })}
      className={styles['content-wrapper']}
      contentPadding='none'
      fitContent
    >
      <div className={styles['actions-wrapper']}>
        <Tooltip
          content={
            selectedCard?.uuid === store.pickedCard?.cardUuid
              ? t('pinboard.actions.unpick')
              : t('pinboard.actions.pick')
          }
        >
          <Button
            disabled={!selectedCard || store.lockedCards.has(selectedCard?.uuid ?? '') || store.readonly}
            icon={<HandIcon />}
            hierarchy='tertiary'
            onClick={(e) => onPick(e as React.MouseEvent<HTMLDivElement>)}
          />
        </Tooltip>
        <Tooltip
          content={store.pinnedCard === selectedCard?.uuid ? t('pinboard.actions.unpin') : t('pinboard.actions.pin')}
        >
          <Button
            disabled={!selectedCard}
            icon={store.pinnedCard === selectedCard?.uuid ? <UnpinIcon /> : <PinIcon />}
            hierarchy='tertiary'
            onClick={onPin}
          />
        </Tooltip>
        <Tooltip
          content={
            store.lockedCards.has(selectedCard?.uuid ?? '') ? t('pinboard.actions.unlock') : t('pinboard.actions.lock')
          }
        >
          <Button
            icon={store.lockedCards.has(selectedCard?.uuid ?? '') ? <UnlockIcon /> : <LockIcon />}
            disabled={!store.placedCardsDay.has(selectedCard?.uuid || '') || store.readonly}
            hierarchy='tertiary'
            onClick={onLock}
          />
        </Tooltip>
        <Tooltip content={t('pinboard.actions.showConditions')}>
          <Button
            disabled={!selectedCard}
            hierarchy='tertiary'
            icon={<ControlIcon />}
            onClick={() => {
              store.setConstraintModalOpen(true);
            }}
          />
        </Tooltip>
        <Tooltip content={t('pinboard.actions.edit')}>
          <Button
            icon={<EditIcon />}
            disabled={!selectedCard || store.readonly}
            hierarchy='tertiary'
            onClick={() =>
              store.setCardEditModal({
                isOpen: true,
              })
            }
          />
        </Tooltip>
        <Tooltip content={t('common.delete')}>
          <Button icon={<DeleteIcon />} disabled={true} hierarchy='tertiary' />
        </Tooltip>
        <div className={styles['info-icons']}>
          {selectedCard && selectedCard.rooms.length === 0 && (
            <Tooltip triggerClass={styles.info} content={t('pinboard.reason.noRoom')}>
              <RoomIcon />
            </Tooltip>
          )}
          <CardInfoConflict />
        </div>
      </div>

      <div className={styles['preview-wrapper']}>
        {selectedCard ? (
          <PinboardCard card={selectedCard} onClick={onCardClick} />
        ) : selectedRows.length > 0 ? (
          selectedRows.map((row, index) => {
            if (index <= 10) {
              if (index <= 9) {
                return (
                  <div key={index + String(row.value)} className={styles['selected-class']}>
                    {row.label.slice(0, 2)}
                  </div>
                );
              } else {
                return (
                  <div key={index + String(row.value)} className={styles['selected-class']}>
                    +{selectedRows.length - index}
                  </div>
                );
              }
            }
            return <></>;
          })
        ) : (
          <></>
        )}
      </div>

      <div className={styles['info-wrapper']}>
        <div className={styles.row}>
          <div className={styles.title}>{t('subject.title', { count: 1 })}</div>
          <div className={styles.content} title={cardTitles}>
            {cardTitles}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.title}>{t('classes.title', { count: 1 })}</div>
          <div className={styles.content} title={cardClasses}>
            {cardClasses}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.title}>{t('persons.title', { count: 1 })}</div>
          <div className={styles.content} title={cardTeachers}>
            {cardTeachers}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.title}>{t('rooms.title', { count: 1 })}</div>
          <div className={styles.content} title={cardRooms}>
            {cardRooms}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.title}>{t('common.group_other')}</div>
          <div className={styles.content} title={cardGroups}>
            {cardGroups}
          </div>
        </div>
      </div>
    </Card>
  );
});
