import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, DeleteIcon, EditIcon, Modal, Row, Table, TableColumns, Tooltip } from '@bp/ui-components';
import { ClassesForm } from '../Forms/ClassesForm';
import { useHiddenColumns } from '../../../hooks/useHiddenColumns';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { observer } from 'mobx-react-lite';
import { useColumnsSort } from '../../../hooks/useColumnsSort';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { useConfirm } from '../../../hooks/useConfirm';
import { useIsUsedInLesson } from '../../../hooks/useIsUsedInLesson';
import { partition } from '../../../utils/arrayFunc';
import { UsedInLessonsTooltipContent } from '../../TooltipContents/LessonUsedInTooltipContent/UsedInLessonsTooltipContent';
import { hexToColorOption } from '../../../utils/colorUtils';
import { showSuccessDeleteToast, showUserErrorToast } from '../../../utils/toast';
import { useDeleteClasses } from '../../../hooks/useDeleteClasses';
import { useLoadBasicData } from '../../../hooks/useLoadBasicData';

export type ClassesTableType = {
  uuid: string;
  name: string;
  shortName: string;
  grade: string;
  gradeGroup: string;
  tutor1: string;
  tutor2: string;
  tutor3: string;
  roomName: string;
  printOrder: number;
  printPageNr: number;
  color: {
    color: string;
    colorLabel: string;
  };
};

export const SettingsClassesTable: FC = observer(() => {
  const { pimAuthClaims } = useAuthClaims();

  const { confirm, ConfirmationDialog } = useConfirm();
  const check = useIsUsedInLesson();

  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [classUuid, setClassUuid] = useState<null | string>(null);
  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;
  const { columnVisibility, saveColumnVisibility } = useHiddenColumns('settings-classes-list', {
    tutor3: false,
    'timetableConfig.printOrder': false,
    'timetableConfig.printPageNr': false,
    color: false,
    roomName: false,
  });
  const { sorting, saveSorting } = useColumnsSort('settings-classes-list');

  const { classesData, gradeGroupData, roomsData, teacherData } = useLoadBasicData({ pause: false });

  const colorsInUse = classesData?.classes?.map((sc) => sc.timetableConfig ?? null) ?? [];

  const { deleteClass } = useDeleteClasses();

  function createColumns(): TableColumns<ClassesTableType>[] {
    return [
      {
        header: t('common.name'),
        accessorKey: 'name',
        id: 'name',
        size: 250,
        canExpand: true,
      },
      {
        header: t('classes.shortName'),
        accessorKey: 'shortName',
        id: 'shortName',
        size: 75,
      },
      {
        header: t('classes.level.short'),
        meta: {
          filterName: t('classes.level.full'),
          tooltip: t('classes.level.full'),
        },
        accessorKey: 'grade',
        id: 'grade',
        size: 75,
        cell: ({ row }) => {
          return row.original.grade ?? 'F';
        },
        accessorFn: ({ grade }) => {
          return grade ?? 'F';
        },
      },
      {
        header: t('gradeGroups.title', { count: 1 }),
        meta: {
          filterName: t('gradeGroups.title', { count: 1 }),
          tooltip: t('gradeGroups.title', { count: 1 }),
        },
        id: 'gradeGroup',
        accessorKey: 'gradeGroup',
        size: 75,
      },
      {
        header: `${t('classes.tutor.short')} 1`,
        meta: {
          filterName: `${t('classes.tutor.full')} 1`,
          tooltip: `${t('classes.tutor.full')} 1`,
        },
        size: 200,
        id: 'tutor1',
        accessorKey: 'tutor1',
      },
      {
        header: `${t('classes.tutor.short')} 2`,
        meta: {
          filterName: `${t('classes.tutor.full')} 2`,
          tooltip: `${t('classes.tutor.full')} 2`,
        },
        size: 200,
        id: 'tutor2',
        accessorKey: 'tutor2',
      },
      {
        header: `${t('classes.tutor.short')} 3`,
        meta: {
          filterName: `${t('classes.tutor.full')} 3`,
          tooltip: `${t('classes.tutor.full')} 3`,
        },
        size: 200,
        id: 'tutor3',
        accessorKey: 'tutor3',
      },
      {
        header: t('lesson.table.rooms'),
        id: 'roomName',
        accessorKey: 'roomName',
        size: 300,
      },
      {
        header: t('classes.printOrder.short'),
        meta: {
          filterName: t('classes.printOrder.full'),
          tooltip: t('classes.printOrder.full'),
        },
        accessorKey: 'printOrder',
        id: 'printOrder',
        size: 40,
      },
      {
        header: t('classes.printPageNr.short'),
        meta: {
          filterName: t('classes.printPageNr.full'),
          tooltip: t('classes.printPageNr.full'),
        },
        accessorKey: 'printPageNr',
        id: 'printPageNr',
        size: 40,
      },
      {
        header: t('common.color'),
        accessorKey: 'color',
        id: 'color',
        type: 'color',
      },
    ];
  }

  const tableColumns = useMemo(createColumns, [t]);
  const memoizedData = useMemo((): ClassesTableType[] => {
    return classesData
      ? classesData.classes.map((c) => {
          const roomName = roomsData?.rooms.find((room) => room.uuid === c.defaultRoom?.uuid)?.name ?? '';
          const { html, label } = hexToColorOption(c.timetableConfig?.color ?? '');
          const gradeGroup = gradeGroupData?.gradeGroups.find((gg) => gg.uuid === c.gradeGroup?.uuid);
          const tutor1 = teacherData?.people.find((t) => t.uuid === c.tutors[0]?.uuid);
          const tutor2 = teacherData?.people.find((t) => t.uuid === c.tutors[1]?.uuid);
          const tutor3 = teacherData?.people.find((t) => t.uuid === c.tutors[2]?.uuid);
          return {
            uuid: c.uuid,
            grade: c.grade ? c.grade.toString() : 'F',
            name: c.name,
            printOrder: c.timetableConfig?.printOrder ?? 0,
            printPageNr: c.timetableConfig?.printPageNr ?? 0,
            shortName: c.shortName,
            tutor1: tutor1?.displayNameShort ?? '',
            tutor2: tutor2?.displayNameShort ?? '',
            tutor3: tutor3?.displayNameShort ?? '',
            gradeGroup: gradeGroup?.name ?? '',
            roomName,
            color: {
              color: html,
              colorLabel: label,
            },
          };
        })
      : [];
  }, [classesData, gradeGroupData?.gradeGroups, roomsData?.rooms, teacherData?.people]);

  const onModalClose = () => {
    setClassUuid(null);
    setIsModalOpen(false);
  };

  const handleEdit = (row: Row<ClassesTableType>) => {
    setClassUuid(row.original.uuid ?? null);
    setIsModalOpen(true);
  };

  async function handleDelete(rows: Row<ClassesTableType>[]) {
    const [used, notUsed] = partition(rows, (row) => check(row.original.uuid, 'class').isUsed);
    const uuids = notUsed.map((r) => r.original.uuid);
    await confirm({
      message: (
        <div>
          <div>{t('classes.deleteConfirm', { count: uuids.length })}</div>
          <ul>
            {notUsed.map((s) => {
              return <li key={s.original.uuid}>{s.original.name}</li>;
            })}
          </ul>
          {used && (
            <>
              <div>{t('classes.canNotDelete', { count: used.length })}</div>
              <ul>
                {used.map((s) => {
                  return <li key={s.original.uuid}>{s.original.name}</li>;
                })}
              </ul>
            </>
          )}
        </div>
      ),
      onConfirm: async () => {
        if (uuids.length) {
          const response = await deleteClass(uuids[0]);
          if (response.error) {
            showUserErrorToast({ error: response.error });
          } else {
            showSuccessDeleteToast(notUsed.map((r) => r.original.name));
          }
        }
      },
    });
  }

  return (
    <>
      <Table<ClassesTableType>
        showBorderRadius
        showShadow
        canScroll
        minHeight={600}
        showVisibility
        breakpoint={null}
        isOnWhite={false}
        sorting={sorting}
        onSortingChange={saveSorting}
        columnVisibility={columnVisibility}
        onColumnVisibilityChange={saveColumnVisibility}
        columns={tableColumns}
        showSort={true}
        data={memoizedData}
        printerSettings={{
          headline: pimAuthClaims.getProfile()?.organization.name,
          subline: `${t('classes.title', { count: 2 })} - ${t('common.schoolYear')} ${currentSchoolYear?.shortName}`,
          filename: `${t('classes.title', { count: 2 })}_${currentSchoolYear?.shortName}`,
        }}
        showActionBar
        actionBarSettings={{
          showExpertFilter: true,
          showAddButton: currentSchoolYear?.name !== '',
          showPrintButton: true,
        }}
        onAddClick={() => {
          setClassUuid(null);
          setIsModalOpen(true);
        }}
        lastColWidth='80px'
        lastCol={(row) => {
          const used = check(row.original.uuid, 'class');
          const deleteButton = (
            <Button
              disabled={used.isUsed}
              hierarchy='secondary'
              type='button'
              icon={<DeleteIcon className='small' />}
              onClick={() => handleDelete([row])}
            />
          );
          return (
            <ButtonGroup>
              <Button
                hierarchy='secondary'
                type='button'
                onClick={() => handleEdit(row)}
                icon={<EditIcon className='small' />}
              />
              {used.isUsed ? (
                <Tooltip
                  content={
                    <UsedInLessonsTooltipContent lessons={used.lessons} usedInText={t('classes.usedInLesson')} />
                  }
                >
                  {deleteButton}
                </Tooltip>
              ) : (
                deleteButton
              )}
            </ButtonGroup>
          );
        }}
      />
      <Modal
        isOpen={isModalOpen}
        onRequestClose={onModalClose}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        title={classUuid ? (t('classes.editClass') as string) : (t('classes.createClass') as string)}
      >
        <ClassesForm
          classUuid={classUuid}
          htmlColorsInUse={colorsInUse}
          closeForm={() => {
            setIsModalOpen(false);
            setClassUuid(null);
          }}
        />
      </Modal>
      <ConfirmationDialog />
    </>
  );
});
