import { SubjectContainerListDataType } from '../../../SubjectContainer/graphql/types';
import {
  BadgeCard,
  Checkbox,
  Grid,
  GridColumn,
  GridRow,
  InfoIcon,
  Input,
  Select,
  SelectOptionType,
  Tooltip,
} from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useFormikContext } from 'formik';
import { SingleValue } from 'react-select';
import { LessonFormType } from '../../types';
import { useCreateSelectOptions } from '../../../../hooks/useCreateSelectOptions';
import { useLoadBasicData } from '../../../../hooks/useLoadBasicData';
import { observer } from 'mobx-react-lite';

export const LessonUnitFormItem = observer(({
  index,
  subjectContainer,
  disabled,
}: {
  index: number;
  subjectContainer: SubjectContainerListDataType[] | undefined;
  disabled: boolean;
}) => {
  const { t } = useTranslation();
  const formik = useFormikContext<LessonFormType>();
  const [options, setOptions] = useState<SelectOptionType[]>([]);

  const { subjectData } = useLoadBasicData({ pause: false });

  useEffect(() => {
    const updatedOptions = [];
    if (formik.values.subject) {
      const subject = subjectData?.subjects.find((s) => s.uuid === formik.values.subject?.uuid);
      if (subject?.subjectHourEnabled) {
        updatedOptions.push({ value: 'subjectHour', label: t('subject.subjectHour') });
      }
      if (subject?.epochEnabled) {
        updatedOptions.push({ value: 'epoch', label: t('subject.epoch') });
      }
      setOptions(updatedOptions);
    }
  }, [formik.values.subject, subjectData?.subjects]);

  const subjectContainerOptions = useCreateSelectOptions(subjectContainer, 'uuid', 'name');
  const lessonUnit = formik.values.lessonUnit;

  useEffect(() => {
    if (lessonUnit && lessonUnit[index].lessonUnitType === 'subjectHour') {
      formik.setFieldValue(`lessonUnit.${index}.subjectContainerUuid`, null);
    }
  }, [lessonUnit, index]); // don't add formik as dependency !!! ;

  return (
    <>
      {lessonUnit && lessonUnit[index] && (
        <Grid useFormGap>
          <GridRow spacingBottom={'none'}>
            <GridColumn width={6}>
              <Select
                readonly={disabled}
                label={t('lessonUnit.subjectType')}
                name={`lessonUnit.${index}.subjectType`}
                options={options}
                onChange={(option) => {
                  const opt = option as SingleValue<SelectOptionType>;
                  formik.setFieldValue(`lessonUnit.${index}.lessonUnitType`, opt?.value);
                }}
                value={options.find((o) => o.value === lessonUnit[index].lessonUnitType)}
              />
            </GridColumn>
            <GridColumn width={6}>
              <Select
                readonly={disabled}
                required={lessonUnit[index].lessonUnitType === 'epoch'}
                label={t('lessonUnit.subjectContainer')}
                disabled={lessonUnit[index].lessonUnitType === 'subjectHour'}
                name={`lessonUnit.${index}.subjectContainerUuid`}
                options={subjectContainerOptions}
                onChange={async (option) => {
                  const opt = option as SingleValue<SelectOptionType>;
                  await formik.setFieldTouched(`lessonUnit.${index}.subjectContainer`, true);
                  await formik.setFieldValue(`lessonUnit.${index}.subjectContainerUuid`, opt?.value);
                }}
                error={
                  formik.errors.lessonUnit
                    ? //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-expect-error
                      (formik.errors.lessonUnit[index]?.subjectContainerUuid ?? undefined)
                    : undefined
                }
                value={
                  formik.values.lessonUnit && formik.values.lessonUnit[index]?.subjectContainerUuid
                    ? subjectContainerOptions.find((sc) => {
                        if (formik.values.lessonUnit && formik.values.lessonUnit[index]?.subjectContainerUuid) {
                          return sc.value === formik.values.lessonUnit![index]?.subjectContainerUuid;
                        }
                        return false;
                      })
                    : null
                }
              ></Select>
            </GridColumn>
          </GridRow>
          <GridRow spacingBottom={'none'}>
            <GridColumn width={3}>
              <Input
                readonly={disabled}
                type={'number'}
                step={1}
                min={1}
                max={formik.values.lessonUnit && formik.values.lessonUnit[index]?.subjectContainerUuid ? 20 : 8}
                //eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                error={formik.errors.lessonUnit ? (formik.errors.lessonUnit[index]?.duration ?? undefined) : undefined}
                label={t('lessonUnit.duration')}
                name={`lessonUnit.${index}.duration`}
                onChange={formik.handleChange}
                value={formik.values.lessonUnit ? (formik.values.lessonUnit[index]?.duration ?? undefined) : undefined}
                suffix={
                  <div>
                    {lessonUnit[index].lessonUnitType === 'subjectHour'
                      ? t('common.hour', {
                          count:
                            formik.values.lessonUnit && formik.values.lessonUnit[index]?.duration
                              ? (formik.values.lessonUnit[index]?.duration ?? 0)
                              : 0,
                        })
                      : t('common.week', {
                          count:
                            formik.values.lessonUnit && formik.values.lessonUnit[index]?.duration
                              ? (formik.values.lessonUnit[index]?.duration ?? 0)
                              : 0,
                        })}
                  </div>
                }
              />
            </GridColumn>
            <GridColumn width={3}>
              <Input
                readonly={disabled}
                className={'quarter'}
                type={'number'}
                step={1}
                min={0}
                label={t('common.count')}
                name={`lessonUnit.${index}.count`}
                onChange={formik.handleChange}
                value={formik.values.lessonUnit ? (formik.values.lessonUnit[index].count ?? undefined) : undefined}
              />
            </GridColumn>
            <GridColumn width={3}>
              <Input
                type={'number'}
                label={t(`lessonUnit.factor`)}
                name={`lessonUnit.${index}.factor`}
                readonly
                onChange={formik.handleChange}
                value={formik.values.lessonUnit ? (formik.values.lessonUnit[index].factor ?? undefined) : undefined}
                suffix={
                  <Tooltip content={t('lessonUnit.tooltip.factor')}>
                    <InfoIcon className={'small'} />
                  </Tooltip>
                }
              />
            </GridColumn>

            <GridColumn width={4} className={'mt-5'}>
              {formik.values.lessonUnit &&
                formik.values.lessonUnit[index] &&
                formik.values.lessonUnit[index]?.duration && (
                  <BadgeCard
                    label={formik.values.subject?.name.slice(0, 2).toUpperCase() ?? ''}
                    count={formik.values.lessonUnit[index].count ?? 0}
                    width={
                      Math.abs(Math.min(Math.floor(formik.values.lessonUnit[index]?.duration ?? 0), 4)) as 1 | 2 | 3 | 4
                    }
                  ></BadgeCard>
                )}
            </GridColumn>
            <GridColumn width={3} className={'mt-4'}>
              {formik.values.lessonUnit?.[index].lessonUnitType === 'epoch' && (
                <Checkbox
                  name={'isClassTrip'}
                  checked={formik.values.isClassTrip ?? false}
                  label={t('lesson.basics.isClassTrip')}
                  onChange={async (event) => {
                    await formik.setFieldValue(`isClassTrip`, event.target.checked, true);
                    await formik.setFieldTouched(`isClassTrip`, true);
                  }}
                />
              )}

              {formik.values.lessonUnit?.[index].lessonUnitType !== 'epoch' && <></>}
            </GridColumn>
          </GridRow>
        </Grid>
      )}
    </>
  );
});
