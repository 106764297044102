import { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './CardInfo.module.scss';
import { observer } from 'mobx-react-lite';
import {
  ArrowHeadRightIcon,
  Button,
  Card,
  ControlIcon,
  DeleteIcon,
  Dropdown,
  DropdownMenu,
  EditIcon,
  ExclamationmarkIcon,
  HandIcon,
  LockIcon,
  PinIcon,
  QuestionmarkIcon,
  RoomIcon,
  Tooltip,
  UnlockIcon,
  UnpinIcon,
} from '@bp/ui-components';
import { useTeachingBlockStore } from '../TeachingBlockContext';
import classNames from 'classnames';
import { TeachingBlockCard } from '../TeachingBlockCard/TeachingBlockCard';
import { niceDate } from '../../../utils/dateCalculations';
import { getWarningTypeTranslation } from '../../../utils/getWarningTypeTranslation';
import { useLoadBasicData } from '../../../hooks/useLoadBasicData';

export const CardInfo: FC = observer(() => {
  const { t } = useTranslation();

  const {
    selectedCard,
    pickedCard,
    selectedRows,
    gridContext,
    pinCard,
    pickCard,
    discardCard,
    lockCard,
    unlockCard,
    setCardDurationModal,
    setCardRoomsModal,
    virtualCards,
  } = useTeachingBlockStore();

  const card = selectedCard;
  const children = virtualCards.filter((vc) => vc.parentUuid === card?.uuid);

  const { classesData, groupsData, roomsData } = useLoadBasicData({
    pause: false,
  });

  const cardTitles = selectedCard
    ? (selectedCard?.subject?.name ?? '-')
    : selectedRows.length > 0
      ? gridContext === 'subjects'
        ? selectedRows.map((value) => value.label).join(', ')
        : '-'
      : '-';

  const cardClasses = selectedCard
    ? (selectedCard?.classes
        .map((c) => classesData?.classes.find((aClass) => aClass.uuid === c.uuid)?.name)
        .join(', ') ?? '-')
    : selectedRows.length > 0
      ? gridContext === 'classes'
        ? selectedRows.map((value) => value.label).join(', ')
        : '-'
      : '-';

  const cardTeachers = selectedCard
    ? (selectedCard?.teachers.map((t) => t.displayNameShort).join(', ') ?? '-')
    : selectedRows.length > 0
      ? gridContext === 'teachers'
        ? selectedRows.map((value) => value.label).join(', ')
        : '-'
      : '-';

  const cardRooms = selectedCard
    ? (selectedCard?.rooms.map((r) => roomsData?.rooms.find((room) => room.uuid === r.uuid)?.name).join(', ') ?? '-')
    : selectedRows.length > 0
      ? gridContext === 'rooms'
        ? selectedRows.map((value) => value.label).join(', ')
        : '-'
      : '-';

  const cardGroups =
    selectedCard?.lessonClasses
      .map((lessonClass) => {
        const groups = lessonClass.groups
          .map((group) => groupsData?.groups.find((g) => g.uuid === group.uuid)?.name)
          .join(', ');
        return groups;
      })
      .filter((s) => !!s)
      .join(' / ') ?? '-';

  function onPick(event: MouseEvent<HTMLDivElement>) {
    if (card && !card.locked && card.uuid !== pickedCard?.card.uuid) {
      pickCard(card, { x: event.clientX, y: event.clientY });
    } else {
      pickCard(null);
    }
  }

  function onPin() {
    if (card) {
      pinCard(card);
    }
  }

  function onLock() {
    if (card) {
      if (card.locked) {
        unlockCard(card);
      } else {
        lockCard(card);
      }
    }
  }

  function onDiscard() {
    if (card) discardCard(card);
  }

  return (
    <Card
      wrapperClass={classNames(styles['card-info'], {
        // [styles['control-mode']]: pinboardStore.controlMode.isActive,
      })}
      className={styles['content-wrapper']}
      contentPadding='none'
      fitContent
    >
      <div className={styles['actions-wrapper']}>
        <Tooltip content={pickedCard?.card === card ? t('pinboard.actions.unpick') : t('pinboard.actions.pick')}>
          <Button
            disabled={!card || card.loading || (card.locked ?? false)}
            icon={<HandIcon />}
            hierarchy='tertiary'
            onClick={(e) => onPick(e as MouseEvent<HTMLDivElement>)}
          />
        </Tooltip>
        <Tooltip content={card?.pinned ? t('pinboard.actions.unpin') : t('pinboard.actions.pin')}>
          <Button
            disabled={!card || card.loading}
            icon={card?.pinned ? <UnpinIcon /> : <PinIcon />}
            hierarchy='tertiary'
            onClick={onPin}
          />
        </Tooltip>
        <Tooltip content={card?.locked ? t('pinboard.actions.unlock') : t('pinboard.actions.lock')}>
          <Button
            icon={card?.locked ? <UnlockIcon /> : <LockIcon />}
            disabled={!card || card.loading || (!!card && !card.startDate)}
            hierarchy='tertiary'
            onClick={onLock}
          />
        </Tooltip>
        <Tooltip content={t('pinboard.actions.showConditions')}>
          <Button
            // disabled={!card || card.loading}
            disabled
            hierarchy='tertiary'
            icon={<ControlIcon />}
            // onClick={() => {
            //   pinboardStore.setConstraintModalOpen(true);
            // }}
          />
        </Tooltip>
        <Tooltip content={t('pinboard.actions.edit')}>
          <Dropdown
            trigger={
              <Button icon={<EditIcon />} disabled={!card || card.locked || !card.startDate} hierarchy='tertiary' />
            }
          >
            <DropdownMenu
              data={[
                {
                  label: t('pinboard.actions.editRooms'),
                  onClick: () =>
                    setCardRoomsModal({
                      isOpen: true,
                      card: card ?? undefined,
                    }),
                },
                {
                  label: t('pinboard.actions.editTime'),
                  onClick: () =>
                    setCardDurationModal({
                      isOpen: true,
                      card: card ?? undefined,
                    }),
                },
              ]}
            />
          </Dropdown>
        </Tooltip>
        <Tooltip content={t('common.delete')}>
          <Button
            icon={<DeleteIcon />}
            disabled={!card || card.loading || !card.startDate || card.locked}
            hierarchy='tertiary'
            onClick={onDiscard}
          />
        </Tooltip>
        <div className={styles['info-icons']}>
          {selectedCard && selectedCard.rooms.length === 0 && (
            <Tooltip triggerClass={styles.info} content={t('pinboard.reason.noRoom')}>
              <RoomIcon />
            </Tooltip>
          )}
          {card &&
            ((card.warnings && card.warnings.length > 0) ||
              (card.availabilityWarnings && card.availabilityWarnings.length > 0)) && (
              <Tooltip
                triggerClass={styles.warning}
                content={card.warnings
                  ?.map((warning) => {
                    return `${getWarningTypeTranslation(warning.type)} (${warning.data?.map((d) => d.name)})`;
                  })
                  .join(', ')}
              >
                <ExclamationmarkIcon />
              </Tooltip>
            )}
          {card && children.length > 0 && (
            <Tooltip
              triggerClass={styles.info}
              content={
                <>
                  <div>{t('teachingBlockVersion.splitCardHint')}</div>
                  <div>{t('teachingBlockVersion.actualDurationHint', { duration: card.duration ?? 1 })}</div>
                </>
              }
            >
              <QuestionmarkIcon />
            </Tooltip>
          )}
        </div>
      </div>

      <div className={styles['preview-wrapper']}>
        {card ? (
          <div className={styles['card-overview']}>
            {!card.startDate ? (
              <div className={styles['split-info']}>
                <TeachingBlockCard card={card} onClick={onPick} passive />
                <div
                  className={styles.dates}
                  style={{
                    maxWidth: `calc(var(--badge-card-width) + var(--badge-card-grow-factor) * ${(card.duration ?? 1) / 7})`,
                  }}
                >
                  <div className={styles.start}>
                    {t('teachingBlockVersion.durationInWeeks', { weeks: card.duration })}
                  </div>
                </div>
              </div>
            ) : children.length > 0 ? (
              children.map((child, i) => {
                return (
                  <div className={styles.split} key={child.uuid}>
                    <div className={styles['split-info']}>
                      <TeachingBlockCard card={card} duration={child.duration} onClick={(e) => onPick(e)} passive />
                      <div
                        className={styles.dates}
                        style={{
                          maxWidth: `calc(var(--badge-card-width) + var(--badge-card-grow-factor) * ${(card.duration ?? 1) / 7})`,
                        }}
                      >
                        <div className={styles.start}>{niceDate(child.startDateString, 'short')}</div>
                        <div className={styles.end}>{niceDate(child.endDateString, 'short')}</div>
                      </div>
                    </div>
                    {i < children.length - 1 && <ArrowHeadRightIcon className='small' />}
                  </div>
                );
              })
            ) : (
              <div className={styles['split-info']}>
                <TeachingBlockCard card={card} onClick={onPick} passive />
                <div
                  className={styles.dates}
                  style={{
                    maxWidth: `calc(var(--badge-card-width) + var(--badge-card-grow-factor) * ${(card.duration ?? 1) / 7})`,
                  }}
                >
                  <div className={styles.start}>{niceDate(card.startDate, 'short')}</div>
                  <div className={styles.end}>{niceDate(card.endDate, 'short')}</div>
                </div>
              </div>
            )}
          </div>
        ) : selectedRows.length > 0 ? (
          selectedRows.map((row, index) => {
            if (index <= 10) {
              if (index <= 9) {
                return (
                  <div key={index + String(row.value)} className={styles['selected-class']}>
                    {row.label.slice(0, 2)}
                  </div>
                );
              } else {
                return (
                  <div key={index + String(row.value)} className={styles['selected-class']}>
                    +{selectedRows.length - index}
                  </div>
                );
              }
            }
            return <></>;
          })
        ) : (
          <></>
        )}
      </div>

      <div className={styles['info-wrapper']}>
        <div className={styles.row}>
          <div className={styles.title}>{t('subject.title', { count: 1 })}</div>
          <div className={styles.content} title={cardTitles}>
            {cardTitles}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.title}>{t('classes.title', { count: 1 })}</div>
          <div className={styles.content} title={cardClasses}>
            {cardClasses}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.title}>{t('persons.title', { count: 1 })}</div>
          <div className={styles.content} title={cardTeachers}>
            {cardTeachers}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.title}>{t('rooms.title', { count: 1 })}</div>
          <div className={styles.content} title={cardRooms}>
            {cardRooms}
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.title}>{t('common.group_other')}</div>
          <div className={styles.content} title={cardGroups}>
            {cardGroups}
          </div>
        </div>
      </div>
    </Card>
  );
});
