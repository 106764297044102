import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { useTranslation } from 'react-i18next';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { useHiddenColumns } from '../../../hooks/useHiddenColumns';
import { useColumnsSort } from '../../../hooks/useColumnsSort';
import { FC, useMemo } from 'react';
import { useDeputateLessons } from '../../Lessons/hooks/useDeputateLessons';
import {
  SortDirection,
  useAdditionalTeachingLoadsListQuery,
  useSubjectsListQuery,
} from '../../../types/planung-graphql-client-defs';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { sumUpArray } from '../../../utils/arrayFunc';
import { ArrowRightIcon, Button, Table, TableColumns } from '@bp/ui-components';
import { formatNumber } from '../../../utils/helper';
import { DeputatesTableData } from './PlannedDeputatesTable';
import { observer } from 'mobx-react-lite';

type CurrentDeputatesTableProps = {
  handleRowClick: (data: string) => void;
};

export const CurrentDeputatesBySubjectTable: FC<CurrentDeputatesTableProps> = observer(
  ({ handleRowClick }: CurrentDeputatesTableProps) => {
    const { pimAuthClaims } = useAuthClaims();
    const { t } = useTranslation();
    const currentSchoolYear = useUserConfigContext().selectedSchoolYear;
    const { columnVisibility, saveColumnVisibility } = useHiddenColumns('deputate-bysubject-list');
    const { sorting, saveSorting } = useColumnsSort('deputate-bysubject-list');

    const { lessonsData: allLessonData } = useDeputateLessons({
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
      schoolYearUuid: currentSchoolYear?.uuid ?? '',
    });

    const context = useMemorizedCacheTag('SUBJECTS');
    const [{ data: subjectsQueryResult }] = useSubjectsListQuery({
      variables: {
        where: {
          organization: {
            uuid: pimAuthClaims.getOrganizationUuid(),
          },
        },
        options: {
          sort: [{ name: SortDirection.Asc }],
        },
      },
      context,
    });

    const atlContext = useMemorizedCacheTag('ADDITIONAL_TEACHINGLOAD');
    const [{ data: addidtionalTeachingLoadQueryResult }] = useAdditionalTeachingLoadsListQuery({
      variables: {
        organizationUuid: pimAuthClaims.getOrganizationUuid(),
        schoolyear: currentSchoolYear?.uuid ?? '',
      },
      context: atlContext,
    });

    const memoizedData = useMemo((): DeputatesTableData[] => {
      return (
        subjectsQueryResult?.subjects.map((subject) => {
          const deputateAdditional =
            sumUpArray(
              addidtionalTeachingLoadQueryResult?.additionalTeachingLoads
                ?.filter((l) => l.subject?.uuid === subject.uuid)
                .map((a) => a.hours) ?? [],
            ) ?? 0;

          const currentSubjectsLessons = allLessonData.filter((l) => l.subjectUuid === subject.uuid);

          return {
            teacherUuid: subject.uuid,
            name: subject.name ?? '',
            teachingBlockWeeks: currentSubjectsLessons.reduce(
              (val, l) => val + (l.lessonInfo?.teachingBlockWeeks ?? 0),
              0,
            ),
            teachingBlocks: currentSubjectsLessons.reduce((val, l) => val + (l.lessonInfo?.teachingBlocks ?? 0), 0),
            deputateAdditional,
            deputateLessonUnit: currentSubjectsLessons.reduce(
              (val, lesson) => val + (lesson.lessonInfo?.fachstundenDeputat ?? 0),
              0,
            ),
            deputateSubjectContainer: currentSubjectsLessons.reduce(
              (val, lesson) => val + (lesson.lessonInfo?.epochenDeputat ?? 0),
              0,
            ),
            contractHours: 0,
            billedHours: 0,
          };
        }) ?? []
      );
    }, [subjectsQueryResult, addidtionalTeachingLoadQueryResult, allLessonData]);

    function tableColumns(): TableColumns<DeputatesTableData>[] {
      return [
        {
          header: t('common.name'),
          id: 'name',
          accessorKey: 'name',
          size: 300,
        },
        {
          header: t('common.week', { count: 2 }),
          id: 'weeks',
          accessorKey: 'weeks',
          size: 100,
          alignment: 'right',
        },
        {
          header: t('common.epochWeeks'),
          accessorKey: 'epochWeeks',
          id: 'epochWeeks',
          size: 100,
          alignment: 'right',
        },
        {
          header: t('deputate.deputateEpoch'),
          accessorFn: (row) => formatNumber(row.deputateSubjectContainer),
          id: 'deputateSubjectContainer',
          size: 100,
          alignment: 'right',
        },
        {
          header: t('deputate.deputateSubject'),
          accessorFn: (row) => formatNumber(row.deputateLessonUnit),
          id: 'deputateLessonUnit',
          size: 100,
          alignment: 'right',
        },
        {
          header: t('deputate.deputateManually'),
          accessorFn: (row) => formatNumber(row.deputateAdditional),
          id: 'deputateAdditional',
          size: 100,
          alignment: 'right',
        },
        {
          header: t('common.sum'),
          accessorKey: 'sum',
          accessorFn: (row) => {
            return formatNumber(row.deputateAdditional + row.deputateSubjectContainer + row.deputateLessonUnit);
          },
          size: 100,
          id: 'sum',
          alignment: 'right',
        },
      ];
    }

    const columns = useMemo(tableColumns, []);

    return (
      <Table<DeputatesTableData>
        showBorderRadius
        showRowHover
        showShadow
        canScroll
        minHeight={600}
        columns={columns}
        data={memoizedData}
        sorting={sorting}
        onSortingChange={saveSorting}
        isOnWhite={false}
        showVisibility
        showActionBar
        actionBarSettings={{
          showPrintButton: true,
        }}
        columnVisibility={columnVisibility}
        onColumnVisibilityChange={saveColumnVisibility}
        printerSettings={{
          headline: pimAuthClaims.getProfile()?.organization.name,
          subline: `${t('subject.title', { count: 2 })} - ${t('common.schoolYear')} ${currentSchoolYear?.shortName}`,
          filename: `${t('subject.title', { count: 2 })}_${currentSchoolYear?.shortName}`,
        }}
        showSelect={false}
        onRowClick={(e, row) => {
          handleRowClick(row.original.teacherUuid);
        }}
        lastColWidth='100px'
        lastCol={(row) => {
          return (
            <Button
              hierarchy='ghost'
              onClick={() => handleRowClick(row.original.teacherUuid)}
              icon={<ArrowRightIcon className='small' />}
            />
          );
        }}
      />
    );
  },
);
