import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHiddenColumns } from '../../../hooks/useHiddenColumns';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { observer } from 'mobx-react-lite';
import { useColumnsSort } from '../../../hooks/useColumnsSort';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { hexToColorOption } from '../../../utils/colorUtils';
import { useLoadBasicData } from '../../../hooks/useLoadBasicData';
import {
  TableColumns,
  Table,
  Row,
  DropdownMenuItem,
  useDefaultSelecting,
  DropdownMenu,
  Dropdown,
  EditIcon,
  Switch,
} from '@bp/ui-components';
import { ClassesTableType } from './SettingsClassesTable';
import { useUpdateClassesAttendancePlanMutation } from '../../../types/planung-graphql-client-defs';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';

type AttendanceClassesTableType = Omit<ClassesTableType, 'grade' | 'gradeGroup' | 'printOrder' | 'printPageNr'> & {
  attendancePlan: boolean;
};

export const AttendanceClassesTable: FC = observer(() => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;

  const context = useMemorizedCacheTag('CLASSES_FIXED');
  const [, updateAttendancePlan] = useUpdateClassesAttendancePlanMutation();

  const { columnVisibility, saveColumnVisibility } = useHiddenColumns('attendance-classes-list', {
    tutor3: false,
    color: false,
    roomName: false,
  });
  const { sorting, saveSorting } = useColumnsSort('attendance-classes-list');
  const { rowSelection, onRowSelectionChange } = useDefaultSelecting();

  const { classesData, roomsData, teacherData } = useLoadBasicData({ pause: false });

  function createColumns(): TableColumns<AttendanceClassesTableType>[] {
    return [
      {
        header: t('common.name'),
        accessorKey: 'name',
        id: 'name',
        size: 300,
        canExpand: true,
      },
      {
        header: t('classes.shortName'),
        accessorKey: 'shortName',
        id: 'shortName',
        size: 200,
      },
      {
        header: `${t('classes.tutor.short')} 1`,
        meta: {
          filterName: `${t('classes.tutor.full')} 1`,
        },
        size: 250,
        id: 'tutor1',
        accessorKey: 'tutor1',
      },
      {
        header: `${t('classes.tutor.short')} 2`,
        meta: {
          filterName: `${t('classes.tutor.full')} 2`,
        },
        size: 250,
        id: 'tutor2',
        accessorKey: 'tutor2',
      },
      {
        header: `${t('classes.tutor.short')} 3`,
        meta: {
          filterName: `${t('classes.tutor.full')} 3`,
        },
        size: 250,
        id: 'tutor3',
        accessorKey: 'tutor3',
      },
      {
        header: t('lesson.table.rooms'),
        id: 'roomName',
        accessorKey: 'roomName',
        size: 350,
      },
      {
        header: t('common.color'),
        accessorKey: 'color',
        id: 'color',
        type: 'color',
        size: 100,
      },
      {
        header: t('classes.attendanceClass', { count: 1 }),
        accessorKey: 'attendancePlan',
        id: 'attendancePlan',
        type: 'boolean',
        size: 100,
      },
    ];
  }

  const tableColumns = useMemo(createColumns, [t]);

  const functionClasses = useMemo((): AttendanceClassesTableType[] => {
    return classesData
      ? classesData.classes
          .filter((c) => !c.grade)
          .map((c) => {
            const roomName = roomsData?.rooms.find((room) => room.uuid === c.defaultRoom?.uuid)?.name ?? '';
            const { html, label } = hexToColorOption(c.timetableConfig?.color ?? '');
            const tutor1 = teacherData?.people.find((t) => t.uuid === c.tutors[0]?.uuid);
            const tutor2 = teacherData?.people.find((t) => t.uuid === c.tutors[1]?.uuid);
            const tutor3 = teacherData?.people.find((t) => t.uuid === c.tutors[2]?.uuid);

            return {
              uuid: c.uuid,
              name: c.name,
              shortName: c.shortName,
              tutor1: tutor1?.displayNameShort ?? '',
              tutor2: tutor2?.displayNameShort ?? '',
              tutor3: tutor3?.displayNameShort ?? '',
              roomName,
              color: {
                color: html,
                colorLabel: label,
              },
              attendancePlan: c.attendancePlan ?? false,
            };
          })
      : [];
  }, [classesData, roomsData?.rooms, teacherData?.people]);

  async function handleAttendancePlanChange(uuids: string[], active: boolean) {
    await updateAttendancePlan(
      {
        uuids,
        attendancePlan: active,
      },
      context,
    );
  }

  const createBulkEditItems = useCallback((rows: Row<AttendanceClassesTableType>[]): DropdownMenuItem[] => {
    return [
      {
        label: `${t('classes.attendanceClass', { count: 2 })} ${t('common.activate').toLowerCase()}`,
        onClick: async () => {
          await handleAttendancePlanChange(
            rows.map(({ original }) => original.uuid),
            true,
          );
        },
      },
      {
        label: `${t('classes.attendanceClass', { count: 2 })} ${t('common.deactivate').toLowerCase()}`,
        onClick: async () => {
          await handleAttendancePlanChange(
            rows.map(({ original }) => original.uuid),
            false,
          );
        },
      },
    ];
  }, []);

  return (
    <>
      <Table<AttendanceClassesTableType>
        showBorderRadius
        showShadow
        canScroll
        showSelect
        minHeight={600}
        showVisibility
        breakpoint={null}
        isOnWhite={false}
        sorting={sorting}
        onSortingChange={saveSorting}
        columnVisibility={columnVisibility}
        onColumnVisibilityChange={saveColumnVisibility}
        rowSelection={rowSelection}
        onRowSelectionChange={onRowSelectionChange}
        showSort
        columns={tableColumns}
        data={functionClasses}
        bulkEditDropdownContent={(rows) => {
          return <DropdownMenu data={createBulkEditItems(rows)} />;
        }}
        printerSettings={{
          headline: pimAuthClaims.getProfile()?.organization.name,
          subline: `${t('classes.attendanceClass', { count: 2 })} - ${t('common.schoolYear')} ${currentSchoolYear?.shortName}`,
          filename: `${t('classes.attendanceClass', { count: 2 })}_${currentSchoolYear?.shortName}`,
        }}
        showActionBar
        actionBarSettings={{
          showBulkEdit: true,
          showPrintButton: true,
        }}
        lastCol={(row) => {
          return (
            <Dropdown trigger={<EditIcon />}>
              <DropdownMenu
                data={[
                  {
                    type: 'component',
                    onClick: async () =>
                      await handleAttendancePlanChange([row.original.uuid], !row.original.attendancePlan),
                    node: (
                      <Switch
                        name={`${row.original.uuid}-attendance`}
                        label={t('classes.attendanceClass', { count: 1 })}
                        onChange={async () =>
                          await handleAttendancePlanChange([row.original.uuid], !row.original.attendancePlan)
                        }
                        checked={row.original.attendancePlan}
                      />
                    ),
                  },
                ]}
              />
            </Dropdown>
          );
        }}
      />
    </>
  );
});
