import { useTranslation } from 'react-i18next';
import { ReactNode, useMemo } from 'react';
import { Card, EmptyState, Table, TableColumns } from '@bp/ui-components';
import { formatNumber } from '../../../utils/helper';
import {
  BillingMethods,
  use_ContractsQuery,
  use_PeopleWithContractQuery,
} from '../../../types/planung-graphql-client-defs';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import styles from './DeputateDetailsTeacherOverview.module.scss';
import classNames from 'classnames';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { _evalBillingMethod } from '../../Lessons/Tables/Teachers/utils/eval-billing-method';

export type DeputatesDetailTableData = {
  teacherUuid: string;
  teacherDisplayName: string;
  subjectUuid: string;
  comment?: string;
  subjectDisplayName: string;
  subjectContainerUuid?: string | null;
  subjectContainerDisplayName?: string | null;
  partialClasses: boolean;
  lessonClassesDisplayName: string;
  countClasses: number;
  countTeacher: number;
  studentCards?: number | null;
  teacherCards?: number | null;
  studentHours?: number | null;
  teacherHours?: number | null;
  teachingBlockWeeks?: number | null;
  teachingBlocks?: number | null;
  factor: number;
  deputat: number;
};

export type DeputateDetailsOverviewData = {
  subjectLessons: Array<DeputatesDetailTableData> | undefined;
  sumSubjectLessons: number;
  additional: DeputatesDetailTableData[];
  subjectContainerLessons: Map<string, Array<DeputatesDetailTableData>>;
  sumSubjectContainerLessons: Map<string, number>;
  sumAdditional: number;
  sumTotal: number;
};

export const DeputateDetailsTeacherOverview = observer(
  ({ deputateData }: { deputateData: DeputateDetailsOverviewData }) => {
    const { t } = useTranslation();

    const teacherUuid = useParams().teacherUuid;
    const [{ data: teacherData }] = use_PeopleWithContractQuery({
      variables: { schoolYearUuid: useUserConfigContext().selectedSchoolYear?.uuid ?? '' },
    });
    const [{ data: contractData }] = use_ContractsQuery({
      pause: false,
    });

    const currentTeacher = teacherData?.people.find((t) => t.uuid === teacherUuid);
    const currentContract = contractData?.contracts.find((c) => c.uuid === currentTeacher?.currentContract?.uuid);

    function tableDetailColumns(): TableColumns<DeputatesDetailTableData>[] {
      return [
        {
          header: t('lesson.table.subject'),
          id: 'name',
          accessorKey: 'subjectDisplayName',
          size: 350,
        },
        {
          header: t('lesson.table.classesGroups'),
          id: 'lessonClassesDisplayName',
          accessorKey: 'lessonClassesDisplayName',
          size: 200,
        },
        {
          header: t('deputate.partialClass'),
          id: 'partialClass',
          accessorFn: (row) => (row.partialClasses ? 1 : 0),
          size: 125,
          type: 'boolean',
        },
        {
          header: t('persons.title', { count: 2 }),
          id: 'countTeacher',
          accessorKey: 'countTeacher',
          size: 90,
          alignment: 'right',
        },
        {
          header: t('common.studentHours'),
          accessorFn: (row) => formatNumber(row.studentHours ?? 0),
          id: 'studentHours',
          size: 90,
          alignment: 'right',
        },
        {
          header: t('subjectHours.subjectHour_other'),
          accessorFn: (row) => formatNumber(row.studentCards ?? 0),
          id: 'studentCards',
          size: 90,
          alignment: 'right',
        },
        {
          header: t('factors.title', { count: 1 }),
          accessorFn: (row) => (row.factor > 0 ? formatNumber(row.factor) : '-'),
          id: 'factor',
          size: 90,
          alignment: 'right',
        },
        {
          header: t('deputate.title', { count: 1 }),
          accessorFn: (row) => formatNumber(row.deputat),
          id: 'deputat',
          size: 90,
          alignment: 'right',
        },
      ];
    }

    function tableContainerColumns(): TableColumns<DeputatesDetailTableData>[] {
      return [
        {
          header: t('lesson.table.subject'),
          id: 'name',
          accessorKey: 'subjectDisplayName',
          size: 350,
        },
        {
          header: t('lesson.table.classesGroups'),
          id: 'lessonClassesDisplayName',
          accessorKey: 'lessonClassesDisplayName',
          size: 200,
        },
        {
          header: t('deputate.partialClass'),
          id: 'partialClass',
          accessorFn: (row) => (row.partialClasses ? 'X' : ''),
          size: 125,
          type: 'boolean',
        },
        {
          header: t('persons.title', { count: 2 }),
          id: 'countTeacher',
          accessorKey: 'countTeacher',
          size: 80,
          alignment: 'right',
        },
        {
          header: t('common.epochs'),
          accessorKey: 'teachingBlocks',
          id: 'teachingBlocks',
          size: 90,
          alignment: 'right',
        },
        {
          header: t('common.week', { count: 2 }),
          id: 'teachingBlockWeeks',
          accessorKey: 'teachingBlockWeeks',
          size: 90,
          alignment: 'right',
        },
        {
          header: t('factors.title', { count: 1 }),
          accessorFn: (row) => (row.factor > 0 ? formatNumber(row.factor) : '-'),
          id: 'factor',
          size: 90,
          alignment: 'right',
        },
        {
          header: t('deputate.title', { count: 1 }),
          accessorFn: (row) => formatNumber(row.deputat),
          size: 90,
          id: 'sum',
          alignment: 'right',
        },
      ];
    }

    function tableAdditionalColumns(): TableColumns<DeputatesDetailTableData>[] {
      return [
        {
          header: t('common.name'),
          id: 'name',
          accessorKey: 'comment',
          size: 350,
        },
        {
          header: t('lesson.table.subject'),
          id: 'subjectDisplayName',
          accessorKey: 'subjectDisplayName',
          size: 200,
        },
        {
          header: t('lesson.table.classesGroups'),
          id: 'lessonClassesDisplayName',
          accessorKey: 'lessonClassesDisplayName',
          size: 200,
        },
        {
          header: t('deputate.title', { count: 1 }),
          accessorFn: (row) => formatNumber(row.deputat),
          id: 'deputat',
          size: 90,
          alignment: 'right',
        },
      ];
    }

    const detailColumns = useMemo(tableDetailColumns, []);
    const containerColumns = useMemo(tableContainerColumns, []);
    const additionalColumns = useMemo(tableAdditionalColumns, []);

    const tables: ReactNode[] = [
      <div key={'fs'}>
        <div className={styles['table-header']}>
          <div>{t('deputate.deputateSubject')}</div>
          <div>{formatNumber(deputateData.sumSubjectLessons)}</div>
        </div>
        <Table<DeputatesDetailTableData>
          className={styles.table}
          columns={detailColumns}
          data={deputateData.subjectLessons ?? []}
          customPadding='var(--spacing-6)'
        />
      </div>,
    ];

    deputateData.subjectContainerLessons.forEach((data, key) =>
      tables.push(
        <div key={'table_' + key}>
          <div className={styles['table-header']}>
            <div>{t('deputate.evaluate.subjectContainer', { container: key })}</div>
            <div>{formatNumber(deputateData.sumSubjectContainerLessons.get(key) ?? 0)}</div>
          </div>
          <Table<DeputatesDetailTableData>
            className={styles.table}
            columns={containerColumns}
            data={data ?? []}
            customPadding='var(--spacing-6)'
          />
        </div>,
      ),
    );

    if (deputateData.additional.length > 0) {
      tables.push(
        <div key={'add'}>
          <div className={styles['table-header']}>
            <div>{t('deputate.deputateManually')}</div>
            <div>{formatNumber(deputateData.sumAdditional)}</div>
          </div>
          <Table<DeputatesDetailTableData>
            className={styles.table}
            columns={additionalColumns}
            data={deputateData.additional ?? []}
            customPadding='var(--spacing-6)'
          />
        </div>,
      );
    }

    const billedHours = _evalBillingMethod({
      deputateLesson:
        deputateData.sumSubjectLessons +
        [...deputateData.sumSubjectContainerLessons.values()].reduce((sum, cur) => sum + cur, 0),
      deputateManually: deputateData.sumAdditional ?? 0,
      deputateContract: currentContract?.hoursWeekly ?? 0,
      billingMethod: currentContract?.billingMethod ?? BillingMethods.GivenHours,
      limitedToFullTime: currentContract?.limitedToFullTime ?? false,
      fullTimeHours: useUserConfigContext().selectedSchoolYear?.fullTimeHours,
    });

    const isEmpty =
      (deputateData.subjectLessons === undefined || deputateData.subjectLessons?.length === 0) &&
      deputateData.additional.length === 0;

    return (
      <>
        <Card contentPadding='none' className={styles['planned-deputate-teacher-overview']}>
          <div className={styles.header}>
            <div className={styles.name}>{currentTeacher?.displayName}</div>
            <div
              className={styles['color-dot']}
              style={{
                backgroundColor:
                  teacherData?.people.find((t) => t.uuid === teacherUuid)?.timetableConfig?.color ?? 'white',
              }}
            ></div>
          </div>

          <div className={styles.stats}>
            <div className={styles.stat}>
              <div>{t('deputate.deputateSubject')}</div>
              <div>{formatNumber(deputateData.sumSubjectLessons)}</div>
            </div>

            {[...deputateData.subjectContainerLessons.keys()].map((key) => {
              return (
                <div key={key} className={styles.stat}>
                  <div>{t('deputate.evaluate.subjectContainer', { container: key })}</div>
                  <div>{formatNumber(deputateData.sumSubjectContainerLessons.get(key) ?? 0)}</div>
                </div>
              );
            })}

            <div className={styles.stat}>
              <div>{t('deputate.deputateManually')}</div>
              <div>{formatNumber(deputateData.sumAdditional)}</div>
            </div>

            <div className={styles.stat}>
              <div>{t('common.sum')}</div>
              <div>{formatNumber(deputateData.sumTotal)}</div>
            </div>
          </div>
          <div className={styles.stats}>
            <div className={styles.stat}>
              <div>{t('contracts.contract')}</div>
              <div>{formatNumber(currentContract?.hoursWeekly ?? 0)}</div>
            </div>

            <div className={styles.stat}>
              <div>{t('deputate.payed')}</div>
              <div>{formatNumber(billedHours ?? 0)}</div>
            </div>
          </div>

          <div className={classNames(styles.tables, { [styles.empty]: isEmpty })}>
            {isEmpty ? <EmptyState title={t('deputate.evaluate.empty')} /> : tables}
          </div>
        </Card>
      </>
    );
  },
);
