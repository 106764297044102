import { Outlet, useParams } from 'react-router-dom';
import { Suspense, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { LazyLoader } from '@bp/ui-components';
import { Page } from '../../../../components/Page/Page';
import { TeachingBlockVersionTopBar } from '../../../../components/TeachingBlockVersion/TeachingBlockVersionTopBar/TeachingBlockVersionTopBar';
import { TeachingBlockProvider } from '../../../../components/TeachingBlockGrid/TeachingBlockContext';
import { StoreHandler } from '../../../../stores/StoreHandler';
import { DayAvailabilityStore } from '../../../../stores/DayAvailabilityStore';
import { useUserConfigContext } from '../../../../hooks/useUserConfigContext';
import dayjs from 'dayjs';

export const availabilityStoreHandler = new StoreHandler<DayAvailabilityStore>();

export const TeachingBlockVersion = observer(({ baseSlug }: { baseSlug: string }) => {
  const { teachingBlockVersionUuid } = useParams();

  const selectedSchoolYear = useUserConfigContext().selectedSchoolYear;

  useEffect(() => {
    if (teachingBlockVersionUuid && selectedSchoolYear) {
      availabilityStoreHandler.register(teachingBlockVersionUuid, () => {
        return new DayAvailabilityStore(
          dayjs(selectedSchoolYear.start).startOf('day').utc().toDate(),
          dayjs(selectedSchoolYear.end).startOf('day').utc().toDate(),
        );
      });
    }
  }, [selectedSchoolYear, teachingBlockVersionUuid]);

  return (
    <Page title={null} fullWidth>
      <Suspense fallback={<LazyLoader embedded transparent forceHeight='60vh' />}>
        {teachingBlockVersionUuid && (
          <TeachingBlockProvider>
            <TeachingBlockVersionTopBar versionUuid={teachingBlockVersionUuid} baseSlug={baseSlug} />
            <Outlet />
          </TeachingBlockProvider>
        )}
      </Suspense>
    </Page>
  );
});
