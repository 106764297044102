import {
  AddIcon,
  Button,
  Card,
  Grid,
  GridColumn,
  GridRow,
  ImportIcon,
  LazyLoader,
  Modal,
  Skeleton,
  Tab,
  Tooltip,
} from '@bp/ui-components';
import { Suspense, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../TeachingBlockVersionData.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useMemorizedCacheTag } from '../../../../../../hooks/useMemorizedCacheTag';
import {
  SortDirection,
  use_TeachingBlockAvailabilityExclusionsQuery,
  useConnectEntityWithVersionMutation,
} from '../../../../../../types/planung-graphql-client-defs';
import { createLessonFilterUrl } from '../../../../../../utils/create-lesson-filter-url';
import { hexToColorOption } from '../../../../../../utils/colorUtils';
import { TeachingBlockVersionTeachersTable } from '../../../../../../components/TeachingBlockVersion/TeachingBlockVersionDataTables/TeachingBlockVersionTeachersTable';
import { ExclusionTimeframeForm } from '../../../../../../components/ExclusionTimeframe/ExclusionTimeframeForm';
import { TeachingBlockVersionTableType } from '../../../../../../components/TeachingBlockVersion/graphql/types';
import { showSuccessSaveToast, showUserErrorToast } from '../../../../../../utils/toast';
import { useAuthClaims } from '../../../../../../hooks/useAuthClaims';
import { Exclusions } from '../../../../../../components/TeachingBlockVersion/Exclusions/Exclusions';
import { useLoadBasicData } from '../../../../../../hooks/useLoadBasicData';
import { useTeachingBlockStore } from '../../../../../../components/TeachingBlockGrid/TeachingBlockContext';

export const PlanTeachingBlockTeachers = () => {
  const { t } = useTranslation();
  const { teachingBlockVersionUuid } = useParams();
  const { pimAuthClaims } = useAuthClaims();
  const navigate = useNavigate();

  const [tab, setTab] = useState<'exclusion' | 'condition'>('exclusion');
  const [exclusionModalOpen, setExclusionModalOpen] = useState(false);
  const [selectedExclusionUuid, setSelectedExclusionUuid] = useState<string | null>(null);

  const context = useMemorizedCacheTag('TEACHINGBLOCK_VERSIONS');
  const availabilityContext = useMemorizedCacheTag('TEACHING_BLOCK_AVAILABILITY_EXCLUSION');

  const [{ data: exclusionsData }] = use_TeachingBlockAvailabilityExclusionsQuery({
    variables: {
      where: {
        teachingBlockVersion: {
          uuid: teachingBlockVersionUuid,
        },
      },
      options: {
        sort: [{ from: SortDirection.Asc }],
      },
    },
    context: availabilityContext,
  });

  const [, updateVersion] = useConnectEntityWithVersionMutation();

  const { teacherData } = useLoadBasicData({ pause: !teachingBlockVersionUuid });

  const store = useTeachingBlockStore();
  const versionTeacherUuids: string[] = Array.from(store.teachers.keys());

  const teachersTableData: TeachingBlockVersionTableType[] = useMemo(() => {
    return (
      teacherData?.people
        .filter((t) => versionTeacherUuids.includes(t.uuid))
        .map((t) => {
          const { html, label } = hexToColorOption(t.timetableConfig?.color ?? '');
          return {
            color: {
              color: html,
              label,
            },
            count: 0,
            name: t.displayNameShort ?? t.fullName,
            shortName: t.shortName,
            uuid: t.uuid,
          };
        }) ?? []
    );
  }, [teacherData?.people, versionTeacherUuids]);

  const [selected, setSelected] = useState<TeachingBlockVersionTableType | null>(teachersTableData[0] ?? null);

  async function handleImport() {
    if (teachingBlockVersionUuid) {
      const result = await updateVersion(
        {
          organizationUuid: pimAuthClaims.getOrganization().uuid,
          versionUuid: teachingBlockVersionUuid,
          entityType: 'teacher',
        },
        context,
      );
      if (!result || result.error) {
        showUserErrorToast({ text: t('common.errorToastText'), error: result.error });
      } else {
        showSuccessSaveToast();
      }
    }
  }

  const selectedExclusionUuids = exclusionsData?.teachingBlockAvailabilityExclusions
    .filter((e) => e.holder.uuid === selected?.uuid)
    .map((e) => e.uuid);

  const onExclusionEdit = (uuid: string) => {
    setSelectedExclusionUuid(uuid);
    setExclusionModalOpen(true);
  };

  const onExclusionModalClose = () => {
    setSelectedExclusionUuid(null);
    setExclusionModalOpen(false);
  };

  return (
    <Grid>
      <GridRow spacingBottom='none'>
        <Button
          hierarchy='tertiary'
          icon={<ImportIcon />}
          className='mb-4'
          style={{ marginLeft: 'auto' }}
          onClick={handleImport}
          disabled={true}
        >
          {t('teachingBlockVersion.import', { import: t('persons.title', { count: 2 }) })}
        </Button>
      </GridRow>
      <GridRow spacingTop='none'>
        <GridColumn width={teachersTableData.length === 0 ? 12 : 6}>
          <TeachingBlockVersionTeachersTable
            onRowSelection={(selected) => {
              setSelected(selected);
            }}
            versionTeacherUuids={versionTeacherUuids}
            versionUuid={teachingBlockVersionUuid}
            onShowLessonClick={(uuid) => {
              if (teachingBlockVersionUuid) {
                navigate(
                  createLessonFilterUrl({
                    context: 'epochenplan',
                    versionUuid: teachingBlockVersionUuid,
                    column: 'subject',
                    filterValue: uuid,
                  }),
                );
              }
            }}
          />
        </GridColumn>
        {teachersTableData.length !== 0 && (
          <GridColumn width={6}>
            <Suspense fallback={<LazyLoader />}>
              <Card className={styles['teaching-block-version-data']} contentPadding='none'>
                <small>{t('teachingBlockVersion.specificInfo', { for: selected?.name })}</small>
                <div className={styles['mini-tab']}>
                  <Tab
                    className={styles.tabs}
                    onValueChange={(content) => setTab(content as 'exclusion' | 'condition')}
                    tabs={[
                      {
                        title: t('exclusion.title', { count: 2 }),
                        value: 'exclusion',
                        content: <Exclusions uuids={selectedExclusionUuids ?? []} onEdit={onExclusionEdit} />,
                      },
                      {
                        title: t('conditions.title', { count: 2 }),
                        value: 'condition',
                        content: (
                          <div className='px-6 py-4'>
                            <Skeleton />
                          </div>
                        ),
                      },
                    ]}
                  />
                  <div className={styles['additional-actions']}>
                    {tab === 'exclusion' && (
                      <Tooltip content={t('exclusion.add')}>
                        <Button hierarchy='ghost' icon={<AddIcon />} onClick={() => setExclusionModalOpen(true)} />
                      </Tooltip>
                    )}
                  </div>
                </div>
              </Card>
            </Suspense>
          </GridColumn>
        )}
      </GridRow>
      <Modal
        width='s'
        title={t('exclusion.add')}
        isOpen={exclusionModalOpen}
        onRequestClose={() => onExclusionModalClose()}
      >
        <ExclusionTimeframeForm
          type='Person'
          holderUuid={selected?.uuid ?? ''}
          teachingBlockVersionUuid={teachingBlockVersionUuid ?? ''}
          exclusionUuid={selectedExclusionUuid}
          onClose={() => onExclusionModalClose()}
        />
      </Modal>
    </Grid>
  );
};
