import { useColumnsSort } from '../../../hooks/useColumnsSort';
import {
  Button,
  ButtonGroup,
  DotsHorizontalIcon,
  Dropdown,
  DropdownMenu,
  Table,
  useDefaultSelecting,
} from '@bp/ui-components';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createTimetableVersionTableColumns } from '../utils/TimetableVersionTableUtils';
import { TimetableVersionTableType } from '../graphql/types';
import { useConfirm } from '../../../hooks/useConfirm';
import { use_LessonsQuery } from '../../../types/planung-graphql-client-defs';
import { useLoadBasicData } from '../../../hooks/useLoadBasicData';
import { hexToColorOption } from '../../../utils/colorUtils';
import { observer } from 'mobx-react-lite';
import { useTimetableStore } from '../../TimetableGrid/TimetableProvider';

type TimetableVersionSubjectsTableProps = {
  versionUuid: string;
  onRowSelection: (rowData: TimetableVersionTableType, index: number) => void;
  onShowLessonClick: (uuid: string) => void;
  versionSubjectUuids: string[];
  onRemove: (uuids: string[]) => void;
};

export const TimetableVersionSubjectsTable = observer(
  ({
    versionUuid,
    onRowSelection,
    onShowLessonClick,
    versionSubjectUuids,
    onRemove,
  }: TimetableVersionSubjectsTableProps) => {
    const { t } = useTranslation();
    const store = useTimetableStore();

    const { sorting, saveSorting } = useColumnsSort('timetableVersionSubjectsTable');
    const { rowSelection, onRowSelectionChange } = useDefaultSelecting({ 0: true });

    const columns = useMemo(() => createTimetableVersionTableColumns(t('subject.title', { count: 1 })), [t]);

    const { subjectData } = useLoadBasicData({ pause: !versionUuid });

    const subjects: TimetableVersionTableType[] = useMemo(() => {
      return (
        subjectData?.subjects
          .filter((s) => versionSubjectUuids.includes(s.uuid))
          .map((s) => {
            const { html, label } = hexToColorOption(s.timetableConfig?.color ?? '');

            return {
              color: {
                color: html,
                label,
              },
              count: 0,
              name: s.name,
              shortName: s.shortName,
              uuid: s.uuid,
            };
          }) ?? []
      );
    }, [subjectData, versionSubjectUuids]);

    const [{ data: lessonsData }] = use_LessonsQuery({
      variables: {
        where: {
          versions_SOME: { uuid: versionUuid },
        },
      },
    });

    const canBeRemoved = (uuids: string[]) => {
      const lesson = lessonsData?.lessons.find((l) => uuids.includes(l.subject.uuid));
      return !lesson;
    };

    const { confirm, ConfirmationDialog } = useConfirm();

    const handleRemove = async (uuids: string[]) => {
      const res = await confirm({
        title: t('timetableVersion.removeSubjects', { count: uuids.length }),
        message: t('timetableVersion.removeSubjectsDescription', { count: uuids.length }),
      });
      if (res) {
        onRemove(uuids);
      }
    };
    const readOnly = store.readonly;

    return (
      <>
        <Table<TimetableVersionTableType>
          showBorderRadius
          showShadow
          canScroll
          minHeight={900}
          sorting={sorting}
          onSortingChange={saveSorting}
          columns={columns}
          data={subjects}
          lastColWidth='140px'
          lastCol={(row) => {
            return (
              <ButtonGroup>
                <Button hierarchy='secondary' onClick={() => onShowLessonClick(row.original.uuid)}>
                  {t('timetableVersion.showLessons')}
                </Button>
                <Dropdown
                  noPadding
                  trigger={<Button hierarchy='secondary' icon={<DotsHorizontalIcon className='small' />} />}
                >
                  <DropdownMenu
                    data={[
                      {
                        label: t('timetableVersion.editData'),
                        disabled: true,
                        onClick: () => {
                          console.log('edit clicked');
                        },
                      },
                      {
                        label: t('timetableVersion.test'),
                        disabled: true,
                      },
                      {
                        label: t('timetableVersion.remove'),
                        type: 'default',
                        color: 'error',
                        disabled: !canBeRemoved([row.original.uuid]) || readOnly,
                        onClick: async () => {
                          await handleRemove([row.original.uuid]);
                        },
                      },
                    ]}
                  />
                </Dropdown>
              </ButtonGroup>
            );
          }}
          showRowHover
          onRowClick={(event, row) => {
            if (onRowSelectionChange) onRowSelectionChange({ [row.id]: true });
            onRowSelection(row.original, row.index);
            document.querySelector('#content')?.scrollTo({ top: 0, behavior: 'smooth' });
          }}
          onRowSelectionChange={onRowSelectionChange}
          rowSelection={rowSelection}
          showSelected
        />
        <ConfirmationDialog />
      </>
    );
  },
);
