import { ReactNode } from 'react';
import styles from './AvailabilityMatrix.module.scss';
import { AvailabilityMatrixItem, AvailabilityMatrixStates } from './AvailabilityMatrixItem';
import { useTranslation } from 'react-i18next';
import { CheckIcon, CloseIcon, QuestionmarkIcon } from '@bp/ui-components';
import { initialMatrix } from '../TimetableVersion/utils/TimetableVersionTableUtils';
import { BitStatus, StatusManager } from '../../stores/StatusManager';
import classNames from 'classnames';
import { TimetableRelationshipProperties } from '@bp/planung-graphql-types';
import { observer } from 'mobx-react-lite';
import { useTimetableStore } from '../TimetableGrid/TimetableProvider';

export type Day = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';
export type Bit = BitStatus;

export type TimeGridEntry = {
  uuid: string;
  order?: number;
  name: string;
  shortName: string;
  pause?: boolean;
};

type AvailabilityMatrixProps = {
  days: Day[];
  hours: TimeGridEntry[];
  availability?: TimetableRelationshipProperties | null;
  onChange: (items: TimetableRelationshipProperties) => void;
  className?: string | undefined;
};

export const AvailabilityMatrix = observer(({ days, hours, availability, onChange, className }: AvailabilityMatrixProps) => {
  const { t } = useTranslation();
  const statusManager = StatusManager();
  const store = useTimetableStore();
  const items: TimetableRelationshipProperties = availability ? availability : initialMatrix;

  function getIcon(state: Bit): ReactNode {
    return statusManager.isFree(state) ? (
      <CheckIcon />
    ) : statusManager.isMaybe(state) ? (
      <QuestionmarkIcon className={styles['questionmark-icon']} />
    ) : (
      <CloseIcon />
    );
  }

  function getTitle(state: Bit): string {
    return statusManager.isFree(state)
      ? t('availabilityMatrix.available')
      : statusManager.isMaybe(state)
        ? t('availabilityMatrix.conditional')
        : t('availabilityMatrix.notAvailable');
  }

  function getStateName(state: Bit): AvailabilityMatrixStates {
    return statusManager.isFree(state) ? 'available' : statusManager.isMaybe(state) ? 'conditional' : 'notAvailable';
  }

  function getNextState(lastState: Bit): Bit {
    return statusManager.isFree(lastState)
      ? BitStatus.MAYBE
      : statusManager.isMaybe(lastState)
        ? BitStatus.OCCUPIED
        : BitStatus.FREE;
  }

  function toggleRow(hour: number) {
    const day = items['mon'];
    if (!day) return;
    const currentBit = statusManager.stringToBit(day[hour]);
    const state = getNextState(currentBit);
    days.forEach((day) => {
      changeValue(hour, day as Day, state as Bit);
    });
    onChange(items);
  }

  function toggleColumn(column: Day) {
    const day = items[column];
    if (!day) return;
    const currentBit = statusManager.stringToBit(day[0]);
    const state = getNextState(currentBit);
    for (let i = 0; i < hours.length; i++) {
      changeValue(i, column, state);
    }
    onChange(items);
  }

  function handleClick(hour: number, day: Day) {
    changeValue(hour, day);
    onChange(items);
  }

  function changeValue(hour: number, day: Day, newState?: Bit) {
    if (store.readonly) return;

    if (items) {
      if (items[day]) {
        const currentValue = items[day] ?? '';
        if (currentValue[hour]) {
          const currentBit = statusManager.stringToBit(currentValue[hour]);
          let newValue = newState ?? getNextState(currentBit);

          if (statusManager.isBlocked(currentBit)) {
            newValue = statusManager.block(newValue);
          }
          items[day] = currentValue.substring(0, hour) + newValue + currentValue.substring(hour + 1);
        }
      }
    }
  }

  function getTranslation(day: Day): string {
    switch (day) {
      case 'mon':
        return t('days.monday');
      case 'tue':
        return t('days.tuesday');
      case 'wed':
        return t('days.wednesday');
      case 'thu':
        return t('days.thursday');
      case 'fri':
        return t('days.friday');
      case 'sat':
        return t('days.saturday');
      case 'sun':
        return t('days.sunday');
      default:
        return t('days.monday');
    }
  }

  return (
    <div className={classNames(styles['matrix'], className)}>
      <div className={styles['header']}>
        {days.map((day, dayIndex) => {
          const dayName = getTranslation(day);
          return (
            <div
              className={styles['day-name']}
              key={dayIndex}
              title={`${dayName} - ${t('availabilityMatrix.switchAll')}`}
              onClick={() => toggleColumn(day)}
            >
              {dayName}
            </div>
          );
        })}
      </div>
      {hours.map((hour) => {
        const order = hour.order ?? 0;

        return (
          <div className={styles['row']} key={order}>
            <div
              className={styles['hour']}
              title={`${hour.name} - ${t('availabilityMatrix.switchAll')}`}
              onClick={() => toggleRow(order)}
            >
              {hour.shortName}
            </div>
            <div className={styles['items']}>
              {days.map((day, dayIndex) => {
                const currentValue = items[day] ?? '';
                const currentBit = statusManager.isFree(statusManager.stringToBit(currentValue[order]))
                  ? BitStatus.FREE
                  : statusManager.isMaybe(statusManager.stringToBit(currentValue[order]))
                    ? BitStatus.MAYBE
                    : BitStatus.OCCUPIED;
                const icon = getIcon(currentBit);
                return (
                  <AvailabilityMatrixItem
                    key={dayIndex}
                    item={{ icon: icon, state: getStateName(currentBit), title: getTitle(currentBit) }}
                    blocked={statusManager.isBlocked(statusManager.stringToBit(currentValue[order]))}
                    readonly={store.readonly}
                    onClick={() => handleClick(order, day)}
                  />
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
});
