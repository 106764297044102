import { Form, Formik, FormikHelpers } from 'formik';
import { schema } from './validation/schema';
import { LessonBasicsForm } from './Basics/LessonBasicsForm';
import { LessonClassesForm } from './Classes/LessonClassesForm';
import { LazyLoader, Tooltip } from '@bp/ui-components';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { LessonUnitForm } from './LessonUnits/LessonUnitForm';
import { LessonTeacherForm } from './Teachers/LessonTeacherForm';
import { FormBlockHeader } from '../../Form/FormBlockHeader';
import { LessonRoomSupplyForm } from './RoomSupply/LessonRoomSupplyForm';
import { LessonFormType } from '../types';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { observer } from 'mobx-react-lite';
import { useDeputateLessons } from '../hooks/useDeputateLessons';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { ModalBottomButtons } from '../../ModalBottomButtons/ModalBottomButtons';
import { showSuccessCreateToast, showSuccessUpdateToast, showUserErrorToast } from '../../../utils/toast';

type LessonFormProps = {
  closeForm: () => void;
  lessonUuid?: string | null;
  defaultInitialValues: Partial<LessonFormType>;
};

export const DeputateLessonForm = observer(({ closeForm, lessonUuid, defaultInitialValues }: LessonFormProps) => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const schoolYear = useUserConfigContext().selectedSchoolYear;

  const { createLesson, updateLesson, queryLessonsData, lessonsData } = useDeputateLessons({
    organizationUuid: pimAuthClaims.getOrganizationUuid(),
    schoolYearUuid: schoolYear?.uuid ?? '',
  });

  const lesson = queryLessonsData?.lessons.find((lesson) => {
    return lesson.uuid === lessonUuid;
  });
  const currentLessonsData = lessonsData.find((lesson) => {
    return lesson.uuid === lessonUuid;
  });

  const status = currentLessonsData?.editStatus;

  const initialValues: LessonFormType = lesson
    ? {
        uuid: lesson.uuid,
        subject: lesson.subject,
        lessonClasses: lesson.lessonClassesConnection.edges.map(({ node }) => {
          return {
            uuid: node.uuid,
            groups: node.groups,
            class: {
              uuid: node.class.uuid,
              name: node.class.name,
              color: node.class.timetableConfig?.color,
              grade: node.class.grade,
            },
          };
        }),
        comment: lesson.comment,
        lessonUnit: lesson.lessonUnitConnection.edges.map(({ node }) => {
          return {
            ...node,
            subjectContainerUuid: node.subjectContainer?.uuid,
            blocked: status === 'blocked' || status === 'placedCards',
            lessonUnitType: node.subjectContainer?.uuid ? 'epoch' : 'subjectHour',
          };
        }),
        elective: lesson.elective,
        isClassTrip: lesson.isClassTrip,
        teachingLoadEnabled: lesson.teachingLoadEnabled,
        timetableEnabled: lesson.timetableEnabled,
        subTitle: lesson.subTitle,
        onlyInTimetableVersion: Boolean(lesson.onlyInTimetableVersion),
        roomSupply: lesson.roomSupplyConnection
          ? lesson.roomSupplyConnection.edges.map((edge) => ({
              uuid: edge.node.uuid,
              description: edge.properties.descriptions ?? '',
            }))
          : [],
        teachers:
          lesson.curriculum && lesson.teachersConnection.edges.length === 0
            ? lesson.curriculum.personsConnection.edges.map((teacherEdge) => {
                return {
                  ...teacherEdge.properties,
                };
              })
            : lesson.teachersConnection.edges.map((teacherEdge) => {
                return {
                  ...teacherEdge.properties,
                  person: {
                    uuid: teacherEdge.node.uuid,
                    fullName: teacherEdge.node.fullName,
                    displayNameShort: teacherEdge.node.displayNameShort ?? teacherEdge.node.fullName,
                    color: teacherEdge.node.timetableConfig?.color,
                  },
                };
              }),
      }
    : {
        ...defaultInitialValues,
        teachingLoadEnabled: true,
        timetableEnabled: true,
        lessonUnit: [],
        onlyInTimetableVersion: false,
        lessonClasses: [],
      };

  const handleSubmit = async (values: LessonFormType, formikHelpers: FormikHelpers<LessonFormType>) => {
    let result: { error?: unknown; success?: boolean };
    if (!values.uuid) {
      const createResult = await createLesson(values);
      result = createResult[0];
      if (!result.error) {
        showSuccessCreateToast();
      }
    } else {
      result = await updateLesson(values);
      if (!result.error) {
        showSuccessUpdateToast();
      }
    }
    if (result.error) {
      showUserErrorToast({ error: result.error });
    }
    formikHelpers.resetForm();
    closeForm();
  };

  return (
    <Suspense fallback={<LazyLoader transparent forceHeight='40vh' />}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
        {(formik) => (
          <Form>
            <div className='form-block'>
              <div className={'form-group'}>
                <LessonBasicsForm key={formik.values.subject?.uuid} status={status} />
              </div>
            </div>
            {!formik.values.onlyInTimetableVersion && (
              <div className='form-block'>
                <FormBlockHeader title={t('classes.title', { count: 2 })} />
                <Suspense fallback={<LazyLoader embedded forceHeight='10vh' />}>
                  <LessonClassesForm disabled={status === 'blocked' || status === 'placedCards'} />
                </Suspense>
              </div>
            )}
            <div className='form-block'>
              <FormBlockHeader title={t('lessonUnit.title', { count: 1 })} />
              <Suspense fallback={<LazyLoader embedded forceHeight='10vh' />}>
                <LessonUnitForm status={status} key={formik.values.subject?.uuid} />
              </Suspense>
            </div>
            <div className='form-block'>
              <FormBlockHeader title={t('persons.title', { count: 1 })}>
                {lesson?.curriculum ? (
                  <Tooltip content={t('lesson.personsInCurriculum')}>
                    {`${formik.values.teachers?.length ?? 0} / ${lesson.curriculum.personsConnection.totalCount}`}
                  </Tooltip>
                ) : (
                  ''
                )}
              </FormBlockHeader>
              <Suspense fallback={<LazyLoader embedded forceHeight='10vh' />}>
                <LessonTeacherForm
                  disabled={status === 'blocked'}
                  defaults={{ writesCertificate: true, teachingLoad: true, present: true }}
                />
              </Suspense>
            </div>
            <div className='form-block'>
              <FormBlockHeader title={t('rooms.stock')} />
              <Suspense fallback={<LazyLoader embedded forceHeight='10vh' />}>
                <LessonRoomSupplyForm disabled={status === 'blocked'} key={formik.values.subject?.uuid} />
              </Suspense>
            </div>
            <ModalBottomButtons
              closeButton={{
                text: status === 'blocked' ? t('common.close') : t('common.cancel'),
                callback: () => {
                  formik.resetForm();
                  closeForm();
                },
              }}
              isLoading={formik.isSubmitting}
              errors={formik.errors}
            />
          </Form>
        )}
      </Formik>
    </Suspense>
  );
});
