import { useTranslation } from 'react-i18next';
import styles from './Profile.module.scss';
import {
  ArrowHeadRightIcon,
  Button,
  Card,
  Grid,
  GridColumn,
  GridRow,
  Header,
  Input,
  LogOutIcon,
  Table,
  TableColumns,
} from '@bp/ui-components';
import { observer } from 'mobx-react-lite';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { useAuth } from 'react-oidc-context';
import { Page } from '../../components/Page/Page';
import { useMemo } from 'react';

export const Profile = observer(() => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const auth = useAuth();

  const login = async () => auth.signinRedirect({ prompt: 'select_account consent' });
  const logout = async () =>
    auth.signoutRedirect({ post_logout_redirect_uri: import.meta.env.VITE_APP_LOGOUT_REDIRECT_URI });

  const profile = pimAuthClaims.getProfile();

  const organizations = useMemo(
    () =>
      pimAuthClaims
        .getOtherProfiles()
        .filter((profile) => profile.organization.name !== '__omni__')
        .map((profile) => ({ uuid: profile.uuid, name: profile.organization.name }))
        .sort((a, b) => a.name.localeCompare(b.name)),
    [pimAuthClaims],
  );

  const columns: TableColumns<{ uuid: string; name: string }>[] = [
    {
      id: 'name',
      header: t('organizations.title', { count: 1 }),
      accessorKey: 'name',
      size: 300,
      canExpand: true,
    },
  ];

  return (
    <Page title={t('profile.title', { count: 2 })} hideHeader className={styles.profile}>
      <Header
        size='l'
        headline={pimAuthClaims.getProfile().displayName ?? ''}
        actions={[
          {
            hierarchy: 'secondary',
            text: t('profile.changeProfile'),
            callback: () => login(),
          },
          {
            icon: <LogOutIcon />,
            hierarchy: 'primary',
            text: t('profile.logout'),
            callback: () => logout(),
          },
        ]}
      />

      <Card
        header={{
          headline: t('profile.profileData'),
        }}
        wrapperClass='mb-6'
      >
        <Grid useFormGap>
          <GridRow spacingTop='s'>
            <GridColumn width={6}>
              <Input label={t('common.firstName')} name='firstName' readonly value={profile?.firstName} />
              <Input label={t('common.email')} name='email' readonly value={profile?.email} />
            </GridColumn>
            <GridColumn width={6}>
              <Input label={t('common.lastName')} name='lastName' readonly value={profile?.lastName} />
              <Input
                label={t('organizations.title', { count: 1 })}
                name='organisation'
                readonly
                value={profile?.organization.name}
              />
            </GridColumn>
          </GridRow>
        </Grid>
      </Card>

      {pimAuthClaims.getUser().isOmniAdmin && (
        <Card
          header={{
            headline: t('auth.support'),
          }}
          wrapperClass='mb-6'
        >
          <Table
            columns={columns}
            data={organizations}
            lastColWidth={'120px'}
            showActionBar
            lastCol={(row) => {
              return (
                <>
                  <Button
                    hierarchy={'secondary'}
                    icon={<ArrowHeadRightIcon />}
                    iconPosition={'right'}
                    onClick={async () => {
                      await auth.signinRedirect({ prompt: 'select_account consent', login_hint: row.original.uuid });
                    }}
                  >
                    Wechseln
                  </Button>
                </>
              );
            }}
          />
        </Card>
      )}
    </Page>
  );
});
