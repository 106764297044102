import { useTranslation } from 'react-i18next';
import { Page } from '../../../../components/Page/Page';
import { AttendanceClassesTable } from '../../../../components/Classes/Tables/AttendanceClassesTable';

export const SetupAttendanceClass = () => {
  const { t } = useTranslation();

  return (
    <Page title={`${t('classes.attendanceClass', { count: 2 })} ${t('common.setup').toLowerCase()}`}>
      <AttendanceClassesTable />
    </Page>
  );
};
