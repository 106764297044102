import { Button, Grid, GridRow, ImportantIcon, Modal, Select, SelectOptionType } from '@bp/ui-components';
import { ModalBottomButtons } from '../../ModalBottomButtons/ModalBottomButtons';
import { t } from 'i18next';
import styles from './CardForm.module.scss';
import classNames from 'classnames';
import { CardType } from '../../TimetableVersion/graphql/types';
import { MultiValue } from 'react-select';
import { useCallback, useEffect, useState } from 'react';
import { useRooms } from '../../Rooms/useRooms';
import { createIntArray, uniqueBy } from '../../../utils/arrayFunc';
import { useMutation } from 'urql';
import {
  CreateCardDocument,
  CreateSubjectContainerCardDocument,
  useDeleteCardsMutation,
} from '../../../types/planung-graphql-client-defs';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { RoomItem } from '../../../stores/PinboardStore';
import { observer } from 'mobx-react-lite';
import { useTimetableStore } from '../TimetableProvider';

type CardFormProps = {
  card: CardType;
  onClose: () => void;
  className?: string | undefined;
};

export const CardForm = observer(({ card, onClose, className }: CardFormProps) => {
  const { roomData } = useRooms();
  const context = useMemorizedCacheTag('LESSONS');
  const pinboardStore = useTimetableStore();

  const [, createSubjectContainerCard] = useMutation(CreateSubjectContainerCardDocument);
  const [, createCard] = useMutation(CreateCardDocument);
  const [, deleteCards] = useDeleteCardsMutation();

  const [conflicts, setConflicts] = useState<RoomItem[]>([]);
  const [overrides, setOverrides] = useState<RoomItem[]>([]);
  const [roomValues, setRoomValues] = useState<SelectOptionType[]>([]);
  const [touched, setTouched] = useState<boolean>(false);

  const groupedRooms = pinboardStore.defaultCardRooms.get(card.uuid) ?? {
    lessonRoomItems: [],
    subjectRoomItems: [],
    classRoomItems: [],
    teacherRoomItems: [],
  };

  const rooms = [
    ...groupedRooms.lessonRoomItems,
    ...groupedRooms.subjectRoomItems,
    ...groupedRooms.classRoomItems,
    ...groupedRooms.teacherRoomItems,
  ];

  // add all missing classrooms from the school
  roomData?.rooms
    .filter((room) => !!room.classroom)
    .forEach((room) => {
      if (rooms.every((r) => r.value !== room.uuid)) {
        rooms.push({ value: room.uuid, label: room.name, group: t('common.school'), inUse: false });
      }
    });

  const roomOptions: SelectOptionType[] = uniqueBy<RoomItem>(rooms, 'value').map(({ value, label, group, inUse }) => {
    return { label, value, group, highlight: inUse ? 'error' : undefined };
  });

  const handleSubmit = async () => {
    if (overrides.length > 0) {
      void pinboardStore.removeRoomsFromCards(
        overrides.map((o) => ({
          uuid: o.value,
        })),
        overrides.map((o) => ({
          label: o.label,
          value: o.value,
          inUse: false,
          group: '',
        })),
      );
    }

    void pinboardStore.setRoomsForCards(
      [card],
      roomValues.map((r) => ({
        label: r.label ?? '',
        value: r.value as string,
        inUse: false,
        group: '',
      })),
    );

    resetForm();
    onClose();
  };

  const convertToSingle = useCallback(async () => {
    let cardsToCreateCounter = 0;
    const cardsToDeleteUuids: string[] = [];

    if (card && card.duration && card.duration > 1) {
      cardsToDeleteUuids.push(card.uuid);
      cardsToCreateCounter = cardsToCreateCounter + card.duration;
    } else {
      return;
    }

    if (card.isSubjectContainer) {
      await Promise.all(
        createIntArray(cardsToCreateCounter).map(() => {
          return createSubjectContainerCard(
            {
              duration: 1,
              lessonUuid: card.lesson?.uuid,
              versionUuid: pinboardStore.getCurrentVersion()?.uuid,
              subjectContainerUuid: card.subject?.uuid,
            },
            context,
          );
        }),
      );
    } else {
      await Promise.all(
        createIntArray(cardsToCreateCounter).map(() => {
          return createCard(
            {
              duration: 1,
              lessonUuid: card.lesson?.uuid,
              versionUuid: pinboardStore.getCurrentVersion()?.uuid,
            },
            context,
          );
        }),
      );
    }

    await deleteCards({ where: { uuid_IN: cardsToDeleteUuids } }, context);
  }, [card, context, createCard, createSubjectContainerCard, deleteCards, pinboardStore]);

  function resetForm() {
    setRoomValues([]);
    setConflicts([]);
    setOverrides([]);
    setTouched(false);
  }

  function onAssignDouble() {
    setRoomValues([...roomValues, ...conflicts.map((c) => ({ value: c.value, label: c.label }))]);
    setConflicts([]);
    setOverrides([]);
    setTouched(true);
  }

  function onRemoveOtherRooms() {
    setRoomValues([...roomValues, ...conflicts.map((c) => ({ value: c.value, label: c.label }))]);
    setOverrides(conflicts);
    setConflicts([]);
    setTouched(true);
  }

  function onCancelConflict() {
    setConflicts([]);
    setOverrides([]);
    setTouched(false);
  }

  const classes = classNames(styles['card-form'], className);

  useEffect(() => {
    setRoomValues(roomOptions.filter((room) => card.rooms.some((r) => r.uuid === room.value)));
  }, []);

  return (
    <>
      <form className={classes}>
        <Grid useFormGap>
          <GridRow headline={t('rooms.title', { count: 2 })} spacingBottom='l' direction='column'>
            <GridRow spacingBottom='s'>
              <Select
                isMulti
                isSearchable
                hasGroups
                options={roomOptions}
                onChange={(option) => {
                  const conflicts: RoomItem[] = [];
                  const selectedRooms = roomOptions.filter((roomOption) =>
                    (option as MultiValue<SelectOptionType>).some(({ value }) => value === roomOption.value),
                  );
                  selectedRooms.forEach((r) => {
                    const room = rooms.find((room) => room.value === r.value);
                    if (room && room.inUse) conflicts.push(room);
                  });
                  setConflicts(conflicts);
                  if (conflicts.length > 0) return;
                  setRoomValues(selectedRooms);
                  setTouched(true);
                }}
                value={roomValues.filter((r) => !conflicts.some((c) => c.value === r.value))}
                disabled={!card.startTimeGridEntry}
                name='rooms'
                label={t('pinboard.edit.assignRoom')}
              />
            </GridRow>
            {overrides && overrides.length > 0 && (
              <GridRow className={styles.overrides} spacingTop='none' direction='column'>
                <div className={styles.warning}>
                  <ImportantIcon className={styles.icon} />
                  {t('pinboard.edit.overrideRoomsHint')}
                </div>
                <div className={styles.cards}></div>
              </GridRow>
            )}
          </GridRow>
          <GridRow headline={t('pinboard.edit.lesson')} spacingBottom='l'>
            <Button
              hierarchy='secondary'
              onClick={async () => {
                await convertToSingle();
              }}
              disabled={!!card.startTimeGridEntry || (!!card.duration && card.duration <= 1)}
              isLoading={false}
            >
              {t('pinboard.actions.singleHour')}
            </Button>
          </GridRow>
        </Grid>

        <ModalBottomButtons
          closeButton={{
            text: t('common.cancelChanges'),
            callback: () => {
              resetForm();
              onClose();
            },
          }}
          submitButton={{
            disabled: !touched,
            callback: handleSubmit,
          }}
        />
      </form>

      <Modal
        isOpen={conflicts.length > 0}
        onRequestClose={() => setConflicts([])}
        title={t('pinboard.roomConflict.alreadyAssigned')}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        hideCloseButton
        style={{ overlay: { zIndex: `calc(var(--z-index-modal) + 1)` } }}
        className={styles['room-conflict-modal']}
      >
        <div className='mb-2'>
          {t('pinboard.roomConflict.alreadyAssignedCards', { room: conflicts && conflicts[0] && conflicts[0].label })}
        </div>
        <div className={styles.cards}></div>
        <div>{t('common.howToSolve')}</div>
        <div className={styles.actions}>
          <Button hierarchy='secondary' onClick={() => onAssignDouble()}>
            {t('pinboard.roomConflict.assignDouble')}
          </Button>
          <Button className={styles['long-button']} hierarchy='secondary' onClick={() => onRemoveOtherRooms()}>
            {t('pinboard.roomConflict.removeOtherRooms')}
          </Button>
          <Button hierarchy='secondary' onClick={() => onCancelConflict()}>
            {t('common.cancel')}
          </Button>
        </div>
      </Modal>
    </>
  );
});
