import { Grid, GridColumn, GridRow, SelectOptionType } from '@bp/ui-components';
import styles from './TimetablePrintConfigurationGrid.module.scss';
import { TimetablePrintConfigurationGridTile } from '../TimetablePrintConfigurationGridTile/TimetablePrintConfigurationGridTile';
import { useTranslation } from 'react-i18next';
import { TimetablePrintLayoutData, TimetablePrintLayoutPositions } from '../TimetablePrintConfiguration';
import { TimetablePrintColorPositions, TimetablePrintLayoutOptions } from '@bp/planung-graphql-types';

type TimetablePrintConfigurationGridProps = {
  data: TimetablePrintLayoutData;
  color: string;
  colorPosition: TimetablePrintColorPositions;
  onLayoutChange: (position: TimetablePrintLayoutPositions, option: TimetablePrintLayoutOptions | null) => void;
};

export const TimetablePrintConfigurationGrid = ({
  data,
  color,
  colorPosition,
  onLayoutChange,
}: TimetablePrintConfigurationGridProps) => {
  const { t } = useTranslation();
  const white = '#f7f7f7';

  const background =
    colorPosition === TimetablePrintColorPositions.Top
      ? `linear-gradient(0deg, ${white} 90%, ${color} 10%)`
      : colorPosition === TimetablePrintColorPositions.Right
        ? `linear-gradient(90deg, ${white} 95%, ${color} 5%)`
        : colorPosition === TimetablePrintColorPositions.Bottom
          ? `linear-gradient(180deg, ${white} 90%, ${color} 10%)`
          : colorPosition === TimetablePrintColorPositions.Left
            ? `linear-gradient(270deg, ${white} 95%, ${color} 5%)`
            : colorPosition === TimetablePrintColorPositions.None
              ? white
              : color;

  const configOptions: SelectOptionType[] = [
    {
      label: t('timetable.configuration.options.subjectShort'),
      value: TimetablePrintLayoutOptions.SubjectShort,
    },
    {
      label: t('timetable.configuration.options.subjectLong'),
      value: TimetablePrintLayoutOptions.SubjectLong,
    },
    {
      label: t('classGroups.group'),
      value: TimetablePrintLayoutOptions.GroupName,
    },
    {
      label: t('classes.title', { count: 1 }),
      value: TimetablePrintLayoutOptions.ClassName,
    },
    {
      label: `${t('classes.title', { count: 1 })}/${t('classGroups.group')}`,
      value: TimetablePrintLayoutOptions.ClassAndGroup,
    },
    {
      label: t('timetable.configuration.options.teacherShort'),
      value: TimetablePrintLayoutOptions.TeacherShort,
    },
    {
      label: t('timetable.configuration.options.teacherLong'),
      value: TimetablePrintLayoutOptions.TeacherLong,
    },
    {
      label: t('rooms.title', { count: 1 }),
      value: TimetablePrintLayoutOptions.RoomName,
    },
    {
      label: t('rooms.roomNumber'),
      value: TimetablePrintLayoutOptions.RoomNumber,
    },
    {
      label: `${t('timetable.configuration.options.teacher')}/${t('rooms.title', { count: 1 })}`,
      value: TimetablePrintLayoutOptions.TeacherAndRoom,
    },
  ];

  const getPreview = (option: TimetablePrintLayoutOptions) => {
    let preview = '';
    switch (option) {
      case TimetablePrintLayoutOptions.SubjectShort:
        preview = t('timetable.configuration.preview.subjectShort');
        break;
      case TimetablePrintLayoutOptions.SubjectLong:
        preview = t('timetable.configuration.preview.subjectLong');
        break;
      case TimetablePrintLayoutOptions.GroupName:
        preview = t('timetable.configuration.preview.groupName');
        break;
      case TimetablePrintLayoutOptions.ClassName:
        preview = t('timetable.configuration.preview.className');
        break;
      case TimetablePrintLayoutOptions.ClassAndGroup:
        preview = t('timetable.configuration.preview.classAndGroup');
        break;
      case TimetablePrintLayoutOptions.TeacherShort:
        preview = t('timetable.configuration.preview.teacherShort');
        break;
      case TimetablePrintLayoutOptions.TeacherLong:
        preview = t('timetable.configuration.preview.teacherLong');
        break;
      case TimetablePrintLayoutOptions.RoomName:
        preview = t('timetable.configuration.preview.roomName');
        break;
      case TimetablePrintLayoutOptions.RoomNumber:
        preview = t('timetable.configuration.preview.roomNumber');
        break;
      case TimetablePrintLayoutOptions.TeacherAndRoom:
        preview = t('timetable.configuration.preview.teacherAndRoom');
        break;
    }
    return preview;
  };

  return (
    <Grid className={styles['timetable-print-configuration-grid']} useFormGap>
      <GridRow>
        <GridColumn width={9}>
          <div className={styles.config}>
            <div className={styles.top}>
              <TimetablePrintConfigurationGridTile
                position={TimetablePrintLayoutPositions.topLeft}
                value={data[TimetablePrintLayoutPositions.topLeft]}
                options={configOptions}
                onChange={onLayoutChange}
              />
              <TimetablePrintConfigurationGridTile
                position={TimetablePrintLayoutPositions.topCenter}
                value={data[TimetablePrintLayoutPositions.topCenter]}
                options={configOptions}
                disabled={true}
                onChange={onLayoutChange}
              />
              <TimetablePrintConfigurationGridTile
                position={TimetablePrintLayoutPositions.topRight}
                value={data[TimetablePrintLayoutPositions.topRight]}
                options={configOptions}
                onChange={onLayoutChange}
              />
            </div>
            <div className={styles.center}>
              <TimetablePrintConfigurationGridTile
                isMandatory
                position={TimetablePrintLayoutPositions.center}
                value={data[TimetablePrintLayoutPositions.center]}
                options={configOptions}
                onChange={onLayoutChange}
              />
            </div>
            <div className={styles.bottom}>
              <TimetablePrintConfigurationGridTile
                position={TimetablePrintLayoutPositions.bottomLeft}
                value={data[TimetablePrintLayoutPositions.bottomLeft]}
                options={configOptions}
                onChange={onLayoutChange}
              />
              <TimetablePrintConfigurationGridTile
                position={TimetablePrintLayoutPositions.bottomCenter}
                value={data[TimetablePrintLayoutPositions.bottomCenter]}
                options={configOptions}
                disabled={true}
                onChange={onLayoutChange}
              />
              <TimetablePrintConfigurationGridTile
                position={TimetablePrintLayoutPositions.bottomRight}
                value={data[TimetablePrintLayoutPositions.bottomRight]}
                options={configOptions}
                onChange={onLayoutChange}
              />
            </div>
          </div>
        </GridColumn>

        <GridColumn width={3}>
          <div
            className={styles.preview}
            style={{ background: colorPosition === TimetablePrintColorPositions.Full ? color : background }}
          >
            <div className={styles.top}>
              <div>{getPreview(data[TimetablePrintLayoutPositions.topLeft])}</div>
              <div>{getPreview(data[TimetablePrintLayoutPositions.topCenter])}</div>
              <div>{getPreview(data[TimetablePrintLayoutPositions.topRight])}</div>
            </div>
            <div className={styles.center}>
              <div>{getPreview(data[TimetablePrintLayoutPositions.center])}</div>
            </div>
            <div className={styles.bottom}>
              <div>{getPreview(data[TimetablePrintLayoutPositions.bottomLeft])}</div>
              <div>{getPreview(data[TimetablePrintLayoutPositions.bottomCenter])}</div>
              <div>{getPreview(data[TimetablePrintLayoutPositions.bottomRight])}</div>
            </div>
          </div>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};
