import { GradeGroupsTable } from '../../../components/GradeGroups/GradeGroupsTable';
import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import { Page } from '../../../components/Page/Page';

export const GradeGroups: FC = () => {
  const { t } = useTranslation();

  return (
    <Page title={t('gradeGroups.title', { count: 2 })}>
      <GradeGroupsTable />
    </Page>
  );
};
