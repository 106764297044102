import { useState } from 'react';
import { SchoolEventTable } from '../../../../components/SchoolEvent/SchoolEventTable';
import { Page } from '../../../../components/Page/Page';
import { useTranslation } from 'react-i18next';
import { SchoolEventModal } from '../../../../components/SchoolEvent/SchoolEventModal';
import { useMemorizedCacheTag } from '../../../../hooks/useMemorizedCacheTag';
import {
  SortDirection,
  use_DeleteSchoolEventsMutation,
  use_SchoolEventsQuery,
} from '../../../../types/planung-graphql-client-defs';
import dayjs from 'dayjs';
import { useLoadBasicData } from '../../../../hooks/useLoadBasicData';
import { useConfirm } from '../../../../hooks/useConfirm';
import { showSuccessDeleteToast, showUserErrorToast } from '../../../../utils/toast';
import { useUserConfigContext } from '../../../../hooks/useUserConfigContext';
import { observer } from 'mobx-react-lite';

export type EventRepetitions = 'daily' | 'weekly' | 'weekdays' | 'annually';

export type SchoolEventType = {
  uuid: string;
  name: string;
  classUuids: string[];
  groupUuids: string[];
  subjectUuid: string;
  teacherUuids: string[];
  startDateTime: Date;
  endDateTime: Date;
  startTime: string; // only used for error messages
  endTime: string; // only used for error messages
  repetition: EventRepetitions | null;
  lastRepetition: Date;
  isClassbookRelevant: boolean;
};

export const SetupSchoolEvent = observer(() => {
  const { t } = useTranslation();
  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedUuid, setSelectedUuid] = useState<string | null>(null);

  const [, deleteEvent] = use_DeleteSchoolEventsMutation();

  const { confirm, ConfirmationDialog } = useConfirm();

  function handleAdd() {
    setSelectedUuid(null);
    setModalOpen(true);
  }

  function handleEdit(uuid: string) {
    setSelectedUuid(uuid);
    setModalOpen(true);
  }

  function handleDuplicate(uuid: string) {
    console.log('TODO: implement');
  }

  async function handleDelete(uuid: string) {
    const res = await confirm({ title: t('schoolEvents.delete.title'), message: t('schoolEvents.delete.warning') });
    if (res) {
      const res = await deleteEvent({ uuids: { uuids: [uuid] } }, context);
      if (res.error) {
        showUserErrorToast({ error: res.error });
      } else {
        showSuccessDeleteToast();
      }
    }
  }
  const context = useMemorizedCacheTag('EVENT');

  const [{ data: eventData }] = use_SchoolEventsQuery({
    variables: {
      options: {
        sort: [{ start: SortDirection.Asc }],
      },
      where: {
        isSchoolEvent: true,
        OR: [
          { start_GTE: currentSchoolYear?.start, end_LTE: currentSchoolYear?.end },
          {
            start_LTE: currentSchoolYear?.start,
            end_GTE: currentSchoolYear?.end,
          },
        ],
      },
    },
    context: context,
  });

  const { classesData, subjectData, teacherData, groupsData, divisionsData } = useLoadBasicData({ pause: !eventData });

  return (
    <Page title={t('schoolEvents.title', { count: 2 })}>
      <SchoolEventTable
        onAdd={handleAdd}
        onEdit={handleEdit}
        onDuplicate={handleDuplicate}
        onDelete={handleDelete}
        data={
          eventData?.events.map((event) => {
            const subject = subjectData?.subjects.find((s) => s.uuid === event.subject.uuid);
            return {
              uuid: event.uuid,
              name: event.comment ?? '',
              groups:
                event.classesOrGroups
                  .filter((ec) => ec.__typename === 'Group')
                  .map((ec) => {
                    const group = groupsData?.groups.find((g) => g.uuid === ec.uuid);

                    const division = divisionsData?.divisions.find(
                      (d) => d.uuid === group?.divisionConnection.edges[0].node.uuid,
                    );

                    return `${group?.shortName} (${division?.class?.shortName}) `;
                  })
                  .sort()
                  .join(', ') ?? '',
              classes:
                classesData?.classes
                  .filter((c) => event.classesOrGroups.map((ec) => ec.uuid).includes(c.uuid))
                  .map((c) => c.name)
                  .join(', ') ?? '',
              subject: subject?.name ?? '',
              teachers: event.persons
                .map((p) => {
                  return teacherData?.people.find((t) => t.uuid === p.uuid)?.displayNameShort ?? '';
                })
                .join(', '),
              start: dayjs(event.start).format('DD.MM.YYYY HH:mm'),
              end: dayjs(event.start).isSame(event.end, 'day')
                ? dayjs(event.end).format('HH:mm')
                : dayjs(event.end).format('DD.MM.YYYY HH:mm'),
            };
          }) ?? []
        }
      />

      <SchoolEventModal selectedEventUuid={selectedUuid} isOpen={modalOpen} onClose={() => setModalOpen(false)} />
      <ConfirmationDialog />
    </Page>
  );
});
