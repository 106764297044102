import {
  DatePicker,
  Grid,
  GridColumn,
  GridRow,
  Input,
  ModalBottomButtons,
  Select,
  SelectOptionType,
} from '@bp/ui-components';
import { Form, Formik, FormikHelpers } from 'formik';
import { TeacherAbsence } from '../../../pages/Substitutions/Plan/PlanTeacherAbsences/PlanTeacherAbsences';
import { AbsenceReason, getAbsenceReasonSelectOptions } from '../../../utils/absenceReasons';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, useState } from 'react';
import { useLoadBasicData } from '../../../hooks/useLoadBasicData';
import { useCreateSelectOptions } from '../../../hooks/useCreateSelectOptions';
import { SingleValue } from 'react-select';
import {
  combineFirstDateWithSecondTime,
  isFirstAfterSecond,
  isFirstBeforeSecond,
} from '../../../utils/dateCalculations';
import dayjs from 'dayjs';
import { useCreateAbsenceMutation } from '../../../types/planung-graphql-client-defs';
import { showErrorToast, showSuccessCreateToast } from '../../../utils/toast';

type TeacherAbsenceFormProps = {
  selectedAbsence?: TeacherAbsence;
  onClose: () => void;
};

export const TeacherAbsenceForm = ({ selectedAbsence, onClose }: TeacherAbsenceFormProps) => {
  const { t } = useTranslation();

  const { teacherData } = useLoadBasicData({ pause: false });

  const [loading, setLoading] = useState<boolean>(false);
  const [, create] = useCreateAbsenceMutation();

  const teacherOpts = useCreateSelectOptions(teacherData?.people, 'uuid', 'listName');
  const reasonOpts = getAbsenceReasonSelectOptions();

  const initialValues: TeacherAbsence = {
    uuid: selectedAbsence?.uuid ?? '',
    teacherUuid: selectedAbsence?.teacherUuid ?? '',
    startDate: selectedAbsence?.startDate ?? new Date(),
    startTime: selectedAbsence?.startTime ?? dayjs(new Date()).startOf('day').toDate(),
    endDate: selectedAbsence?.endDate ?? new Date(),
    endTime: selectedAbsence?.endTime ?? dayjs(new Date()).endOf('day').toDate(),
    reason: selectedAbsence?.reason ?? AbsenceReason.Illness,
    comment: selectedAbsence?.comment ?? '',
  };

  async function handleSubmit(values: TeacherAbsence, formHelpers: FormikHelpers<TeacherAbsence>) {
    setLoading(true);

    if (!values.uuid) {
      const { error } = await create({
        input: {
          person: values.teacherUuid,
          reason: values.reason,
          start: combineFirstDateWithSecondTime(values.startDate, values.startTime),
          end: combineFirstDateWithSecondTime(values.endDate, values.endTime),
          comment: values.comment,
        },
      });

      if (error) {
        showErrorToast(error);
      } else {
        showSuccessCreateToast();
      }
    }

    setLoading(false);
    onClose();
  }

  return (
    <Formik<TeacherAbsence>
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize={true}
    >
      {({ errors, values, setFieldValue, isSubmitting, dirty, isValidating, handleSubmit, setFieldError }) => {
        return (
          <Form>
            <Grid useFormGap>
              <GridRow spacingBottom='s'>
                <GridColumn width={6}>
                  <Select
                    name='teacherUuid'
                    isSearchable
                    label={t('persons.title', { count: 1 })}
                    value={teacherOpts.find((t) => t.value === values?.teacherUuid)}
                    options={teacherOpts}
                    onChange={async (option) => {
                      const opt = option as SingleValue<SelectOptionType>;
                      await setFieldValue('teacherUuid', opt?.value);
                    }}
                    menuPosition='fixed'
                    error={errors.teacherUuid}
                  />
                </GridColumn>
                <GridColumn width={6}>
                  <Select
                    name='reason'
                    label={t('absences.reason')}
                    value={reasonOpts.find((r) => r.value === values?.reason)}
                    options={reasonOpts}
                    onChange={async (option) => {
                      const opt = option as SingleValue<SelectOptionType>;
                      await setFieldValue('reason', opt?.value);
                    }}
                    menuPosition='fixed'
                    error={errors.reason}
                  />
                </GridColumn>
              </GridRow>
              <GridRow spacingBottom='s' spacingTop='s'>
                <GridColumn width={3}>
                  <DatePicker
                    name='startDate'
                    value={values.startDate}
                    label={t('common.from')}
                    onChange={async (e) => {
                      await setFieldValue('startDate', e);

                      if (isFirstAfterSecond(e, values.endDate)) {
                        setFieldError('startDate', t('validation.common.startDateAfterEnd'));
                      } else {
                        setFieldError('startDate', undefined);
                      }

                      if (isFirstBeforeSecond(values.endDate, e)) {
                        setFieldError('endDate', t('validation.common.endDateAfterStart'));
                      } else {
                        setFieldError('endDate', undefined);
                      }

                      if (isFirstAfterSecond(e, values.endTime, 'minutes')) {
                        setFieldError('startTime', t('validation.common.startTimeAfterEnd'));
                      } else {
                        setFieldError('startTime', undefined);
                      }
                    }}
                    error={errors.startDate as string}
                  />
                </GridColumn>
                <GridColumn width={3}>
                  <Input
                    name='startTime'
                    label={t('common.start')}
                    value={dayjs(values.startTime).format('HH:mm')}
                    onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                      const time = dayjs(values.startDate).format('YYYY-MM-DD') + 'T' + event.target.value;
                      if (isFirstAfterSecond(time, values.endTime, 'minutes')) {
                        setFieldError('startTime', t('validation.common.startTimeAfterEnd'));
                      } else {
                        setFieldError('startTime', undefined);
                      }
                      await setFieldValue('startTime', time);
                    }}
                    type='time'
                    error={errors.startTime as string}
                  />
                </GridColumn>
                <GridColumn width={3}>
                  <DatePicker
                    name='endDate'
                    value={values.endDate}
                    label={t('common.until')}
                    onChange={async (e) => {
                      await setFieldValue('endDate', e);

                      if (isFirstBeforeSecond(e, values.startDate)) {
                        setFieldError('endDate', t('validation.common.endDateAfterStart'));
                      } else {
                        setFieldError('endDate', undefined);
                      }

                      if (isFirstAfterSecond(values.startDate, e)) {
                        setFieldError('startDate', t('validation.common.startDateAfterEnd'));
                      } else {
                        setFieldError('startDate', undefined);
                      }

                      if (isFirstBeforeSecond(e, values.startTime, 'minutes')) {
                        setFieldError('endTime', t('validation.common.endTimeBeforeStart'));
                      } else {
                        setFieldError('endTime', undefined);
                      }
                    }}
                    error={errors.endDate as string}
                  />
                </GridColumn>
                <GridColumn width={3}>
                  <Input
                    name='endTime'
                    label={t('common.end')}
                    value={dayjs(values.endTime).format('HH:mm')}
                    onChange={async (event: ChangeEvent<HTMLInputElement>) => {
                      const time = dayjs(values.endDate).format('YYYY-MM-DD') + 'T' + event.target.value;
                      if (isFirstBeforeSecond(time, values.startTime, 'minutes')) {
                        setFieldError('endTime', t('validation.common.endTimeBeforeStart'));
                      } else {
                        setFieldError('endTime', undefined);
                      }
                      await setFieldValue('endTime', time);
                    }}
                    type='time'
                    error={errors.endTime as string}
                  />
                </GridColumn>
              </GridRow>
              <GridRow spacingTop='s'>
                <Input
                  name='comment'
                  value={values.comment}
                  label={t('common.comment')}
                  onChange={async (e) => await setFieldValue('comment', e.target.value)}
                  error={errors.comment}
                />
              </GridRow>
            </Grid>

            <ModalBottomButtons
              closeButton={{
                callback: onClose,
              }}
              submitButton={{
                disabled: isSubmitting || !dirty || isValidating,
                callback: () => {
                  handleSubmit();
                },
              }}
              isLoading={loading}
              errors={errors}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
