import {
  AddIcon,
  Button,
  Card,
  Grid,
  GridColumn,
  GridRow,
  ImportIcon,
  LazyLoader,
  Modal,
  Skeleton,
  Tab,
  Tooltip,
} from '@bp/ui-components';
import { Suspense, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../TeachingBlockVersionData.module.scss';
import { useParams } from 'react-router-dom';
import { useMemorizedCacheTag } from '../../../../../../hooks/useMemorizedCacheTag';
import {
  SortDirection,
  use_TeachingBlockAvailabilityExclusionsQuery,
  useConnectEntityWithVersionMutation,
} from '../../../../../../types/planung-graphql-client-defs';
import { ExclusionTimeframeForm } from '../../../../../../components/ExclusionTimeframe/ExclusionTimeframeForm';
import { TeachingBlockVersionTableType } from '../../../../../../components/TeachingBlockVersion/graphql/types';
import { TeachingBlockVersionRoomsTable } from '../../../../../../components/TeachingBlockVersion/TeachingBlockVersionDataTables/TeachingBlockVersionRoomsTable';
import { useAuthClaims } from '../../../../../../hooks/useAuthClaims';
import { Exclusions } from '../../../../../../components/TeachingBlockVersion/Exclusions/Exclusions';
import { showSuccessSaveToast, showUserErrorToast } from '../../../../../../utils/toast';
import { useTeachingBlockStore } from '../../../../../../components/TeachingBlockGrid/TeachingBlockContext';
import { observer } from 'mobx-react-lite';
import { useLoadBasicData } from '../../../../../../hooks/useLoadBasicData';

export const PlanTeachingBlockRooms = observer(() => {
  const { t } = useTranslation();
  const { teachingBlockVersionUuid } = useParams();
  const { pimAuthClaims } = useAuthClaims();
  const [, updateVersion] = useConnectEntityWithVersionMutation();

  const [tab, setTab] = useState<'exclusion' | 'condition'>('exclusion');
  const [exclusionModalOpen, setExclusionModalOpen] = useState(false);
  const [selectedExclusionUuid, setSelectedExclusionUuid] = useState<string | null>(null);

  const availabilityContext = useMemorizedCacheTag('TEACHING_BLOCK_AVAILABILITY_EXCLUSION');
  const context = useMemorizedCacheTag('TEACHINGBLOCK_VERSIONS');

  const store = useTeachingBlockStore();
  const versionRoomsUuids: string[] = Array.from(store.rooms.keys());

  const { roomsData } = useLoadBasicData({ pause: !teachingBlockVersionUuid });

  const roomsTableData: TeachingBlockVersionTableType[] = useMemo(() => {
    return (
      roomsData?.rooms
        .filter((r) => versionRoomsUuids.includes(r.uuid))
        .map((r) => {
          return {
            color: {},
            count: 0,
            name: r.name,
            shortName: r.roomNumber ?? '',
            uuid: r.uuid,
          };
        }) ?? []
    );
  }, [roomsData?.rooms, versionRoomsUuids]);

  const [selected, setSelected] = useState<TeachingBlockVersionTableType | null>(roomsTableData[0] ?? null);

  const [{ data: exclusionsData }] = use_TeachingBlockAvailabilityExclusionsQuery({
    variables: {
      where: {
        teachingBlockVersion: {
          uuid: teachingBlockVersionUuid,
        },
      },
      options: {
        sort: [{ from: SortDirection.Asc }],
      },
    },
    context: availabilityContext,
  });

  async function handleImport() {
    if (teachingBlockVersionUuid) {
      const result = await updateVersion(
        {
          organizationUuid: pimAuthClaims.getOrganization().uuid,
          versionUuid: teachingBlockVersionUuid,
          entityType: 'room',
        },
        context,
      );
      if (!result || result.error) {
        showUserErrorToast({ text: t('common.errorToastText'), error: result.error });
      } else {
        showSuccessSaveToast();
      }
    }
  }

  const selectedExclusionUuids = exclusionsData?.teachingBlockAvailabilityExclusions
    .filter((e) => e.holder.uuid === selected?.uuid)
    .map((e) => e.uuid);

  const onExclusionEdit = (uuid: string) => {
    setSelectedExclusionUuid(uuid);
    setExclusionModalOpen(true);
  };

  const onExclusionModalClose = () => {
    setSelectedExclusionUuid(null);
    setExclusionModalOpen(false);
  };

  return (
    <Grid>
      <GridRow spacingBottom='none'>
        <Button
          hierarchy='tertiary'
          icon={<ImportIcon />}
          className='mb-4'
          style={{ marginLeft: 'auto' }}
          onClick={handleImport}
          disabled={true}
        >
          {t('teachingBlockVersion.import', { import: t('rooms.title', { count: 2 }) })}
        </Button>
      </GridRow>
      <GridRow spacingTop='none'>
        <GridColumn width={roomsTableData.length === 0 ? 12 : 6}>
          <TeachingBlockVersionRoomsTable
            versionUuid={teachingBlockVersionUuid}
            versionRoomUuids={versionRoomsUuids}
            onRowSelection={(selected) => {
              setSelected(selected);
            }}
            onShowLessonClick={(uuid) => {
              console.log(uuid);
            }}
          />
        </GridColumn>
        {roomsTableData.length !== 0 && (
          <GridColumn width={6}>
            <Suspense fallback={<LazyLoader />}>
              <Card className={styles['teaching-block-version-data']} contentPadding='none'>
                <small>{t('teachingBlockVersion.specificInfo', { for: selected?.name })}</small>
                <div className={styles['mini-tab']}>
                  <Tab
                    className={styles.tabs}
                    onValueChange={(content) => setTab(content as 'exclusion' | 'condition')}
                    tabs={[
                      {
                        title: t('exclusion.title', { count: 2 }),
                        value: 'exclusion',
                        content: <Exclusions uuids={selectedExclusionUuids ?? []} onEdit={onExclusionEdit} />,
                      },
                      {
                        title: t('conditions.title', { count: 2 }),
                        value: 'condition',
                        content: (
                          <div className='px-6 py-4'>
                            <Skeleton />
                          </div>
                        ),
                      },
                    ]}
                  />
                  <div className={styles['additional-actions']}>
                    {tab === 'exclusion' && (
                      <Tooltip content={t('exclusion.add')}>
                        <Button hierarchy='ghost' icon={<AddIcon />} onClick={() => setExclusionModalOpen(true)} />
                      </Tooltip>
                    )}
                  </div>
                </div>
              </Card>
            </Suspense>
          </GridColumn>
        )}
      </GridRow>
      <Modal
        width='s'
        title={t('exclusion.add')}
        isOpen={exclusionModalOpen}
        onRequestClose={() => onExclusionModalClose()}
      >
        <ExclusionTimeframeForm
          type='Room'
          holderUuid={selected?.uuid ?? ''}
          teachingBlockVersionUuid={teachingBlockVersionUuid ?? ''}
          exclusionUuid={selectedExclusionUuid}
          onClose={() => onExclusionModalClose()}
        />
      </Modal>
    </Grid>
  );
});
