import styles from './GridCard.module.scss';
import React, { ReactNode, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { CellItem, RoomItem } from '../../../stores/PinboardStore';
import { useTimetableStore } from '../TimetableProvider';
import { CardIcons } from './CardIcons/CardIcons';
import { toJS } from 'mobx';
import { useTranslation } from 'react-i18next';
import { ContextMenu, ContextMenuItem } from '@bp/ui-components';
import { getWarningTypeTranslation } from '../../../utils/getWarningTypeTranslation';
import { useDebounceCallback } from 'usehooks-ts';
import { CardType } from '../../TimetableVersion/graphql/types';

type GridCardProps = {
  card: CardType;
  width: string;
  height: string;
  top: string;
  background: string;
  textColor: string;
  left: string;
  cell: CellItem;
};

export const GridCard = observer(({ card, top, width, background, height, textColor, left, cell }: GridCardProps) => {
  const store = useTimetableStore();
  const { t } = useTranslation();

  const cardConflicts = store.cardConflicts.get(card.uuid);
  let hasConflicts = false;
  let hasMaybe = false;

  cardConflicts?.forEach((conflict) => {
    if (!conflict.maybe) {
      hasConflicts = true;
    } else {
      hasMaybe = true;
    }
    if (cell.row.isFunctionClass && (conflict.type === 'class' || conflict.type === 'divisionGroup')) {
      hasConflicts = false;
      hasMaybe = false;
    }

    // ignore conflicts if all cards from the same subject container
    if ((card.isSubjectContainer && conflict.type === 'teacher') || conflict.type === 'room') {
      let allCardsFromSameSubjectContainer = true;
      conflict.placed.forEach((uuid) => {
        const _card = store.getCardByUuid(uuid);
        if (
          _card?.isSubjectContainer &&
          _card.subject?.uuid === card.subject?.uuid &&
          allCardsFromSameSubjectContainer
        ) {
          allCardsFromSameSubjectContainer = true;
        } else {
          allCardsFromSameSubjectContainer = false;
        }
      });
      if (allCardsFromSameSubjectContainer) {
        hasConflicts = false;
      }
    }
  });

  const groupedRooms = store.defaultCardRooms.get(card.uuid) ?? {
    lessonRoomItems: [],
    subjectRoomItems: [],
    classRoomItems: [],
    teacherRoomItems: [],
  };

  const _cardRooms = store.cardRooms.get(card.uuid);

  const menuClasses = classNames(styles.menu);

  const cardClasses = classNames(styles['grid-card'], {
    [styles.pinned]: store.pinnedCard === card.uuid,
    [styles.locked]: store.lockedCards.has(card.uuid),
    [styles.conflict]: hasConflicts,
    [styles['high-light']]: store.highlightMode.includes('rooms'),
    [styles['low-light']]: store.controlMode.isActive && !store.controlModeCardHighlights.has(card.uuid),
  });

  const contextMenu: ContextMenuItem[] = useMemo(() => {
    const currentRoomsUuids = _cardRooms ?? new Set<string>();

    const hasRooms = currentRoomsUuids.size > 0;

    const menu: ContextMenuItem[] = [];
    menu.push({
      type: 'component',
      node: <div className={styles['menu-name']}>{card?.subject?.name}</div>,
    });

    if (hasConflicts || !hasRooms || hasMaybe) {
      menu.push({
        type: 'ruler',
      });
    }

    if (!hasRooms) {
      menu.push({
        type: 'component',
        node: <div className={styles['menu-info']}>{t('pinboard.reason.noRoom')}</div>,
      });
    }

    const conflicts: ReactNode[] = [];
    const maybe: ReactNode[] = [];

    cardConflicts?.forEach((conflict, key) => {
      const placedWithoutCurrent = Array.from(conflict.placed).filter((uuid) => uuid !== card.uuid);

      if (conflict.type === 'class' && conflict.maybe) {
        maybe.push(
          <>
            {getWarningTypeTranslation('classMaybe')} ({store.getNameForUuid(key, 'class')})
          </>,
        );
      }
      if (conflict.type === 'teacher' && conflict.maybe) {
        maybe.push(
          <>
            {getWarningTypeTranslation('teacherMaybe')} ({store.getNameForUuid(key, 'teacher')})
          </>,
        );
      }
      if (conflict.type === 'room' && conflict.maybe) {
        maybe.push(
          <>
            {getWarningTypeTranslation('roomMaybe')} ({store.getNameForUuid(key, 'room')})
          </>,
        );
      }
      if (conflict.type === 'subject' && conflict.maybe) {
        maybe.push(
          <>
            {getWarningTypeTranslation('subjectMaybe')} ({store.getNameForUuid(key, 'subject')})
          </>,
        );
      }
      if (conflict.type === 'subject' && conflict.blocked) {
        conflicts.push(<>{getWarningTypeTranslation('subjectNotAvailable')}</>);
      }
      if (conflict.type === 'class' && conflict.blocked) {
        conflicts.push(
          <>
            {getWarningTypeTranslation('classNotAvailable')} ({store.getNameForUuid(key, 'class')})
          </>,
        );
      }
      if (conflict.type === 'class' && !conflict.blocked && !conflict.maybe && placedWithoutCurrent.length > 0) {
        conflicts.push(
          <>
            {getWarningTypeTranslation('overlappingClass')} ({store.getNameForUuid(key, 'class')})
          </>,
        );
      }
      if (conflict.type === 'teacher' && conflict.blocked) {
        conflicts.push(
          <>
            {getWarningTypeTranslation('teacherNotAvailable')} ({store.getNameForUuid(key, 'teacher')})
          </>,
        );
      }
      if (conflict.type === 'teacher' && !conflict.blocked && !conflict.maybe && placedWithoutCurrent.length > 0) {
        conflicts.push(
          <>
            {getWarningTypeTranslation('overlappingTeachers')} ({store.getNameForUuid(key, 'teacher')})
          </>,
        );
      }
      if (conflict.type === 'room' && conflict.blocked) {
        conflicts.push(
          <>
            {getWarningTypeTranslation('roomNotAvailable')} ({store.getNameForUuid(key, 'room')})
          </>,
        );
      }
      if (conflict.type === 'room' && !conflict.blocked && !conflict.maybe && placedWithoutCurrent.length > 0) {
        conflicts.push(
          <>
            {getWarningTypeTranslation('overlappingRooms')} ({store.getNameForUuid(key, 'room')})
          </>,
        );
      }
      if (
        conflict.type === 'divisionGroup' &&
        !conflict.blocked &&
        !conflict.maybe &&
        placedWithoutCurrent.length > 0
      ) {
        conflicts.push(
          <>
            {getWarningTypeTranslation('notMatchingDivision')} ({store.getNameForUuid(key, 'divisionGroup')})
          </>,
        );
      }
    });

    if (hasConflicts) {
      menu.push({
        type: 'component',
        node: (
          <div className={styles['menu-warning']}>
            {conflicts?.map((conflict, index) => {
              return (
                <div key={index} className={styles['menu-warning']}>
                  {conflict}
                </div>
              );
            })}
          </div>
        ),
      });
    }
    if (hasMaybe) {
      menu.push(
        {
          type: 'ruler',
        },
        {
          type: 'component',
          node: (
            <div className={styles['menu-info']}>
              {maybe?.map((conflict, index) => {
                return (
                  <div key={index} className={styles['menu-info']}>
                    {conflict}
                  </div>
                );
              })}
            </div>
          ),
        },
      );
    }

    menu.push(
      {
        type: 'ruler',
      },
      {
        label: store.pinnedCard === card?.uuid ? t('pinboard.actions.unpin') : t('pinboard.actions.pin'),
        onClick: (e) => {
          e.stopPropagation();
          if (card) {
            if (store.pinnedCard === card?.uuid) {
              store.pinnedCard = null;
              store.setPinnedCard(null);
            } else {
              store.setPinnedCard(card?.uuid ?? null);
            }
          }
        },
      },
      {
        disabled: store.context === 'rooms',
        label: store.lockedCards.has(card?.uuid ?? '') ? t('pinboard.actions.unlock') : t('pinboard.actions.lock'),
        onClick: () => {
          if (card) {
            if (store.lockedCards.has(card?.uuid ?? '')) {
              store.unlockCard(card?.uuid ?? '');
            } else {
              store.lockCard(card?.uuid ?? '');
            }
          }
        },
      },
      {
        type: 'ruler',
      },
      {
        label: t('rooms.title', { count: 2 }),
        subContent: getRoomsSubmenu(),
      },
      {
        label: t('pinboard.actions.singleHour'),
        tooltip: t('pinboard.actions.noSingleHourHint'),
        disabled: true,
      },
      {
        type: 'ruler',
      },
    );

    function getRoomsSubmenu(): ContextMenuItem[] {
      const roomsSubmenu: ContextMenuItem[] = [];

      if ((_cardRooms?.size ?? 0) > 0) {
        _cardRooms?.forEach((room) => {
          if (currentRoomsUuids.has(room.value)) {
            roomsSubmenu.push({
              value: currentRoomsUuids.has(room.value),
              className: room.inUse ? styles['menu-room-used'] : undefined,
              onValueChange: (value) => onRoomValueChange(value as boolean, room),
              label: room.label,
              type: 'switch',
            });
          }
        });
        roomsSubmenu.push({ type: 'ruler' });
      }

      if (groupedRooms.lessonRoomItems.length > 0) {
        groupedRooms.lessonRoomItems
          .filter((lr) => !currentRoomsUuids.has(lr.value))
          .forEach((room) => {
            roomsSubmenu.push({
              value: currentRoomsUuids.has(room.value),
              className: room.inUse ? styles['menu-room-used'] : undefined,
              onValueChange: (value) => onRoomValueChange(value as boolean, room),
              label: room.label,
              type: 'switch',
            });
          });
      }

      if (groupedRooms.subjectRoomItems.length > 0) {
        groupedRooms.subjectRoomItems
          .filter((lr) => !currentRoomsUuids.has(lr.value))
          .forEach((room) => {
            roomsSubmenu.push({
              value: currentRoomsUuids.has(room.value),
              className: room.inUse ? styles['menu-room-used'] : undefined,
              onValueChange: (value) => onRoomValueChange(value as boolean, room),
              label: room.label,
              type: 'switch',
            });
          });
      }

      if (groupedRooms.classRoomItems.length > 0) {
        groupedRooms.classRoomItems
          .filter((lr) => !currentRoomsUuids.has(lr.value))
          .forEach((room) => {
            roomsSubmenu.push({
              value: currentRoomsUuids.has(room.value),
              className: room.inUse ? styles['menu-room-used'] : undefined,
              onValueChange: (value) => onRoomValueChange(value as boolean, room),
              label: room.label,
              type: 'switch',
            });
          });
      }

      if (groupedRooms.teacherRoomItems.length > 0) {
        roomsSubmenu.push({ type: 'header', label: t('rooms.title.teacher') });
        groupedRooms.teacherRoomItems
          .filter((lr) => !currentRoomsUuids.has(lr.value))
          .forEach((room) => {
            roomsSubmenu.push({
              value: currentRoomsUuids.has(room.value),
              className: room.inUse ? styles['menu-room-used'] : undefined,
              onValueChange: (value) => onRoomValueChange(value as boolean, room),
              label: room.label,
              type: 'switch',
            });
          });
        roomsSubmenu.push({ type: 'ruler' });
      }

      function onRoomValueChange(value: boolean, room: RoomItem) {
        if (value) {
          store.setRoomsForCards([card], [room], true);
        } else {
          store.removeRoomsFromCards([card], [room]);
        }
      }
      return roomsSubmenu;
    }

    return menu;
  }, [
    _cardRooms,
    card,
    hasConflicts,
    hasMaybe,
    cardConflicts,
    store,
    t,
    groupedRooms.lessonRoomItems,
    groupedRooms.subjectRoomItems,
    groupedRooms.classRoomItems,
    groupedRooms.teacherRoomItems,
  ]);

  const onCardClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      e.preventDefault();
      store.setHoveredCard(card.uuid);

      if (store.pickedCard) {
        store.placeCard(cell);
      } else if (card) {
        store.pickCard(
          {
            cardUuid: card.uuid,
            fromStack: false,
            label: card.subject?.shortName ?? '',
            counter: 1,
            labelColor: card.badgeCardTextColor,
            duration: card.duration ?? 1,
            cardRows: card.badgeCardRows,
            position: { x: e.clientX, y: e.clientY },
          },
          {
            day: cell.day,
            hour: cell.hour,
            row: toJS(cell.row),
          },
          false,
        );
      }
    },
    [card, cell, store],
  );

  const debounceOnCardHover = useDebounceCallback(() => {
    store.setHoveredCard(card.uuid);
  }, 500);

  const onMouseEnter = useCallback(() => {
    if (!store.hoveredCard) {
      store.setHoveredCard(card.uuid);
    } else {
      debounceOnCardHover();
    }
  }, [card.uuid, debounceOnCardHover, store]);

  const onMouseLeave = useCallback(() => {
    debounceOnCardHover.cancel();
  }, [debounceOnCardHover]);

  const onOpenChange = useCallback(() => {
    debounceOnCardHover.cancel();
    store.setHoveredCard(card.uuid);
  }, [card.uuid, debounceOnCardHover, store]);

  return (
    <ContextMenu className={menuClasses} data={contextMenu} usePortal={false} onOpenChange={onOpenChange}>
      <div
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onCardClick}
        title={card.subject?.name}
        className={cardClasses}
        style={{
          height: hasConflicts ? `calc(${height} - 4px)` : height,
          width: hasConflicts ? `calc(${width} - 4px)` : width,
          top: hasConflicts ? `calc(${top} + 2px)` : top,
          background,
          color: textColor,
          left: hasConflicts ? `calc(${left} + 2px)` : left,
        }}
      >
        <div className={styles.title}>{card.subject?.shortName}</div>

        <div className={styles.info}>
          <CardIcons cardUuid={toJS(card.uuid)} />
        </div>
      </div>
    </ContextMenu>
  );
});
GridCard.displayName = 'GridCard';
