import {
  AddIcon,
  Button,
  ButtonGroup,
  Card,
  DuplicateIcon,
  ImportIcon,
  LazyLoader,
  Modal,
  Skeleton,
  Tab,
  Tooltip,
} from '@bp/ui-components';
import { Suspense, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../TimetableVersionData.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { TimetableVersionClassesTable } from '../../../../../../components/TimetableVersion/TimetableVersionDataTables/TimetableVersionClassesTable';
import { TimetableVersionTableType } from '../../../../../../components/TimetableVersion/graphql/types';
import { TimetableVersionClassesMatrix } from '../../../../../../components/TimetableVersion/TimetableVersionDataAvailabilityMatrix/TimetableVersionClassesMatrix';
import { useMemorizedCacheTag } from '../../../../../../hooks/useMemorizedCacheTag';
import {
  useClassConstraintsQuery,
  useUpdateTimetableVersionClassConditionsMutation,
} from '../../../../../../types/planung-graphql-client-defs';
import { constraintOrder, ConstraintsFormInitialValuesType } from '../../../../../../components/Constraints/types';
import { TimetableVersionClassesConstraints } from '../../../../../../components/TimetableVersion/TimetableVersionDataConditions/TimetableVersionClassesConditions';
import { removeNullAndRelatedFields } from '../../../../../../utils/removeNullAndRelatedFields';
import { availabilityStore } from '../../TimetableVersion';
import { createLessonFilterUrl } from '../../../../../../utils/create-lesson-filter-url';
import { ConstraintsForm } from '../../../../../../components/Constraints/Form/ConstaintsForm';
import { TimetableRelationshipProperties } from '@bp/planung-graphql-types';
import { TimetableVersionImportClasses } from '../../../../../../components/TimetableVersion/TimetableVersionImportClasses/TimetableVersionImportClasses';
import { useTimetableClasses } from '../../../../../../components/TimetableVersion/hooks/useTimetableClasses';
import { useTimetableStore } from '../../../../../../components/TimetableGrid/TimetableProvider';
import { useLoadBasicData } from '../../../../../../hooks/useLoadBasicData';
import { observer } from 'mobx-react-lite';
import { hexToColorOption } from '../../../../../../utils/colorUtils';

export const PlanTimetableClasses = observer(() => {
  const { t } = useTranslation();
  const { versionUuid } = useParams();
  const navigate = useNavigate();

  const store = useTimetableStore();

  const versionClassesUuids: string[] = Array.from(store.classes.keys());

  const timeAvailabilityStore = store.getTimeAvailabilityStore();

  const { classesData } = useLoadBasicData({ pause: !versionUuid });

  const delta = classesData?.classes.filter((c) => !versionClassesUuids.includes(c.uuid));
  const { removeClasses } = useTimetableClasses(versionUuid ?? '');

  const classTableData: TimetableVersionTableType[] = useMemo(() => {
    return (
      classesData?.classes
        .filter((c) => versionClassesUuids.includes(c.uuid))
        .map((c) => {
          const { html, label } = hexToColorOption(c.timetableConfig?.color ?? '');
          return {
            color: {
              color: html,
              label,
            },
            count: 0,
            name: c.name,
            shortName: c.shortName,
            uuid: c.uuid,
          };
        }) ?? []
    );
  }, [classesData?.classes, versionClassesUuids]);

  const [selected, setSelected] = useState<TimetableVersionTableType | null>(classTableData[0] ?? null);

  const onRemove = async (uuids: string[]) => {
    if (store.readonly) return;
    setSelected(classTableData[0]);
    await removeClasses(uuids);
    store.removeClasses(uuids);
  };

  const [tab, setTab] = useState<'availability' | 'condition' | 'division'>('availability');
  const [constraintModalOpen, setConstraintModalOpen] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState(false);

  const [, updateClassCondition] = useUpdateTimetableVersionClassConditionsMutation();

  const constraintsContext = useMemorizedCacheTag('CLASSES_CONSTRAINTS');

  const timeGridEntries = store.timeGridEntries ?? [];

  const [{ data: constraintsResult }] = useClassConstraintsQuery({
    variables: {
      versionUuid: versionUuid ?? '',
      uuid: selected?.uuid ?? '',
    },
    context: constraintsContext,
  });

  const constraintsData = useMemo(() => {
    return JSON.parse(
      constraintsResult?.timetableVersions[0]?.classesConnection?.edges[0]?.properties.constraints ??
        JSON.stringify({
          comment: '',
        }),
    ) as ConstraintsFormInitialValuesType;
  }, [constraintsResult]);

  async function handleMatrixChange(items: TimetableRelationshipProperties) {
    if (store.readonly) return;
    if (versionUuid && selected) {
      store.removeConflicts();

      availabilityStore?.setAvailability('class', selected.uuid, items, versionUuid);
      timeAvailabilityStore?.setResourceAvailability(
        [
          {
            uuid: selected.uuid,
            days: {
              mon: items.mon,
              tue: items.tue,
              wed: items.wed,
              thu: items.thu,
              fri: items.fri,
              sat: items.sat,
              sun: items.sun,
            },
          },
        ],
        'class',
      );
      store.setConflicts();
    }
  }

  async function handleConditionsChange(data: ConstraintsFormInitialValuesType) {
    if (versionUuid && selected) {
      // clean up data
      const removeNullAndRelatedField = removeNullAndRelatedFields(data, constraintOrder, 'Priority');
      const { error } = await updateClassCondition(
        {
          versionUuid: versionUuid,
          classUuid: selected?.uuid ?? '',
          data: JSON.stringify(removeNullAndRelatedField),
        },
        constraintsContext,
      );
      return !error;
    }
    return false;
  }

  return (
    <div>
      <div className={'tks__grid'}>
        <div className='tks__row row end-xs'>
          <Button
            hierarchy='tertiary'
            disabled={!delta?.length || store.readonly}
            icon={<ImportIcon />}
            onClick={() => setImportModalOpen(true)}
          >
            {t('timetableVersion.import', { import: t('classes.title', { count: 2 }) })}
          </Button>
        </div>
        <div className={'tks__row'}>
          <div className={`${versionClassesUuids.length === 0 ? 'col-xs-12' : 'col-xs-6'} ${styles['left']}`}>
            {versionUuid && (
              <TimetableVersionClassesTable
                onRemove={onRemove}
                onRowSelection={(selected) => setSelected(selected)}
                versionUuid={versionUuid}
                versionClassesUuids={versionClassesUuids}
                onShowLessonClick={(uuid) => {
                  if (versionUuid) {
                    navigate(
                      createLessonFilterUrl({
                        context: 'stundenplan',
                        filterValue: uuid,
                        versionUuid: versionUuid,
                        column: 'classes',
                      }),
                    );
                  }
                }}
              />
            )}
          </div>
          {versionClassesUuids.length !== 0 && timeGridEntries && (
            <div className={'tks__col col-xs-6'}>
              <Card className={styles['version-data']} contentPadding='none' fitContent>
                <small>{t('timetableVersion.specificInfo', { for: selected?.name })}</small>
                <div className={styles['mini-tab']}>
                  <Tab
                    value={tab}
                    key={selected?.uuid}
                    onValueChange={(content) => setTab(content as 'availability' | 'condition' | 'division')}
                    className={styles.tabs}
                    tabs={[
                      {
                        title: t('availability.title', { count: 2 }),
                        value: 'availability',
                        content: (
                          <Suspense fallback={<LazyLoader embedded forceHeight='30vh' />}>
                            <TimetableVersionClassesMatrix
                              versionUuid={versionUuid}
                              selected={selected}
                              onChange={handleMatrixChange}
                              timeGridEntries={timeGridEntries}
                            />
                          </Suspense>
                        ),
                      },
                      {
                        title: t('conditions.title', { count: 2 }),
                        value: 'condition',
                        content: (
                          <Suspense fallback={<LazyLoader embedded forceHeight='30vh' />}>
                            <TimetableVersionClassesConstraints
                              constraintsData={constraintsData}
                              onChange={handleConditionsChange}
                            />
                          </Suspense>
                        ),
                      },
                      {
                        title: t('divisions.title', { count: 2 }),
                        value: 'division',
                        content: (
                          <div className='px-6 py-4'>
                            <Skeleton />
                          </div>
                        ),
                      },
                    ]}
                  />
                  <div className={styles['additional-actions']}>
                    {tab === 'availability' && (
                      <Tooltip content={t('availability.duplicate')}>
                        <Button hierarchy='ghost' icon={<DuplicateIcon />} disabled />
                      </Tooltip>
                    )}
                    {tab === 'condition' && (
                      <ButtonGroup>
                        <Tooltip content={t('constraint.duplicate')}>
                          <Button hierarchy='ghost' icon={<DuplicateIcon />} disabled />
                        </Tooltip>
                        <Tooltip content={t('constraint.edit')}>
                          <Button
                            hierarchy='ghost'
                            icon={<AddIcon />}
                            onClick={() => setConstraintModalOpen(true)}
                            disabled={store.readonly}
                          />
                        </Tooltip>
                      </ButtonGroup>
                    )}
                  </div>
                </div>
              </Card>
            </div>
          )}
        </div>
      </div>
      <Modal
        isOpen={constraintModalOpen}
        onRequestClose={() => setConstraintModalOpen(false)}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        title={t('conditions.for', { for: selected?.name })}
      >
        <ConstraintsForm
          constraints={constraintsData}
          timeGridEntries={timeGridEntries}
          onSubmit={(values) => handleConditionsChange(values)}
          onClose={() => setConstraintModalOpen(false)}
        />
      </Modal>

      <Modal
        isOpen={importModalOpen}
        onRequestClose={() => setImportModalOpen(false)}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        title={t('timetableVersion.import', { import: t('classes.title', { count: 2 }) })}
        height={'l'}
      >
        {versionUuid && importModalOpen && (
          <TimetableVersionImportClasses
            versionUuid={versionUuid}
            onClose={() => {
              setImportModalOpen(false);
            }}
          />
        )}
      </Modal>
    </div>
  );
});
