import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormikHelpers } from 'formik/dist/types';
import { Field, FieldArray, Form, Formik, FormikProps, getIn } from 'formik';
import { AddIcon, Autocomplete, Button, DeleteIcon, Input } from '@bp/ui-components';
import { validationSchema } from './validation/validationSchema';
import { type cacheTags, useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { useCreateSelectOptions } from '../../../hooks/useCreateSelectOptions';
import { FormBlockHeader } from '../../Form/FormBlockHeader';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import {
  DivisionGroupsCreateFieldInput,
  DivisionGroupsUpdateFieldInput,
  useCreateDivisionsMutation,
  useDivisionsFormQuery,
  useUpdateDivisionsMutation,
} from '../../../types/planung-graphql-client-defs';
import { showSuccessCreateToast, showSuccessUpdateToast, showUserErrorToast } from '../../../utils/toast';

export type DivisionsFormGroupType = {
  name: string;
  shortName: string;
  uuid?: string;
};

export type DivisionsFormType = {
  uuid?: string;
  name: string;
  groups: DivisionsFormGroupType[];
};

type DivisionsFormProps = {
  divisionUuid: string | null;
  closeForm: () => void;
  cacheTag: keyof typeof cacheTags;
  classUuid?: string;
  className?: string;
  showDefaultDivisionsSelect?: boolean;
};

export const DivisionsForm = ({
  divisionUuid,
  closeForm,
  cacheTag,
  classUuid,
  className,
  showDefaultDivisionsSelect,
}: DivisionsFormProps) => {
  const { pimAuthClaims } = useAuthClaims();
  const { t } = useTranslation();

  const context = useMemorizedCacheTag(cacheTag);

  const [, createDivision] = useCreateDivisionsMutation();
  const [, updateDivision] = useUpdateDivisionsMutation();

  const [{ data, fetching, error }] = useDivisionsFormQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
      divisionUuid: divisionUuid,
    },
    context,
  });

  const handleSubmit = async (values: DivisionsFormType, formHelpers: FormikHelpers<DivisionsFormType>) => {
    let mutationResult;

    const currentGroups =
      data?.currentDivision
        .find((division) => division.uuid === divisionUuid)
        ?.groupsConnection.edges.map((e) => e.node) ?? [];

    const divisionGroupsToCreate: Array<DivisionGroupsCreateFieldInput> = values.groups
      .filter((g) => g.uuid === undefined)
      .map((group) => {
        return {
          node: {
            name: group.name,
            shortName: group.shortName,
            organization: {
              connect: {
                where: {
                  node: {
                    uuid: pimAuthClaims.getOrganizationUuid(),
                  },
                },
              },
            },
          },
          edge: {
            order: values.groups.findLastIndex((g) => g.name === group.name),
          },
        };
      });

    const divisionGroupsToDelete: DivisionGroupsUpdateFieldInput[] =
      currentGroups
        .filter((g) => !values.groups.some((cg) => cg.uuid === g.uuid))
        .map((g) => ({
          where: {
            node: {
              uuid: g.uuid,
            },
          },
          delete: [{ where: { node: { uuid: g.uuid } } }],
        })) ?? [];

    const divisionGroupsToUpdate: DivisionGroupsUpdateFieldInput[] = values.groups
      .filter((g) => currentGroups.some((cg) => cg.uuid === g.uuid) && g.uuid !== undefined)
      .map((group, index) => ({
        where: {
          node: {
            uuid: group.uuid,
          },
        },
        update: {
          node: {
            name: group.name,
            shortName: group.shortName,
          },
          edge: { order: index },
        },
      }));

    if (divisionUuid) {
      mutationResult = await updateDivision(
        {
          divisionName: values.name,
          divisionUuid: divisionUuid,
          groups: [
            ...divisionGroupsToUpdate,
            ...divisionGroupsToCreate.flatMap((g) => ({ create: [g] })),
            ...divisionGroupsToDelete,
          ],
        },
        context,
      );
      showSuccessCreateToast([values.name]);
    } else {
      mutationResult = await createDivision(
        {
          divisionName: values.name,
          organizationUuid: pimAuthClaims.getOrganizationUuid(),
          classUuid: classUuid ?? null,
          groups: divisionGroupsToCreate,
        },
        context,
      );
      if (!mutationResult.error) {
        showSuccessUpdateToast([values.name]);
      }
    }
    if (mutationResult.error) {
      showUserErrorToast({ error: mutationResult.error });
    }
    formHelpers.resetForm();
    closeForm();
  };

  let initialValues: DivisionsFormType = {
    uuid: '',
    name: '',
    groups: [
      {
        uuid: undefined,
        name: '',
        shortName: '',
      },
    ],
  };
  if (data && data.currentDivision && divisionUuid) {
    initialValues = {
      uuid: data.currentDivision[0].uuid,
      name: data.currentDivision[0].name,
      groups: data.currentDivision[0].groupsConnection.edges.map((e) => e.node),
    };
  }

  // default divisions
  const defaultDivisionsSuggestions = useCreateSelectOptions(data?.defaultDivisions, 'uuid', 'name');

  const validateShortName = (value: string, shortNames: string[]) => {
    let errorMessage;

    // ignore for default division
    if (showDefaultDivisionsSelect) {
      const has = data?.divisions
        .filter((division) => division.uuid !== divisionUuid && division?.class?.uuid === classUuid)
        .some((division) => {
          return division.groupsConnection.edges.some((group) => {
            return group.node.shortName === value;
          });
        });
      if (has) {
        errorMessage = `${value} ${t('already used')}`;
      }
    }
    if (shortNames.includes(value)) {
      errorMessage = `${value} ${t('already used')}`;
    }

    return errorMessage;
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {({
        values,
        handleChange,
        resetForm,
        isSubmitting,
        isValidating,
        dirty,
        setFieldValue,
        setFieldTouched,
        errors,
        touched,
      }: FormikProps<DivisionsFormType>) => (
        <Form>
          <div className={'form-block'}>
            <div className={'form-group'}>
              <div className={'form-col'}>
                <div className={'form-row'}>
                  {showDefaultDivisionsSelect && (
                    <Autocomplete
                      openOnActive
                      suggestions={defaultDivisionsSuggestions.map((suggestion) => {
                        return {
                          label: suggestion.label ?? '',
                          value: suggestion.value,
                        };
                      })}
                      name={'name'}
                      className={'half'}
                      inputProps={{
                        label: t('divisions.type'),
                      }}
                      onChange={async (value) => {
                        await setFieldValue('name', value.label);
                        if (value.value) {
                          const selectedDivision = data?.defaultDivisions.find((d) => d.uuid === value.value);
                          if (selectedDivision) {
                            await setFieldValue(
                              'groups',
                              selectedDivision.groupsConnection.edges.map((group) => {
                                return group.node;
                              }),
                            );
                            await setFieldTouched('groups', true);
                          }
                        }
                      }}
                      defaultValue={{ label: values.name }}
                    />
                  )}
                  {!showDefaultDivisionsSelect && (
                    <Input
                      name={'name'}
                      label={t('divisions.type') as string}
                      onChange={handleChange}
                      value={values.name}
                      className={`${className ? 'half' : 'full'}`}
                    />
                  )}
                  {className && (
                    <Input
                      readonly={true}
                      name={'className'}
                      label={t('divisions.ofClass') as string}
                      onChange={handleChange}
                      value={className}
                      className={'half'}
                    />
                  )}
                </div>
              </div>
              <div className={'form-col'}></div>
            </div>
          </div>
          <FieldArray
            name={'groups'}
            render={(arrayHelpers) => {
              const GroupsArrayErrors = () =>
                typeof errors.groups === 'string' ? <div className={'error-message'}>{errors.groups}</div> : <></>;

              return (
                <div className={'form-block'}>
                  <FormBlockHeader title={t('common.group_other')} />

                  {values.groups.map((group, index) => {
                    return (
                      <div key={group.uuid + '_' + index} className={'form-group'}>
                        <div className={'form-col'}>
                          <div className={'form-row'}>
                            <Input
                              label={t('divisions.groupNameCounted', { index: index + 1 }) as string}
                              name={`groups.${index}.name`}
                              onChange={handleChange}
                              value={values.groups[index].name}
                              className={'three-quarters'}
                              error={
                                getIn(touched, `groups.${index}`) && getIn(errors, `groups.${index}.name`)
                                  ? getIn(errors, `groups.${index}.name`)
                                  : undefined
                              }
                            />
                            <Field
                              className={'quarter'}
                              validate={(value: string) =>
                                validateShortName(
                                  value,
                                  values.groups.filter((v, i) => i !== index).map((group) => group.shortName),
                                )
                              }
                              name={`groups.${index}.shortName`}
                              label={t('divisions.groupShortNameCounted', { index: index + 1 }) as string}
                              error={
                                getIn(touched, `groups.${index}`) && getIn(errors, `groups.${index}.shortName`)
                                  ? getIn(errors, `groups.${index}.shortName`)
                                  : undefined
                              }
                              value={values.groups[index].shortName}
                              onChange={handleChange}
                              as={Input}
                            />
                          </div>
                        </div>
                        <div className={'form-col'}>
                          <div className={'form-row'}>
                            <div className={'form-row-actions full'}>
                              <Button
                                hierarchy={'tertiary'}
                                icon={<DeleteIcon />}
                                onClick={() => arrayHelpers.remove(index)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <Button
                    hierarchy={'tertiary'}
                    icon={<AddIcon />}
                    onClick={() =>
                      arrayHelpers.push({
                        name: '',
                        shortName: '',
                      })
                    }
                  >
                    {t('classGroups.group')}
                  </Button>
                  <GroupsArrayErrors />
                </div>
              );
            }}
          ></FieldArray>
          <div className={'modal-bottom'}>
            <Button
              hierarchy='tertiary'
              type='button'
              onClick={() => {
                resetForm();
                closeForm();
              }}
            >
              {t('common.cancelChanges')}
            </Button>
            <Button type='submit' disabled={isSubmitting || !dirty || isValidating || fetching || !!error}>
              {t('common.save')}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
