import { Button, ButtonGroup, DeleteIcon, EditIcon, Modal, Table, TableColumns } from '@bp/ui-components';
import { Row } from '@tanstack/react-table';
import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useColumnsSort } from '../../hooks/useColumnsSort';
import { useHiddenColumns } from '../../hooks/useHiddenColumns';
import { useMemorizedCacheTag } from '../../hooks/useMemorizedCacheTag';
import { useDeleteSchoolYearsMutation, useSchoolYearsQuery } from '../../types/planung-graphql-client-defs';
import { SchoolYearForm } from './Form/SchoolYearForm';
import { SchoolYearType } from './graphql/types';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { showSuccessDeleteToast, showUserErrorToast } from '../../utils/toast';
import { useConfirm } from '../../hooks/useConfirm';
import { observer } from 'mobx-react-lite';

export const SchoolYearTable = observer(() => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const { confirm, ConfirmationDialog } = useConfirm();

  const { columnVisibility, saveColumnVisibility } = useHiddenColumns('schoolyear-list');
  const { sorting, saveSorting } = useColumnsSort('schoolyear-list');

  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [schoolYear, setSchoolYear] = useState<null | SchoolYearType>(null);
  const [, deleteSchoolYear] = useDeleteSchoolYearsMutation();

  const context = useMemorizedCacheTag('SCHOOL_YEAR');
  const [result] = useSchoolYearsQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
    },
    context: context,
  });
  const { data, fetching, error } = result;

  const createColumns = (): TableColumns<SchoolYearType>[] => {
    return [
      {
        header: t('schoolYear.name'),
        accessorKey: 'name',
        id: 'name',
      },
      {
        header: t('schoolYear.shortName') as string,
        accessorKey: 'shortName',
        id: 'shortName',
      },
      {
        header: t('schoolYear.start') as string,
        id: 'start',
        type: 'date',
        cell: ({ row }) => {
          return dayjs(row.original.start).format('DD.MM.YYYY');
        },
      },
      {
        header: t('schoolYear.end') as string,
        id: 'end',
        type: 'date',
        cell: ({ row }) => {
          return dayjs(row.original.end).format('DD.MM.YYYY');
        },
      },
      {
        header: t('common.status') as string,
        id: 'status',
        cell: ({ row }) => {
          const isBefore = dayjs().isBefore(dayjs(row.original.start), 'day');
          const isAfter = dayjs().isAfter(dayjs(row.original.end), 'day');
          return !isBefore && !isAfter
            ? t('schoolYear.status.current')
            : isAfter
              ? t('schoolYear.status.archive')
              : t('schoolYear.status.planning');
        },
      },
    ];
  };

  const tableColumns = useMemo(createColumns, []);

  const memoizedData = useMemo((): SchoolYearType[] => {
    return data && data ? data.schoolYears : [];
  }, [data]);

  async function handleDelete(row: Row<SchoolYearType>) {
    if (!row.original.uuid) {
      return;
    }

    await confirm({
      message: t('schoolYear.deleteConfirm', {
        name: row.original.name,
      }),
      onConfirm: async () => {
        const response = await deleteSchoolYear(
          {
            uuid: row.original.uuid,
          },
          context,
        );

        if (response.error) {
          showUserErrorToast({ error: response.error });
        } else {
          showSuccessDeleteToast();
        }
      },
    });
  }

  const handleEdit = (row: Row<SchoolYearType>) => {
    setSchoolYear(row.original ?? null);
    setModalOpen(true);
  };
  const onModalClose = () => {
    setSchoolYear(null);
    setModalOpen(false);
  };

  const onAddClick = () => {
    setSchoolYear(null);
    setModalOpen(true);
  };

  return (
    <>
      {error && <div>ERROR!</div>}
      {!error && !fetching && (
        <Table
          showBorderRadius
          showShadow
          canScroll
          minHeight={600}
          isOnWhite={false}
          columns={tableColumns}
          data={memoizedData}
          printerSettings={{
            headline: pimAuthClaims.getProfile()?.organization.name,
            subline: `${t('schoolYear.title', { count: 2 })} - ${t('common.schoolYear')} ${schoolYear?.shortName}`,
            filename: `${t('schoolYear.title', { count: 2 })}_${schoolYear?.shortName}`,
          }}
          showActionBar
          actionBarSettings={{ showAddButton: true, addButtonText: t('common.add') }}
          onAddClick={() => onAddClick()}
          onColumnVisibilityChange={saveColumnVisibility}
          columnVisibility={columnVisibility}
          sorting={sorting}
          onSortingChange={saveSorting}
          lastColWidth='80px'
          lastCol={(schoolYear) => {
            return (
              <ButtonGroup>
                <Button
                  hierarchy='secondary'
                  type='button'
                  icon={<EditIcon className='small' />}
                  onClick={() => {
                    handleEdit(schoolYear);
                  }}
                />

                <Button
                  hierarchy='secondary'
                  type='button'
                  icon={<DeleteIcon className='small' />}
                  onClick={() => handleDelete(schoolYear)}
                />
              </ButtonGroup>
            );
          }}
        />
      )}
      <Modal
        isOpen={modalOpen}
        onRequestClose={onModalClose}
        shouldCloseOnEsc={false}
        shouldCloseOnOverlayClick={false}
        title={schoolYear ? (t('schoolYear.edit') as string) : (t('schoolYear.add') as string)}
      >
        <SchoolYearForm
          schoolYear={schoolYear}
          others={memoizedData.filter((year) => year.uuid !== schoolYear?.uuid)}
          handleClose={onModalClose}
        ></SchoolYearForm>
      </Modal>
      <ConfirmationDialog />
    </>
  );
});
