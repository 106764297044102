import { memo, useMemo } from 'react';
import { ArrowRightIcon, Button, Row, Table, TableColumns } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { useHiddenColumns } from '../../../../hooks/useHiddenColumns';
import { useColumnsSort } from '../../../../hooks/useColumnsSort';
import { useUserConfigContext } from '../../../../hooks/useUserConfigContext';
import { useNavigate } from 'react-router-dom';
import { ColorType } from '../../../../types/global';
import { useAuthClaims } from '../../../../hooks/useAuthClaims';
import { useDeputateLessons } from '../../hooks/useDeputateLessons';
import { hexToColorOption } from '../../../../utils/colorUtils';
import { useLoadBasicData } from '../../../../hooks/useLoadBasicData';

type LessonClassesTableType = {
  uuid: string;
  class: string;
  planned: number | null;
  caretakers: string;
  color: ColorType;
};

type ClassesTableProps = {
  onRowClickRoute?: string;
  isEvaluation?: boolean;
};

export const ClassesTable = memo(({ onRowClickRoute, isEvaluation = false }: ClassesTableProps) => {
  const { pimAuthClaims } = useAuthClaims();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const schoolYear = useUserConfigContext().selectedSchoolYear;

  const { columnVisibility, saveColumnVisibility } = useHiddenColumns('lesson-classes-table', {
    color: !isEvaluation,
    caretakers: !isEvaluation,
    status: !isEvaluation,
    sst: !isEvaluation,
  });
  const { sorting, saveSorting } = useColumnsSort('lesson-classes-table');

  const { classesData, teacherData } = useLoadBasicData({ pause: false });

  const classes = useMemo(() => {
    return (
      classesData?.classes
        .filter((c) => c.schoolYear.uuid === schoolYear?.uuid)
        .sort((a, b) => {
          return isEvaluation ? (a.grade ?? 0) - (b.grade ?? 0) || a.name.localeCompare(b.name) : 0;
        }) ?? []
    );
  }, [classesData?.classes, isEvaluation, schoolYear?.uuid]);

  const { lessonsData } = useDeputateLessons({
    organizationUuid: pimAuthClaims.getOrganizationUuid(),
    schoolYearUuid: schoolYear?.uuid ?? '',
  });

  const tableColumns = useMemo((): TableColumns<LessonClassesTableType>[] => {
    return [
      {
        header: t('classes.title', { count: 1 }),
        id: 'class',
        accessorKey: 'class',
        meta: {
          filterName: t('classes.title', { count: 1 }),
        },
        size: 300,
      },
      {
        header: t('deputate.planned'),
        id: 'planned',
        accessorKey: 'planned',
        meta: {
          filterName: t('deputate.planned'),
        },
        accessorFn: (row) => {
          return row.planned?.toFixed(2);
        },
        alignment: 'right',
        size: 50,
      },
      {
        header: t('deputate.caretaker'),
        id: 'caretakers',
        accessorKey: 'caretakers',
        meta: {
          filterName: t('deputate.caretaker'),
        },
        size: 200,
      },
      {
        header: t('common.color'),
        accessorKey: 'color',
        id: 'color',
        type: 'color',
      },
    ];
  }, []);

  const memoizedData = useMemo((): LessonClassesTableType[] => {
    return classes
      ? classes.map((c) => {
          const { html, label } = hexToColorOption(c.timetableConfig?.color ?? '');
          return {
            class: c.name,
            uuid: c.uuid,
            caretakers:
              c.tutors
                .map((tutor) => {
                  const tutorEdge = teacherData?.people.find((t) => t.uuid === tutor.uuid);
                  return tutorEdge?.fullName ?? '';
                })
                .toString() ?? '',
            planned:
              lessonsData
                .filter((ld) => ld.classes.some((lc) => (lc.uuid = c.uuid)))
                .map((ld) => ld.lessonInfo?.classInfos.get(c.uuid)?.deputat ?? 0)
                .reduce((prev, cur) => prev + cur, 0) ?? 0,
            color: { color: html, colorLabel: label },
          };
        })
      : [];
  }, [classes, lessonsData, teacherData?.people]);

  const handleRowClick = (row: Row<LessonClassesTableType>) => {
    onRowClickRoute ? navigate(`${onRowClickRoute}/${row.original.uuid}`) : navigate(`${row.original.uuid}`);
  };

  return (
    <Table<LessonClassesTableType>
      showPagination={true}
      pageSize={50}
      showBorderRadius
      showShadow
      canScroll
      showSort
      minHeight={1000}
      isOnWhite={false}
      showVisibility
      columnVisibility={columnVisibility}
      onColumnVisibilityChange={saveColumnVisibility}
      sorting={sorting}
      onSortingChange={saveSorting}
      columns={tableColumns}
      data={memoizedData}
      printerSettings={{
        headline: pimAuthClaims.getProfile()?.organization.name,
        subline: `${t('classes.title', { count: 2 })} (${t('lesson.title', { count: 2 })}) - ${t(
          'common.schoolYear',
        )} ${schoolYear?.shortName}`,
        filename: `${t('classes.title', { count: 2 })}_${t('lesson.title', { count: 2 })}_${schoolYear?.shortName}`,
      }}
      showActionBar
      actionBarSettings={{
        showExpertFilter: !isEvaluation,
        showPrintButton: true,
      }}
      showRowHover
      onRowClick={(event, row) => {
        handleRowClick(row);
      }}
      lastCol={(row) => {
        return (
          <Button hierarchy='ghost' onClick={() => handleRowClick(row)} icon={<ArrowRightIcon className='small' />} />
        );
      }}
    />
  );
});

ClassesTable.displayName = 'ClassesTable';
