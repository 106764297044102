import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonGroup,
  DeleteIcon,
  EditIcon,
  Modal,
  Table,
  TableColumns,
  useDefaultSorting,
} from '@bp/ui-components';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { useConfirm } from '../../../hooks/useConfirm';
import {
  useAdditionalTeachingLoadsListQuery,
  useDeleteAdditionalTeachingLoadsMutation,
} from '../../../types/planung-graphql-client-defs';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { AdditionalTeachingLoadForm } from '../Forms/AdditionalTeachingLoadForm';
import { showSuccessDeleteToast, showUserErrorToast } from '../../../utils/toast';
import { formatNumber } from '../../../utils/helper';

export type AdditionalTeachingLoadTableType = {
  uuid: string;
  teacherName: string;
  teacherUuid: string;
  subjectName: string;
  subjectUuid?: string;
  className: string;
  classUuid?: string;
  hours: string;
  hoursRaw: string;
  comment: string;
};
export const AdditionalTeachingLoadTable: FC = () => {
  const { pimAuthClaims } = useAuthClaims();
  const { t } = useTranslation();
  const schoolYear = useUserConfigContext().selectedSchoolYear;

  const [currentDeputat, setCurrentDeputat] = useState<AdditionalTeachingLoadTableType | null>(null);
  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const { confirm, ConfirmationDialog } = useConfirm();

  const context = useMemorizedCacheTag('ADDITIONAL_TEACHINGLOAD');
  const [{ data }] = useAdditionalTeachingLoadsListQuery({
    variables: { organizationUuid: pimAuthClaims.getOrganizationUuid(), schoolyear: schoolYear?.uuid ?? '' },
    context,
  });

  const [, deleteAdditionalTeachingLoad] = useDeleteAdditionalTeachingLoadsMutation();

  const tableData: AdditionalTeachingLoadTableType[] = useMemo(
    () =>
      data?.additionalTeachingLoads.map((teachingLoad) => ({
        uuid: teachingLoad.uuid,
        teacherName: teachingLoad.teacher.listName ?? '',
        teacherUuid: teachingLoad.teacher.uuid,
        subjectName: teachingLoad.subject?.name ?? '',
        subjectUuid: teachingLoad.subject?.uuid ?? '',
        className: teachingLoad.class?.name ?? '',
        classUuid: teachingLoad.class?.uuid ?? '',
        hours: teachingLoad.hours.toString() ?? '0',
        hoursRaw: teachingLoad.hoursRaw ?? teachingLoad.hours.toString(),
        comment: teachingLoad.comment ?? '',
      })) ?? [],
    [data],
  );
  const handleAdd = () => {
    setEditModalOpen(true);
  };

  const handleEdit = (currentATL: AdditionalTeachingLoadTableType) => {
    setCurrentDeputat(currentATL);
    setEditModalOpen(true);
  };

  const handleClose = () => {
    setCurrentDeputat(null);
    setEditModalOpen(false);
  };

  const handleDelete = async (additionalTeachingLoad: AdditionalTeachingLoadTableType) => {
    const confirmed = await confirm({ message: t('additionalTeachingLoad.deleteConfirm') });
    if (confirmed) {
      const response = await deleteAdditionalTeachingLoad({ uuid: additionalTeachingLoad.uuid }, context);
      if (response.error) {
        showUserErrorToast({ error: response.error });
      } else {
        showSuccessDeleteToast();
      }
    }
  };

  const tableColumns = useMemo((): TableColumns<AdditionalTeachingLoadTableType>[] => {
    return [
      {
        header: t('lesson.table.teacher'),
        id: 'teacherName',
        accessorKey: 'teacherName',
        size: 150,
      },

      {
        header: t('subject.name'),
        id: 'subjectName',
        accessorKey: 'subjectName',
      },

      {
        header: t('classes.title', { count: 1 }),
        id: 'className',
        accessorKey: 'className',
        size: 70,
      },

      {
        header: t('common.hours'),
        id: 'hours',
        accessorFn: (cell) => formatNumber(parseFloat(cell.hours)),
        size: 40,
      },

      {
        header: t('common.comment'),
        id: 'comments',
        accessorKey: 'comment',
        size: 400,
      },
    ];
  }, [t]);

  const { sorting, onSortingChange } = useDefaultSorting();
  return (
    <>
      <Table<AdditionalTeachingLoadTableType>
        sorting={sorting}
        onSortingChange={onSortingChange}
        showBorderRadius
        showShadow
        canScroll
        minHeight={1000}
        isOnWhite={false}
        printerSettings={{
          headline: pimAuthClaims.getProfile()?.organization.name,
          subline: `${t('deputate.manual')} - ${t('common.schoolYear')} ${schoolYear?.shortName}`,
          filename: `${t('deputate.manual')}_${schoolYear?.shortName}`,
        }}
        showActionBar
        actionBarSettings={{
          showExpertFilter: true,
          showPrintButton: true,
          showAddButton: true,
          addButtonText: t('common.add'),
        }}
        showSort={true}
        onAddClick={handleAdd}
        columns={tableColumns}
        data={tableData}
        lastColWidth='80px'
        lastCol={(row) => {
          return (
            <ButtonGroup>
              <Button
                hierarchy='secondary'
                type='button'
                onClick={() => handleEdit(row.original)}
                icon={<EditIcon className='small' />}
              />
              <Button
                hierarchy='secondary'
                type='button'
                onClick={() => handleDelete(row.original)}
                icon={<DeleteIcon className='small' />}
              />
            </ButtonGroup>
          );
        }}
      />
      <Modal
        isOpen={editModalOpen}
        onRequestClose={handleClose}
        title={currentDeputat ? t('deputate.edit') : t('deputate.add')}
      >
        {editModalOpen && <AdditionalTeachingLoadForm currentDeputat={currentDeputat} closeForm={handleClose} />}
      </Modal>
      <ConfirmationDialog />
    </>
  );
};
