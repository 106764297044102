import { FieldArray, Form, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { AddIcon, Button, DeleteIcon, Input, Select, SelectOptionType, showToast } from '@bp/ui-components';
import { SingleValue } from 'react-select';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { FormBlockHeader } from '../../Form/FormBlockHeader';
import { useCreateSelectOptions } from '../../../hooks/useCreateSelectOptions';
import { partition } from '../../../utils/arrayFunc';
import { isConstraintError } from '../../../utils/formHelpers';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { observer } from 'mobx-react-lite';
import {
  useCreateTimetableMutation,
  useTimetableQuery,
  useUpdateTimetableMutation,
} from '../../../types/planung-graphql-client-defs';
import { timetableSchema } from './validation/schema';

type TimetableFormProps = {
  timetableUuid: string | null;
  selectAllClasses: boolean;
  onClose: () => void;
};

type TimegridConfigsType = {
  timegridUuid: string | null;
  classes?: { uuid: string; name: string }[];
};

type TimetableFormType = {
  uuid: string;
  draft: boolean;
  name: string;
  comment: string;
  schoolYearUuid: string;
  timegridConfigs: TimegridConfigsType[];
};

export const TimetableForm = observer(({ timetableUuid, selectAllClasses, onClose }: TimetableFormProps) => {
  const { pimAuthClaims } = useAuthClaims();
  const { t } = useTranslation();
  const context = useMemorizedCacheTag('TIMETABLE');
  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;
  const [, create] = useCreateTimetableMutation();
  const [, update] = useUpdateTimetableMutation();

  const [{ data }, reexecuteQuery] = useTimetableQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
      schoolyearUuid: currentSchoolYear?.uuid ?? '', // TODO: Guard against missing SchoolYear!
    },
    context,
  });

  const [current, other] = partition(data?.timetables ?? [], (elem) => elem.uuid === timetableUuid);
  const currentDraft: TimetableFormType =
    current.length > 0
      ? {
          uuid: current[0].uuid,
          name: current[0].name,
          draft: current[0].draft,
          comment: current[0].comment ?? '',
          schoolYearUuid: current[0].schoolYear.uuid,
          timegridConfigs: current[0].timegridConfigs.map((tc) => ({ timegridUuid: tc.timegrid?.uuid ?? '' })),
        }
      : {
          uuid: '',
          draft: true,
          name: '',
          comment: '',
          schoolYearUuid: currentSchoolYear?.uuid ?? '',
          timegridConfigs: [{ timegridUuid: data?.timeGrids[0].uuid ?? '' }],
        };

  const schoolYearsOptions: SelectOptionType[] = useCreateSelectOptions(data?.schoolYears, 'uuid', 'name');
  const timeGridOptions: SelectOptionType[] = useCreateSelectOptions(data?.timeGrids, 'uuid', 'name');

  const defaultClassesSelect = useCreateSelectOptions(data?.classes, 'uuid', 'name');

  const classesOptions: SelectOptionType[] = selectAllClasses
    ? defaultClassesSelect
    : [{ value: null, label: `(${t('common.all')})` }];

  const handleSubmit = async (values: TimetableFormType, formHelpers: FormikHelpers<TimetableFormType>) => {
    let mutationResult;
    if (timetableUuid) {
      mutationResult = await update(
        {
          uuids: [timetableUuid],
          update: {
            comment: values.comment,
            name: values.name,
            draft: true,

            schoolYear: {
              disconnect: {},
              connect: {
                where: {
                  node: {
                    uuid: values.schoolYearUuid,
                  },
                },
              },
            },
            timegridConfigs: [
              { delete: [{}] },
              {
                create: values?.timegridConfigs.map((timegridConfig, index) => {
                  return {
                    node: {
                      timegrid: { connect: { where: { node: { uuid: timegridConfig.timegridUuid } } } },
                    },
                    edge: { order: index },
                  };
                }),
              },
            ],
          },
        },
        context,
      );
    } else {
      const date = new Date();

      mutationResult = await create(
        {
          input: [
            {
              comment: values.comment,
              name: values.name,
              updatedAt: date.toISOString(),
              draft: true,
              schoolYear: {
                connect: {
                  where: {
                    node: {
                      uuid: values.schoolYearUuid,
                    },
                  },
                },
              },
              organization: {
                connect: { where: { node: { uuid: pimAuthClaims.getOrganizationUuid() } } },
              },
              timegridConfigs: {
                create:
                  values?.timegridConfigs.map((timegridConfig, index) => {
                    return {
                      node: {
                        timegrid: { connect: { where: { node: { uuid: timegridConfig.timegridUuid } } } },
                      },
                      edge: { order: index },
                    };
                  }) ?? [],
              },
            },
          ],
        },
        context,
      );
    }
    if (!mutationResult || mutationResult.error) {
      if (isConstraintError(mutationResult.error)) {
        reexecuteQuery({ requestPolicy: 'network-only' });
        await formHelpers.submitForm(); // trigger submit for revalidation
      }
    } else {
      showToast('Success', { type: 'success' });
      formHelpers.resetForm();
      onClose();
    }
  };

  return (
    <>
      <Formik
        initialValues={currentDraft}
        onSubmit={handleSubmit}
        validationSchema={() => {
          return timetableSchema(other.map((tt) => tt.name));
        }}
      >
        {({
          values,
          handleChange,
          resetForm,
          isSubmitting,
          isValidating,
          dirty,
          setFieldValue,
          setFieldTouched,
          errors,
          touched,
        }) => {
          return (
            <Form>
              <div className='tks__grid mt-6 mb-8'>
                <div className='tks__row row-xs-10'>
                  <div className='tks__col col-xs-6'>
                    <Input
                      label={t('common.name') as string}
                      name={'name'}
                      onChange={handleChange}
                      value={values.name}
                      {...(errors.name &&
                        touched.name && {
                          error: errors.name,
                        })}
                    />
                  </div>
                  <div className='tks__col col-xs-6'>
                    <Select
                      label={t('common.schoolYear') as string}
                      options={schoolYearsOptions}
                      onChange={(option) => {
                        const opt = option as SingleValue<SelectOptionType>;
                        setFieldTouched('schoolYearUuid', true);
                        setFieldValue('schoolYearUuid', opt?.value, true);
                      }}
                      value={schoolYearsOptions.find((syo) => {
                        return syo.value === values.schoolYearUuid;
                      })}
                      name={'schoolYearUuid'}
                      {...(errors.schoolYearUuid &&
                        touched.schoolYearUuid && {
                          error: errors.schoolYearUuid,
                        })}
                    />
                  </div>
                </div>
                <div className='tks__row row-xs-10'>
                  <div className='tks__col col-xs'>
                    <Input
                      label={t('common.comment') as string}
                      name={'comment'}
                      onChange={handleChange}
                      value={values.comment ?? ''}
                      {...(errors.comment &&
                        touched.comment && {
                          error: errors.comment,
                        })}
                    />
                  </div>
                </div>
              </div>
              <FieldArray
                name={'timegridConfigs'}
                render={(arrayHelpers) => {
                  const ArrayErrors = () =>
                    typeof errors.timegridConfigs === 'string' && touched.timegridConfigs ? (
                      <div className={'error-message'}>{errors.timegridConfigs}</div>
                    ) : (
                      <></>
                    );
                  return (
                    <div className={'mb-10'}>
                      <FormBlockHeader title={t('timeGrid.title', { count: 2 })} />
                      {values.timegridConfigs?.map((timegridConfig, index) => {
                        return (
                          <div key={timegridConfig.timegridUuid} className={'tks__grid no-wrap'}>
                            <div className={'tks__row'}>
                              <div className={'tks__col col-xs-5'}>
                                <Select
                                  label={t('timeGrid.title', { count: 2 }) as string}
                                  name={`timegridConfigs.${index}.timegridUuid`}
                                  options={timeGridOptions}
                                  onChange={(option) => {
                                    const opt = option as SingleValue<SelectOptionType>;
                                    setFieldTouched(`timegridConfigs.${index}.timegridUuid`, true);
                                    setFieldValue(`timegridConfigs.${index}.timegridUuid`, opt?.value, true);
                                  }}
                                  value={timeGridOptions.find((tgo) => {
                                    return tgo.value === values.timegridConfigs[index].timegridUuid;
                                  })}
                                />
                              </div>
                              <div className={'tks__col col-xs-5'}>
                                <Select
                                  label={t('timeGrid.validFor') as string}
                                  name={`timegridConfigs.${index}.classes`}
                                  isMulti={!selectAllClasses}
                                  readonly
                                  value={{ value: null, label: t('timeGrid.forAllClasses') }}
                                  onChange={(option) => {
                                    const opt = option as SingleValue<SelectOptionType>;
                                    setFieldTouched(`timegridConfigs.${index}.classes`, true);
                                    setFieldValue(`timegridConfigs.${index}.classes`, opt?.value, true);
                                  }}
                                  options={classesOptions}
                                />
                              </div>
                              <div className={'tks__col col-xs-2'}>
                                <Button
                                  className='mt-5 align-end'
                                  hierarchy={'tertiary'}
                                  icon={<DeleteIcon />}
                                  disabled={true}
                                  onClick={() => arrayHelpers.remove(index)}
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {
                        <Button
                          hierarchy={'tertiary'}
                          disabled={values.timegridConfigs.length > 0}
                          icon={<AddIcon />}
                          onClick={() => arrayHelpers.push({ timegridUuid: timeGridOptions[0].value })}
                        >
                          {t('timeGrid.title', { count: 2 })}
                        </Button>
                      }
                      <ArrayErrors />
                    </div>
                  );
                }}
              />
              <div className={'modal-bottom'}>
                <Button
                  hierarchy='tertiary'
                  type='button'
                  onClick={() => {
                    resetForm();
                    onClose();
                  }}
                  className={'buttons'}
                >
                  {t('common.cancelChanges')}
                </Button>
                <Button type='submit' disabled={isSubmitting || !dirty || isValidating} className={'buttons'}>
                  {t('common.save')}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
});
