import { Card, Grid, GridColumn, GridRow, Table } from '@bp/ui-components';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUserConfigContext } from '../../hooks/useUserConfigContext';
import { use_TeachingBlocksQuery, use_TimetableBlocksQuery } from '../../types/planung-graphql-client-defs';
import { ReportTopic } from './types';
import { observer } from 'mobx-react-lite';
import { TimetablePrintConfigurationModal } from '../TimetablePrintConfiguration/TimetablePrintConfigurationModal/TimetablePrintConfigurationModal';
import styles from './TimetableReports.module.scss';
import { TimetableReportType } from '@bp/planung-graphql-types';
import { useMemorizedCacheTag } from '../../hooks/useMemorizedCacheTag';
import { TimetableReportsContent } from './TimetableReportsContent';
import dayjs from 'dayjs';

type TimetableReportsProps = {
  tableData: {
    id: TimetableReportType | null;
    name: string;
  }[];
  topic: ReportTopic;
};
export const TimetableReports = observer(({ tableData, topic }: TimetableReportsProps) => {
  const { t } = useTranslation();
  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;

  const [showConfigModal, setShowConfigModal] = useState<boolean>(false);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const [selected, setSelected] = useState<TimetableReportType | null>(tableData[0].id);
  const [headline, setHeadline] = useState(tableData[0].name);

  const timetableBlocksContext = useMemorizedCacheTag('TIMETABLE_BLOCK');
  const teachingBlocksContext = useMemorizedCacheTag('TEACHINGBLOCK');

  const [{ data: timetableBlocks }] = use_TimetableBlocksQuery({
    variables: {
      where: {
        schoolYear: {
          uuid: currentSchoolYear?.uuid,
        },
        timetables_SOME: {
          versions_SINGLE: {
            active: true,
          },
        },
      },
    },
    context: timetableBlocksContext,
  });

  const [{ data: teachingBlocks }] = use_TeachingBlocksQuery({
    variables: {
      where: {
        schoolYear: {
          uuid: currentSchoolYear?.uuid,
        },
        draft: false,
        versions_SOME: {
          active: true,
        },
      },
    },
    context: teachingBlocksContext,
  });

  const tableColumns = [
    {
      header: '',
      id: 'name',
      accessorKey: 'name',
      canExpand: true,
    },
  ];
  const timetableBlocksData = useMemo(() => {
    return timetableBlocks?.timetableBlocks
      .sort((a, b) => {
        return dayjs(a.start).isAfter(dayjs(b.start)) ? -1 : 1;
      })
      .map((tb) => ({
        uuid: tb.uuid,
        name: tb.name,
      }));
  }, [timetableBlocks?.timetableBlocks]);

  const teachingBlocksData = useMemo(() => {
    return teachingBlocks?.teachingBlocks.map((tb) => ({
      uuid: tb.uuid,
      name: tb.name,
    }));
  }, [teachingBlocks?.teachingBlocks]);

  return (
    <>
      <Grid useFormGap className={styles['timetable-reports']}>
        <GridRow mobileGap={'32px'}>
          <GridColumn width={6}>
            <Card header={{ headline: t('timetable.title', { count: 2 }) }} contentPadding={'none'}>
              <Table
                hideHeader
                data={tableData}
                columns={tableColumns}
                onRowClick={(e, row) => {
                  const current = tableData.find((item) => item.id === row.original.id);
                  setSelectedIndex(parseInt(row.id));
                  setHeadline(current?.name || '');
                  setSelected(current?.id ?? null);
                }}
                rowSelection={{ [selectedIndex]: true }}
                lastColWidth={'0'}
                customPadding='var(--spacing-6)'
                showRowHover
                showSelected
              />
            </Card>
          </GridColumn>
          <GridColumn width={6}>
            <Card
              header={{
                headline,
                actions: [
                  {
                    text: t('timetable.configuration.configure'),
                    disabled: !selected,
                    callback: () => setShowConfigModal(true),
                  },
                ],
              }}
              contentPadding={'none'}
            >
              <TimetableReportsContent
                topic={topic}
                selected={selected}
                timetableBlocks={timetableBlocksData}
                teachingBlocks={teachingBlocksData}
              />

              {selected === null && (
                <div className={styles.block}>
                  <div className={styles.name}>{t('common.planned')}</div>
                </div>
              )}
            </Card>
          </GridColumn>
        </GridRow>
      </Grid>

      {selected && showConfigModal && (
        <TimetablePrintConfigurationModal
          isOpen={showConfigModal}
          onClose={() => setShowConfigModal(false)}
          topic={selected}
        />
      )}
    </>
  );
});
