import { Button, ButtonGroup, DeleteIcon, EditIcon, Table, TableColumns } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';

type TeacherAbsencesTableProps = {
  onAdd: () => void;
  onEdit: (uuid: string) => void;
  onDelete: (uuid: string) => void;
};

type TeacherAbsenceTableType = {
  uuid: string;
  teacherName: string;
  start: string;
  end: string;
  reason: string;
  comment: string;
};

export const TeacherAbsencesTable = ({ onAdd, onEdit, onDelete }: TeacherAbsencesTableProps) => {
  const { t } = useTranslation();

  const absences: TeacherAbsenceTableType[] = [];

  const columns: TableColumns<TeacherAbsenceTableType>[] = [
    {
      header: t('persons.titleNeutral'),
      id: 'teacherName',
      accessorKey: 'teacherName',
      size: 150,
    },
    {
      header: t('common.start'),
      id: 'start',
      accessorKey: 'start',
      size: 150,
    },
    {
      header: t('common.end'),
      id: 'end',
      accessorKey: 'end',
      size: 150,
    },
    {
      header: t('absences.reason'),
      id: 'reason',
      accessorKey: 'reason',
      size: 150,
    },
    {
      header: t('common.comment'),
      id: 'comment',
      accessorKey: 'comment',
      size: 150,
    },
  ];

  return (
    <Table<TeacherAbsenceTableType>
      columns={columns}
      data={absences}
      showShadow
      showBorderRadius
      showActionBar
      actionBarSettings={{ showAddButton: true }}
      onAddClick={onAdd}
      isOnWhite={false}
      lastColWidth='80px'
      lastCol={(row) => {
        return (
          <ButtonGroup>
            <Button
              hierarchy='secondary'
              onClick={() => onEdit(row.original.uuid)}
              icon={<EditIcon className='small' />}
            />
            <Button
              hierarchy='secondary'
              onClick={() => onDelete(row.original.uuid)}
              icon={<DeleteIcon className='small' />}
            />
          </ButtonGroup>
        );
      }}
    />
  );
};
