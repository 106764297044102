import {
  FactorType,
  PersonRole,
  useCreateTeachingLoadFactorMutation,
  useSubjectGroupsQuery,
  useTeachingLoadScenariosQuery,
  useUpdateTeachingLoadFactorMutation,
} from '../../../types/planung-graphql-client-defs';
import { FormikHelpers } from 'formik/dist/types';
import { Form, Formik } from 'formik';
import { schema } from './validation/schema';
import { Checkbox, Input, Select, SelectOptionType } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { MultiValue, SingleValue } from 'react-select';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { useCreateSelectOptions } from '../../../hooks/useCreateSelectOptions';
import { FormBlockHeader } from '../../Form/FormBlockHeader';
import { ModalBottomButtons } from '../../ModalBottomButtons/ModalBottomButtons';
import { useMemoizedClassNumberOptions } from '../../../hooks/useMemoizedClassNumberOptions';
import { FactorInterpretationInfo } from './components/FactorInterpretationInfo';
import { interpreteAsNumber } from '../../../utils/helper';
import { FactorsFormProps, FactorsFormTypes } from '../types';
import { submitFactor } from './utils/submitFactor';
import { showSuccessCreateToast, showSuccessUpdateToast, showUserErrorToast } from '../../../utils/toast';
import { useLoadBasicData } from '../../../hooks/useLoadBasicData';
import { useAuthClaims } from '../../../hooks/useAuthClaims';

export const TeacherFactorForm = ({ currentSchoolYear, closeForm, teachingLoadFactor }: FactorsFormProps) => {
  const { t } = useTranslation();
  const context = useMemorizedCacheTag('TEACHINGLOADFACTOR');
  const { pimAuthClaims } = useAuthClaims();

  const { teacherData, gradeGroupData, subjectData, subjectContainerData } = useLoadBasicData({ pause: false });

  const [subjectGroupsQueryResult] = useSubjectGroupsQuery({
    variables: { organizationUuid: pimAuthClaims.getOrganizationUuid() },
    context,
  });
  const [scenariosQueryResult] = useTeachingLoadScenariosQuery({ context });

  const gradeGroups = gradeGroupData?.gradeGroups;

  const teachingLoadScenarios = scenariosQueryResult.data && scenariosQueryResult.data.teachingLoadScenarios;
  const subjectContainers = subjectContainerData?.subjectContainers;
  const subjectGroups = subjectGroupsQueryResult.data && subjectGroupsQueryResult.data.subjectGroups;
  const subjects = subjectData?.subjects;
  const teachers = teacherData?.people?.filter((person) =>
    person.organizationConnection.edges.some((edge) => edge.properties.name === PersonRole.Teacher),
  );

  // select options
  const classNumberOptions = useMemoizedClassNumberOptions();
  const gradeGroupsOptions: SelectOptionType[] = useCreateSelectOptions(gradeGroups, 'uuid', 'name');
  const subjectsOptions: SelectOptionType[] = useCreateSelectOptions(subjects, 'uuid', 'name');
  const teachersOptions: SelectOptionType[] = useCreateSelectOptions(teachers, 'uuid', 'displayName');
  const teachingLoadScenariosOptions: SelectOptionType[] = useCreateSelectOptions(
    teachingLoadScenarios,
    'uuid',
    'name',
  );

  // SubjectGroup = Fachbereich
  // SubjectContainer = Epochenschiene
  const subjectContainersOptions: SelectOptionType[] = useCreateSelectOptions(subjectContainers, 'uuid', 'name');
  const subjectGroupsOptions: SelectOptionType[] = useCreateSelectOptions(subjectGroups, 'uuid', 'name');
  const kindOptions = [...subjectContainersOptions, ...subjectGroupsOptions];

  const [, createTeachingLoadFactor] = useCreateTeachingLoadFactorMutation();
  const [, updateTeachingLoadFactor] = useUpdateTeachingLoadFactorMutation();

  const handleSubmit = async (values: FactorsFormTypes, formikHelpers: FormikHelpers<FactorsFormTypes>) => {
    const result = await submitFactor(
      values,
      teachingLoadFactor,
      currentSchoolYear,
      context,
      updateTeachingLoadFactor,
      createTeachingLoadFactor,
    );

    if (result.error) {
      showUserErrorToast({ error: result.error });
    } else if (teachingLoadFactor?.uuid) {
      showSuccessUpdateToast();
    } else {
      showSuccessCreateToast();
    }
    formikHelpers.resetForm();
    closeForm();
  };

  const initialValues: FactorsFormTypes = {
    uuid: teachingLoadFactor?.uuid ?? null,
    factorType: FactorType.Person, // default "Lehrer"
    grades: teachingLoadFactor?.grades ?? [],
    gradeGroups: teachingLoadFactor?.gradeGroup?.map((item) => item.uuid),
    subjects: teachingLoadFactor?.subject?.map((item) => item.uuid),
    value: teachingLoadFactor?.value.toString() ?? '1',
    valueRaw: teachingLoadFactor?.valueRaw ?? '1',
    valuePartial: teachingLoadFactor?.valuePartial?.toString() ?? '1',
    valuePartialRaw: teachingLoadFactor?.valuePartialRaw ?? '1',
    comment: teachingLoadFactor?.comment ?? '',
    schoolYear: currentSchoolYear,
    teachingLoadScenario: teachingLoadFactor?.scenario?.uuid ?? '',
    active: teachingLoadFactor?.active ?? true,
    teachers: teachingLoadFactor?.teachers.map((item) => item.uuid),
  };

  return (
    <Formik<FactorsFormTypes>
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
      validateOnChange={true}
      validateOnBlur={true}
    >
      {({
        values,
        handleChange,
        resetForm,
        dirty,
        isValidating,
        isSubmitting,
        setFieldTouched,
        setFieldValue,
        errors,
      }) => {
        const handleMultiSelectChange = async (name: keyof FactorsFormTypes, options: MultiValue<SelectOptionType>) => {
          await setFieldTouched(name, true);
          await setFieldValue(
            name,
            options.map((o) => o.value),
          );
        };

        return (
          <Form>
            <div className='tks__grid mt-6 mb-8'>
              <div className='tks__row'>
                {/*Lehrende*/}
                <div className='tks__col col-xs-10'>
                  <Select
                    placeholder={t('common.all')}
                    tooltipText={t('common.allIfNothingSelected')}
                    name={'teachers'}
                    onChange={async (options) =>
                      handleMultiSelectChange('teachers', options as MultiValue<SelectOptionType>)
                    }
                    defaultValue={values?.teachers?.map((teacherUuid) => {
                      return (
                        teachersOptions.find((option) => option.value === teacherUuid) ?? { value: null, label: null }
                      );
                    })}
                    options={teachersOptions}
                    isSearchable
                    isClearable
                    isMulti
                    label={t('routes.teacher.title')}
                  />
                </div>

                <div className='tks__col col-xs-2'>
                  {/* Sceanrio */}
                  <Select
                    name={'teachingLoadScenario'}
                    onChange={async (option) => {
                      const o = option as SingleValue<SelectOptionType>;
                      if (o !== null) {
                        await setFieldTouched('teachingLoadScenario', true);
                        await setFieldValue('teachingLoadScenario', o.value);
                      }
                    }}
                    options={teachingLoadScenariosOptions}
                    isSearchable
                    isClearable
                    label={t('common.scenario')}
                    defaultValue={
                      teachingLoadFactor?.scenario
                        ? { label: teachingLoadFactor?.scenario?.name, value: teachingLoadFactor?.scenario.uuid }
                        : null
                    }
                  />
                </div>
              </div>

              {/*Fächer*/}
              <div className='tks__row'>
                <div className='tks__col col-xs-10'>
                  <Select
                    tooltipText={t('common.allIfNothingSelected')}
                    placeholder={t('common.all')}
                    name={'subjects'}
                    onChange={async (options) =>
                      handleMultiSelectChange('subjects', options as MultiValue<SelectOptionType>)
                    }
                    options={subjectsOptions}
                    defaultValue={
                      teachingLoadFactor?.subject
                        ? teachingLoadFactor?.subject.map((item) => ({ label: item.name, value: item.uuid }))
                        : null
                    }
                    isSearchable
                    isClearable
                    isMulti
                    label={t('subject.title', { count: 2 })}
                  />
                </div>
                <div className='tks__col col-xs-2 align-center'>
                  <Checkbox
                    checked={values.active}
                    name={'active'}
                    label={t('common.active.full')}
                    onChange={async (e) => {
                      await setFieldValue('active', e.target.checked);
                      await setFieldTouched('active', true);
                    }}
                  />
                </div>
              </div>

              <div className='tks__row row-xs-10'>
                {/*Jahrgangsstufen*/}
                <div className='tks__col col-xs-6'>
                  <Select
                    tooltipText={t('common.allIfNothingSelected')}
                    placeholder={t('common.all')}
                    name={'grades'}
                    onChange={async (options) =>
                      handleMultiSelectChange('grades', options as MultiValue<SelectOptionType>)
                    }
                    defaultValue={classNumberOptions.filter((opt) => values.grades.includes(opt.value))}
                    options={classNumberOptions}
                    isSearchable
                    isClearable
                    isMulti={true}
                    label={t('common.grades')}
                  />
                </div>

                {/*Klassenzug*/}
                <div className='tks__col col-xs-6'>
                  <Select
                    tooltipText={t('common.allIfNothingSelected')}
                    placeholder={t('common.all')}
                    name={'gradeGroups'}
                    onChange={async (options) =>
                      handleMultiSelectChange('gradeGroups', options as MultiValue<SelectOptionType>)
                    }
                    options={gradeGroupsOptions}
                    isSearchable
                    isClearable
                    isMulti
                    label={t('gradeGroups.title', { count: 1 })}
                    defaultValue={
                      teachingLoadFactor?.gradeGroup
                        ? teachingLoadFactor?.gradeGroup.map((item) => ({ label: item.name, value: item.uuid }))
                        : null
                    }
                  />
                </div>
              </div>

              {/* Art  */}
              <div className='tks__row row-xs-10'>
                <div className='tks__col col-xs-12'>
                  <Select
                    tooltipText={t('common.allIfNothingSelected')}
                    placeholder={t('common.all')}
                    name={'kind'}
                    onChange={async (options) =>
                      handleMultiSelectChange('kinds', options as MultiValue<SelectOptionType>)
                    }
                    options={kindOptions}
                    isSearchable
                    isMulti
                    isClearable
                    label={t('common.kind')}
                  />
                </div>
              </div>
            </div>

            <FormBlockHeader title={'Faktor'} />

            <div className='tks__grid'>
              <div className='tks__row row-xs-10'>
                {/*Faktor*/}
                <div className='tks__col col-xs-2'>
                  <Input
                    tooltipText={t('factors.tooltip')}
                    value={values.valueRaw}
                    name={'value'}
                    onChange={async (e) => {
                      await setFieldValue('valueRaw', e.target.value);
                      await setFieldValue('value', interpreteAsNumber(e.target.value));
                      await setFieldTouched('value');
                    }}
                    label={t('factors.title', { count: 1 })}
                    error={errors.value}
                    type={'text'}
                  />
                </div>

                <div className='tks__col col-xs-3 align-center'>
                  <FactorInterpretationInfo value={values.value} />
                </div>
                <div className='tks__col  col-xs-3'>
                  <Input
                    tooltipText={t('factors.tooltip')}
                    value={values.valuePartialRaw}
                    name={'value'}
                    onChange={async (e) => {
                      await setFieldValue('valuePartialRaw', e.target.value);
                      await setFieldValue('valuePartial', interpreteAsNumber(e.target.value));
                      await setFieldTouched('valuePartial');
                    }}
                    label={t('factors.titlePartial')}
                    error={errors.value}
                    type={'text'}
                  />
                </div>

                <div className='tks__col col-xs-3 align-center'>
                  <FactorInterpretationInfo value={values.valuePartial as string} />
                </div>
              </div>

              <div className='tks__row row-xs-10'>
                {/*Bemerkung*/}
                <div className='tks__col col-xs-12'>
                  <Input
                    name={'comment'}
                    onChange={handleChange}
                    label={t('common.comment')}
                    value={values.comment ?? ''}
                    error={errors.comment}
                  />
                </div>
              </div>
            </div>

            <ModalBottomButtons
              closeButton={{
                callback: () => {
                  resetForm();
                  closeForm();
                },
                disabled: isSubmitting || !dirty || isValidating,
              }}
              errors={errors}
              isLoading={isSubmitting}
            />
          </Form>
        );
      }}
    </Formik>
  );
};
