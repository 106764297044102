import { GroupPicker, Select, SelectOptionType } from '@bp/ui-components';
import { MultiValue, SingleValue } from 'react-select';
import { useCreateSelectOptions } from '../../../../hooks/useCreateSelectOptions';
import { useFormikContext } from 'formik';
import { useMemorizedCacheTag } from '../../../../hooks/useMemorizedCacheTag';
import { useDivisionsQuery, useGradeGroupsListQuery } from '../../../../types/planung-graphql-client-defs';
import { useTranslation } from 'react-i18next';
import { CurriculumClassType, CurriculumFormType } from '../../types';
import { useAuthClaims } from '../../../../hooks/useAuthClaims';
import { useMemo } from 'react';
import { useGradeOptions } from '../../../../hooks/useGradeOptions';

export const CurriculumClassesFormItem = ({
  index,
  multiEnabled,
  curriculumClass,
  disabled,
  enableGroups,
  selectableGrades,
}: {
  index: number;
  multiEnabled: boolean;
  curriculumClass: CurriculumClassType;
  disabled: boolean;
  enableGroups: boolean;
  selectableGrades: number[];
}) => {
  const formik = useFormikContext<CurriculumFormType>();
  const { pimAuthClaims } = useAuthClaims();
  const { t } = useTranslation();

  const [{ data: gradeGroupsListQuery }] = useGradeGroupsListQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
    },
    context: useMemorizedCacheTag('GRADE_GROUPS'),
  });

  const context = useMemorizedCacheTag('CLASS_DIVISIONS');
  const [classDivisionsListResult] = useDivisionsQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
      classCount: 0,
    },
    context,
  });

  const options =
    useMemo(() => {
      return classDivisionsListResult.data?.divisions.map((division) => {
        return {
          options: division.groupsConnection.edges.map((group) => {
            return { value: group.node.uuid, label: group.node.shortName };
          }),
        };
      });
    }, [classDivisionsListResult.data?.divisions]) ?? [];

  const gradeOptions = useGradeOptions(selectableGrades);

  const gradeGroupsOptions = useCreateSelectOptions(gradeGroupsListQuery?.gradeGroups, 'uuid', 'shortName');

  return (
    <div className={'form-row'}>
      <div className='form-group tks__grid'>
        <Select
          className={'third'}
          onChange={async (option) => {
            const multiValue = option as MultiValue<SelectOptionType>;
            const singleValue = option as SingleValue<SelectOptionType>;
            await formik.setFieldTouched(`curriculumGrades.${index}.grades`, true);
            await formik.setFieldValue(
              `curriculumGrades.${index}.grades`,
              multiEnabled
                ? multiValue.map((value) => {
                    return value.value;
                  })
                : [singleValue?.value ?? 0],
            );
          }}
          label={t('classes.level.full')}
          options={gradeOptions}
          isSearchable
          isMulti={multiEnabled}
          name={`curriculumGrades.${index}.grades`}
          tooltipText={t('curriculum.multiplyHint')}
          defaultValue={
            curriculumClass.grades
              ? curriculumClass.grades.map((g) => {
                  return {
                    value: g,
                    label: '' + g,
                  };
                })
              : undefined
          }
        />
        <Select
          className={'third'}
          onChange={async (option) => {
            const multiValue = option as MultiValue<SelectOptionType>;
            const singleValue = option as SingleValue<SelectOptionType>;
            await formik.setFieldTouched(`curriculumGrades.${index}.gradeGroups`, true);
            const value = multiEnabled
              ? multiValue.map((value) => {
                  return { uuid: value.value };
                })
              : singleValue
                ? [{ uuid: singleValue?.value }]
                : null;

            await formik.setFieldValue(`curriculumGrades.${index}.gradeGroups`, value);
          }}
          defaultValue={
            curriculumClass.gradeGroups
              ? curriculumClass.gradeGroups.map((gg) => {
                  return {
                    value: gg.uuid,
                    label: gg.name ?? '',
                  };
                })
              : undefined
          }
          options={gradeGroupsOptions}
          label={t('gradeGroups.title', { count: 2 })}
          tooltipText={t('curriculum.multiplyHint')}
          name={`curriculumGrades.${index}.gradeGroups`}
          isSearchable
          isClearable
          isMulti={multiEnabled}
        />
        {enableGroups && (
          <GroupPicker
            readonly={disabled}
            className={'quarter'}
            name={`curriculumGrades.${index}.groups`}
            label={t('common.group_other')}
            disabled={disabled}
            tooltipText={t('curriculum.multiplyHint')}
            options={options}
            onChange={async (multiValue) => {
              await formik.setFieldTouched(`curriculumGrades.${index}.groups`, true);
              await formik.setFieldValue(
                `curriculumGrades.${index}.groups`,
                multiValue.map((value) => {
                  return { uuid: value.value, name: value.label };
                }),
              );
            }}
            defaultValue={
              curriculumClass.groups
                ? curriculumClass.groups.map((group) => {
                    return {
                      value: group.uuid,
                      label: group.name ?? '',
                    };
                  })
                : undefined
            }
            dropdownHeader={t('divisions.default')}
          />
        )}
      </div>
    </div>
  );
};
