import { useTranslation } from 'react-i18next';
import { EvaluateIllustration, PlanIllustration, SetupIllustration } from '@bp/ui-components';
import { IconMenu } from '../../components/IconMenu/IconMenu';
import { IconMenuItem } from '../../components/IconMenuItem/IconMenuItem';
import { useNavigate } from 'react-router-dom';
import { FC } from 'react';
import { Page } from '../../components/Page/Page';

export const DeputatePage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Page title={t('deputate.title', { count: 2 })}>
      <IconMenu headline={t('common.whatToDo')}>
        <IconMenuItem
          label={t('deputate.setup')}
          icon={<SetupIllustration />}
          bubbleType={14}
          onClick={() => navigate(t('routes.setup.slug'))}
        />
        <IconMenuItem
          label={t('deputate.plan')}
          icon={<PlanIllustration />}
          bubbleType={16}
          onClick={() => navigate(t('routes.plan.slug'))}
        />
        <IconMenuItem
          label={t('deputate.evaluate.title')}
          icon={<EvaluateIllustration />}
          bubbleType={17}
          onClick={() => navigate(t('routes.evaluate.slug'))}
        />
      </IconMenu>
    </Page>
  );
};
