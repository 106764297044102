import { Table, TableColumns } from '@bp/ui-components';
import { RowSelectionState } from '@tanstack/react-table';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useColumnsSort } from '../../hooks/useColumnsSort';
import { SchoolYearType } from '../SchoolYear/graphql/types';
import { CurrentSchoolYear } from '../../stores/UserConfigStore';
import { observer } from 'mobx-react-lite';

type HolidaysListProps = {
  data: SchoolYearType[] | null;
  currentSchoolYear: CurrentSchoolYear | null;
  onSelect: (yearUuid: string) => void;
  selected: RowSelectionState;
};

export const HolidaysList = observer(({ data, currentSchoolYear, onSelect, selected }: HolidaysListProps) => {
  const { t } = useTranslation();
  const { sorting, saveSorting } = useColumnsSort('holidays-list');

  const createColumns = (): TableColumns<SchoolYearType>[] => {
    return [
      {
        header: t('schoolYear.name'),
        accessorKey: 'name',
        id: 'name',
      },
      {
        header: t('schoolYear.shortName'),
        accessorKey: 'shortName',
        id: 'shortName',
      },
      {
        header: t('holidays.title', { count: 2 }) as string,
        id: 'holidays',
        type: 'number',
        cell: ({ row }) => {
          return row.original.holidays.length;
        },
      },
    ];
  };

  const columns = useMemo(createColumns, [t]);

  const memoizedData = useMemo((): SchoolYearType[] => {
    return data && data ? data : [];
  }, [data]);

  useEffect(() => {
    const index = memoizedData.findIndex((year) => year.uuid === currentSchoolYear?.uuid);
    if (index >= 0) {
      onSelect(memoizedData[index].uuid);
    }
  }, [currentSchoolYear?.uuid, memoizedData, onSelect]);

  return (
    <Table
      showBorderRadius
      showShadow
      showSelected
      canScroll
      breakpoint={null}
      columns={columns}
      data={memoizedData}
      sorting={sorting}
      onSortingChange={saveSorting}
      showRowHover
      rowSelection={selected}
      onRowClick={(event, row) => {
        onSelect(row.original.uuid);
      }}
    />
  );
});
