import { useState } from 'react';
import { TeacherAbsencesTable } from '../../../../components/Absences/tables/TeacherAbsencesTable';
import { TeacherAbsenceModal } from '../../../../components/Absences/modals/TeacherAbsenceModal';
import { AbsenceReason } from '../../../../utils/absenceReasons';

export type TeacherAbsence = {
  uuid: string;
  teacherUuid: string;
  startDate: Date;
  startTime: Date;
  endDate: Date;
  endTime: Date;
  reason: AbsenceReason;
  comment: string;
};

export const PlanTeacherAbsences = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedAbsence, setSelectedAbsence] = useState<TeacherAbsence | null>(null);

  return (
    <>
      <TeacherAbsencesTable
        onAdd={() => setIsModalOpen(true)}
        onEdit={(uuid) => {
          console.log('TODO: implement');
        }}
        onDelete={(uuid) => {
          console.log('TODO: implement');
        }}
      />

      <TeacherAbsenceModal
        selectedAbsence={selectedAbsence ?? undefined}
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedAbsence(null);
        }}
      />
    </>
  );
};
