import { Modal } from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { TeacherAbsenceForm } from '../forms/TeacherAbsenceForm';
import { TeacherAbsence } from '../../../pages/Substitutions/Plan/PlanTeacherAbsences/PlanTeacherAbsences';

type TeacherAbsenceModalProps = {
  selectedAbsence?: TeacherAbsence;
  isOpen: boolean;
  onClose: () => void;
};

export const TeacherAbsenceModal = ({ selectedAbsence, isOpen, onClose }: TeacherAbsenceModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal title={selectedAbsence ? t('absences.edit') : t('absences.add')} isOpen={isOpen} onRequestClose={onClose}>
      <TeacherAbsenceForm selectedAbsence={selectedAbsence} onClose={onClose} />
    </Modal>
  );
};
