import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IconMenu } from '../../../components/IconMenu/IconMenu';
import { IconMenuItem } from '../../../components/IconMenuItem/IconMenuItem';
import {
  ClassesIllustration,
  RoomIllustration,
  SubjectIllustration,
  TeacherIllustration,
  TimegridIllustration,
} from '@bp/ui-components';
import { Page } from '../../../components/Page/Page';

export const TimetableSetupPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Page title={`${t('timetable.title', { count: 2 })} ${t('timetable.setup').toLowerCase()}`}>
      <IconMenu headline={t('common.coreData')}>
        <IconMenuItem
          label={`${t('subject.title', { count: 2 })}/${t('subjectContainer.title', { count: 2 })}`}
          icon={<SubjectIllustration />}
          bubbleType={1}
          onClick={() => navigate(t('routes.subjects.slug'))}
        />
        <IconMenuItem
          label={t('persons.title', { count: 2 })}
          icon={<TeacherIllustration />}
          bubbleType={2}
          onClick={() => navigate(t('routes.teacher.slug'))}
        />
        <IconMenuItem
          label={t('classes.title', { count: 2 })}
          icon={<ClassesIllustration />}
          bubbleType={3}
          onClick={() => navigate(t('routes.classes.slug'))}
        />
        <IconMenuItem
          label={t('rooms.titleLong')}
          icon={<RoomIllustration />}
          bubbleType={4}
          onClick={() => navigate(t('routes.rooms.slug'))}
        />
      </IconMenu>
      <IconMenu headline={t('common.additionalData')} hasMarginTop>
        <IconMenuItem
          label={t('timeGrid.title', { count: 2 })}
          icon={<TimegridIllustration />}
          bubbleType={9}
          onClick={() => navigate(t('routes.timegrid.slug'))}
        />
      </IconMenu>
    </Page>
  );
};
