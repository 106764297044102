import { useTranslation } from 'react-i18next';
import { PersonRole } from '../../../../types/planung-graphql-client-defs';
import { Tab } from '@bp/ui-components';
import { TimetablePersonsTable } from '../../../../components/Persons/Tables/TimetablePersonsTable';
import { Page } from '../../../../components/Page/Page';

export const SetupPersons = () => {
  const { t } = useTranslation();

  return (
    <Page title={null}>
      <Tab
        variant={'large'}
        tabs={[
          {
            title: t('persons.title', { count: 2 }),
            value: 'teachers',
            content: <TimetablePersonsTable personsRole={PersonRole.Teacher} />,
          },
          {
            title: t('persons.other'),
            value: 'other',
            content: <TimetablePersonsTable personsRole={PersonRole.Other} />,
          },
        ]}
      />
    </Page>
  );
};
