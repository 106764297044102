import {
  Button,
  Card,
  Chip,
  DotsHorizontalIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  SearchInput,
} from '@bp/ui-components';
import { CardControl } from '../CardControl/CardControl';
import { CardPicker } from '../CardPicker/CardPicker';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import styles from './GridActionArea.module.scss';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useTimetableStore } from '../TimetableProvider';

export const GridActionArea: FC = observer(() => {
  const { t } = useTranslation();

  const pinboardStore = useTimetableStore();

  const [filterFunctionClasses, setFilterFunctionClasses] = useState<boolean>(false);
  const [searchString, setSearchString] = useState<string>('');

  const selectedRows = pinboardStore.selectedRows
    .map((row) => row.label)
    .sort()
    .join(', ');

  const menu: DropdownMenuItem[] = [
    {
      type: 'component',
      node: (
        <SearchInput
          name='search-string'
          isOnWhite
          value={searchString}
          onChange={(event) => setSearchString(event.target.value)}
          placeholder={t('pinboard.actions.searchCards')}
          className={styles['search-input']}
        />
      ),
    },
    {
      type: 'ruler',
    },
    {
      label: t('pinboard.actions.filterFunctionClasses'),
      type: 'switch',
      value: filterFunctionClasses,
      onValueChange: (value) => setFilterFunctionClasses(value as boolean),
    },
    {
      label: t('pinboard.actions.showTimetable'),
      disabled: true,
    },
  ];

  function getBoardContextString(): string {
    let contextString = '';
    if (pinboardStore.context === 'classes') {
      if (selectedRows) contextString = t('pinboard.showSelectionFor', { type: t('classes.title', { count: 1 }) });
      else contextString = t('pinboard.showContextFor', { context: t('classes.title', { count: 2 }) });
    }
    if (pinboardStore.context === 'teachers') {
      if (selectedRows) contextString = t('pinboard.showSelectionFor', { type: t('persons.title', { count: 2 }) });
      else contextString = t('pinboard.showContextFor', { context: t('persons.title', { count: 2 }) });
    }
    if (pinboardStore.context === 'rooms') {
      if (selectedRows) contextString = t('pinboard.showSelectionFor', { type: t('rooms.title', { count: 2 }) });
      else contextString = t('pinboard.showContextFor', { context: t('rooms.title', { count: 2 }) });
    }
    return contextString;
  }

  useEffect(() => {
    setFilterFunctionClasses(false);
    setSearchString('');
  }, [pinboardStore.context]);

  return (
    <Card
      className={styles['grid-action-area']}
      wrapperClass={classNames(styles.wrapper, {
        [styles['control-mode']]: pinboardStore.controlMode.isActive,
      })}
      contentPadding='none'
      fitContent
    >
      {pinboardStore.controlMode.isActive ? (
        <CardControl className={styles.control} />
      ) : (
        <>
          <div className={styles.header}>
            <div className={styles.selection}>
              <div className={styles['board-context']}>{getBoardContextString()}</div>
              <div className={styles['selected-rows']}>{selectedRows}</div>
            </div>
            <div className={styles.actions}>
              {(filterFunctionClasses || (searchString && searchString !== '')) && (
                <Chip value={t('pinboard.activeFilters')} />
              )}
              <Dropdown
                className={styles.menu}
                trigger={<Button icon={<DotsHorizontalIcon className='small' />} className='mr-3' hierarchy='ghost' />}
              >
                <DropdownMenu data={menu} />
              </Dropdown>
            </div>
          </div>
          <CardPicker searchString={searchString} filterFunctionClasses={filterFunctionClasses} />
        </>
      )}
    </Card>
  );
});
