import {
  Checkbox,
  ColorPicker,
  DatePicker,
  Grid,
  GridColumn,
  GridRow,
  Input,
  Select,
  SelectOptionType,
  showToast,
} from '@bp/ui-components';
import { Form, Formik } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import {
  PersonRole,
  SortDirection,
  usePersonsQuery,
  usePimProfilesQuery,
  useRoomsListQuery,
} from '../../../types/planung-graphql-client-defs';
import { colorPickerOptions, UsedColors } from '../../../utils/colorPickerOptions';
import { ContractsForm } from './ContractsForm';
import { formSubmit } from './formSubmit';
import { QualificationsForm } from './QualificationsForm';
import { validationSchema } from './validation/validationsSchema';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { ModalBottomButtons } from '../../ModalBottomButtons/ModalBottomButtons';
import { isNotEmpty } from '../../../utils/typeguards';
import { useCreateSelectOptions } from '../../../hooks/useCreateSelectOptions';
import { PersonFormValuesType } from '../graphql/types';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';

type PersonFormProps = {
  personUuid?: string | null;
  pimProfileUuid?: string | null;
  closeForm: () => void;
  personsRole: PersonRole;
  htmlColorsInUse: Array<UsedColors | null>;
};

export const PersonForm = ({
  personUuid,
  closeForm,
  personsRole,
  pimProfileUuid,
  htmlColorsInUse,
}: PersonFormProps) => {
  const { pimAuthClaims } = useAuthClaims();
  const { t } = useTranslation();
  const context = useMemorizedCacheTag('PERSONS');
  const pimProfileContext = useMemorizedCacheTag('PIM_PROFILE');
  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;

  const [{ data: pimProfiles }] = usePimProfilesQuery({
    variables: {
      where: { organization: { uuid: pimAuthClaims.getOrganization().uuid }, uuid: pimProfileUuid },
      options: { sort: [{ lastName: SortDirection.Asc }] },
    },
    context: pimProfileContext,
  });

  const [{ data }] = usePersonsQuery({
    variables: {
      schoolYearUuid: currentSchoolYear?.uuid ?? '',
      where: {
        organizationConnection: {
          node: {
            uuid: pimAuthClaims.getOrganizationUuid(),
          },
        },
        uuid: personUuid,
      },
      contractOptions: {
        sort: [
          {
            validUntil: SortDirection.Desc,
          },
        ],
      },
    },
    context,
  });

  const currentPerson = personUuid ? data?.people[0] : null;
  const currentPimProfile = pimProfileUuid ? pimProfiles?.pimProfiles[0] : null;

  const roomsContext = useMemorizedCacheTag('ROOMS');
  const [{ data: roomsListQuery }] = useRoomsListQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
    },
    context: roomsContext,
  });

  const roomOptions = useCreateSelectOptions(roomsListQuery?.rooms, 'uuid', 'name');

  const initialValue: PersonFormValuesType = useMemo(() => {
    return {
      uuid: currentPerson?.uuid || '',
      pimProfileUuid: currentPerson?.pimProfileUuid || currentPimProfile?.uuid || null,
      personalId: currentPerson?.personalId,
      firstName: currentPerson?.firstName || currentPimProfile?.firstName || '',
      lastName: currentPerson?.lastName || currentPimProfile?.lastName || '',
      fullName: currentPerson?.fullName || currentPimProfile?.fullName || '',
      listName: currentPerson?.listName || currentPimProfile?.listName || '',
      selectName: currentPerson?.selectName || currentPimProfile?.selectName || '',
      displayName: currentPerson?.displayName || currentPimProfile?.displayName || '',
      shortName: currentPerson?.shortName || currentPimProfile?.shortName || '',
      callingName: currentPerson?.callingName,
      prefixName: currentPerson?.prefixName || currentPimProfile?.prefixName || '',
      postfixName: '',
      salutation: currentPerson?.salutation || currentPimProfile?.salutation || '',
      title: currentPerson?.title || currentPimProfile?.title || '',
      email: currentPerson?.email || currentPimProfile?.email || '',
      birthday: currentPerson?.birthday || currentPimProfile?.birthday || null,
      gender: currentPerson?.gender,
      active: isNotEmpty(currentPerson?.active) ? currentPerson?.active : true,
      timetableConfig: currentPerson?.timetableConfig,
      contracts: currentPerson?.contracts ? currentPerson.contracts : [],
      organizationConnection: {
        edges: [],
      },
      qualifications: currentPerson?.qualifications ?? [],
      currentContractOpenEnd: currentPerson?.currentContractOpenEnd ?? [],
      defaultRoom: currentPerson?.defaultRoom?.uuid ?? undefined,
    };
  }, [currentPerson, currentPimProfile]);

  const colorPickerData = colorPickerOptions({
    htmlColorsInUse: htmlColorsInUse,
    currentColor: currentPerson?.timetableConfig?.color ?? '',
    currentColorUsedBy: currentPerson?.displayName,
    forTypename: 'Person',
  });

  const genders = ['male', 'female', 'diverse'];
  const genderOptions: SelectOptionType[] = genders.map((gender) => {
    return { value: gender, label: t(`common.genders.${gender}`) };
  });

  const handleSubmit = async (values: PersonFormValuesType, formHelpers: FormikHelpers<PersonFormValuesType>) => {
    const submitResult = await formSubmit({
      values,
      uuid: values.uuid ?? null,
      pimProfileUuid: values.pimProfileUuid ?? null,
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
      personsRole: personsRole,
      context: context,
    });
    if (!submitResult || submitResult.error) {
      showToast(t(`persons.message.${currentPerson ? 'edit' : 'create'}.error`), { type: 'error' });
    } else {
      showToast(t(`persons.message.${currentPerson ? 'edit' : 'create'}.success`), { type: 'success' });
    }
    formHelpers.resetForm();
    closeForm();
  };

  return (
    <>
      <Formik<PersonFormValuesType>
        validationSchema={validationSchema}
        initialValues={initialValue}
        enableReinitialize
        onSubmit={handleSubmit}
        validateOnChange={false}
        validateOnBlur={true}
      >
        {(formik) => {
          const {
            resetForm,
            values,
            handleChange,
            handleBlur,
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            isSubmitting,
            dirty,
            isValidating,
          } = formik;
          return (
            <Form>
              <Grid useFormGap>
                <GridRow spacingBottom={'none'}>
                  <GridColumn width={2}>
                    <Input
                      label={t('common.salutation')}
                      name={'salutation'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.salutation ?? ''}
                      className={'quarter'}
                      {...(errors.salutation &&
                        touched.salutation && {
                          error: errors.salutation,
                        })}
                    />
                  </GridColumn>

                  <GridColumn width={2}>
                    <Input
                      label={t('common.title')}
                      name={'title'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title ?? ''}
                      className={'quarter'}
                      {...(errors.title &&
                        touched.title && {
                          error: errors.title,
                        })}
                    />
                  </GridColumn>
                  <GridColumn width={4}>
                    <Input
                      label={t('common.firstNames')}
                      name={'firstName'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                      className={'half'}
                      error={errors.firstName}
                    />
                  </GridColumn>
                  <GridColumn width={2} align={'center'}>
                    <Checkbox
                      className={'mt-3 ml-3'}
                      name={`active`}
                      label={t(`common.active.full`)}
                      checked={isNotEmpty(values.active) ? values.active : true}
                      onChange={async (event) => {
                        await setFieldValue(`active`, event.target.checked, true);
                        await setFieldTouched(`active`, true);
                      }}
                    />
                  </GridColumn>
                </GridRow>

                <GridRow spacingBottom={'none'}>
                  <GridColumn width={2}>
                    <Input
                      label={t('common.prefixName')}
                      name={'prefixName'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.prefixName ?? ''}
                      className={'quarter'}
                      {...(errors.prefixName &&
                        touched.prefixName && {
                          error: errors.prefixName,
                        })}
                    />
                  </GridColumn>
                  <GridColumn width={4}>
                    <Input
                      label={t('common.lastName')}
                      name={'lastName'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                      className={'half'}
                      error={errors.lastName}
                    />
                  </GridColumn>
                  <GridColumn width={2}>
                    <Input
                      label={t('common.shortName')}
                      name={'shortName'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.shortName ?? ''}
                      className={'quarter'}
                      {...(errors.shortName &&
                        touched.shortName && {
                          error: errors.shortName,
                        })}
                    />
                  </GridColumn>
                </GridRow>

                <GridRow spacingBottom={'none'}>
                  <GridColumn width={2}>
                    <Select
                      label={t('common.gender')}
                      name={'gender'}
                      onChange={async (value) => {
                        const selectedValue = value as SingleValue<SelectOptionType>;
                        if (selectedValue) {
                          await setFieldValue('gender', selectedValue.value, true);
                        }
                        await setFieldTouched('gender', true);
                      }}
                      onBlur={handleBlur}
                      value={
                        values.gender
                          ? {
                              value: genders.includes(values.gender ?? '') ? values.gender : genders[0],
                              label: values.gender
                                ? values.gender === 'male'
                                  ? t('common.genders.male')
                                  : values.gender === 'female'
                                    ? t('common.genders.female')
                                    : t('common.genders.diverse')
                                : t('common.genders.diverse'),
                            }
                          : {
                              value: '',
                              label: '',
                            }
                      }
                      options={genderOptions}
                      className={'quarter'}
                    />
                  </GridColumn>
                  <GridColumn width={2}>
                    <Input
                      label={t('persons.personalId')}
                      name={'personalId'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.personalId ?? ''}
                      className={'quarter'}
                      {...(errors.personalId &&
                        touched.personalId && {
                          error: errors.personalId,
                        })}
                    />
                  </GridColumn>
                  <GridColumn width={4}>
                    <Input
                      label={t('common.email')}
                      name={'email'}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email ?? ''}
                      className={'half'}
                      {...(errors.email &&
                        touched.email && {
                          error: errors.email,
                        })}
                    />
                  </GridColumn>
                </GridRow>
                <GridRow spacingBottom={'none'}>
                  <GridColumn width={4}>
                    <DatePicker
                      className={'half'}
                      name={'birthday'}
                      label={t('persons.birthday')}
                      onChange={async (date) => {
                        await setFieldValue('birthday', date);
                      }}
                      value={values.birthday ? new Date(values.birthday) : undefined}
                      onBlur={async () => {
                        await setFieldTouched('birthday', true);
                      }}
                      locale={'de'}
                      showMonthYearDropdown={true}
                    />
                  </GridColumn>
                  <GridColumn width={4}>
                    <ColorPicker
                      name='color'
                      label={t('common.color')}
                      className={'half'}
                      options={colorPickerData.groupedColorOptions}
                      maxMenuHeight={300}
                      placeholder=''
                      onChange={async (option) => {
                        await setFieldValue('timetableConfig.color', option?.html ?? '');
                        await setFieldValue('timetableConfig.colorLabel', option?.label ?? '');
                        await setFieldTouched('color', true, false);
                      }}
                      onBlur={handleBlur}
                      defaultValue={colorPickerData.currentColor}
                    />
                  </GridColumn>
                </GridRow>

                {personsRole === PersonRole.Teacher && (
                  <>
                    <GridRow spacingBottom={'none'}>
                      <GridColumn width={12}>
                        <ContractsForm />
                      </GridColumn>
                    </GridRow>
                    <GridRow spacingBottom={'none'}>
                      <GridColumn width={12}>
                        <QualificationsForm subjects={data?.subjects} />
                      </GridColumn>
                    </GridRow>
                  </>
                )}
                <GridRow headline={t('rooms.asStandard')} spacingBottom='none'>
                  <GridColumn width={3}>
                    <Select
                      isSearchable
                      label={t('rooms.title', { count: 1 })}
                      options={roomOptions}
                      name={`defaultRoom`}
                      className={'half'}
                      isClearable
                      value={roomOptions.find((opt) => opt.value === values.defaultRoom)}
                      onChange={async (option) => {
                        const opt = option as SingleValue<SelectOptionType>;
                        await setFieldTouched(`defaultRoom`, true);
                        await setFieldValue(`defaultRoom`, opt?.value);
                      }}
                    ></Select>
                  </GridColumn>
                </GridRow>
              </Grid>

              <ModalBottomButtons
                closeButton={{
                  callback: () => {
                    resetForm();
                    closeForm();
                  },
                  disabled: isSubmitting || !dirty || isValidating,
                }}
                errors={errors}
                isLoading={isSubmitting}
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
