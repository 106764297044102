import { FieldArray, useFormikContext } from 'formik';
import { CurriculumFormType } from '../types';
import { useTranslation } from 'react-i18next';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { useSubjectContainersListQuery } from '../../../types/planung-graphql-client-defs';
import { AddIcon, Button, DeleteIcon } from '@bp/ui-components';
import { LessonFormUnitType } from '../../Lessons/types';
import { v4 as uuid } from 'uuid';
import { LessonUnitFormItem } from '../../Lessons/Forms/LessonUnits/LessonUnitFormItem';

export const CurriculumUnitForm = () => {
  const { values, setFieldTouched } = useFormikContext<CurriculumFormType>();
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const context = useMemorizedCacheTag('SUBJECT_CONTAINER');
  const [{ data }] = useSubjectContainersListQuery({
    variables: {
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
    },
    context,
  });

  const grades = values.curriculumGrades?.flatMap((lc) => lc.grades);

  const subjectContainers = data?.subjectContainers.filter((sc) => {
    if (sc.grades.length === 0) {
      return true;
    }
    return grades ? grades.every((g) => (g ? sc.grades.includes(g) : false)) : false;
  });

  return (
    <>
      <FieldArray name={'lessonUnit'}>
        {(arrayHelpers) => {
          return (
            <>
              <div className='form-group tks__grid'>
                {values.lessonUnit?.map((lessonUnit, index) => {
                  return (
                    <div key={lessonUnit.uuid} className='tks__row'>
                      <div className='tks__col col-xs-9 no-gap'>
                        <LessonUnitFormItem index={index} subjectContainer={subjectContainers} disabled={false} />
                      </div>
                      <div className='tks__col col-xs-3'>
                        <Button
                          className='mt-6 align-end'
                          hierarchy='tertiary'
                          icon={
                            <DeleteIcon
                              onClick={() => {
                                arrayHelpers.remove(index);
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                  );
                })}
                <Button
                  key={values.subject?.uuid}
                  disabled={!values.subject?.uuid}
                  hierarchy={'tertiary'}
                  icon={<AddIcon />}
                  onClick={async () => {
                    if (values.lessonUnit && values.lessonUnit.length > 0) {
                      for (const index in values.lessonUnit) {
                        await setFieldTouched(`lessonUnit.${index}`);
                      }
                    }
                    const lessonUnit: LessonFormUnitType = {
                      uuid: uuid(),
                      count: null,
                      factor: null,
                      duration: null,
                      lessonUnitType: 'subjectHour',
                    };
                    arrayHelpers.push(lessonUnit);
                  }}
                >
                  {t('lessonUnit.add')}
                </Button>
              </div>
            </>
          );
        }}
      </FieldArray>
    </>
  );
};
