import { Form, Formik, FormikHelpers } from 'formik';
import { schema } from './validation/schema';
import { LessonBasicsForm } from './Basics/LessonBasicsForm';
import { LessonClassesForm } from './Classes/LessonClassesForm';
import { LazyLoader } from '@bp/ui-components';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { LessonTeacherForm } from './Teachers/LessonTeacherForm';
import { FormBlockHeader } from '../../Form/FormBlockHeader';
import { LessonRoomSupplyForm } from './RoomSupply/LessonRoomSupplyForm';
import { VersionLessonFormType } from '../types';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';
import { observer } from 'mobx-react-lite';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { TimetableDurationAndCounter } from './DurationAndCounter/TimetableDurationAndCounter';
import { useTimetableLessons } from '../hooks/useTimetableLessons';
import { ModalBottomButtons } from '../../ModalBottomButtons/ModalBottomButtons';
import { showSuccessCreateToast, showSuccessUpdateToast, showUserErrorToast } from '../../../utils/toast';
import { v4 } from 'uuid';

type LessonVersionFormProps = {
  closeForm: () => void;
  lessonUuid?: string | null;
  defaultInitialValues: Partial<VersionLessonFormType>;
  versionUuid: string;
};

export const TimetableLessonForm = observer(
  ({ closeForm, lessonUuid, defaultInitialValues, versionUuid }: LessonVersionFormProps) => {
    const { t } = useTranslation();
    const { pimAuthClaims } = useAuthClaims();
    const schoolYear = useUserConfigContext().selectedSchoolYear;

    const { createLesson, updateLesson, queryLessonsData, lessonsData } = useTimetableLessons({
      organizationUuid: pimAuthClaims.getOrganizationUuid(),
      schoolYearUuid: schoolYear?.uuid ?? '',
      versionUuid: versionUuid,
    });
    const currentLessonsData = lessonsData.find((lesson) => {
      return lesson.uuid === lessonUuid;
    });
    const lesson = queryLessonsData?.lessons.find((lesson) => {
      return lesson.uuid === lessonUuid;
    });

    const initialValues: VersionLessonFormType = lesson
      ? {
          uuid: lesson.uuid,
          subject: {
            uuid: lesson.subject.uuid,
            name: lesson.subject.name,
          },
          lessonClasses: lesson.lessonClassesConnection
            ? lesson.lessonClassesConnection.edges.map(({ node }) => {
                return {
                  uuid: node.uuid,
                  groups: node.groups,
                  class: {
                    uuid: node.class.uuid,
                    name: node.class.name,
                    color: node.class.timetableConfig?.color,
                    grade: node.class.grade,
                  },
                };
              })
            : [
                {
                  uuid: v4(),
                  groups: [],
                  class: {
                    uuid: v4(),
                  },
                },
              ],
          comment: lesson.comment,
          elective: lesson.elective,
          isClassTrip: lesson.isClassTrip,
          teachingLoadEnabled: lesson.teachingLoadEnabled,
          timetableEnabled: lesson.timetableEnabled,
          subTitle: lesson.subTitle,
          roomSupply:
            lesson.roomSupplyConnection &&
            lesson.roomSupplyConnection.edges.map((edge) => ({
              uuid: edge.node.uuid,
              description: edge.properties.descriptions ?? '',
            })),
          teachers: lesson.teachersConnection.edges.map((teacherEdge) => {
            return {
              present: teacherEdge.properties.present,
              teachingLoad: teacherEdge.properties.teachingLoad,
              teachingLoadHours: teacherEdge.properties.teachingLoadHours,
              description: teacherEdge.properties.description,
              writesCertificate: teacherEdge.properties.writesCertificate,
              person: {
                uuid: teacherEdge.node.uuid,
                fullName: teacherEdge.node.fullName,
                displayNameShort: teacherEdge.node.displayNameShort ?? teacherEdge.node.fullName,
                color: teacherEdge.node.timetableConfig?.color,
              },
            };
          }),
          durationCountArray: [
            {
              duration: 1,
              count: 1,
            },
          ],
        }
      : {
          ...defaultInitialValues,
          timetableEnabled: true,
          teachingLoadEnabled: true,
          durationCountArray: [
            {
              duration: 1,
              count: 1,
            },
          ],
        };

    const handleSubmit = async (values: VersionLessonFormType, formikHelpers: FormikHelpers<VersionLessonFormType>) => {
      const errors: Array<Error> = [];
      if (!values.uuid) {
        const createResult = await createLesson(values);
        if (!createResult || createResult.error) {
          errors.push(createResult.error ?? { name: 'error', message: 'error' });
        }
        !errors.length && showSuccessCreateToast();
      } else {
        const updateResult = await updateLesson(values);
        if (!updateResult || updateResult.error) {
          errors.push(updateResult.error ?? { name: 'error', message: 'error' });
        }
        !errors.length && showSuccessUpdateToast();
      }

      if (errors.length) {
        showUserErrorToast({ text: 'error', error: 'error' });
      }

      if (!errors.length) {
        formikHelpers.resetForm();
        closeForm();
      }
    };

    const disabled = currentLessonsData?.editStatus === 'blocked';

    return (
      <Suspense fallback={<LazyLoader transparent forceHeight='40vh' />}>
        <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
          {(formik) => (
            <Form>
              <div className='form-block'>
                <div className={'form-group'}>
                  <LessonBasicsForm status={currentLessonsData?.editStatus} timetableDisabled={true} />
                </div>
              </div>
              <div className='form-block'>
                <FormBlockHeader title={t('classes.title', { count: 2 })} />
                <Suspense fallback={<LazyLoader />}>
                  <LessonClassesForm disabled={disabled} />
                </Suspense>
              </div>
              <div className='form-block'>
                <FormBlockHeader title={t('persons.title', { count: 1 })} />
                <Suspense fallback={<LazyLoader />}>
                  <LessonTeacherForm
                    disabled={disabled}
                    defaults={{ teachingLoad: true, present: true, writesCertificate: true }}
                  />
                </Suspense>
              </div>
              {!formik.values.uuid && (
                <div className='form-block'>
                  <FormBlockHeader title={t('badgeCard.title', { count: 2 })} />
                  <Suspense fallback={<LazyLoader />}>
                    <TimetableDurationAndCounter />
                  </Suspense>
                </div>
              )}
              <div className='form-block'>
                <FormBlockHeader title={t('rooms.stock')} />
                <Suspense fallback={<LazyLoader />}>
                  <LessonRoomSupplyForm key={formik.values.subject?.uuid} disabled={disabled} />
                </Suspense>
              </div>
              <ModalBottomButtons
                closeButton={{
                  text: disabled ? t('common.close') : t('common.cancel'),
                  callback: () => {
                    formik.resetForm();
                    closeForm();
                  },
                }}
                submitButton={{ disabled: disabled }}
                isLoading={formik.isSubmitting}
                errors={formik.errors}
              />
            </Form>
          )}
        </Formik>
      </Suspense>
    );
  },
);
