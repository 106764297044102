import { useTranslation } from 'react-i18next';
import { Tab } from '@bp/ui-components';
import { RoomsTable } from '../../../components/Rooms/Tables/RoomsTable';
import { SettingsBuildingsTable } from '../../../components/Buildings/Tables/SettingsBuildingsTable';
import { Page } from '../../../components/Page/Page';

export const SetupRoomsAndBuildings = () => {
  const { t } = useTranslation();

  return (
    <Page title={null}>
      <Tab
        variant={'large'}
        tabs={[
          {
            title: t('rooms.title', { count: 2 }),
            value: 'rooms',
            content: <RoomsTable />,
          },
          {
            title: t('buildings.title', { count: 2 }),
            value: 'buildings',
            content: <SettingsBuildingsTable />,
          },
        ]}
      />
    </Page>
  );
};
