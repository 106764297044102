import { ReactNode } from 'react';

type FormBlockHeaderTypes = {
  title: string;
  children?: ReactNode;
};

export const FormBlockHeader = ({ title, children }: FormBlockHeaderTypes) => {
  return (
    <div className={'form-block-header'}>
      <div className={'content'}>
        <h3>{title}</h3>
        <div className={'actions'}>{children}</div>
      </div>
      <span>&nbsp;</span>
    </div>
  );
};
