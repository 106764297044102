import { useTranslation } from 'react-i18next';
import { useAuthClaims } from './useAuthClaims';
import { useUserConfigContext } from './useUserConfigContext';
import { useTimetableLessons } from '../components/Lessons/hooks/useTimetableLessons';
import { useLoaderData, useSearchParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { FilterLoaderArgs } from '../utils/routes-loader/filterLoader';
import { isFilterType } from '../utils/typeguards';
import { Tag } from '@bp/ui-components';
import { LessonTableType } from '../components/Lessons/Tables/TimetableVersionLessonsTable';

export const useFilterTags = (versionUuid: string) => {
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const schoolYear = useUserConfigContext().selectedSchoolYear;
  const { queryLessonsData } = useTimetableLessons({
    organizationUuid: pimAuthClaims.getOrganizationUuid(),
    schoolYearUuid: schoolYear?.uuid ?? '',
    versionUuid: versionUuid ?? '',
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const clearSearchParams = useCallback(() => {
    if (searchParams.has('filter')) {
      searchParams.delete('filter');
      setSearchParams(searchParams);
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    clearSearchParams();
  }, [clearSearchParams]);

  const filterConfig = useLoaderData();
  const [_filterConfig, _setFilterConfig] = useState<FilterLoaderArgs | null>(null);

  useEffect(() => {
    if (filterConfig && isFilterType(filterConfig)) {
      _setFilterConfig(filterConfig);
    }
  }, [filterConfig]);

  const subject =
    _filterConfig && _filterConfig.column === 'subject'
      ? queryLessonsData?.subjects.find((s) => s.uuid === _filterConfig?.filterValue)
      : null;
  const classes =
    _filterConfig && _filterConfig.column === 'classes'
      ? queryLessonsData?.classes.find((s) => s.uuid === _filterConfig?.filterValue)
      : null;
  const teacher =
    _filterConfig && _filterConfig.column === 'teachers'
      ? queryLessonsData?.people.find((s) => s.uuid === _filterConfig?.filterValue)
      : null;

  const rooms =
    _filterConfig && _filterConfig.column === 'rooms'
      ? queryLessonsData?.rooms.find((s) => s.uuid === _filterConfig?.filterValue)
      : null;

  const memoFilter = useCallback(
    (data: LessonTableType) => {
      if (_filterConfig && _filterConfig.column === 'subject') {
        return data.subjectUuid === _filterConfig.filterValue;
      }
      if (_filterConfig && _filterConfig.column === 'teachers') {
        return data.teachers.some((t) => t.uuid === _filterConfig.filterValue);
      }
      if (_filterConfig && _filterConfig.column === 'classes') {
        return data.classesUuids.some((c) => c === _filterConfig.filterValue);
      }
      if (_filterConfig && _filterConfig.column === 'rooms') {
        return data.roomsUuids?.some((c) => c === _filterConfig.filterValue);
      }
      return true;
    },
    [_filterConfig],
  );

  const FilterTag = (label: string, value?: string | null) => {
    if (!_filterConfig || !value) return null;
    return (
      <Tag
        closable={true}
        value={`${label}: ${value}`}
        onClose={() => {
          clearSearchParams();
          _setFilterConfig(null);
        }}
      />
    );
  };

  return {
    memoFilter,
    filterTags: [
      FilterTag('subject.name', subject?.name),
      FilterTag(t('classes.title', { count: 1 }), classes?.name),
      FilterTag('teachers.titleSingular', teacher?.selectName),
      FilterTag(t('rooms.title', { count: 1 }), rooms?.name),
    ],
  };
};
