import styles from './NavigationTabs.module.scss';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { HTMLAttributeAnchorTarget, memo } from 'react';

export type NavigationTabsType = {
  title: string;
  path: string;
  active?: boolean;
  disabled?: boolean;
  pathMatchEnd?: boolean;
  className?: string;
  target?: HTMLAttributeAnchorTarget | undefined;
};

export type NavigationTabsProps = {
  tabs: NavigationTabsType[];
  size?: 'm' | 'xxl';
  hideBorder?: boolean;
  className?: string;
};

export const NavigationTabs = memo(({ tabs, size = 'm', hideBorder, className }: NavigationTabsProps) => {
  return (
    <div
      className={classNames(
        styles['navigation-tabs'],
        {
          [styles['size-xxl']]: size === 'xxl',
          [styles['hide-border']]: hideBorder,
        },
        className,
      )}
      role='tablist'
      aria-orientation='vertical'
    >
      {tabs.map((tab, key) => {
        return tab.disabled ? (
          <div key={key} className={classNames(styles.tab, styles.disabled)}>
            {tab.title}
          </div>
        ) : (
          <NavLink
            to={tab.path}
            key={tab.path}
            className={classNames(styles['nav-link'], tab.className)}
            tabIndex={key}
            target={tab.target}
            end={tab.pathMatchEnd}
          >
            {({ isActive }) => {
              return (
                <div
                  className={classNames(styles.tab, {
                    [styles.active]: tab.active !== undefined ? tab.active : isActive,
                  })}
                >
                  {tab.title}
                </div>
              );
            }}
          </NavLink>
        );
      })}
    </div>
  );
});

NavigationTabs.displayName = 'NavigationTabs';
