import React, { FC, useState } from 'react';
import styles from './TimetableCardsForm.module.scss';
import { useTranslation } from 'react-i18next';
import { AddIcon, BadgeCard, BadgeCardWidth, Button, DeleteIcon, Select, SelectOptionType } from '@bp/ui-components';
import { CardType, TimetableCardsType } from '../graphql/types';
import { FieldArray, Form, Formik } from 'formik';
import { SingleValue } from 'react-select';
import {
  _ClassesQuery,
  _LessonClassesQuery,
  _PeopleQuery,
  use_LessonsQuery,
  useCreateCardMutation,
  useDeleteCardsMutation,
} from '../../../types/planung-graphql-client-defs';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { FormikHelpers } from 'formik/dist/types';
import { createArrayOf } from '../../../utils/arrayFunc';
import { observer } from 'mobx-react-lite';
import { maxDivisionAndRemainder } from '../../../utils/helper';
import { ModalBottomButtons } from '../../ModalBottomButtons/ModalBottomButtons';
import { useTimetableStore } from '../../TimetableGrid/TimetableProvider';
import { useLoadBasicData } from '../../../hooks/useLoadBasicData';
import { dayToTranslation } from '../../../utils/dayToTranslation';
import dayjs from 'dayjs';
import classNames from 'classnames';

type LessonsListFormProps = {
  onClose: () => void;
  lessonUuid: string;
  versionUuid: string;
};
type LessonCardsFormType = { cards: CardType[] };

export const TimetableCardsForm: FC<LessonsListFormProps> = observer(({ onClose, lessonUuid, versionUuid }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [, createCard] = useCreateCardMutation();
  const [, deleteCards] = useDeleteCardsMutation();
  const lessonContext = useMemorizedCacheTag('LESSONS');

  const [{ data }] = use_LessonsQuery({
    variables: {
      where: {
        uuid: lessonUuid,
      },
    },
    context: lessonContext,
  });

  const lesson = data?.lessons[0];
  const store = useTimetableStore();

  const { subjectData, lessonClassesData, classesData, teacherData, groupsData } = useLoadBasicData({
    pause: !data,
  });

  const subject = subjectData?.subjects.find((subject) => subject.uuid === lesson?.subject.uuid);

  const lessonClasses =
    lesson?.lessonClassesConnection.edges.map((edge) => {
      return lessonClassesData?.lessonClasses.find((lessonClass) => lessonClass.uuid === edge.node.uuid);
    }) ?? [];

  const groupUuids = lessonClasses.map((lc) => {
    return lc?.groups.map((g) => g.uuid);
  });

  const groups = groupsData?.groups.filter((group) => {
    return groupUuids.some((groupUuid) => groupUuid?.includes(group.uuid)) ?? false;
  });

  const classes = lessonClasses
    .map((lc) => {
      return classesData?.classes.find((c) => c.uuid === lc?.class.uuid);
    })
    .sort((a, b) => {
      return a?.name.localeCompare(b?.name ?? '') ?? 0;
    });

  const teachers = lesson?.teachersConnection.edges.map((t) => {
    return teacherData?.people.find((p) => p.uuid === t.node.uuid) ?? ({} as Pick<_PeopleQuery, 'people'>['people'][0]);
  });
  const originalCards: TimetableCardsType[] = [];

  lesson?.lessonUnitConnection.edges.forEach((lessonUnitEdge) => {
    const lessonUnit = lessonUnitEdge.node;
    const count = lessonUnit.count ? Array.from(Array(Math.abs(lessonUnit.count)), (x, i) => i) : [];
    count.forEach(() => {
      originalCards.push({
        __typename: 'TimetableCard',
        uuid: Math.random().toString(36).substring(7),
        lesson: { __typename: 'Lesson', uuid: lessonUuid },
        duration: lessonUnit.duration ?? 1,
        locked: false,
        rooms: [],
        lessonClasses: lesson.lessonClassesConnection.edges.map((edge) => {
          return { __typename: 'LessonClass', uuid: edge.node.uuid };
        }),
        teachers: lesson.teachersConnection.edges.map((edge) => {
          return { __typename: 'Person', uuid: edge.node.uuid };
        }),
        subject: { __typename: 'Subject', uuid: lesson.subject.uuid },
      });
    });
  });

  if (lesson && lessonClasses && teachers && subject && groups) {
    const cards = store.getCardsByLessonUuid(lesson.uuid);
    const _cards = cards?.sort((card, card2) => {
      if (store.placedCardsSet.has(card.uuid) && !store.placedCardsSet.has(card2.uuid)) {
        return 1;
      } else if (!store.placedCardsSet.has(card.uuid) && store.placedCardsSet.has(card2.uuid)) {
        return -1;
      } else {
        return 0;
      }
    });

    const currentVersion = store.getCurrentVersion();

    const initialValue: LessonCardsFormType = {
      cards:
        _cards?.map((card) => {
          return {
            ...card,
          };
        }) ?? [],
    };

    const handleSubmit = async (values: LessonCardsFormType, formikHelpers: FormikHelpers<LessonCardsFormType>) => {
      if (currentVersion) {
        setLoading(true);

        const newCards = values.cards.filter((card) => !store.placedCardsSet.has(card.uuid));
        const cardUuidsToRemoveUuids = initialValue.cards
          .filter((card) => !store.placedCardsSet.has(card.uuid))
          .map((card) => card.uuid);

        if (cardUuidsToRemoveUuids.length > 0) {
          await deleteCards(
            { where: { uuid_IN: cardUuidsToRemoveUuids } },
            { additionalTypenames: ['Lesson', 'TimetableCard', 'ClassGridCard'] },
          );
        }
        if (newCards.length > 0) {
          const promises: Promise<unknown>[] = [];

          newCards.forEach((newCard) => {
            promises.push(
              createCard(
                { duration: Number(newCard.duration), lessonUuid: lesson.uuid, versionUuid: currentVersion?.uuid },
                { additionalTypenames: ['Lesson', 'TimetableCard', 'ClassGridCard'] },
              ),
            );
          });

          await Promise.all(promises);
        }
        onClose();
        setLoading(false);
        formikHelpers.resetForm();
      }
      setLoading(false);
    };

    const options: SelectOptionType[] = Array.from({ length: 6 }, (_, index) => ({
      label: t('subjectHours.subjectHourCount', { count: index + 1 }),
      value: index + 1,
    }));

    let originalSumDuration = 0;
    originalCards.forEach((card) => {
      if (card.duration) {
        originalSumDuration = originalSumDuration + card.duration;
      }
    });

    const defaultCard: CardType = {
      isSubjectContainer: false,
      __typename: 'TimetableCard',
      uuid: '',
      end: '',
      endTime: '',
      start: '',
      startTime: '',
      teachers: teachers,
      duration: 1,
      lesson: lesson,
      locked: false,
      rooms: [],
      classGridCards: [],
      lessonClasses:
        (lessonClasses.filter((lc) => lc) as Pick<_LessonClassesQuery, 'lessonClasses'>['lessonClasses']) ?? [],
      classes: (classes.filter((c) => c) as Pick<_ClassesQuery, 'classes'>['classes']) ?? [],
      subject: subject,
      groups: groups,
      badgeCardTextColor: '',
      badgeCardRows: [],
    };

    const cardInfos = cards ? cards[0] : null;

    const placedInfoClasses = classNames(styles['placed-info'], {});
    return (
      <>
        <Formik initialValues={initialValue} onSubmit={handleSubmit} enableReinitialize={true}>
          {({ setFieldValue, values, isSubmitting, resetForm, dirty }) => {
            const placedCards = values.cards.filter((card) => {
              return store.placedCardsSet.has(card.uuid);
            });
            const cardsForBulkEdit = values.cards.filter((card) => !store.placedCardsSet.has(card.uuid));

            const setCards = async (_cards: CardType[]) => {
              await setFieldValue('cards', [..._cards, ...placedCards]);
            };
            const doubleCardIndex = cardsForBulkEdit.findIndex((card) => card.duration === 2);
            const cardsForBulkEditDuration = cardsForBulkEdit.reduce((sum, card) => sum + (card.duration || 1), 0);

            return (
              <Form>
                <div className={styles['lessons-list-form']}>
                  <div className={'tks__grid  mb-8'}>
                    <div className={'tks__row '}>
                      <div className={'tks__col '}>{`${t('subject.title', { count: 1 })}: ${subject.name}`}</div>
                      <div className={'tks__col'}>
                        {`${t('persons.title', { count: 1 })}: ${teachers.map(({ displayNameShort }) => displayNameShort).join(', ')}`}
                      </div>
                      <div className={'tks__col'}>
                        {`${t('classes.title', { count: 2 })}: ${classes.map((c) => c?.shortName).join(', ')}`}
                      </div>
                    </div>
                    <div className={'tks__row'}>
                      <div className={'tks__col'}>
                        <div className={styles['planned']}>
                          {t('lesson.planned', {
                            planned: originalSumDuration,
                          })}
                        </div>
                      </div>
                      <div className={'tks__col'}>
                        <div className={styles['given']}>
                          {t('lesson.given', {
                            given: values.cards.reduce(
                              (totalDuration, card) => totalDuration + (card.duration ?? 0),
                              0,
                            ),
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles['form-items']}>
                    <div className={'tks__grid'}>
                      <div className={'tks__row'}>
                        <Button
                          hierarchy={'secondary'}
                          disabled={!cardsForBulkEdit.some((card) => card.duration && card.duration > 1)}
                          onClick={async () => {
                            await setCards(createArrayOf(cardsForBulkEditDuration, defaultCard));
                          }}
                        >
                          {t('subjectHour.allSingle')}
                        </Button>
                        <Button
                          hierarchy={'secondary'}
                          disabled={
                            !cardsForBulkEdit.some((card) => card.duration && card.duration > 1) ||
                            doubleCardIndex === -1
                          }
                          onClick={async () => {
                            const newCards = [
                              ...cardsForBulkEdit.slice(0, doubleCardIndex),
                              defaultCard,
                              defaultCard,
                              ...cardsForBulkEdit.slice(doubleCardIndex + 1),
                            ];
                            await setCards(newCards);
                          }}
                        >
                          {t('subjectHour.seperateDouble')}
                        </Button>

                        <Button
                          disabled={
                            cardsForBulkEditDuration < 2 ||
                            (cardsForBulkEdit.length === 1 && cardsForBulkEditDuration <= 2) ||
                            (cardsForBulkEdit.length > 1 && cardsForBulkEdit.every((c) => c.duration === 2))
                          }
                          hierarchy={'secondary'}
                          onClick={async () => {
                            const [quotient, rest] = maxDivisionAndRemainder(cardsForBulkEditDuration, 2);
                            const newDoubleCards = createArrayOf(quotient, { ...defaultCard, duration: 2 });
                            const otherCards = createArrayOf(rest, { ...defaultCard, duration: 1 });
                            await setCards([...newDoubleCards, ...otherCards]);
                          }}
                        >
                          {t('subjectHour.maxDouble')}
                        </Button>
                        {!lesson?.onlyInTimetableVersion && (
                          <Button
                            hierarchy={'secondary'}
                            disabled={placedCards.length > 0}
                            onClick={async () => {
                              await setFieldValue('cards', originalCards);
                            }}
                          >
                            {t('lesson.reset')}
                          </Button>
                        )}
                      </div>
                    </div>
                    <div className={`${styles['items-wrapper']} tks__grid no-gap`}>
                      <FieldArray name={'cards'}>
                        {(arrayHelpers) => {
                          return (
                            <>
                              {values.cards
                                .sort((card, card2) => {
                                  const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
                                  const _placedDay = store.placedCardsDay.get(card.uuid);
                                  const _placedDay2 = store.placedCardsDay.get(card2.uuid);
                                  const day = _placedDay ? days.indexOf(_placedDay) : -1;
                                  const day2 = _placedDay2 ? days.indexOf(_placedDay2) : -1;
                                  return day - day2;
                                })
                                .map((card, index) => {
                                  const _placedDay = store.placedCardsDay.get(card.uuid);
                                  const placedTimeGridEntries = store.placedCardsTimeGridEntries.get(card.uuid);
                                  let startTime = '';
                                  let endTime = '';
                                  let startName = '';

                                  if (placedTimeGridEntries && placedTimeGridEntries.length > 0) {
                                    const start = store.timeGridEntries?.find(
                                      (entry) => entry.uuid === placedTimeGridEntries[0],
                                    );
                                    const end = store.timeGridEntries?.find(
                                      (entry) => entry.uuid === placedTimeGridEntries[placedTimeGridEntries.length - 1],
                                    );
                                    startName = start ? start.name : '';
                                    startTime = start ? `${dayjs(start.start).format('HH:mm')}` : '';
                                    endTime = end ? `${dayjs(end.end).format('HH:mm')}` : '';
                                  }

                                  return (
                                    <div className='tks__row big-gap' key={index}>
                                      <div className='tks__col col-xs-4'>
                                        <Select
                                          options={options}
                                          disabled={card.locked || !!card.weekday}
                                          value={options.find((o) => o.value === card.duration)}
                                          onChange={async (option) => {
                                            const opt = option as SingleValue<SelectOptionType>;
                                            await setFieldValue(`cards.${index}`, { ...card, duration: opt?.value });
                                          }}
                                          name={`cards.${index}`}
                                          label={`${t('badgeCard.title', { count: 1 })} ${index + 1}`}
                                        ></Select>
                                      </div>
                                      <div className='tks__col col-xs-4 align-center mt-2'>
                                        <div className={'tks__row '}>
                                          {store.lockedCards.get(card.uuid) ? (
                                            <div className={placedInfoClasses}>{t('card.locked')}</div>
                                          ) : store.placedCardsSet.has(card.uuid) ? (
                                            <div className={placedInfoClasses}>
                                              {t('card.placed')}: {_placedDay && dayToTranslation(_placedDay)}{' '}
                                              {startTime} bis {endTime} ({startName})
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </div>
                                      <div className='tks__col col-xs-2 align-center mt-2'>
                                        <div className={'tks__row justify-end'}>
                                          {cardInfos && (
                                            <BadgeCard
                                              label={card.subject?.shortName ?? ''}
                                              width={card.duration as BadgeCardWidth}
                                              rows={cardInfos.badgeCardRows}
                                              color={cardInfos.badgeCardTextColor}
                                              count={1}
                                            />
                                          )}
                                        </div>
                                      </div>
                                      <div className='tks__col col-xs-2 align-center mt-2'>
                                        <div className={'tks__row justify-end'}>
                                          <Button
                                            hierarchy='tertiary'
                                            disabled={store.placedCardsSet.has(card.uuid)}
                                            onClick={async () => {
                                              await arrayHelpers.remove(index);
                                            }}
                                            icon={<DeleteIcon />}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              <Button
                                hierarchy={'tertiary'}
                                icon={<AddIcon />}
                                onClick={async () => {
                                  await setFieldValue('cards', [...cardsForBulkEdit, defaultCard, ...placedCards]);
                                }}
                              >
                                {t('badgeCard.title', { count: 1 })}
                              </Button>
                            </>
                          );
                        }}
                      </FieldArray>
                    </div>
                  </div>
                  <ModalBottomButtons
                    closeButton={{
                      callback: () => {
                        resetForm();
                        onClose();
                      },
                      text: t('common.cancelChanges'),
                    }}
                    submitButton={{
                      disabled: isSubmitting || !dirty,
                    }}
                    isLoading={loading}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }
  return <></>;
});
