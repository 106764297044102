import {
  AddIcon,
  AlertDialog,
  Button,
  DeleteIcon,
  Grid,
  GridColumn,
  GridRow,
  Input,
  Select,
  SelectOptionType,
} from '@bp/ui-components';
import { FieldArray, getIn, isEmptyArray, isObject, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { SingleValue } from 'react-select';
import { useCreateSelectOptions } from '../../../hooks/useCreateSelectOptions';
import { PersonDataType, PersonFormSubjects } from '../graphql/types';
import styles from './ContractsForm.module.scss';

export const QualificationsForm = ({ subjects }: { subjects?: PersonFormSubjects }) => {
  const { values, errors, touched, setFieldValue, setFieldTouched } = useFormikContext<PersonDataType>();
  const { t } = useTranslation();
  const subjectsOptions = useCreateSelectOptions(subjects, 'uuid', 'name');

  return (
    <>
      <FieldArray name={'qualifications'}>
        {(arrayHelpers) => {
          const QualificationsErrors = () =>
            typeof errors.qualifications === 'string' ? (
              <div className={'error-message'}>{errors.qualifications}</div>
            ) : (
              <></>
            );
          return (
            <Grid className={`${styles['qualifications']}`} useFormGap>
              <GridRow headline={t('qualifications.title', { count: 1 })}>
                <Button
                  hierarchy={'tertiary'}
                  className={'mb-4'}
                  icon={<AddIcon />}
                  onClick={async () => {
                    if (values.qualifications.length > 0) {
                      for (const index in values.qualifications) {
                        await setFieldTouched(`qualifications.${index}`);
                      }

                      if (!isObject(errors.qualifications) || isEmptyArray(errors.qualifications)) {
                        arrayHelpers.insert(0, { subject: {} });
                      }
                    } else {
                      arrayHelpers.insert(0, { subject: {} });
                    }
                  }}
                >
                  {t('qualifications.add')}
                </Button>
              </GridRow>
              {values.qualifications.map((qualification, index) => {
                return (
                  <Grid key={index}>
                    <GridRow>
                      <GridColumn width={3}>
                        <Select
                          name={`qualifications.${index}.subject`}
                          options={subjectsOptions}
                          isSearchable
                          value={
                            subjectsOptions.find((s) => {
                              return qualification.subject ? qualification.subject.uuid === s.value : [];
                            }) ?? []
                          }
                          onChange={(option) => {
                            const opt = option as SingleValue<SelectOptionType>;
                            setFieldTouched(`qualifications.${index}.subject`, true);
                            setFieldValue(
                              `qualifications.${index}.subject`,
                              subjects?.find((s) => opt?.value && s.uuid === opt.value),
                            );
                          }}
                          label={t('qualifications.subject')}
                          error={
                            getIn(touched, `qualifications.${index}`) &&
                            getIn(errors, `qualifications.${index}.subject.uuid`)
                              ? getIn(errors, `qualifications.${index}.subject.uuid`)
                              : undefined
                          }
                        />
                      </GridColumn>
                      <GridColumn width={3}>
                        <Input
                          type={'number'}
                          name={`qualifications.${index}.minGrade`}
                          onChange={(event) => {
                            setFieldValue(
                              `qualifications.${index}.minGrade`,
                              event.target.value === '' || isNaN(+event.target.value) ? null : +event.target.value,
                            );
                            setFieldTouched(`qualifications.${index}.minGrade`, true);
                          }}
                          value={`${values.qualifications[index].minGrade}`}
                          placeholder={t('qualifications.placeholder.minGrade')}
                          label={t('qualifications.minGrade')}
                          error={
                            getIn(touched, `qualifications.${index}`) &&
                            getIn(errors, `qualifications.${index}.minGrade`)
                              ? getIn(errors, `qualifications.${index}.minGrade`)
                              : undefined
                          }
                        ></Input>
                      </GridColumn>
                      <GridColumn width={3}>
                        <Input
                          type={'number'}
                          name={`qualifications.${index}.maxGrade`}
                          onChange={(event) => {
                            setFieldValue(
                              `qualifications.${index}.maxGrade`,
                              event.target.value === '' || isNaN(+event.target.value) ? null : +event.target.value,
                            );
                            setFieldTouched(`qualifications.${index}.maxGrade`, true);
                          }}
                          value={`${values.qualifications[index].maxGrade}`}
                          placeholder={t('qualifications.placeholder.maxGrade')}
                          label={t('qualifications.maxGrade')}
                          error={
                            getIn(touched, `qualifications.${index}`) &&
                            getIn(errors, `qualifications.${index}.maxGrade`)
                              ? getIn(errors, `qualifications.${index}.maxGrade`)
                              : undefined
                          }
                        ></Input>
                      </GridColumn>
                      <GridColumn width={3}>
                        <Input
                          type={'number'}
                          name={`qualifications.${index}.maxTeachingLoad`}
                          onChange={(event) => {
                            setFieldValue(
                              `qualifications.${index}.maxTeachingLoad`,
                              event.target.value === '' || isNaN(+event.target.value) ? null : +event.target.value,
                            );
                            setFieldTouched(`qualifications.${index}.maxTeachingLoad`, true);
                          }}
                          value={`${values.qualifications[index].maxTeachingLoad}`}
                          placeholder={t('qualifications.placeholder.maxTeachingLoad')}
                          label={t('qualifications.maxTeachingLoad')}
                          error={
                            getIn(touched, `qualifications.${index}`) &&
                            getIn(errors, `qualifications.${index}.maxTeachingLoad`)
                              ? getIn(errors, `qualifications.${index}.maxTeachingLoad`)
                              : undefined
                          }
                        ></Input>
                      </GridColumn>
                      <GridColumn width={2}>
                        <div className={'ml-3 pt-4'}>
                          <AlertDialog
                            title={t('qualifications.deleteConfirm')}
                            confirmText={t('common.confirmDelete')}
                            cancelText={t('common.cancelDelete')}
                            onConfirm={() => {
                              arrayHelpers.remove(index);
                            }}
                            trigger={<Button hierarchy='tertiary' type='button' icon={<DeleteIcon />}></Button>}
                          />
                        </div>
                      </GridColumn>
                    </GridRow>
                  </Grid>
                );
              })}
              <GridRow>
                <QualificationsErrors />
              </GridRow>
            </Grid>
          );
        }}
      </FieldArray>
    </>
  );
};
