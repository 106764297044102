import {
  Button,
  DotsHorizontalIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  Row,
  RowSelectionState,
  Table,
  TableColumns,
} from '@bp/ui-components';
import { useTranslation } from 'react-i18next';
import { AbsenceReason } from '../../../utils/absenceReasons';
import { useState } from 'react';
import { useColumnsSort } from '../../../hooks/useColumnsSort';
import { useHiddenColumns } from '../../../hooks/useHiddenColumns';

type SubstitutionSubjectsTableProps = {
  className?: string | undefined;
};

type SubstitutionsSubjectsTableType = {
  output: boolean;
  start: Date;
  end: Date;
  classShortName: string;
  groupShortName: string;
  subjectShortName: string;
  missingTeacherShortName: string;
  teachersShortName: string;
  roomsShortName: string;
  action: string;
  reason: AbsenceReason;
  comment: string;
};

export const SubstitutionSubjectsTable = ({ className }: SubstitutionSubjectsTableProps) => {
  const { t } = useTranslation();

  const [selected, setSelected] = useState<RowSelectionState>({ 0: true });
  const { sorting, saveSorting } = useColumnsSort('substitution-subjects');
  const { columnVisibility, saveColumnVisibility } = useHiddenColumns('substitution-subjects', {});

  const substitutionSubjects: SubstitutionsSubjectsTableType[] = [];

  const columns: TableColumns<SubstitutionsSubjectsTableType>[] = [
    {
      id: 'timeframe',
      header: t('common.time'),
    },
    {
      id: 'class',
      header: t('classes.title', { count: 1 }),
    },
    {
      id: 'group',
      header: t('groups.title', { count: 1 }),
    },
    {
      id: 'subject',
      header: t('subject.title', { count: 1 }),
    },
    {
      id: 'rooms',
      header: t('rooms.title', { count: 2 }),
    },
    {
      id: 'missingTeacher',
      header: t('substitutions.missing'),
    },
    {
      id: 'substitution',
      header: t('substitutions.title', { count: 1 }),
    },
    {
      id: 'action',
      header: t('substitutions.action'),
    },
    {
      id: 'reason',
      header: t('absences.reason'),
    },
    {
      id: 'comment',
      header: t('common.comment'),
    },
  ];

  const menu = (row: Row<SubstitutionsSubjectsTableType>): DropdownMenuItem[] => {
    return [
      {
        label: t('substitutions.substitute'),
      },
      {
        label: t('substitutions.relocate'),
      },
      {
        label: t('substitutions.merge'),
      },
      {
        label: t('substitutions.independentWork'),
      },
      {
        label: t('substitutions.cancel'),
      },
      {
        type: 'ruler',
      },
      {
        label: t('substitutions.reset'),
        color: 'error',
      },
    ];
  };

  return (
    <Table<SubstitutionsSubjectsTableType>
      className={className}
      columns={columns}
      data={substitutionSubjects}
      showBorderRadius
      showShadow
      showSelect
      rowSelection={selected}
      onRowSelectionChange={setSelected}
      showSelected
      showSort
      sorting={sorting}
      onSortingChange={saveSorting}
      showVisibility
      columnVisibility={columnVisibility}
      onColumnVisibilityChange={saveColumnVisibility}
      lastColWidth='36px'
      lastCol={(row) => {
        return (
          <Dropdown trigger={<Button hierarchy='secondary' icon={<DotsHorizontalIcon />} />}>
            <DropdownMenu data={menu(row)} />
          </Dropdown>
        );
      }}
    />
  );
};
