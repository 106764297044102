import {
  Button,
  ButtonGroup,
  DotsHorizontalIcon,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  EditIcon,
  Modal,
  Row,
  Table,
  TableColumns,
} from '@bp/ui-components';
import { observer } from 'mobx-react-lite';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useColumnsSort } from '../../hooks/useColumnsSort';
import { useHiddenColumns } from '../../hooks/useHiddenColumns';
import dayjs from 'dayjs';
import { useUserConfigContext } from '../../hooks/useUserConfigContext';
import { useAuthClaims } from '../../hooks/useAuthClaims';
import { TeachingBlockOverviewModal } from '../TeachingBlockVersion/TeachingBlockVersionsOverview/TeachingBlockOverviewModal';
import { TeachingBlockForm } from './Forms/TeachingBlockForm';
import {
  use_SchoolYearsQuery,
  use_SubjectContainersQuery,
  use_TeachingBlocksQuery,
  use_UpdateTeachingBlocksMutation,
  useCreateBackendTeachingBlockVersionMutation,
} from '../../types/planung-graphql-client-defs';
import { useLoadBasicData } from '../../hooks/useLoadBasicData';
import { showSuccessCreateToast, showUserErrorToast } from '../../utils/toast';

type TeachingBlocksTableType = {
  subjectContainerName: string;
  subjectContainerUuid: string;
  versionsCount: number;
  schoolYear: string;
  grades: string;
  gradeGroups: string;
  updatedAt: string;
  description: string;
  draft: boolean;
  uuid: string;
  comment: string;
};

export const TeachingBlocksTable = observer(() => {
  const { pimAuthClaims } = useAuthClaims();
  const { t } = useTranslation();
  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;

  const { columnVisibility, saveColumnVisibility } = useHiddenColumns('teachingBlocksTable', {
    gradeGroups: false,
  });
  const { sorting, saveSorting } = useColumnsSort('teachingBlocksTable');

  const [{ data: schoolYearData }] = use_SchoolYearsQuery();

  const [activeLoading, setActiveLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [, setShowContextMenu] = useState(true);

  const [currentSubjectContainerUuid, setCurrentSubjectContainerUuid] = useState<string | null>(null);

  const [showVersionModal, setShowVersionModal] = useState(false);

  const versionCreateContext = useMemo(() => {
    return {
      additionalTypenames: ['TeachingBlockVersion', 'TeachingBlock', 'SubjectContainer'],
    };
  }, []);

  const teachingBlockContext = useMemo(() => {
    return {
      additionalTypenames: ['TeachingBlock'],
    };
  }, []);

  const [{ data: subjectContainersData }] = use_SubjectContainersQuery({});

  const filteredSubjectContainersData = useMemo(() => {
    return subjectContainersData?.subjectContainers.filter((sc) => {
      const scStartSchoolYear = schoolYearData?.schoolYears.find((sy) => sy.uuid === sc.validFrom?.uuid);
      const scEndSchoolYear = schoolYearData?.schoolYears.find((sy) => sy.uuid === sc.validUntil?.uuid);

      if (!scEndSchoolYear && scStartSchoolYear && currentSchoolYear) {
        return currentSchoolYear?.start >= scStartSchoolYear?.start;
      }

      return currentSchoolYear?.start && currentSchoolYear?.end
        ? currentSchoolYear?.start >= scStartSchoolYear?.start && currentSchoolYear?.end <= scEndSchoolYear?.end
        : false;
    });
  }, [subjectContainersData?.subjectContainers, schoolYearData?.schoolYears, currentSchoolYear]);

  const [{ data: teachingBlocksData }] = use_TeachingBlocksQuery({
    variables: {
      where: {
        schoolYear: {
          uuid: currentSchoolYear?.uuid,
        },
      },
    },
    context: teachingBlockContext,
    pause: !currentSchoolYear,
  });

  const [, updateTeachingBlock] = use_UpdateTeachingBlocksMutation();
  const [, createTeachingBlockVersionMutation] = useCreateBackendTeachingBlockVersionMutation();

  const { gradeGroupData } = useLoadBasicData({ pause: !teachingBlocksData });

  const memoizedData = useMemo(
    (): TeachingBlocksTableType[] =>
      filteredSubjectContainersData?.map((sc) => {
        const teachingBlock = teachingBlocksData?.teachingBlocks?.find((tb) => tb.subjectContainer.uuid === sc.uuid);
        return {
          subjectContainerName: sc.name,
          subjectContainerUuid: sc.uuid,
          versionsCount: teachingBlock?.versions.length ?? 0,
          updatedAt: teachingBlock?.updatedAt ? dayjs(teachingBlock?.updatedAt).format('DD.MM.YYYY - HH:mm') : '-',
          description: teachingBlock?.comment ?? '',
          draft: teachingBlock?.draft ?? true,
          uuid: teachingBlock?.uuid ?? '',
          comment: teachingBlock?.comment ?? '',
          schoolYear: currentSchoolYear?.shortName ?? '',
          grades: sc?.grades.sort((a, b) => a - b).join(', ') ?? '',
          gradeGroups:
            sc?.gradeGroup
              .map((gg) => {
                return gradeGroupData?.gradeGroups.find((g) => g.uuid === gg.uuid)?.shortName;
              })
              .sort()
              .join(', ') ?? '',
        };
      }) ?? [],
    [
      gradeGroupData?.gradeGroups,
      currentSchoolYear?.shortName,
      subjectContainersData?.subjectContainers,
      teachingBlocksData?.teachingBlocks,
    ],
  );

  const tableColumns: TableColumns<TeachingBlocksTableType>[] = useMemo(() => {
    return [
      {
        header: t('subjectContainer.title', { count: 1 }),
        accessorKey: 'subjectContainerName',
        id: 'subjectContainerName',
        size: 200,
        canExpand: true,
      },
      {
        header: t('common.schoolYear'),
        accessorKey: 'schoolYear',
        id: 'schoolYear',
        size: 100,
        canExpand: true,
      },
      {
        header: t('common.grades'),
        accessorKey: 'grades',
        id: 'grades',
        size: 100,
        canExpand: true,
      },
      {
        header: t('gradeGroups.title', { count: 2 }),
        accessorKey: 'gradeGroups',
        id: 'gradeGroups',
        size: 100,
        canExpand: true,
      },
      {
        header: t('common.comment'),
        id: 'description',
        accessorKey: 'description',
        size: 100,
        canExpand: true,
      },
      {
        header: t('timetableDraft.versionCount'),
        id: 'versionsCount',
        accessorKey: 'versionsCount',
        size: 100,
      },
      {
        header: t('common.updatedAt'),
        id: 'updatedAt',
        accessorKey: 'updatedAt',
        type: 'date',
        size: 150,
      },
    ];
  }, [t]);

  const handleEdit = (row: Row<TeachingBlocksTableType>) => {
    setCurrentSubjectContainerUuid(row.original.subjectContainerUuid);
    setIsModalOpen(true);
  };

  function closeModal() {
    setCurrentSubjectContainerUuid(null);
    setIsModalOpen(false);
  }

  function handleShowVersionModal(row: Row<TeachingBlocksTableType>) {
    setCurrentSubjectContainerUuid(row.original.subjectContainerUuid);
    setShowVersionModal(true);
  }

  function handleHideVersionModal() {
    setCurrentSubjectContainerUuid(null);
    setShowVersionModal(false);
  }

  const handleDraftChange = useCallback(
    async (uuid: string, value: boolean) => {
      setShowContextMenu(false);
      await updateTeachingBlock({
        where: {
          uuid: uuid,
        },
        update: {
          draft: value,
        },
      });
      setShowContextMenu(true);
    },
    [updateTeachingBlock],
  );

  const createActionItems = useCallback(
    (row: Row<TeachingBlocksTableType>): DropdownMenuItem[] => {
      return [
        {
          label: t('common.active.full'),
          type: 'switch',
          disabled: activeLoading || row.original.versionsCount === 0,
          value: !row.original.draft,
          onValueChange: async () => {
            setActiveLoading(true);
            await handleDraftChange(row.original.uuid, !row.original.draft);
            setActiveLoading(false);
          },
        },
      ];
    },
    [t, activeLoading, handleDraftChange],
  );

  async function onVersionAdd({ subjectContainerUuid }: { subjectContainerUuid: string }) {
    setActiveLoading(true);
    const response = await createTeachingBlockVersionMutation(
      {
        organizationUuid: pimAuthClaims.getOrganizationUuid(),
        schoolYearUuid: currentSchoolYear?.uuid ?? '',
        subjectContainerUuid: subjectContainerUuid,
      },
      versionCreateContext,
    );
    response.error ? showUserErrorToast({ error: response.error }) : showSuccessCreateToast();
    setActiveLoading(false);
  }

  return (
    <>
      <Table<TeachingBlocksTableType>
        showBorderRadius
        showShadow
        canScroll
        minHeight={1100}
        breakpoint={null}
        showVisibility
        showSort={true}
        columnVisibility={columnVisibility}
        onColumnVisibilityChange={saveColumnVisibility}
        sorting={sorting}
        onSortingChange={saveSorting}
        columns={tableColumns}
        data={memoizedData}
        showActionBar
        actionBarSettings={{ showAddButton: true }}
        isOnWhite={false}
        printerSettings={{
          headline: pimAuthClaims.getProfile()?.organization.name,
          subline: `${t('teachingBlockDraft.title', { count: 2 })} - ${t('common.schoolYear')} ${currentSchoolYear?.shortName}`,
          filename: `${t('teachingBlockDraft.title', { count: 2 })}_${currentSchoolYear?.shortName}`,
        }}
        lastColWidth='160px'
        lastCol={(row) => {
          if (row.original.versionsCount === 0) {
            return (
              <Button
                isLoading={activeLoading}
                onClick={() => onVersionAdd({ subjectContainerUuid: row.original.subjectContainerUuid })}
                hierarchy='secondary'
              >
                {t('teachingBlock.add')}
              </Button>
            );
          }

          return (
            <ButtonGroup className='ml-4'>
              <Button hierarchy={'secondary'} onClick={() => handleShowVersionModal(row)}>
                {t('pinboard.versions')}
              </Button>
              <Button
                hierarchy={'secondary'}
                disabled={row.original.versionsCount === 0}
                onClick={() => {
                  handleEdit(row);
                }}
                icon={<EditIcon className='small' />}
              />
              <Dropdown
                noPadding
                trigger={<Button hierarchy='secondary' icon={<DotsHorizontalIcon className='small' />} />}
              >
                <DropdownMenu data={createActionItems(row)} />
              </Dropdown>
            </ButtonGroup>
          );
        }}
      />

      <Modal isOpen={isModalOpen} onRequestClose={closeModal} title={t('teachingBlockDraft.edit')}>
        {currentSubjectContainerUuid && (
          <TeachingBlockForm onClose={closeModal} currentSubjectContainerUuid={currentSubjectContainerUuid} />
        )}
      </Modal>

      {currentSubjectContainerUuid && (
        <TeachingBlockOverviewModal
          currentSubjectContainerUuid={currentSubjectContainerUuid}
          isOpen={showVersionModal}
          onClose={() => handleHideVersionModal()}
        />
      )}
    </>
  );
});
