import { Modal } from '@bp/ui-components';
import { SchoolEventForm } from './SchoolEventForm';
import { useTranslation } from 'react-i18next';

type SchoolEventModalProps = {
  selectedEventUuid: string | null;
  isOpen: boolean;
  onClose: () => void;
};

export const SchoolEventModal = ({ selectedEventUuid, isOpen, onClose }: SchoolEventModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={selectedEventUuid ? t('schoolEvents.edit') : t('schoolEvents.add')}
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <SchoolEventForm onClose={onClose} eventUuid={selectedEventUuid} />
    </Modal>
  );
};
