import { Card, Grid, GridColumn, GridRow, Table } from '@bp/ui-components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TeachingLoadReportsTable } from './TeachingLoadReportsTable';
import { TeachingLoadReportType } from '@bp/planung-graphql-types';

type TeachingLoadReportProps = {
  tableData: {
    id: TeachingLoadReportType | null;
    name: string;
  }[];
};

export const TeachingLoadReports = ({ tableData }: TeachingLoadReportProps) => {
  const { t } = useTranslation();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const tableColumns = [
    {
      header: '',
      id: 'name',
      accessorKey: 'name',
      canExpand: true,
    },
  ];

  const [headline, setHeadline] = useState('');

  useEffect(() => {
    setHeadline(tableData[selectedIndex].name);
  }, [selectedIndex, tableData]);

  return (
    <Grid useFormGap>
      <GridRow mobileGap={'32px'}>
        <GridColumn width={6}>
          <Card header={{ headline: t('timetable.title', { count: 2 }) }} contentPadding={'none'}>
            <Table
              data={tableData}
              columns={tableColumns}
              onRowClick={(e, row) => {
                setSelectedIndex(parseInt(row.id));
              }}
              lastColWidth={'80px'}
              showRowHover
              rowSelection={{ [selectedIndex]: true }}
              showSelected
            />
          </Card>
        </GridColumn>
        <GridColumn width={6}>
          <Card
            header={{
              headline,
            }}
            contentPadding={'none'}
          >
            <div className={'p-5'}>
              {tableData[selectedIndex].id && (
                <TeachingLoadReportsTable scenarioName={'Standard'} subType={tableData[selectedIndex].id} />
              )}
            </div>
            {!tableData[selectedIndex].id && <div className={'p-5'}>{t('common.planned')}</div>}
          </Card>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};
