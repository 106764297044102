import { useTranslation } from 'react-i18next';
import { FieldArray, Form, Formik, getIn } from 'formik';
import { FormikHelpers } from 'formik/dist/types';
import { Button, Checkbox, Input, Select, SelectOptionType } from '@bp/ui-components';
import { useCreateSelectOptions } from '../../../hooks/useCreateSelectOptions';
import { FormBlockHeader } from '../../Form/FormBlockHeader';
import React from 'react';
import { SingleValue } from 'react-select';
import { schema } from './schema';
import { RoomsFormInitialValuesType, useRooms } from '../useRooms';
import { showSuccessCreateToast, showSuccessUpdateToast, showUserErrorToast } from '../../../utils/toast';
import { observer } from 'mobx-react-lite';

type RoomsFormProps = {
  roomUuid: string | null;
  closeForm: () => void;
};

export const RoomsForm = observer(({ roomUuid, closeForm }: RoomsFormProps) => {
  const { t } = useTranslation();

  const { createRoom, getFormData, roomData, updateRoom } = useRooms();

  const currentRoom = getFormData(roomUuid);

  const buildings = useCreateSelectOptions(roomData?.buildings, 'uuid', 'name');
  const people = useCreateSelectOptions(roomData?.people, 'uuid', 'displayNameShort');

  const handleSubmit = async (
    values: RoomsFormInitialValuesType,
    formHelpers: FormikHelpers<RoomsFormInitialValuesType>,
  ) => {
    if (roomUuid) {
      const mutationResult = await updateRoom(values, roomUuid);
      if (!mutationResult || mutationResult.error) {
        showUserErrorToast({ error: mutationResult.error });
      } else {
        showSuccessUpdateToast([values.name]);
      }
    } else {
      const mutationResult = await createRoom(values);
      if (!mutationResult || mutationResult.error) {
        showUserErrorToast({ error: mutationResult.error });
      } else {
        showSuccessCreateToast([values.name]);
      }
    }

    formHelpers.resetForm();
    closeForm();
  };

  return (
    <>
      <Formik initialValues={currentRoom} onSubmit={handleSubmit} validationSchema={schema}>
        {(formik) => {
          const values = formik.values;
          return (
            <Form>
              <div className={'form-block'}>
                <div className={'form-group'}>
                  <div className={'form-col'}>
                    <div className={'form-row'}>
                      <Input
                        label={t('rooms.name')}
                        name={'name'}
                        onChange={formik.handleChange}
                        className={'half'}
                        value={values.name}
                        error={formik.errors.name}
                      />
                      <Select
                        label={t('rooms.building')}
                        name={'building'}
                        onChange={(option) => {
                          const opt = option as SingleValue<SelectOptionType>;
                          formik.setFieldTouched('building', true);
                          formik.setFieldValue(
                            'building',
                            roomData?.buildings.find((s) => opt?.value && s.uuid === opt.value),
                          );
                        }}
                        className={'half'}
                        defaultValue={{ label: values.building?.name ?? '', value: values.building?.uuid }}
                        options={buildings}
                        error={formik.errors.building}
                      />
                    </div>
                    <div className={'form-row'}>
                      <Input
                        label={t('rooms.position')}
                        name={'position'}
                        onChange={formik.handleChange}
                        className={'half'}
                        value={values.position ?? ''}
                        error={formik.errors.position}
                      />
                      <Input
                        label={t('rooms.roomNumber')}
                        name={'roomNumber'}
                        onChange={formik.handleChange}
                        className={'half'}
                        value={values.roomNumber ?? ''}
                        error={formik.errors.roomNumber}
                      />
                    </div>
                    <div className={'form-row'}>
                      <Input
                        label={t('rooms.description')}
                        name={'description'}
                        onChange={formik.handleChange}
                        className={'full'}
                        value={values.description ?? ''}
                        error={formik.errors.description}
                      />
                    </div>
                  </div>
                  <div className={'form-col'}>
                    <div className={'form-row'}>
                      <Checkbox
                        name='classroom'
                        label={t('rooms.isClassroom')}
                        onChange={(e) => {
                          formik.setFieldValue('classroom', e.target.checked);
                          formik.setFieldTouched('classroom', true);
                        }}
                        checked={values.classroom ?? false}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={'form-block'}>
                <FormBlockHeader title={t('rooms.properties')} />
                <div className={'form-group'}>
                  <div className={'form-col'}>
                    <div className={'form-row'}>
                      <Input
                        label={t('rooms.capacity')}
                        name={'capacity'}
                        type={'number'}
                        onChange={formik.handleChange}
                        className={'quarter'}
                        value={values.capacity ?? ''}
                        error={formik.errors.capacity}
                      />
                      <Input
                        label={t('rooms.chairs')}
                        name={'chairs'}
                        type={'number'}
                        onChange={formik.handleChange}
                        className={'quarter'}
                        value={values.chairs ?? ''}
                        error={formik.errors.chairs}
                      />
                      <Input
                        label={t('rooms.tables')}
                        name={'tables'}
                        type={'number'}
                        onChange={formik.handleChange}
                        className={'quarter'}
                        value={values.tables ?? ''}
                        error={formik.errors.tables}
                      />
                      <Input
                        label={t('rooms.size')}
                        name={'size'}
                        type={'number'}
                        onChange={formik.handleChange}
                        className={'quarter'}
                        value={values.size ?? ''}
                        error={formik.errors.size}
                        suffix={'m²'}
                      />
                    </div>
                    <FieldArray name={'responsiblePersons'}>
                      {() => {
                        return (
                          <>
                            {[...Array(3)].map((x, index) => (
                              <div className={'form-row'} key={index}>
                                {formik.values.responsiblePersons &&
                                formik.values.responsiblePersons.length &&
                                formik.values.responsiblePersons[index] ? (
                                  <Select
                                    isClearable={true}
                                    isSearchable={true}
                                    name={`responsiblePersons.${index}`}
                                    options={people}
                                    className={'full'}
                                    defaultValue={
                                      values.responsiblePersons[index].uuid
                                        ? {
                                            value: values.responsiblePersons[index].uuid,
                                            label: values.responsiblePersons[index].displayNameShort,
                                          }
                                        : undefined
                                    }
                                    onChange={(option) => {
                                      const opt = option as SingleValue<SelectOptionType>;
                                      formik.setFieldTouched(`responsiblePersons.${index}`, true);
                                      formik.setFieldValue(
                                        `responsiblePersons.${index}`,
                                        roomData?.people.find((s) => opt?.value && s.uuid === opt.value),
                                      );
                                    }}
                                    label={t('rooms.responsiblePersons') + ` ${index + 1}`}
                                    error={
                                      getIn(formik.touched, `responsiblePersons.${index}`) &&
                                      getIn(formik.errors, `responsiblePersons.${index}`)
                                        ? getIn(formik.errors, `responsiblePersons.${index}`)
                                        : undefined
                                    }
                                  ></Select>
                                ) : (
                                  <Select
                                    name={`responsiblePersons.${index}`}
                                    options={people}
                                    className={'full'}
                                    onChange={(option) => {
                                      const opt = option as SingleValue<SelectOptionType>;
                                      formik.setFieldTouched(`responsiblePersons.${index}`, true);
                                      formik.setFieldValue(
                                        `responsiblePersons.${index}`,
                                        roomData?.people.find((s) => opt?.value && s.uuid === opt.value),
                                      );
                                    }}
                                    label={t('rooms.responsiblePersons') + ` ${index + 1}`}
                                  ></Select>
                                )}{' '}
                              </div>
                            ))}
                          </>
                        );
                      }}
                    </FieldArray>

                    <div className={'form-row'}>
                      <Input
                        label={t('rooms.otherInventory')}
                        name={'otherInventory'}
                        onChange={formik.handleChange}
                        className={'full'}
                        value={values.otherInventory ?? ''}
                        error={formik.errors.otherInventory}
                      />
                    </div>
                  </div>
                  <div className={'form-col'}>
                    <Checkbox
                      name='canBeLeased'
                      label={t('rooms.canBeLeased')}
                      onChange={(e) => {
                        formik.setFieldValue('canBeLeased', e.target.checked);
                        formik.setFieldTouched('canBeLeased', true);
                      }}
                      checked={values.canBeLeased ?? false}
                    />
                    <Checkbox
                      name='barrierFree'
                      label={t('rooms.isBarrierFree')}
                      onChange={(e) => {
                        formik.setFieldValue('barrierFree', e.target.checked);
                        formik.setFieldTouched('barrierFree', true);
                      }}
                      checked={values.barrierFree ?? false}
                    />
                    <Checkbox
                      name='canBeDarkened'
                      label={t('rooms.canBeDarkened')}
                      onChange={(e) => {
                        formik.setFieldValue('canBeDarkened', e.target.checked);
                        formik.setFieldTouched('canBeDarkened', true);
                      }}
                      checked={values.canBeDarkened ?? false}
                    />
                    <Checkbox
                      name='hasProjector'
                      label={t('rooms.hasProjector')}
                      onChange={(e) => {
                        formik.setFieldValue('hasProjector', e.target.checked);
                        formik.setFieldTouched('hasProjector', true);
                      }}
                      checked={values.hasProjector ?? false}
                    />
                    <Checkbox
                      name='hasScreen'
                      label={t('rooms.hasScreen')}
                      onChange={(e) => {
                        formik.setFieldValue('hasScreen', e.target.checked);
                        formik.setFieldTouched('hasScreen', true);
                      }}
                      checked={values.hasScreen ?? false}
                    />
                    <Checkbox
                      name='hasMediaCabinet'
                      label={t('rooms.hasMediaCabinet')}
                      onChange={(e) => {
                        formik.setFieldValue('hasMediaCabinet', e.target.checked);
                        formik.setFieldTouched('hasMediaCabinet', true);
                      }}
                      checked={values.hasMediaCabinet ?? false}
                    />
                    <Checkbox
                      name='hasPiano'
                      label={t('rooms.hasPiano')}
                      onChange={(e) => {
                        formik.setFieldValue('hasPiano', e.target.checked);
                        formik.setFieldTouched('hasPiano', true);
                      }}
                      checked={values.hasPiano ?? false}
                    />
                  </div>
                </div>
              </div>
              <div className={'modal-bottom'}>
                <Button
                  hierarchy='tertiary'
                  type='button'
                  onClick={() => {
                    formik.resetForm();
                    closeForm();
                  }}
                  className={'buttons'}
                >
                  {t('common.cancelChanges')}
                </Button>
                <Button
                  type='submit'
                  disabled={formik.isSubmitting || !(formik.isValid && formik.dirty) || formik.isValidating}
                  className={'buttons'}
                >
                  {t('common.save')}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
});
