import { Suspense, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useClassDivisionsClassesListQuery } from '../../types/planung-graphql-client-defs';
import { Card, LazyLoader, RowSelectionState, Table, TableColumns } from '@bp/ui-components';
import { ClassDivisionsClassesType } from './graphql/types';
import { ClassDivisionsTable } from './ClassDivisionsTable';
import { useMemorizedCacheTag } from '../../hooks/useMemorizedCacheTag';
import { useUserConfigContext } from '../../hooks/useUserConfigContext';
import { observer } from 'mobx-react-lite';
import { useColumnsSort } from '../../hooks/useColumnsSort';
import { useAuthClaims } from '../../hooks/useAuthClaims';

export const ClassDivisionsClassesTable = observer(() => {
  const { pimAuthClaims } = useAuthClaims();
  const { t } = useTranslation();
  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;
  const classDivisionsQueryContext = useMemorizedCacheTag('CLASS_DIVISIONS');
  const [selected, setSelected] = useState<RowSelectionState>({ 0: true });
  const { sorting, saveSorting } = useColumnsSort('class-divisions');

  useEffect(() => {
    setSelected({ 0: true });
  }, [currentSchoolYear?.uuid]);

  const [{ data }] = useClassDivisionsClassesListQuery({
    variables: {
      where: {
        organizationConnection: {
          node: {
            uuid: pimAuthClaims.getOrganizationUuid(),
          },
        },
        schoolYear: {
          name: currentSchoolYear?.name,
        },
      },
    },
    context: classDivisionsQueryContext,
  });

  const memoizedData = useMemo((): ClassDivisionsClassesType[] => {
    return data && data ? data.classes : [];
  }, [data]);

  const key = Object.entries(selected).filter(([key, value]) => value)?.[0];
  const classUuid = key && memoizedData[+key[0]] ? memoizedData[+key[0]].uuid : memoizedData[0]?.uuid;

  function createColumns(): TableColumns<ClassDivisionsClassesType>[] {
    return [
      {
        header: t('classes.title', { count: 1 }),
        accessorKey: 'name',
        id: 'name',
        size: 300,
        canExpand: true,
      },
      {
        header: t('divisions.title', { count: 2 }),
        accessorKey: 'divisionsAggregate.count',
        id: 'divisionsAggregates',
        enableGlobalFilter: false,
        enableColumnFilter: false,
        size: 100,
      },
      {
        header: t('common.color'),
        accessorKey: 'timetableConfig',
        id: 'timetableConfig.color',
        type: 'color',
      },
    ];
  }

  const tableColumns = useMemo(createColumns, [t]);

  return (
    <div className='tks__grid'>
      <div className='tks__row'>
        <div className='tks__col col-xs-6'>
          <Table<ClassDivisionsClassesType>
            showBorderRadius
            showShadow
            canScroll
            minHeight={800}
            onRowClick={(event, row) => {
              setSelected({ [row.id]: true });
            }}
            sorting={sorting}
            onSortingChange={saveSorting}
            columns={tableColumns}
            data={memoizedData}
            showRowHover
            rowSelection={selected}
            showSelected
          />
        </div>
        <div className='tks__col col-md-6'>
          <Card contentPadding='none' style={{ paddingTop: 'var(--spacing-5)' }}>
            <Suspense fallback={<LazyLoader embedded />}>
              <ClassDivisionsTable classUuid={classUuid} />
            </Suspense>
          </Card>
        </div>
      </div>
    </div>
  );
});
