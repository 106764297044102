import { LazyLoader } from '@bp/ui-components';
import { Form, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import React, { Suspense } from 'react';
import { FormBlockHeader } from '../../Form/FormBlockHeader';
import { LessonRoomSupplyForm } from '../../Lessons/Forms/RoomSupply/LessonRoomSupplyForm';
import { ModalBottomButtons } from '../../ModalBottomButtons/ModalBottomButtons';
import { CurriculaListQuery } from '../../../types/planung-graphql-client-defs';
import { CurriculumFormType } from '../types';
import { CurriculumPersonsForm } from './CurriculumPersonsForm';
import { CombinedError } from 'urql';
import { CurriculumGradesForm } from './CurriculumGradesForm';
import { LessonBasicsForm } from '../../Lessons/Forms/Basics/LessonBasicsForm';
import { CurriculumUnitForm } from './CurriculumUnitForm';
import { schema } from './validation/schema';
import { showSuccessCreateToast, showSuccessUpdateToast, showUserErrorToast } from '../../../utils/toast';
import { useCurriculum } from '../hooks/useCurriculum';

type CurriculumFormProps = {
  closeForm: () => void;
  defaultInitialValues: CurriculumFormType;
  curriculum?: Pick<CurriculaListQuery, 'curricula'>['curricula'][number];
  versionUuid: string;
};

export const CurriculumForm = ({ closeForm, defaultInitialValues, curriculum, versionUuid }: CurriculumFormProps) => {
  const { t } = useTranslation();

  const { updateCurriculum, createCurriculum } = useCurriculum({ versionUuid, grade: undefined });

  const initialValues: CurriculumFormType = curriculum
    ? {
        isClassTrip: curriculum.isClassTrip,
        comment: curriculum.comment,
        subTitle: curriculum.subTitle,
        timetableEnabled: curriculum.timetableEnabled,
        teachingLoadEnabled: curriculum.timetableEnabled,
        elective: curriculum.elective ?? false,
        curriculumGrades:
          curriculum.curriculumGrades.map((curriculumClass) => ({
            gradeGroups: curriculumClass.gradeGroups,
            grades: curriculumClass.grades,
            groups: curriculumClass.groups,
            uuid: curriculumClass.uuid,
          })) ?? [],
        // need this name, because we use the LessonUnitForm which names the field;
        // naming it curriculumUnit breaks the form, thus creation / update
        lessonUnit: curriculum.curriculumUnit.map((cu) => ({
          uuid: cu.uuid,
          duration: cu.duration,
          count: cu.count,
          subjectContainerUuid: cu.subjectContainer?.uuid,
          factor: null,
          blocked: false,
          lessonUnitType: cu.subjectContainer?.uuid ? 'epoch' : 'subjectHour',
        })),
        subject: {
          uuid: curriculum.subject.uuid,
          name: curriculum.subject.name,
          subjectHourEnabled:
            curriculum.subject.__typename === 'Subject' ? curriculum.subject.subjectHourEnabled : true,
          epochEnabled: curriculum.subject.__typename === 'Subject' ? curriculum.subject.epochEnabled : false,
          grades: curriculum.subject.__typename === 'SubjectContainer' ? (curriculum.subject.grades ?? []) : [],
        },
        teachers: curriculum.persons?.map((person) => {
          const properties = curriculum.personsConnection?.edges.find(
            (edge) => edge.node.uuid === person.uuid,
          )?.properties;

          return {
            ...person,
            teachingLoad: !!properties?.teachingLoad,
            present: !!properties?.present,
          };
        }),
        roomSupply:
          curriculum.roomSupplyConnection &&
          curriculum.roomSupplyConnection.edges.map((edge) => ({
            uuid: edge.node.uuid,
            description: edge.properties.descriptions ?? '',
          })),
        curriculumVersionUuid: defaultInitialValues.curriculumVersionUuid,
      }
    : { ...defaultInitialValues, lessonUnit: [] };

  const handleSubmit = async (values: CurriculumFormType, formikHelpers: FormikHelpers<CurriculumFormType>) => {
    let result: { error?: CombinedError | undefined };
    if (curriculum) {
      result = await updateCurriculum({ curriculumUuid: curriculum.uuid, values });
      if (!result.error) {
        showSuccessUpdateToast();
      }
    } else {
      result = await createCurriculum(values);
      if (!result.error) {
        showSuccessCreateToast();
      }
    }
    if (result.error) {
      showUserErrorToast({ error: result.error });
    }
    formikHelpers.resetForm();
    closeForm();
  };

  return (
    <Suspense fallback={<LazyLoader transparent forceHeight='40vh' />}>
      <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema} validateOnChange>
        {(formik) => (
          <Form>
            <div className='form-block'>
              <div className={'form-group'}>
                <LessonBasicsForm status={'unused'} />
              </div>
            </div>
            <div className='form-block'>
              <FormBlockHeader title={t('classes.title', { count: 2 })} />
              <Suspense fallback={<LazyLoader embedded forceHeight='10vh' />}>
                <CurriculumGradesForm enableGroups={true} selectableGrades={[]} />
              </Suspense>
            </div>
            <div className='form-block'>
              <FormBlockHeader title={t('lessonUnit.title', { count: 1 })} />
              <Suspense fallback={<LazyLoader embedded forceHeight='10vh' />}>
                <CurriculumUnitForm />
              </Suspense>
            </div>
            <div className='form-block'>
              <FormBlockHeader title={t('persons.title', { count: 1 })} />
              <Suspense fallback={<LazyLoader embedded forceHeight='10vh' />}>
                <CurriculumPersonsForm />
              </Suspense>
            </div>
            <div className='form-block'>
              <FormBlockHeader title={t('rooms.stock')} />
              <Suspense fallback={<LazyLoader embedded forceHeight='10vh' />}>
                <LessonRoomSupplyForm disabled={false} />
              </Suspense>
            </div>
            <ModalBottomButtons
              closeButton={{
                text: t('common.cancel'),
                callback: () => {
                  formik.resetForm();
                  closeForm();
                },
              }}
              submitButton={{
                disabled: Object.keys(formik.errors).length > 0 || !formik.dirty,
              }}
              isLoading={formik.isSubmitting}
              errors={formik.errors}
            />
          </Form>
        )}
      </Formik>
    </Suspense>
  );
};
