import {
  Button,
  ButtonGroup,
  DotsHorizontalIcon,
  Dropdown,
  DropdownMenu,
  EditIcon,
  LazyLoader,
  Modal,
  Row,
  Table,
  TableColumns,
} from '@bp/ui-components';
import { useNavigate } from 'react-router-dom';
import { useAuthClaims } from '../../../hooks/useAuthClaims';
import { Suspense, useMemo, useState } from 'react';
import { useColumnsSort } from '../../../hooks/useColumnsSort';
import { useTranslation } from 'react-i18next';
import { CurriculumVersionForm, CurriculumVersionType } from '../Forms/CurriculumVersionForm';
import {
  useCurriculumVersionsListQuery,
  useDeleteCurriculumVersionMutation,
} from '../../../types/planung-graphql-client-defs';
import { useConfirm } from '../../../hooks/useConfirm';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { showSuccessDeleteToast, showUserErrorToast } from '../../../utils/toast';
import { useUserConfigContext } from '../../../hooks/useUserConfigContext';

export const CurriculumVersionsTable = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pimAuthClaims } = useAuthClaims();
  const currentSchoolYear = useUserConfigContext().selectedSchoolYear;
  const { confirm, ConfirmationDialog } = useConfirm();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [currentVersion, setCurrentVersion] = useState<CurriculumVersionType | null>(null);

  const context = useMemorizedCacheTag('CURRICULUM_VERSIONS');
  const [{ data }] = useCurriculumVersionsListQuery({
    variables: {
      where: {
        organization: { uuid: pimAuthClaims.getOrganizationUuid() },
      },
    },
    context,
  });

  const [, deleteCurriculumVersion] = useDeleteCurriculumVersionMutation();
  const handleClose = () => {
    setModalOpen(false);
    setCurrentVersion(null);
  };

  const handleAdd = () => {
    setModalOpen(true);
  };

  const handleEdit = (row: Row<CurriculumVersionType>) => {
    row && setCurrentVersion(row.original);
    setModalOpen(true);
  };
  const handleDelete = async (row: Row<CurriculumVersionType>) => {
    await confirm({
      onConfirm: async () => {
        const resp = await deleteCurriculumVersion({ where: { uuid: row.original.uuid } }, context);
        if (resp.error) {
          showUserErrorToast({ error: resp.error });
        }
        if (!resp.error) showSuccessDeleteToast();
      },
      message: t('curriculumVersion.deleteConfirm', { curriculumVersion: row.original.name }),
    });
  };

  const { sorting, saveSorting } = useColumnsSort('table-curriculum-versions');

  const tableColumns = useMemo((): TableColumns<CurriculumVersionType>[] => {
    return [
      {
        header: t('curriculumVersion.name'),
        id: 'name',
        accessorKey: 'name',
        canExpand: false,
        size: 250,
      },
      {
        header: t('curriculumVersion.description'),
        id: 'description',
        accessorKey: 'description',
        canExpand: true,
      },
    ];
  }, []);

  return (
    <>
      <Table<CurriculumVersionType>
        showBorderRadius
        showShadow
        canScroll
        minHeight={1000}
        isOnWhite={false}
        onAddClick={handleAdd}
        printerSettings={{
          headline: pimAuthClaims.getProfile()?.organization.name,
          subline: `${t('curriculumVersion.title', { count: 2 })} - ${t('common.schoolYear')} ${currentSchoolYear?.shortName}`,
          filename: `${t('curriculumVersion.title', { count: 2 })}_${currentSchoolYear?.shortName}`,
        }}
        showActionBar
        showSort={true}
        sorting={sorting}
        onSortingChange={saveSorting}
        actionBarSettings={{
          showExpertFilter: true,
          showAddButton: true,
          showPrintButton: true,
          showBulkEdit: true,
        }}
        columns={tableColumns}
        data={data?.curriculumVersions.map((c) => ({ ...c, used: c.curricula.length > 0 })) ?? []}
        lastColWidth='80px'
        lastCol={(row) => {
          return (
            <ButtonGroup className='ml-4'>
              <Button hierarchy='secondary' onClick={() => navigate(row.original.uuid ?? '')}>
                {t('curriculum.title', { count: 2 })}
              </Button>
              <Button
                hierarchy={'secondary'}
                onClick={() => {
                  handleEdit(row);
                }}
                icon={<EditIcon className='small' />}
              />
              <Dropdown
                noPadding
                trigger={<Button hierarchy='secondary' icon={<DotsHorizontalIcon className='small' />} />}
              >
                <DropdownMenu
                  data={[
                    {
                      label: t('common.delete') as string,
                      type: 'default',
                      color: 'error',
                      disabled: row.original.used ?? false,
                      onClick: async () => {
                        !(row.original.used ?? false) && (await handleDelete(row));
                      },
                    },
                  ]}
                />
              </Dropdown>
            </ButtonGroup>
          );
        }}
      />

      <Modal isOpen={modalOpen} onRequestClose={handleClose} title={t('curriculumVersion.add')}>
        {modalOpen && (
          <Suspense fallback={<LazyLoader />}>
            <CurriculumVersionForm closeForm={handleClose} currentVersion={currentVersion} />
          </Suspense>
        )}
      </Modal>
      <ConfirmationDialog />
    </>
  );
};
