import { HolidayType } from '../graphql';
import { Grid, GridColumn, GridRow, Input } from '@bp/ui-components';
import dayjs from 'dayjs';
import { Form, Formik, FormikHelpers } from 'formik';
import { t } from 'i18next';
import { useMutation } from 'urql';
import { CreateHolidaysDocument, UpdateHolidaysDocument } from '../../../types/planung-graphql-client-defs';
import { useMemorizedCacheTag } from '../../../hooks/useMemorizedCacheTag';
import { holidaysSchema } from './validation/schema';
import { SchoolYearType } from '../../SchoolYear/graphql/types';
import { DatePickerHolidaysField } from './DatePickerHolidaysField';
import { dateTimeToUTCDateString } from '../../../utils/helper';
import { ModalBottomButtons } from '../../ModalBottomButtons/ModalBottomButtons';

type HolidaysFormProps = {
  holiday: HolidayType | null;
  schoolYear?: SchoolYearType | null;
  handleClose: () => void;
};

export const HolidaysForm = ({ holiday, handleClose }: HolidaysFormProps) => {
  const initialValue: HolidayType = {
    uuid: holiday?.uuid ?? '',
    name: holiday?.name ?? '',
    start: holiday?.start ? dayjs(holiday?.start).toDate() : dayjs().toDate(),
    end: holiday?.end ? dayjs(holiday?.end).toDate() : dayjs().add(1, 'days').toDate(),
  };
  const context = useMemorizedCacheTag('SCHOOL_YEAR');

  const [, update] = useMutation(UpdateHolidaysDocument);
  const [, create] = useMutation(CreateHolidaysDocument);

  // TODO: update/create needs testing
  const handleSubmit = async (values: HolidayType, formHelpers: FormikHelpers<HolidayType>) => {
    if (values.uuid) {
      await update(
        {
          where: { uuid: holiday?.uuid },
          update: {
            name: values.name,
            start: dateTimeToUTCDateString(values.start),
            end: dateTimeToUTCDateString(values.end),
          },
        },
        context,
      );
    } else {
      await create(
        {
          input: {
            start: dateTimeToUTCDateString(values.start),
            end: dateTimeToUTCDateString(values.end),
            name: values.name,
          },
        },
        context,
      );
    }
    formHelpers.resetForm();
    handleClose();
  };

  return (
    <>
      <Formik initialValues={initialValue} onSubmit={handleSubmit} validationSchema={holidaysSchema}>
        {({ resetForm, values, touched, handleChange, errors, isSubmitting, dirty, isValidating }) => {
          return (
            <Form>
              <Grid useFormGap>
                <GridRow spacingBottom='s'>
                  <Input
                    label={t('common.name')}
                    name='name'
                    onChange={handleChange}
                    value={values.name}
                    {...(errors.name &&
                      touched.name && {
                        error: errors.name,
                      })}
                  />
                </GridRow>
                <GridRow spacingTop='s'>
                  <GridColumn width={6}>
                    <DatePickerHolidaysField label={t('holidays.start')} fieldName='start' updateEnd={!holiday} />
                  </GridColumn>
                  <GridColumn width={6}>
                    <DatePickerHolidaysField label={t('holidays.end')} fieldName='end' updateEnd={!holiday} />
                  </GridColumn>
                </GridRow>
              </Grid>

              <ModalBottomButtons
                closeButton={{
                  text: t('common.cancelChanges'),
                  callback: () => {
                    resetForm();
                    handleClose();
                  },
                }}
                submitButton={{
                  disabled: isSubmitting || !dirty || isValidating,
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
